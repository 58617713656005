import { getModule, Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { store } from '@/store';
import { DisplayPreferencesApi } from '@/api/profile/display-preferences.api';
import { CarRule, DisplayPreferencesCar } from '@/api/profile/display-preferences-car.model';
import DisplayPreferencesStore from '@/modules/settings/display-preferences/display-preferences.store';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'displayPreferencesCar'
})
class DisplayPreferencesCarStore extends VuexModule {
  displayPreferencesCar: DisplayPreferencesCar = new DisplayPreferencesCar();
  carRule: CarRule = new CarRule();

  get displayPreferencesCarPayload() {
    const { withCompliantTravelPolicy, displayPreferences } = this.displayPreferencesCar;
    return {
      withCompliantTravelPolicy,
      displayPreferences: displayPreferences.map(({ $index, ...rule }) => rule),
    };
  }

  @Mutation
  setDisplayPreferencesCar(payload?: DisplayPreferencesCar) {
    this.displayPreferencesCar = new DisplayPreferencesCar(payload);
  }

  @Mutation
  setCarRule(payload?: Partial<CarRule>) {
    this.carRule = new CarRule(payload);
  }

  @Action
  async saveCarRulesConfiguration({ configurationId, rootCompanyId }: { configurationId: string, rootCompanyId: string }) {
    try {
      DisplayPreferencesStore.setLoading(true);
      await DisplayPreferencesApi.saveCarConfiguration(configurationId, {
        ...this.displayPreferencesCarPayload,
        rootCompanyId,
        name: DisplayPreferencesStore.currentConfigName,
      });
      await this.getRules(configurationId);
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        DisplayPreferencesStore.setCustomError(true);
      } else {
        DisplayPreferencesStore.setErrors(error);
      }
    } finally {
      DisplayPreferencesStore.setLoading(false);
    }
  }

  @Action
  async updateCarRulesConfiguration(configurationId: string) {
    try {
      DisplayPreferencesStore.setLoading(true);
      await DisplayPreferencesApi.updateCarConfiguration(configurationId, {
        ...this.displayPreferencesCarPayload,
        name: DisplayPreferencesStore.currentConfigName,
      });
      await this.getRules(configurationId);
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        DisplayPreferencesStore.setCustomError(true);
      } else {
        DisplayPreferencesStore.setErrors(error);
      }
    } finally {
      DisplayPreferencesStore.setLoading(false);
    }
  }

  @Action
  async getRules(configurationId: string) {
    try {
      DisplayPreferencesStore.setLoading(true);
      DisplayPreferencesStore.clearErrors();
      const result = await DisplayPreferencesApi.getCarConfiguration(configurationId);
      this.setDisplayPreferencesCar(result.data);
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        if (error.response.data.error.code !== 'RESOURCE_NOT_FOUND') {
          DisplayPreferencesStore.setCustomError(true);
        }
      } else {
        DisplayPreferencesStore.setErrors(error);
      }
    } finally {
      DisplayPreferencesStore.setLoading(false);
    }
  }

  @Action
  async getCarRulesConfiguration(configurationId?: string) {
    if (configurationId && this.displayPreferencesCar.displayPreferences.length === 0) {
      await this.getRules(configurationId);
    }
  }
}

export default getModule(DisplayPreferencesCarStore);


































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

import { userFullName } from '@/core/user-full-name';
import { Recommendation, Train, Proposal } from '@/api/train-engine/train-search.model';
import TrainFareConditions from './TrainFareConditions.vue';
import { VueContentLoading } from 'vue-content-loading';
import { sanitizeUrl } from '@braintree/sanitize-url';
import AccountStore from '@/store/account.store';
import formatPrice from '@/filters/format-price.filter';

@Component({
  components: {
    VueContentLoading,
    TrainFareConditions,
  },
  filters: {
    moment(date) {
      return moment(date).format('hh:mm');
    },
    momentTime(date) {
      return moment(date).format('HH:mm');
    },
    momentDate2(date) {
      return moment(date).format('Do MMM');
    },
    timeFormat(date) {
      return moment(date).format('ddd Do MMM HH:mm');
    },
    dateFormat(date) {
      return moment(date).format('ddd Do MMM');
    },
    timeConvert(n) {
      let num = n;
      let hours = (num / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes) as any;
      if (rminutes && rminutes < 10) {
        rminutes = '0' + rminutes;
      }
      return rhours + 'h' + rminutes + 'm';
    },
  }
})

export default class TrainTimelineDetailsBasket extends Vue {
  @Prop() travellers!: any[];
  @Prop() uniqTrain!: Train;
  @Prop() proposal!: Proposal;
  @Prop() trains!: Train[];
  @Prop() offer!: Recommendation;
  @Prop() index!: number;
  @Prop({ default: false }) isShortView!: boolean;
  @Prop({ default: false }) isInSearch!: boolean;
  @Prop({ default: false }) isInPopup!: boolean;
  @Prop({ default: false }) legNumber!: number;

  showFullConditionsPopup: boolean = false;

  get trainInJourney() {
    return this.trains.filter(train => {
      return train.type === 'Train';
    });
  }

  get searchMode() {
    return -1 < [
      'basket',
      'basketAddSegment',
      'basketTravellers',
    ].indexOf(this.$route.name || '') && this.offer.basketItemStatus !== 'Confirmed'
      && this.offer.basketItemStatus !== 'Cancelled' && this.offer.basketItemStatus !== 'Held'
      && this.offer.basketItemStatus !== 'PendingApproval';
  }

  get user() {
    return AccountStore.current;
  }

  get legTrains() {
    return this.trains.map(train => {
      if ((train.selectedSeats && train.selectedSeats.length) || (train.selectedAncillaries && train.selectedAncillaries.length)) {
        return {
          ...train,
          travellers: this.travellers
            .map(traveller => {
              return {
                ...traveller,
                seat: train.selectedSeats && train.selectedSeats.length ? train.selectedSeats.find(seat => traveller.id === seat.profileId) : null,
                ancillaries: train.selectedAncillaries && train.selectedAncillaries.length ? train.selectedAncillaries.filter(ancillary => traveller.id === ancillary.profileId) : [],
              };
            })
            .filter(traveller => traveller.seat || traveller.ancillaries.length),
        };
      } else {
        return {
          ...train,
        };
      }
    });
  }



  userFullName(user) {
    return userFullName(user);
  }

  formatPrice(price) {
    return formatPrice(price);
  }

  momentDate(date) {
    if (date && this.user && (this.user.profile.displayLanguage === 'it' || this.user.profile.displayLanguage === 'pl')) {
      return moment(date).format('dddd, D MMM');
    } else {
      return moment(date).format('dddd, Do MMM');
    }
  }

  generalTravellerUrl(traveller) {
    return sanitizeUrl('/profile/' + traveller.id);
  }

  showFullConditions() {
    this.showFullConditionsPopup = true;
  }

  getFareConditions(train) {
    return this.offer.proposals.forEach(proposal => {
      if (proposal.segmentKey === train.segmentKey) {
        return proposal.fares[0].shortFareCondidtion;
      }
      return [];
    });
  }

  isAnotherDepartureDay(train) {
    if (train) {
      let startOfDay = moment(this.uniqTrain.departure).startOf('day');
      let endOfDay = moment(train.departure).endOf('day');

      let diff = endOfDay.diff(startOfDay, 'days');
      if (diff > 0) {
        return diff;
      }
      return 0;
    }
    return 0;
  }

  passenger(profileId) {
    return this.travellers.find(item => item.id === profileId) || {};
  }

  isAnotherArrivalDay(train) {
    if (train) {
      let startOfDay = moment(this.uniqTrain.departure).startOf('day');
      let endOfDay = moment(train.arrival).endOf('day');

      let diff = endOfDay.diff(startOfDay, 'days');
      if (diff > 0) {
        return diff;
      }
      return 0;
    }
    return 0;
  }

  getRemarks(remark) {
    return remark.filter(r => r.image !== null);
  }

  flagClasses(train) {
    return {
      'flag__public': 'Public' === train.selectedProposal.isPublic,
      'flag__nego': 'Nego' === train.selectedProposal.isNego,
    };
  }
}

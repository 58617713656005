






























































































































import { Vue, Component } from 'vue-property-decorator';
import { VueContentLoading } from 'vue-content-loading';
import _ from 'lodash';
import AccountStore from '@/store/account.store';
import HotelSearchStore from './hotel-search.store';
import { router } from '@/router';
import layoutStore from '@/modules/layout/layout.store';

@Component({
  components: {
    VueContentLoading,
  }
})
export default class HotelSort extends Vue {
  imagesConst = '/assets/img/loader/1.gif';
  imagesErrorInfoConst = '/assets/img/ic_error.png';
  toggleSortOptions: boolean = false;
  isProviderErrorsVisible: boolean = false;

  get loading() {
    return HotelSearchStore.loading;
  }

  get isDisabled() {
    return HotelSearchStore.selectingOffer;
  }

  get sortOptions() {
    return HotelSearchStore.sortOptions;
  }

  get offers() {
    return HotelSearchStore.offers;
  }  

  get priceSorterEnabled() {
     return this.sortOptions!.PRICE !== undefined;
  }

  get priceSorterStatus() {
    return this.sortOptions!.PRICE === 'Ascending';
  }

  get distanceSorterEnabled() {
    return this.sortOptions!.DISTANCE !== undefined;
  }

  get distanceSorterStatus() {
    return this.sortOptions!.DISTANCE === 'Ascending';
  }
  
  get currentSortType() {
    if (this.priceSorterEnabled) {
      return 'sort-by-cheapest-offer';
    }
    return 'sort-by-shortest-distance';
  }

  get offerVisible() {
    return HotelSearchStore.offersVisible;
  }

  get displayedOffers() {
    return Math.min(this.offerVisible, this.totalOffers);
  }

  get totalOffers() {
    return HotelSearchStore.totalOffers;
  }

  get recommendationsHotelCount() {
    return HotelSearchStore.recommendationsHotelCount;
  }

  get isMapViewMode() {
    return HotelSearchStore.isMapView;
  }

  get providersErrors() {
    return HotelSearchStore.providersErrors;
  }

  get groupedProvidersErrors() {
    return _.groupBy(HotelSearchStore.providersErrors, 'supplier');
  }

  get canSeeProviderWarnings() {
    return AccountStore.HasPermission('CanSeeProviderWarnings');
  }


  toggleSort(sorterCode) {
    if (this.isDisabled) {
      return;
    }
    if (this.sortOptions[sorterCode] === undefined) {
      HotelSearchStore.setNewSort({ sorterCode, isAscending: true });

      HotelSearchStore.setSearchFreezed(true);
      HotelSearchStore.getOffers(this.$route.params.searchId);
    }
    this.toggleSortOptions = false;
  }

  toggleSortDropdown() {
    if (this.isDisabled) {
      return;
    }
    this.toggleSortOptions = !this.toggleSortOptions;
  }

  gotoMap() {
    layoutStore.slots.background.meta.height = 700;
    router.push({
      name: 'hotelMap',
      params: this.$route.params
    });
  }

  showProviderErrors() {
    this.isProviderErrorsVisible = true;
  }

  hideProviderErrors() {
    this.isProviderErrorsVisible = false;
  }
}

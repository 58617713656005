











































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import BasketStore from '@/modules/basket/basket.store';
import { BasketItemApi } from '@/api/trip/basket-item.api';
import { TEventDetail, TSncfSeatPreferencesModel } from '@/modules/basket/basket-rail-seats-extras/basket-rail-seats-extras.model';
import { Traveller } from '@/api/trip/basket.model';
import priceConst from '@/const/price.const';
import consts from '@/const/rail-seat-map.const';
import AccountStore from '@/store/account.store';

@Component({})
export default class SeatMapSncfPopup extends Vue {
  @Prop() show!: boolean;
  @Prop() value!: TSncfSeatPreferencesModel[];
  @Prop() segmentId!: string;
  @Prop() supplier!: string;

  isProviderError: boolean = false;
  seatMapVisible: boolean = false;
  seatMapLoading: boolean = true;
  seatMapErrors: any[] = [];

  get primaryColor() {
    return AccountStore.current!.profile.theme.primaryColor || consts.sncf.primaryColor;
  }

  get secondaryColor() {
    return consts.sncf.secondaryColor;
  }

  get ternaryColor() {
    return consts.sncf.ternaryColor;
  }

  get quaternaryColor() {
    return consts.sncf.quaternaryColor;
  }

  get travellers() {
    return (BasketStore.basket && BasketStore.basket.travellers) || [];
  }

  @Emit('update:show')
  closePopup() {
    this.seatMapVisible = false;
    this.seatMapLoading = true;
    return false;
  }

  seatSelected({ detail }: TEventDetail) {
    const currentSeats = this.travellers.reduce((prev, next) => {
      const seatRequirement = BasketStore.railTravellersSeatRequirements.find(({ id, isVirtual }) => id === (isVirtual ? next.virtualTravellerId : next.id));
      if (seatRequirement && !seatRequirement.needsSeat) {
        return prev;
      }
      return prev.concat(next);
    }, [] as Traveller[]);
    const seatMapPopupSelections = detail.placings.map((item, index) => ({
      coachNumber: `${detail.coach}`,
      passengerRank: index + 1,
      seatNumber: `${item.seatNumber}`,
      segmentId: this.segmentId,
      price: {
        amount: item.extraPrice || priceConst.priceModel.amount,
        currency: priceConst.priceModel.currency,
      },
      traveller: currentSeats[index],
    }));
    const model = this.value
      .filter(({ segmentId }) => segmentId !== this.segmentId)
      .concat({
        segmentId: this.segmentId,
        seatMapPreferences: {
          assignments: seatMapPopupSelections.map(({ segmentId, price, traveller, ...assignments }) => assignments),
        },
      });
    this.$emit('update-seat-map-popup-selections', seatMapPopupSelections);
    this.$emit('input', model);
    this.closePopup();
  }

  showSeatMap(data) {
    this.seatMapVisible = true;
    setTimeout(() => {
      const element = this.$refs.seatmap as any;
      element.primaryColor = this.primaryColor;
      element.secondaryColor = this.secondaryColor;
      element.ternaryColor = this.ternaryColor;
      element.quaternaryColor = this.quaternaryColor;
      element.numberOfPassengers = BasketStore.railTravellersSeatRequirements.filter((req) => req.needsSeat === true).length;
      element.data = data;
    });
  }

  async openPopup() {
    this.isProviderError = false;
    this.seatMapErrors = [];
    this.seatMapLoading = true;
    const offerId = BasketStore.bookingStepTripItem!.providerReferenceId || '';
    try {
      await BasketStore.getRailTravellersSeatRequirements({ offerId, railSupplier: this.supplier });
      const response = await BasketItemApi.getRailSncfSeatMap(offerId, this.segmentId, this.supplier);
      this.showSeatMap(response.data);
    } catch (error) {
      if (
        error.response && error.response.data &&
        error.response.data.error &&
        error.response.data.error.code === 'PROVIDER_EXECUTION_FAILURE' &&
        error.response.data.error.details.length &&
        error.response.data.error.details[0].params
      ) {
        this.isProviderError = true;
        this.seatMapErrors = error.response.data.error.details
          .filter(detail => !!detail.params)
          .map(detail => {
            return {
              message: [
                detail.params.MESSAGE,
                ' (',
                detail.code,
                ')'
              ].join(''),
            };
          });
      } else {
        this.seatMapErrors = this.$handleErrors(error, true);
      }
    } finally {
      this.seatMapLoading = false;
    }
  }

  @Watch('show', { immediate: true })
  onShow(isShow: boolean) {
    if (isShow) {
      this.openPopup();
    }
  }
}

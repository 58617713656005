













































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { maxLength, required, requiredIf } from 'vuelidate/lib/validators';
import BillingCustomFieldsStore from './billing-custom-fields.store';
import { BillingConfigurationModeEnum } from '@/api/billing/billing.model';
import { translate } from '@/i18n';
import { BillingApi } from '@/api/billing/billing.api';

const urlRegex = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&/=]*)/.test(value);
};

@Component({})
export default class BillingCustomFieldsConfiguration extends Vue {
  $v;
  loading: boolean = false;
  formPending: boolean = false;
  Form: any = {
    configurationId: '',
    name: '',
    tripCustomFields: [],
    travellerCustomFields: [],
  };
  imagesConst: string = '/assets/img/loader/1.gif';
  errors: any[] = [];

  tripCustomFieldsOptions = [
    {
      label: 'BookerName',
      value: 'BookerName',
      tooltip: translate('settings-back-office-trip-custom-fields-tooltips.booker-name-info'),
    },
    {
      label: 'InitialBookingType',
      value: 'InitialBookingType',
      tooltip: translate('settings-back-office-trip-custom-fields-tooltips.initial-booking-type-info'),
    },
    {
      label: 'InvoiceRecipientCode',
      value: 'InvoiceRecipientCode',
      tooltip: translate('settings-back-office-trip-custom-fields-tooltips.invoice-recipient-code-info'),
    },
    {
      label: 'Seller',
      value: 'Seller',
      tooltip: translate('settings-back-office-trip-custom-fields-tooltips.seller-info'),
    },
    {
      label: 'SellerType',
      value: 'SellerType',
      tooltip: translate('settings-back-office-trip-custom-fields-tooltips.seller-type-info'),
    },
    {
      label: 'TripCode',
      value: 'TripCode',
      tooltip: translate('settings-back-office-trip-custom-fields-tooltips.trip-code-info'),
    },
  ];
  travellerCustomFieldsOptions = [
    {
      label: 'AirlineCarrierType',
      value: 'AirlineCarrierType',
      tooltip: translate('settings-back-office-traveller-custom-fields-tooltips.airline-carrier-type-info'),
    },
    {
      label: 'BookingCode',
      value: 'BookingCode',
      tooltip: translate('settings-back-office-traveller-custom-fields-tooltips.booking-code-info'),
    },
    {
      label: 'BookingType',
      value: 'BookingType',
      tooltip: translate('settings-back-office-traveller-custom-fields-tooltips.booking-type-info'),
    },
    {
      label: 'CardToken',
      value: 'CardToken',
      tooltip: translate('settings-back-office-traveller-custom-fields-tooltips.card-token-info'),
    },
    {
      label: 'DistributionChannel',
      value: 'DistributionChannel',
      tooltip: translate('settings-back-office-traveller-custom-fields-tooltips.distribution-channel-info'),
    },
    {
      label: 'FilteredProviderName',
      value: 'FilteredProviderName',
      tooltip: translate('settings-back-office-traveller-custom-fields-tooltips.filtered-provider-name-info'),
    },
    {
      label: 'InvoicingChannel',
      value: 'InvoicingChannel',
      tooltip: translate('settings-back-office-traveller-custom-fields-tooltips.invoicing-channel-info'),
    },
    {
      label: 'InvoicingChannelB2B',
      value: 'InvoicingChannelB2B',
      tooltip: translate('settings-back-office-traveller-custom-fields-tooltips.invoicing-channel-b2b-info'),
    },
    {
      label: 'PrePaid',
      value: 'PrePaid',
      tooltip: translate('settings-back-office-traveller-custom-fields-tooltips.prepaid-info'),
    },
    {
      label: 'ProviderReference',
      value: 'ProviderReference',
      tooltip: translate('settings-back-office-traveller-custom-fields-tooltips.provider-reference-info'),
    },
    {
      label: 'TravellerInvoicingCode',
      value: 'TravellerInvoicingCode',
      tooltip: translate('settings-back-office-traveller-custom-fields-tooltips.traveller-invoicing-code-info'),
    },
    {
      label: 'TravellerPrimaryEmail',
      value: 'TravellerPrimaryEmail',
      tooltip: translate('settings-back-office-traveller-custom-fields-tooltips.traveller-primary-email-info'),
    },
    {
      label: 'TravellerPrimaryPhoneNumber',
      value: 'TravellerPrimaryPhoneNumber',
      tooltip: translate('settings-back-office-traveller-custom-fields-tooltips.traveller-primary-phone-number-info'),
    },
  ];

  @Validation()
  validationObject() {
    const cantBeSameTripFields = (value, model) => {
      const entryCheck = entry => entry.code === model.code;
      const firstIndex = this.Form.tripCustomFields.findIndex(entryCheck);
      const lastIndex = this.Form.tripCustomFields.findLastIndex(entryCheck);

      return firstIndex === lastIndex;
    };
    const cantBeSameTravellerFields = (value, model) => {
      const entryCheck = entry => entry.code === model.code;
      const firstIndex = this.Form.travellerCustomFields.findIndex(entryCheck);
      const lastIndex = this.Form.travellerCustomFields.findLastIndex(entryCheck);

      return firstIndex === lastIndex;
    };
    return {
      Form: {
        name: {
          required,
          maxLength: maxLength(64),
        },
        tripCustomFields: {
          $each: {
            code: {
              required,
              cantBeSameTripFields,
            },
            parameterName: {
              required,
            },
          },
        },
        travellerCustomFields: {
          $each: {
            code: {
              required,
              cantBeSameTravellerFields,
            },
            parameterName: {
              required,
            },
          },
        },
      }
    };
  }

  get inEditMode() {
    return !!(this.$route.params.configurationId);
  }

  get errMessages() {
    return BillingCustomFieldsStore.errMessages;
  }

  get showErrMessages() {
    return BillingCustomFieldsStore.showError;
  }

  get isModeBackOffice() {
    return this.Form.mode ? this.Form.mode.value === BillingConfigurationModeEnum.BackOffice : false;
  }

  get isModeGestour() {
    return this.Form.mode ? this.Form.mode.value === BillingConfigurationModeEnum.Gestour : false;
  }

  get formLoading() {
    return BillingCustomFieldsStore.loading;
  }

  get canShowCustomError() {
    return BillingCustomFieldsStore.canShowCustomError;
  }

  goToConfigurationList() {
    this.$router.push({
      name: 'billing-custom-fields-configurations',
      params: {
        id: this.$route.params.id
      }
    });
  }

  resetStoreErrors() {
    BillingCustomFieldsStore.setErrMessages([]);
    BillingCustomFieldsStore.setShowError(false);
  }

  tripCustomFieldsValue(index) {
    return this.tripCustomFieldsOptions
      .find(item => item.value === this.Form.tripCustomFields[index].parameterName);
  }

  setTripCustomFieldsValue(index, value) {
    this.Form.tripCustomFields[index].parameterName = value.value;
  }

  addTripCustomField() {
    this.Form.tripCustomFields.push({
      code: '',
      parameterName: '',
    });
    if (this.$v.$dirty) {
      this.$v.$touch();
    }
  }

  removeTripCustomField(index) {
    this.Form.tripCustomFields.splice(index, 1);
  }

  travellerCustomFieldsValue(index) {
    return this.travellerCustomFieldsOptions
      .find(item => item.value === this.Form.travellerCustomFields[index].parameterName);
  }

  setTravellerCustomFieldsValue(index, value) {
    this.Form.travellerCustomFields[index].parameterName = value.value;
  }

  addTravellerCustomField() {
    this.Form.travellerCustomFields.push({
      code: '',
      parameterName: '',
    });
    if (this.$v.$dirty) {
      this.$v.$touch();
    }
  }

  removeTravellerCustomField(index) {
    this.Form.travellerCustomFields.splice(index, 1);
  }

  travellerItemHasTooltip(index) {
    const fieldValue = this.travellerCustomFieldsValue(index);

    return !!fieldValue && !!fieldValue.tooltip;
  }

  travellerItemTooltip(index) {
    const el = this.travellerCustomFieldsValue(index);
    if (!el) {
      return '';
    }

    return el.tooltip;
  }

  tripItemHasTooltip(index) {
    const fieldValue = this.tripCustomFieldsValue(index);

    return !!fieldValue && !!fieldValue.tooltip;
  }

  tripItemTooltip(index) {
    const el = this.tripCustomFieldsValue(index);
    if (!el) {
      return '';
    }

    return el.tooltip;
  }

  async saveConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.resetStoreErrors();
      let request = this.mapFormDataForRequest(false);
      await BillingCustomFieldsStore.addCustomFieldsConfiguration(request);
      if (!(this.errMessages && this.errMessages.length)) {
        this.goToConfigurationList();
      }
    }
  }

  async updateConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.resetStoreErrors();
      let request = this.mapFormDataForRequest(true);
      await BillingCustomFieldsStore.updateCustomFieldsConfiguration(request);
      if (!(this.errMessages && this.errMessages.length)) {
        this.goToConfigurationList();
      }
    }
  }

  backofficeConfiguration() {
    return this.Form.notificationEnabled ? {
      ...this.Form.backofficeConfiguration,
      notificationMode: this.Form.backofficeConfiguration.notificationMode ? this.Form.backofficeConfiguration.notificationMode.value : 'disabled',
      billingAccountId: this.Form.backofficeConfiguration.billingAccountId,
    } : {
      notificationMode: 'disabled',
      url: null,
      billingAccountId: this.Form.backofficeConfiguration.billingAccountId,
    };
  }

  mapFormDataForRequest(edit: boolean = false) {
    let request;
    if (edit) {
      request = {
        id: this.$route.params.configurationId,
        configuration: {
          name: this.Form.name,
          rootCompanyId: this.$route.params.id,
          tripCustomFields: this.Form.tripCustomFields,
          travellerCustomFields: this.Form.travellerCustomFields,
        },
      };
      return request;
    }
    request = {
      name: this.Form.name,
      rootCompanyId: this.$route.params.id,
      tripCustomFields: this.Form.tripCustomFields,
      travellerCustomFields: this.Form.travellerCustomFields,
    };
    
    return request;
  }

  mapFormData(data?: any) {
    if (data) {
      this.Form = {
        ...data,
      };
    } else {
      this.Form = {
        configurationId: '',
        name: '',
        tripCustomFields: [],
        travellerCustomFields: [],
      };
    }
  }

  async created() {
    BillingCustomFieldsStore.setShowError(false);
    if (this.inEditMode) {
      try {
        this.loading = true;
        const result = await BillingApi.getCustomFieldsConfiguration(this.$route.params.configurationId);
        if (result && result.data) {
          this.mapFormData(result.data);
        }
      } catch (error) {
        this.errors = this.$handleErrors(error);
      } finally {
        this.loading = false;
      }
    } else {
      this.mapFormData();
    }
  }
}

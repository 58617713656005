






















































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  TrainProviderConfiguration
} from '@/api/train-engine/train-provider-configuration.model';
import { SupplierName } from '@/services/supplier-name.service';

@Component({})
export default class ProviderConfigurationDetailSimple extends Vue {
  @Prop() providerConfiguration!: TrainProviderConfiguration;
  @Prop() v!: Object;
  @Prop() providerIndex!: number;
  @Prop({ default: true }) inViewMode!: boolean;
  @Prop({ default: false }) hasSaveError!: boolean;
  serverErrors = [];

  getSupplierName(name) {
    return SupplierName(name);
  }
}







































































































































































































import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, decimal, integer, minValue, maxValue } from 'vuelidate/lib/validators';

import consts from '@/const/numbers.const';
import { CustomFieldsProfileApi } from '@/api/custom-fields/custom-fields-profile.api';
import { UpdateCustomFieldMethodType } from '@/api/custom-fields/custom-fields-profile.model';
import EventBus from '@/services/event-handler';

@Component({})
export default class EditCustomFieldValue extends Vue {
  @Prop() field!: any;
  @Prop({ default: UpdateCustomFieldMethodType.Default }) updateCustomFieldMethodType?: UpdateCustomFieldMethodType;

  isLoading: boolean = false;
  hasErrors: boolean = false;
  errors: any[] = [];
  $v;

  get maxInt32Value() {
    return consts.integer.max;
  }

  get minInt32Value() {
    return consts.integer.min;
  }

  get fieldType() {
    if (!this.field) {
      return '';
    }

    return this.field.customField.typeName;
  }

  get dictionaryOptions() {
    if (!this.field) {
      return [];
    }

    const defaultValue: {
      id: string | null,
      name: string | null,
      code: string | null,
    } = {
      id: null,
      name: null,
      code: null,
    };
    return [defaultValue].concat(this.field.customField.customFieldDictionaryItems);
  }

  get dictionaryOptionsSorted() {
    return this.dictionaryOptions
      .sort((a, b) => {
        if (!a.code) {
          return -1;
        }
        if (!b.code) {
          return 1;
        }
        return a.code.localeCompare(b.code);
      });
  }

  get fieldValue() {
    return this.dictionaryOptions.find(item => item.id === this.field.profileDictionaryItemId);
  }

  set fieldValue(val) {
    this.field.profileDictionaryItemId = val ? val.id : null;
    this.field.profileValue = val ? val.code : null;
  }

  @Validation()
  validationObject() {
    const ifRegex = (value, vm) => {
      const fieldType = vm.customField.typeName;
      const fieldDef = vm.customField;

      if (
        fieldType &&
        fieldDef.validationRegExpRule !== '' &&
        (-1 === ['Dictionary', 'Bool'].indexOf(fieldType))
      ) {
        const newValidationRegExpRule = new RegExp(fieldDef.validationRegExpRule);

        return ( newValidationRegExpRule.test(value) );
      }

      return true;
    };
    let profileValue: any = {
      ifRegex,
    };

    if (this.field.mandatory) {
      profileValue.required = required;
    }

    if (this.field && this.field.customField) {
      if ('Double' === this.field.customField.typeName) {
        profileValue.decimal = decimal;
      } else if ('Integer' === this.field.customField.typeName) {
        profileValue.integer = integer;
        profileValue.maxValue = maxValue(this.maxInt32Value);
        profileValue.minValue = minValue(this.minInt32Value);
      }
    }
    return {
      field: {
        profileValue,
      },
    };
  }

  focusOnSaveButton() {
    setTimeout(() => {
      if (!this.$refs.saveButton) {
        return;
      }
      ((this.$refs.saveButton as Vue).$el as HTMLElement).focus();
    }, 100);
  }

  @Emit()
  close() {

  }

  resetValue() {
    this.field.profileValue = this.field.defaultValue;
    this.field.profileDictionaryItemId = this.field.defaultDictionaryItemId;
  }

  checkForm() {
    this.hasErrors = false;
    this.$v.field.$touch();
    if (this.$v.field.$pending || this.$v.field.$error) {
      this.hasErrors = true;
      return;
    }
  }
  

  async updateField() {
    switch (this.updateCustomFieldMethodType) {
      case UpdateCustomFieldMethodType.ArrangerCustomFields:
        return await CustomFieldsProfileApi
          .updateProfileArrangerCustomField(this.$route.params.id, {
            travelCustomFieldAssignmentId: this.field.id,
            value: this.field.profileValue,
            customFieldDictionaryItemId: this.field.profileDictionaryItemId,
          });
      case UpdateCustomFieldMethodType.ApproverCustomFields:
        return await CustomFieldsProfileApi
          .updateProfileApproverCustomField(this.$route.params.id, {
            travelCustomFieldAssignmentId: this.field.id,
            value: this.field.profileValue,
            customFieldDictionaryItemId: this.field.profileDictionaryItemId,
          });
      case UpdateCustomFieldMethodType.Default:
        return await CustomFieldsProfileApi
          .updateProfileCustomField(this.$route.params.id, {
            travelCustomFieldAssignmentId: this.field.id,
            value: this.field.profileValue,
            customFieldDictionaryItemId: this.field.profileDictionaryItemId,
          });
      default:
        throw new Error('missing update method for ' + JSON.stringify(this.updateCustomFieldMethodType));
    }
  }

  async submit() {
    this.checkForm();
    if (this.hasErrors) {
      return;
    }

    this.isLoading = false;

    try {
      const result = await this.updateField();
      
      this.$emit('reload');
      EventBus.$emit('profile-data-saved');
      this.close();
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.isLoading = false;
    }
  }
}

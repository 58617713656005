























































































































import { Vue, Component } from 'vue-property-decorator';
import SettingsStore from '@/modules/settings/settings.store';
import { router } from '@/router';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import NegoFaresStore from '@/modules/settings/nego-fare/nego-fares.store';
import NegoFaresFlightStore from '@/modules/settings/nego-fare/nego-fares-flight.store';
import NegoFaresTrainStore from '@/modules/settings/nego-fare/nego-fares-train.store';
import NegoFaresAccommodationStore from '@/modules/settings/nego-fare/nego-fares-accommodation.store';
import {
  AirNegoFareConfigurationSupplierEnum,
  TrainNegoFareConfigurationSupplierEnum,
} from '@/api/nego-fares/nego-fares.model';
import NegoFareCodesAir from './NegoFareCodesAir.vue';
import NegoFareCodesRail from './NegoFareCodesRail.vue';
import NegoFareCodesAccommodation from './NegoFareCodesAccommodation.vue';

@Component({
  components: {
    NegoFareCodesAir,
    NegoFareCodesRail,
    NegoFareCodesAccommodation,
  }
})
export default class NegoFareConfiguration extends Vue {
  $v;
  errors: any[] = [];
  navLinks = [
    {
      value: 'Flight',
      name: translate('settings-travel-policy.flight'),
    },
    {
      value: 'Train',
      name: translate('settings-travel-policy.rail'),
    },
    {
      value: 'Accommodation',
      name: translate('settings-travel-policy.accomodation'),
    },
  ];
  saving: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';

  @Validation()
  validationObject() {
    return {
      currentConfigurationName: {
        required,
        maxLength: maxLength(128)
      }
    };
  }
  
  get canReadNotWrite() {
    return this.$hasAccess('ReadNegoFare') && !this.$hasAccess('WriteNegoFare');
  }

  get links() {
    return this.navLinks;
  }

  get loading() {
    return NegoFaresStore.loading;
  }

  get configurationId() {
    return this.$router.currentRoute.params.configurationId || '';
  }

  get storeConfigurationId() {
    return NegoFaresStore.configurationId;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get flightNegoFareConfig() {
    return NegoFaresFlightStore.currentConfiguration.negoFaresConfigurations;
  }

  get trainNegoFareConfig() {
    return NegoFaresTrainStore.currentConfiguration.negoFaresConfigurations;
  }

  get accommodationNegoFareConfig() {
    return NegoFaresAccommodationStore.currentConfiguration.negoFaresConfigurations;
  }

  get corporateCodes() {
    return NegoFaresFlightStore.CorporateCodes;
  }

  get trainCorporateCodes() {
    return NegoFaresTrainStore.CorporateCodes;
  }

  get accommodationCorporateCodes() {
    return NegoFaresAccommodationStore.CorporateCodes;
  }

  get currentConfiguration() {
    return NegoFaresStore.configuration;
  }

  get currentConfigurationName() {
    return NegoFaresStore.configurationName;
  }

  get configIsEdit() {
    return !!(this.$route.params.configurationId && this.$route.params.configurationId !== 'new');
  }

  set currentConfigurationName(value) {
    NegoFaresStore.setCurrentConfigurationName(value);
  }

  get selectedTab() {
    return NegoFaresStore.selectedTab;
  }

  get isFromManage() {
    return NegoFaresStore.isFromManage;
  }

  get errMessages() {
    return NegoFaresStore.getErrMessages;
  }

  get canShowCustomError() {
    return NegoFaresStore.canShowCustomError;
  }



  changeSelectedTab(value) {
    NegoFaresStore.setSelectedTab(value);
  }

  backToConfigurationsList() {
    router.push({
      name: 'nego-fare-configurations',
      params: {
        id: this.$router.currentRoute.params.id
      }
    });
  }

  deleteUnwantedProperties(collection: any[]) {
    collection.forEach(item => {
      delete item.status;
      delete item.supplier;
      delete item.id;
    });
  }

  prepareNewFlightConfigs() {
    const sabreCodes = this.corporateCodes.filter(code => {
      return code.supplier === AirNegoFareConfigurationSupplierEnum.Sabre;
    });
    const lufthansaCodes = this.corporateCodes.filter(code => {
      return code.supplier === AirNegoFareConfigurationSupplierEnum.Lufthansa;
    });
    const afklmCodes = this.corporateCodes.filter(code => {
      return code.supplier === AirNegoFareConfigurationSupplierEnum.AirFranceKlm;
    });
    const amadeusCodes = this.corporateCodes.filter(code => {
      return code.supplier === AirNegoFareConfigurationSupplierEnum.Amadeus;
    });
    const americanAirlinesCodes = this.corporateCodes.filter(code => {
      return code.supplier === AirNegoFareConfigurationSupplierEnum.AmericanAirlines;
    });
    const britishCodes = this.corporateCodes.filter(code => {
      return code.supplier === AirNegoFareConfigurationSupplierEnum.BritishAirways;
    });
    const emiratesCodes = this.corporateCodes.filter(code => {
      return code.supplier === AirNegoFareConfigurationSupplierEnum.Emirates;
    });

    this.deleteUnwantedProperties([...sabreCodes, ...lufthansaCodes, ...afklmCodes, ...amadeusCodes, ...americanAirlinesCodes, ...britishCodes, ...emiratesCodes]);

    let flightConfigs = [
      {
        supplier: AirNegoFareConfigurationSupplierEnum.Sabre,
        corporateCodes: sabreCodes
      },
      {
        supplier: AirNegoFareConfigurationSupplierEnum.Lufthansa,
        corporateCodes: lufthansaCodes
      },
      {
        supplier: AirNegoFareConfigurationSupplierEnum.AirFranceKlm,
        corporateCodes: afklmCodes
      },
      {
        supplier: AirNegoFareConfigurationSupplierEnum.Amadeus,
        corporateCodes: amadeusCodes
      },
      {
        supplier: AirNegoFareConfigurationSupplierEnum.AmericanAirlines,
        corporateCodes: americanAirlinesCodes
      },
      {
        supplier: AirNegoFareConfigurationSupplierEnum.BritishAirways,
        corporateCodes: britishCodes
      },
      {
        supplier: AirNegoFareConfigurationSupplierEnum.Emirates,
        corporateCodes: emiratesCodes
      }
    ];
    return {
      rootCompanyId: router.currentRoute.params.id,
      name: this.currentConfigurationName,
      negoFaresConfigurations: flightConfigs
    };
  }

  prepareExistingFlightConfigs() {
    const flightConfigs = JSON.parse(JSON.stringify(this.flightNegoFareConfig));
    flightConfigs.forEach(config => {
      delete config.error;
      delete config.succeeded;

      config.corporateCodes = this.corporateCodes.filter(code => {
        return code.supplier === config.supplier;
      });
      this.deleteUnwantedProperties(config.corporateCodes);
    });

    return flightConfigs;
  }

  prepareNewTrainConfigs() {
    const trenitaliaCodes = this.trainCorporateCodes.filter(code => {
      return code.supplier === TrainNegoFareConfigurationSupplierEnum.Trenitalia;
    });
    const sncfCodes = this.trainCorporateCodes.filter(code => {
      return code.supplier === TrainNegoFareConfigurationSupplierEnum.Sncf;
    });
    const ouigoCodes = this.trainCorporateCodes.filter(code => {
      return code.supplier === TrainNegoFareConfigurationSupplierEnum.Ouigo;
    });
    const ntvCodes = this.trainCorporateCodes.filter(code => {
      return code.supplier === TrainNegoFareConfigurationSupplierEnum.Ntv;
    });
    const benerailCodes = this.trainCorporateCodes.filter(code => {
      return code.supplier === TrainNegoFareConfigurationSupplierEnum.Benerail;
    });

    this.deleteUnwantedProperties([...trenitaliaCodes, ...sncfCodes, ...ouigoCodes, ...ntvCodes, ...benerailCodes]);

    let trainConfigs = [
      {
        supplier: TrainNegoFareConfigurationSupplierEnum.Trenitalia,
        corporateCodes: trenitaliaCodes
      },
      {
        supplier: TrainNegoFareConfigurationSupplierEnum.Sncf,
        corporateCodes: sncfCodes
      },
      {
        supplier: TrainNegoFareConfigurationSupplierEnum.Ouigo,
        corporateCodes: ouigoCodes
      },
      {
        supplier: TrainNegoFareConfigurationSupplierEnum.Ntv,
        corporateCodes: ntvCodes,
      },
      {
        supplier: TrainNegoFareConfigurationSupplierEnum.Benerail,
        corporateCodes: benerailCodes,
      },
    ];
    return {
      rootCompanyId: router.currentRoute.params.id,
      name: this.currentConfigurationName,
      negoFaresConfigurations: trainConfigs
    };
  }

  prepareExistingTrainConfigs() {
    const trainConfigs = JSON.parse(JSON.stringify(this.trainNegoFareConfig));
    trainConfigs.forEach(config => {
      delete config.error;
      delete config.succeeded;

      config.corporateCodes = this.trainCorporateCodes.filter(code => {
        return code.supplier === config.supplier;
      });

      this.deleteUnwantedProperties(config.corporateCodes);
    });

    return trainConfigs;
  }

  prepareNewAccommodationConfigs() {
    const sabreCodes = this.accommodationCorporateCodes.filter(code => {
      return code.supplier === AirNegoFareConfigurationSupplierEnum.Sabre;
    });
    
    this.deleteUnwantedProperties([...sabreCodes]);

    let accommodationConfigs = [
      {
        supplier: AirNegoFareConfigurationSupplierEnum.Sabre,
        corporateCodes: sabreCodes
      }
    ];
    return {
      rootCompanyId: router.currentRoute.params.id,
      name: this.currentConfigurationName,
      negoFaresConfigurations: accommodationConfigs
    };
  }

  prepareExistingAccommodationConfigs() {
    const accommodationConfigs = JSON.parse(JSON.stringify(this.accommodationNegoFareConfig));
    accommodationConfigs.forEach(config => {
      delete config.error;
      delete config.succeeded;

      config.corporateCodes = this.accommodationCorporateCodes.filter(code => {
        return code.supplier === config.supplier;
      });

      this.deleteUnwantedProperties(config.corporateCodes);
    });

    return accommodationConfigs;
  }

  showSaveSuccessMessage() {
    const obj = {
      type: translate('settings-nego-fare.success'),
      title: translate('common.saved'),
      message: translate('settings-nego-fare.configuration-saved')
    };
    EventHandler.$emit('show-toast', obj);
  }

  async saveNegoFareConfiguration() {
    if (this.saving) {
      return;
    }
    this.$v.currentConfigurationName.$touch();
    if (this.$v.$error) {
      return;
    }
    this.saving = true;
    if (!this.storeConfigurationId) {
      try {
        await NegoFaresStore.createNegoFareConfiguration(this.prepareNewFlightConfigs());
        NegoFaresStore.setCurrentConfigurationName(this.currentConfigurationName);
        await NegoFaresTrainStore.updateNegoFareConfiguration({
          configurationId: this.storeConfigurationId,
          name: this.currentConfigurationName,
          negoFaresConfigurations: this.prepareNewTrainConfigs().negoFaresConfigurations,
        });
        await NegoFaresAccommodationStore.updateNegoFareConfiguration({
          configurationId: this.storeConfigurationId,
          name: this.currentConfigurationName,
          negoFaresConfigurations: this.prepareNewAccommodationConfigs().negoFaresConfigurations,
        });
        await NegoFaresFlightStore.getAirNegoFareConfiguration(this.storeConfigurationId);
        await NegoFaresTrainStore.getRailNegoFareConfiguration(this.storeConfigurationId);
        await NegoFaresAccommodationStore.getAccommodationNegoFareConfiguration(this.storeConfigurationId);
        router.push({
          name: 'nego-fare-configuration',
          params: {
            id: this.$route.params.id,
            configurationId: this.storeConfigurationId
          }
        });
        this.showSaveSuccessMessage();
      } catch (error) {

      }

    } else {
      const flightConfigs = this.prepareExistingFlightConfigs();
      const trainConfigs = this.prepareExistingTrainConfigs();
      const accommodationConfigs = this.prepareExistingAccommodationConfigs();
      try {
        await NegoFaresFlightStore.updateNegoFareConfiguration({
          configurationId: this.storeConfigurationId,
          name: this.currentConfigurationName,
          negoFaresConfigurations: flightConfigs
        });
        await NegoFaresTrainStore.updateNegoFareConfiguration({
          configurationId: this.storeConfigurationId,
          name: this.currentConfigurationName,
          negoFaresConfigurations: trainConfigs,
        });
        await NegoFaresAccommodationStore.updateNegoFareConfiguration({
          configurationId: this.storeConfigurationId,
          name: this.currentConfigurationName,
          negoFaresConfigurations: accommodationConfigs,
        });
        this.showSaveSuccessMessage();
      } catch (error) {

      }
    }
    this.saving = false;
  }

  async created() {
    NegoFaresStore.setCustomError(false);
    NegoFaresStore.clearErrMessages();
    NegoFaresFlightStore.clearErrMessages();
    NegoFaresTrainStore.clearErrMessages();
    NegoFaresAccommodationStore.clearErrMessages();
    if (this.configurationId && !this.isFromManage) {
      NegoFaresStore.setLoading(true);
      await Promise.all([
        NegoFaresFlightStore.getAirNegoFareConfiguration(this.configurationId),
        NegoFaresTrainStore.getRailNegoFareConfiguration(this.configurationId),
        NegoFaresAccommodationStore.getAccommodationNegoFareConfiguration(this.configurationId),
      ]);
      NegoFaresStore.setLoading(false);
    }
  }
}
















































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { requiredIf } from 'vuelidate/lib/validators';
import cloneDeep from 'lodash/cloneDeep';
import { TravellersStateParams } from '@/modules/search/travellers.params';
import SearchStore from '@/modules/search/search.store';

type TSelectedTraveller = {
  age: number | null;
  childAge: number | null;
  isMainTraveller: boolean;
  isVirtual: boolean;
  passengerTypeCode: string;
};

const MAX_LIMIT = 10;

@Component({})
export default class UiGuestTravellersSelect extends Vue {
  @Prop() options!: any;
  @Prop() disabled!: boolean;
  @Prop() ageStrict!: boolean;

  show = false;
  selectedTravellers: TSelectedTraveller[] = [];
  $v;

  @Validation()
  validationObject() {
    return {
      selectedTravellers: {
        $each: {
          age: {
            required: requiredIf(() => this.ageStrict),
          }
        },
      },
    };
  }

  get editedTravellersState() {
    return SearchStore.editedTravellersState;
  }

  get isMaxLimit() {
    return this.selectedTravellers.length >= MAX_LIMIT;
  }

  get isEmptySelectedTravellers() {
    return this.selectedTravellers.length === 0;
  }

  getSelectedTravellersByCode(code: string) {
    return this.selectedTravellers.filter((item) => item.passengerTypeCode === code);
  }

  getCodesLength(code: string) {
    const codes = this.getSelectedTravellersByCode(code);
    return codes.length;
  }

  getAgeOptions([start, end]: [number, number]) {
    return Array.from({ length: end - start + 1 }, (item, index) => start + index);
  }

  addGuestTraveller(code: string) {
    if (this.selectedTravellers.length < MAX_LIMIT) {
      this.selectedTravellers = this.selectedTravellers.concat({
        age: null,
        childAge: null,
        isMainTraveller: false,
        isVirtual: true,
        passengerTypeCode: code,
      });
    }
  }

  removeGuestTraveller(code: string) {
    if (this.selectedTravellers.length > 0) {
      const lastIndex = this.selectedTravellers.map((item) => item.passengerTypeCode).lastIndexOf(code);
      if (lastIndex !== -1) {
        this.selectedTravellers = [...this.selectedTravellers.slice(0, lastIndex), ...this.selectedTravellers.slice(lastIndex + 1)];
      }
    }
  }

  setMainTraveller() {
    const isMainTraveller = this.selectedTravellers.find((item) => item.isMainTraveller);
    if (!isMainTraveller) {
      const adultIndex = this.selectedTravellers.findIndex((item) => item.passengerTypeCode === 'ADT');
      const mainTravellerIndex = adultIndex !== -1 ? adultIndex : 0;
      this.selectedTravellers = this.selectedTravellers.map((item, index) => {
        if (index === mainTravellerIndex) {
          return { ...item, isMainTraveller: true };
        }
        return item;
      });
    }
  }

  init() {
    this.selectedTravellers = cloneDeep(this.editedTravellersState.travellers);
    if (this.selectedTravellers.length === 0) {
      this.options.forEach((option) => {
        if (option.value > 0) {
          [...Array(option.value)].forEach(() => this.addGuestTraveller(option.code));
        }
      });
      this.setMainTraveller();
      SearchStore.updateEditedTravellers(new TravellersStateParams({
        ...this.editedTravellersState,
        travellers: this.selectedTravellers,
      }));
    }
  }

  emitChanges() {
    this.setMainTraveller();
    this.$emit('change', new TravellersStateParams({
      ...this.editedTravellersState,
      travellers: this.selectedTravellers,
    }));
  }

  open() {
    this.init();
    this.show = true;
  }

  close() {
    if (!this.$v.$invalid && !this.isEmptySelectedTravellers) {
      this.emitChanges();
      this.show = false;
    }
  }

  onPlusMinusInput(value: number, code: string) {
    const codesLength = this.getCodesLength(code);
    const length = Math.abs(value - codesLength);
    Array
      .from({ length })
      .forEach(() => {
        if (value > codesLength) {
          this.addGuestTraveller(code);
        } else {
          this.removeGuestTraveller(code);
        }
      });
  }

  onOkClick() {
    this.$v.$touch();
    this.close();
  }

  onBackClick() {
    this.selectedTravellers = cloneDeep(this.editedTravellersState.travellers);
    this.show = false;
  }

  onOutsideClick(e) {
    if (window.innerWidth > 799 && this.show && !this.$el.contains(e.target)) {
      this.close();
      this.selectedTravellers = cloneDeep(this.editedTravellersState.travellers);
      this.show = false;
    }
  }

  created() {
    this.init();
    window.addEventListener('click', this.onOutsideClick);
  }

  beforeDestroy() {
    window.removeEventListener('click', this.onOutsideClick);
  }
}



















































































































































import { Vue, Component } from 'vue-property-decorator';
import AccountStore from '@/store/account.store';
import BasketStore from './basket.store';

@Component
export default class BasketPrice extends Vue {
  showingDetails: boolean = false;

  get status() {
    return BasketStore.status ? BasketStore.status.status : null;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get basketItems() {
    return BasketStore.basketItems;
  }

  get totalPrice() {
    return {
      ...BasketStore.totalPrice,
      currency: {
        symbol: BasketStore.totalPrice.symbol,
      },
    };
  }

  get totalSeats() {
    return BasketStore.totalSeatsPrice;
  }

  get totalAncillaries() {
    return BasketStore.totalAncillariesPrice;
  }

  get totalExtras() {
    return BasketStore.totalExtrasPrice;
  }

  get totalTax() {
    return {
      ...BasketStore.totalTax,
      currency: {
        symbol: BasketStore.totalTax.symbol,
      },
    };
  }

  get totalFees() {
    return {
      ...BasketStore.totalFees,
      currency: {
        symbol: BasketStore.totalFees.symbol,
      },
    };
  }

  get totalAgencyMarkup() {
    return {
      ...BasketStore.totalAgencyMarkup,
      currency: {
        symbol: BasketStore.totalAgencyMarkup.symbol,
      },
    };
  }

  get totalSystemMarkup() {
    return {
      ...BasketStore.totalSystemMarkup,
      currency: {
        symbol: BasketStore.totalSystemMarkup.symbol,
      },
    };
  }

  get shouldShowFees() {
    return this.basketItems.some(item => {
      return item.price.agencyFee !== undefined && item.price.agencyFee !==  null;
    });
  }

  get shouldShowSystemMarkup() {
    return this.basketItems.some(item => {
      return item.price.systemMarkup !== undefined && item.price.systemMarkup !==  null;
    });
  }

  get shouldShowAgencyMarkup() {
    return this.basketItems.some(item => {
      return item.price.agencyMarkup !== undefined && item.price.agencyMarkup !==  null;
    });
  }

  get hasEditFeePermission() {
    return this.$hasAccess('CanEditBookingFee');
  }

  get hasAdvancedOfferInfoPermission() {
    return this.$hasAccess('CanReadAdvancedOfferInfo');
  }

  get user() {
    return AccountStore.current;
  }
  get priceNameDisplay() {
    if (this.user!.profile.displayLanguage === 'pl') {
      return true;
    }
    return false;
  }

  showPriceDetails() {
    this.showingDetails = true;
  }

}


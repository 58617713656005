import {
  AxiosResponse
} from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { UpdateRailSncfAncillariesPreference } from './train-offers.model';

class TrainOffersApiClass {
  public updateRailAncillariesPreferences(offerId: string, railSupplier: string, params: UpdateRailSncfAncillariesPreference[]): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/offers/{offerId}/ancillaries?railSupplier={railSupplier}',
      method: RequestMethod.PUT,
    }, { offerId, railSupplier }, params, true);
  }

  public getSelectedRailAncillariesOffers(offerId: string, railSupplier: string): Promise<AxiosResponse<any[]>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/offers/{offerId}/ancillaries?railSupplier={railSupplier}',
      method: RequestMethod.GET,
    }, { offerId, railSupplier }, {});
  }

  public getVoucherCodesDetails(offerId: string, fareId: string, railSupplier: string): Promise<AxiosResponse<any[]>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/offers/{offerId}/fares/{fareId}/discount-codes-info?supplier={railSupplier}',
      method: RequestMethod.GET,
    }, { offerId, fareId, railSupplier }, {});
  }

  public getVoucherCodesOffersBasket(offerId: string, railSupplier: string): Promise<AxiosResponse<any[]>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/offers/{offerId}/discount-codes-info?supplier={railSupplier}',
      method: RequestMethod.GET,
    }, { offerId, railSupplier }, {});
  }

  public getVoucherCodesOrdersBasket(offerId: string, railSupplier: string): Promise<AxiosResponse<any[]>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/orders/{offerId}/discount-codes-info?supplier={railSupplier}',
      method: RequestMethod.GET,
    }, { offerId, railSupplier }, {});
  }

  public getTravellersSeatRequirements(offerId: string, railSupplier: string): Promise<AxiosResponse<any[]>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/offers/{offerId}/travellers-seat-requirements?supplier={railSupplier}',
      method: RequestMethod.GET
    }, {
      offerId, railSupplier
    }, {});
  }
}

export const TrainOffersApi: TrainOffersApiClass = new TrainOffersApiClass();
































































































































import { Vue, Component, Watch } from 'vue-property-decorator';

import { router } from '@/router';
import EventBus from '@/services/event-handler';
import filtersConst from '@/const/filter.const';
import SearchConst from '@/const/search.const';
import { Debounce } from '@/core/decorators/debounce.decorator';
import SearchStore from '@/modules/search/search.store';
import FiltersContentLoading from '../controls/FiltersContentLoading.vue';
import RangeFilter from '../controls/RangeFilter.vue';
import RangeCo2EmissionFilter from '../controls/RangeCo2EmissionFilter.vue';
import RangeTimeSlider from '../controls/RangeTimeFilter.vue';
import CategoriesFilter from '../controls/CategoriesFilter.vue';
import AirSearchStore from './air-search.store';
import { translate } from '@/i18n';
import price from '@/const/price.const';

@Component({
  components: {
    CategoriesFilter,
    RangeFilter,
    RangeCo2EmissionFilter,
    FiltersContentLoading,
    RangeTimeSlider
  }
})
export default class AirResultsFilters extends Vue {

  get searchCompleted() {
    return AirSearchStore.searchCompleted;
  }

  get filtersMetadata() {
    return {
      rangeFilters: filtersConst.air.filtersdata.rangeFilters.map((item) => {
        return {
          ...item,
          label: translate(item.label),
          type: item.type,
        };
      }),
      categoriesFilters: filtersConst.air.filtersdata.categoriesFilters
        .filter(item => {
          return item.code !== 'WITHOUT_AIRPORT_CHANGE' || this.roundTrip;
        })
        .map((item) => {
          return {
            ...item,
            label: translate(item.label)
          };
        }),
      hashFilters: filtersConst.air.filtersdata.hashFilters
    };
  }

  get roundTrip() {
    return this.searchModeStatus === 'RoundTrip';
  }

  get filters() {
    return AirSearchStore.filters;
  }

  get offers() {
    return AirSearchStore.offers;
  }

  get loadingFilter() {
    return AirSearchStore.loading;
  }

  get averageCo2Emission() {
    return AirSearchStore.averageCo2Emission;
  }

  get searchModeStatus() {
    return SearchStore.airSearchParameters.searchMode;
  }

  get currency() {
    return AirSearchStore.currency || price.priceModel.currency;
  }

  get globalClass() {
    return {
      'md-hidden-mobile': !this.$route.meta.mobile,
      'filters-loading': this.isDisabled,
      'filters-container': this.$route.meta.mobile,
    };
  }

  get isDisabled() {
    return AirSearchStore.isSelected;
  }

  get isFreezed() {
    return AirSearchStore.searchFreezed;
  }

  get hasFiltersError() {
    return AirSearchStore.filtersError;
  }

  get fareUnavailableCode() {
    return AirSearchStore.fareUnavailableErrorParams;
  }

  get rangeFilters() {
    return this.filtersMetadata.rangeFilters.map((m) => {
      if (m.code === 'OUTBOUND_DEPART_TIME' || m.code === 'INBOUND_DEPART_TIME' || this.legRangeFilter(m)) {
        const filterData = this.getFilterData(m.code);
        const filterValue = this.getFilterTimeData(m.code);

        if (m.translate && filterValue) {
          filterValue.forEach(f => {
            if (f.name) {
              f.name = translate('search-air-filters.' + f.code);
            }
          });
        }

        return {
          code: m.code,
          label: m.label,
          type: m.type,
          data: filterData,
          values: filterValue,
        };
      } else {
        const filterData = this.getFilterData(m.code);
        return {
          code: m.code,
          label: m.label,
          type: m.type,
          data: filterData
        };
      }
    });
  }

  get topCategoriesFilters() {
    return this.categoriesFilters
      .filter(filter => !!filter.stickTop);
  }

  get bottomCategoriesFilters() {
    return this.categoriesFilters
      .filter(filter => !!filter.stickBottom);
  }

  get regularCategoriesFilters() {
    return this.categoriesFilters
      .filter(filter => !filter.stickTop && !filter.stickBottom);
  }

  get rangeCo2EmissionFilter() {
    return this.rangeFilters
      .find((filter: any) => filter.type === 'rangeCo2');
  }

  get rangeCo2EmissionFilterVisible() {
    return this.rangeCo2EmissionFilter 
      && this.rangeCo2EmissionFilter.data.maxLimit > this.rangeCo2EmissionFilter.data.minLimit;
  }

  get regularRangeFilters() {
    return this.rangeFilters
      .filter(filter => filter.type !== 'rangeCo2');
  }

  get categoriesFilters() {
    return this.filtersMetadata.categoriesFilters.map((category) => {
      const data = {
        type: category.type,
        code: category.code,
        label: category.label,
        maxCategoriesInCollapsedMode: category.maxCategoriesInCollapsedMode,
        data: this.getFilterData(category.code),
        stickTop: !!category.stickTop,
        stickBottom: !!category.stickBottom
      };

      if (category && category.itemLabels) {
        data.data.forEach(d => {
          d.name = category.itemLabels![d.code] ? translate(category.itemLabels![d.code]) : d.name;
        });
      }

      return data;
    });
  }

  get hashFilters() {
    return this.filtersMetadata.hashFilters.map(m => {
      const data = {
        code: m.code,
        data: this.getFilterData(m.code)
      };

      return data;
    });
  }

  get filtersChanging() {
    return AirSearchStore.filtersChanging;
  }

  get isModify() {
    return (-1 < [
      'airModification',
      'airModificationFilters',
      'airModificationTravellers'
    ].indexOf(this.$route.name || '')) || AirSearchStore.modifyMode;
  }



  legRangeFilter(m) {
    return -1 !== [...SearchConst.legNumberData].indexOf(m.code);
  }

  legFilterExistInFilters(filterName) {
    return !!this.filters.find(filter => filter.code === filterName);
  }

  filterVisible(filterMetadata) {
    const isVisible = filterMetadata.isVisible;
    const roundtripVisible = filterMetadata.code === 'INBOUND_DEPART_TIME' && this.searchModeStatus === 'OneWay';
    const multilegTripVisible = this.legFilterExistInFilters(filterMetadata.code);

    const supplierFilterInvisible = filterMetadata.code === 'SUPPLIER' && !this.$hasAccess('CanSeeProviderName');

    if (isVisible !== undefined && roundtripVisible) {
      return isVisible(this);
    } else if (roundtripVisible || supplierFilterInvisible || !multilegTripVisible) {
      return false;
    } else {
      return true;
    }
  }

  getFilterData(filterCode) {
    const filter = this.filters.find(f => f.code === filterCode);
    return filter ? filter.values : null;
  }

  getFilterTimeData(filterCode) {
    const filter = this.filters.find(f => f.code === filterCode);
    return filter ? filter.data : null;
  }

  convertRangeFilter(filter) {
    const minEnabled = filter.data && filter.data.minValue > filter.data.minLimit;
    const maxEnabled = filter.data && filter.data.maxValue < filter.data.maxLimit;

    return {
      code: filter.code,
      min: minEnabled ? filter.data.minValue : null,
      max: maxEnabled ? filter.data.maxValue : null,
    };
  }

  convertCategoryFilter(filter) {
    return {
      code: filter.code,
      requiredCategories: filter.data ? filter.data.filter(v => v.selected).map(v => v.code) : [],
    };
  }

  convertHashFilter(filter) {
    return {
      code: filter.code,
      hashes: filter.data != null ? filter.data : [],
    };
  }

  buildFiltersDataPayload() {
    return {
      numericRangeFilters: this.rangeFilters.map(f => this.convertRangeFilter(f)),
      categoriesFilters: this.categoriesFilters.map(f => this.convertCategoryFilter(f)),
      hashFilters: this.hashFilters.map(f => this.convertHashFilter(f))
    };
  }

  refreshFiltersComponents(refs) {
    if (!refs) {
      return;
    }
    (refs as any[]).forEach(element => {
      EventBus.$emit('refresh-filter-component', {
        code: element.code,
        data: this.getFilterData(element.code),
      });
    });
  }

  callFiltersChanged() {
    const searchId = this.$route.params.searchId;

    AirSearchStore.setFiltersRequest({
      filtersData: this.buildFiltersDataPayload(),
      searchId,
    });
    AirSearchStore.setFiltersChanging(true);
    this.filtersChanged();
  }

  @Debounce({
    delay: 300,
  })
  filtersChanged() {
    this.filterOffersMethod();
  }

  async filterOffersMethod() {
    const requestId = AirSearchStore.filtersRequestId + 1;
    try {
      const searchId = this.$route.params.searchId;
      if (!searchId) {
        AirSearchStore.setFiltersChanging(false);
        return;
      }

      await AirSearchStore.updateFilterOffers(AirSearchStore.filtersRequest);
      AirSearchStore.setFiltersChanging(false);
      if (!this.hasFiltersError && requestId === AirSearchStore.filtersRequestId) {
        if (this.isModify) {
          AirSearchStore.getExchangeOffers(searchId);
        } else {
          AirSearchStore.getOffers(searchId);
        }
      }
      EventBus.$emit('hash-refresh');
    } catch (error) {
      AirSearchStore.setErrMessages({ error, customParams: this.fareUnavailableCode, addMessageToError: true });
    }
  }

  updateHashFilter() {
    this.callFiltersChanged();
  }

  categoriesFilterUpdated(value) {
    AirSearchStore.clearOpenOffers();
    AirSearchStore.setFilterLoadingView(true);
    AirSearchStore.loader(true);
    AirSearchStore.updateOffers([]);
    const filter: any = this.filters.find((f: any) => f.code === value.code);
    filter.values = value.data;
    this.callFiltersChanged();
  }

  rangeFilterUpdated(value) {
    AirSearchStore.clearOpenOffers();
    AirSearchStore.setFilterLoadingView(true);
    AirSearchStore.loader(true);
    AirSearchStore.updateOffers([]);
    const filter: any = this.filters.find((f: any) => f.code === value.code);
    filter.values.minValue = value.data.min;
    filter.values.maxValue = value.data.max;

    this.callFiltersChanged();
  }

  showResults() {
    router.push({
      name: this.$route.params.basketId ? 'airModification' : 'air',
    });
  }

  @Watch('filters')
  onChangeFilters() {
    const refs = [
      this.$refs.topCategoriesFilters,
      this.$refs.bottomCategoriesFilters,
      this.$refs.categoriesFilters,
      this.$refs.rangeTimeFilters,
      this.$refs.rangeFilters,
      this.$refs.rangeCo2EmissionFilter ? [this.$refs.rangeCo2EmissionFilter] : undefined
    ];
    refs.forEach(refList => this.refreshFiltersComponents(refList));
  }

  async created() {
    EventBus.$on('update-hashfilter', this.updateHashFilter);

    if (this.$route.meta.mobile) {
      let searchId = this.$route.params.searchId;

      if (searchId === AirSearchStore.searchId) {
        return;
      }
      AirSearchStore.clear();

      if (searchId) {
        if (!this.isModify) {
          await AirSearchStore.getSearchSession(searchId);
          AirSearchStore.getOffers(searchId);
        }
      }
    }
  }

  beforeDestroy() {
    EventBus.$off('update-hashfilter', this.updateHashFilter);
  }

}

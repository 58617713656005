




















































































































































import { Component, Prop } from 'vue-property-decorator';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import SettingsStore from '@/modules/settings/settings.store';
import ExpensePolicyStore from './expense-policy.store';
import ExpensePerDiemStore  from './expense-per-diem.store';
import { PerDiemRatesResult } from '@/api/expense//expense-per-diem.model';
import { ExpensePerDiemRatesApi } from '@/api/expense/expense-per-diem.api';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import $handleErrors from '@/core/errors/handle-errors.service';


@Component({})
export default class LengthOfStayPerDiemRules extends BaseTable<PerDiemRatesResult> {
  @Prop({}) readonly!: boolean;

  params: BaseTableParams = new BaseTableParams({
    sortBy: 'name',
  });
  imagesConst: string = '/assets/img/loader/1.gif';
  selected: any = null;
  menuActive: boolean = false;
  removePopup: boolean = false;
  errorsOnPopup: any[] = [];
  fields = {
    name: {
      sortable: false,
      label: translate('settings-expense.name'),
    },
    minHours: {
      sortable: false,
      label: translate('settings-expense.min-hours'),
    },
    maxHours: {
      sortable: false,
      label: translate('settings-expense.max-hours'),
    },
    reduction: {
      sortable: false,
      label: translate('settings-expense.reduction'),
    },
    reductionFixed: {
      sortable: false,
      label: translate('settings-expense.reduction-fixed'),
    },
    active: {
      sortable: false,
      label: translate('settings-expense.active'),
    },
    actions: {
      sortable: false,
      label: ''
    }
  };

  get missionData() {
    return ExpensePolicyStore.expenseWorkflowSetting;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configuration() {
    return ExpensePolicyStore.configuration;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get lengthOfStayPerDiemRules() {
    return ExpensePerDiemStore.lengthOfStayPerDiemRules;
  }

  get count() {
    return this.lengthOfStayPerDiemRules.length || 0;
  }

  get policyId() {
    return router.currentRoute.params.itemId;
  }

  get loadingData() {
    return ExpensePerDiemStore.loading;
  }

  toggleMenu(item) {
    if (item !== this.selected) {
      this.selected = item;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.removePopup = false;
  }

  async removeField() {
    this.errorsOnPopup = [];

    try {
      ExpensePerDiemStore.setLoading(true);
      await ExpensePerDiemRatesApi.removeLengthOfStayPerDiemRule(this.selected.id);
      const obj = {
        type: translate('common.success'),
        title: translate('settings-expense.rule-removed'),
        message: translate('settings-expense.info-rule-removed')
      };

      EventHandler.$emit('show-toast', obj);
    } catch (error) {
      this.errorsOnPopup = $handleErrors(error, true);
    } finally {
      if (!this.errorsOnPopup.length) {
        ExpensePerDiemStore.getLengthOfStayPerDiemRules(this.policyId);
        this.removePopup = false;
      }
      ExpensePerDiemStore.setLoading(false);
    }
  }

  edit(item) {
    router.push({
      name: 'length-stay-rule',
      params: {
        id: this.currentCompany!.rootCompanyId,
        itemId: this.$route.params.itemId,
        configurationId: this.$route.params.configurationId,
        perDiemRateId: item.id
      }
    });
  }

  addLengthOfStayPerDiemRule() {
    ExpensePerDiemStore.clearErrMessages();
    ExpensePolicyStore.clearErrMessages();
    router.push({
      name: 'length-stay-rule',
      params: {
        id: this.currentCompany!.rootCompanyId,
        itemId: this.$route.params.itemId,
        configurationId: this.$route.params.configurationId
      }
    });
  }

  created() {
    ExpensePerDiemStore.clearErrMessages();
    ExpensePolicyStore.clearErrMessages();
    if (this.policyId) {
      ExpensePerDiemStore.getLengthOfStayPerDiemRules(this.policyId);
    }
  }

}

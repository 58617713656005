






































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VueContentLoading } from 'vue-content-loading';
import moment from 'moment';

import { getBundledSeatName } from '@/core/bundled-seat-name';
import { LegDetail } from '@/api/air-engine/offers.model';
import SearchStore from '@/modules/search/search.store';
import AirSearchStore from '@/modules/search/air/air-search.store';
import { technicalStopTooltip } from '@/modules/search/air/technical-tooltip.service';
import { userFullName } from '@/core/user-full-name';
import { sanitizeUrl } from '@braintree/sanitize-url';
import AirBritishAirwaysShortOfferDetails from '@/modules/search/air/AirBritishAirwaysShortOfferDetails.vue';

@Component({
  components: {
    VueContentLoading,
    AirBritishAirwaysShortOfferDetails
  },
  filters: {
    moment(date) {
      return moment(date).format('hh:mm');
    },
    momentDate(date) {
      return moment(date).format('ddd, Do MMM');
    },
    momentTime(date) {
      return moment(date).format('HH:mm');
    },
    momentDate2(date) {
      return moment(date).format('Do MMM');
    },
    timeConvert(n) {
      let num = n;
      let hours = (num / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      return rhours + 'h' + rminutes + 'm';
    },
  }
})

export default class BasketAirLegTimelineDetailsMobile extends Vue {
  @Prop() leg!: any;
  @Prop() proposal!: any;
  @Prop() offerDetails!: LegDetail;
  @Prop({default: false}) isInBasket!: boolean;
  @Prop({ default: false }) useOfferPassengerData!: boolean;

  infoHeights: any[] = [];
  traveller: any;

  get travellers() {
    return SearchStore.getTravellersState;
  }

  get legFlights() {
    return this.leg.flights.map(flight => {
      return {
        ...flight,
        travellers: this.proposal.passengers
          .map(passenger => {
            const traveller = this.travellers.travellers.find(t => {
              return t.id === passenger.profileId;
            });

            if (!traveller) {
              return null;
            }
            return {
              ...traveller,
              firstName: this.canUseOfferPassengerData ? passenger.firstName : traveller.firstName,
              lastName: this.canUseOfferPassengerData ? passenger.lastName : traveller.lastName,
              middleName: this.canUseOfferPassengerData ? undefined : traveller.middleName,
              seat: flight.seats.find(seat => seat.profileId === traveller.id) || null,
              ancillaries: passenger.ancillaries.filter(item => item.flightIds.find(id => id === flight.id)),
            };
          })
          .filter(traveller => traveller !== null)
          .filter(traveller => traveller.seat !== null || traveller.ancillaries.length > 0),
      };
    });
  }

  get modifyMode() {
    return AirSearchStore.modifyMode;
  }

  get isSabreSupplier() {
    return this.proposal.supplier === 'Sabre';
  }

  get isAmadeusSupplier() {
    return this.proposal.supplier === 'Amadeus';
  }

  get canUseOfferPassengerData() {
    return this.proposal.supplier === 'AirFranceKlm' && this.useOfferPassengerData;
  }




  travellerUrl(traveller) {
    return sanitizeUrl('/profile/' + traveller.id);
  }

  isWeightGiven(detail) {
    if (-1 < ['kg', 'lb'].indexOf(detail.unit)) {
      return true;
    }

    return false;
  }

  userFullName(user) {
    return userFullName(user);
  }

  getBundledSeatName(bundledSeatName) {
    return getBundledSeatName(bundledSeatName);
  }

  iconBasedOnLocation(location, defaultValue) {
    if (location && !location.isAirport && location.isRailwayStation) {
      return 'train';
    }
    return defaultValue;
  }

  applyMinHeight() {
    this.$nextTick(() => {
      const fareDetails = this.$refs.fareDetails as HTMLElement[];
      const fareName = this.$refs.fareName as HTMLElement[];

      if (!fareDetails || !fareDetails.length) {
        return;
      }

      this.infoHeights = [];

      fareDetails.forEach((fareDetailsItem, index) => {
        const fareDetailsRect = fareDetailsItem.getBoundingClientRect();
        const fareNameRect = fareName[index].getBoundingClientRect();

        this.infoHeights.push(fareNameRect.height + fareDetailsRect.height);
      });
    });
  }

  flagClasses(flight) {
    return {
      'flag__public': 'Public' === flight.fareInfo.fareType,
      'flag__nego': 'Nego' === flight.fareInfo.fareType,
      'flag__private': 'Private' === flight.fareInfo.fareType,
    };
  }

  flightDetails(flight) {
    if (!this.offerDetails || !this.offerDetails.segments || this.offerDetails.error) {
      return null;
    }
    if (flight.type === 'Stop') {
      return null;
    }

    return this.offerDetails.segments.find((segment) => segment.carrierCode === flight.flightInfo.marketingCarrier.code && segment.flightNumber === flight.flightInfo.number);      
  }


  technicalStopTooltip(stop) {
    return technicalStopTooltip(stop);
  }

  mounted() {
    this.applyMinHeight();
  }
}

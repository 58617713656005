






















































































import { Prop, Vue, Watch } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import TrainsTile from '@/modules/basket/basket-rail-seats-extras/TrainsTile.vue';
import SeatsSncfContent from '@/modules/basket/basket-rail-seats-extras/content/SeatsSncfContent.vue';
import ExtrasSncfContent from '@/modules/basket/basket-rail-seats-extras/content/ExtrasSncfContent.vue';
import BasketStore from '@/modules/basket/basket.store';
import {
  TSeatMapPopupSelection,
  TSncfAncillaryModel,
  TSncfSeatPreferencesModel
} from '@/modules/basket/basket-rail-seats-extras/basket-rail-seats-extras.model';
import { Price } from '@/api/expense/expense.model';

@Component({
  components: {
    TrainsTile,
    SeatsSncfContent,
    ExtrasSncfContent,
  }
})
export default class RailSncfAncillariesAndSeatSelection extends Vue {
  @Prop() basketItem!: any;
  @Prop() tripItemId!: string;

  seatMapPopupSelections: TSeatMapPopupSelection[] = [];

  get sncfSeatPreferencesModel() {
    return BasketStore.sncfSeatPreferencesModel;
  }
  set sncfSeatPreferencesModel(sncfSeatPreferences: TSncfSeatPreferencesModel[]) {
    BasketStore.setSncfSeatPreferencesModel(sncfSeatPreferences);
    BasketStore.setPriceChangesForStep({
      tripItemId: BasketStore.bookingStepDef.tripItemId,
      step: this.bookingStep,
      type: 'seats',
      prices: this.seatMapPreferencesPrices,
    });
  }
  get sncfAncillaryModel() {
    return BasketStore.sncfAncillaryModel;
  }
  set sncfAncillaryModel(sncfAncillaryModel: TSncfAncillaryModel[]) {
    BasketStore.setSncfAncillaryModel(sncfAncillaryModel);
    BasketStore.setPriceChangesForStep({
      tripItemId: BasketStore.bookingStepDef.tripItemId,
      step: this.bookingStep,
      type: 'railAncillaries',
      prices: this.ancillariesPrices,
    });
  }

  get isLoading() {
    return BasketStore.basketRailSeatsAncillariesLoading;
  }

  get passengers() {
    return (BasketStore.basket && BasketStore.basket.travellers) || [];
  }

  get seatsPreferencesOptions() {
    return BasketStore.sncfSeatsAncillaries.seatsPreferencesOptions;
  }

  get ancillariesOffers() {
    return BasketStore.sncfSeatsAncillaries.ancillariesOffers;
  }

  get seatTrains() {
    return this.seatsPreferencesOptions.map((item) => ({
      trainNumber: item.train.trainInfo.number,
      to: item.train.to.stationName,
      departureDate: item.train.departure,
      from: item.train.from.stationName,
      segmentId: item.train.segmentId,
      seatMapAvailable: item.train.seatMapAvailable,
      type: item.train.trainInfo.trainType,
    }));
  }

  get ancillariesTrains() {
    const segmentIds = new Set();
    return this.ancillariesOffers.reduce((prev, next) => {
      const trains = next.ancillaries.flatMap((ancillary) => {
        return ancillary.segments
          .filter((segment) => {
            if (segmentIds.has(segment.segmentId)) {
              return false;
            }
            segmentIds.add(segment.segmentId);
            return true;
          })
          .map((segment) => ({
            trainNumber: segment.trainInfo.number,
            to: segment.to.stationName,
            departureDate: segment.departure,
            from: segment.from.stationName,
            segmentId: segment.segmentId,
            type: segment.trainInfo.trainType,
          }));
      });
      return prev.concat(trains);
    }, [] as {
      trainNumber: string;
      to: string;
      departureDate: string;
      from: string;
      segmentId: string;
    }[]);
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get ancillariesPrices() {
    return this.sncfAncillaryModel.reduce((prices, next) => {
      const basketRailSncfAncillary = this.ancillariesOffers.find((item) => item.traveller.id === next.travellerId);
      if (!basketRailSncfAncillary) {
        return prices;
      }
      const ancillary = basketRailSncfAncillary.ancillaries.find((item) => item.product.code === next.ancillaryCode);
      if (ancillary) {
        return prices.concat(ancillary.price);
      }
      return prices;
    }, [] as Price[]);
  }

  get seatMapPreferencesPrices() {
    return this.sncfSeatPreferencesModel.reduce((prices, next) => {
      const { segmentId, seatMapPreferences } = next;
      if (seatMapPreferences && seatMapPreferences.assignments) {
        const seatMapPopupSelections = this.seatMapPopupSelections.find((item) => {
          const isSegmentId = segmentId === item.segmentId;
          const isPlace = seatMapPreferences.assignments.find(({ coachNumber, seatNumber }) => {
            const isCoachNumber = coachNumber === item.coachNumber;
            const isSeatNumber = seatNumber === item.seatNumber;
            return isCoachNumber && isSeatNumber;
          });
          return isSegmentId && !!isPlace;
        });
        if (seatMapPopupSelections) {
          return prices.concat(seatMapPopupSelections.price);
        }
        return prices;
      }
      return prices;
    }, [] as Price[]);
  }

  get isAvailableLeastOneSeatSelection() {
    return this.seatsPreferencesOptions.some((item) => item.preferencesCategories.length > 0 || item.train.seatMapAvailable);
  }

  isAvailableAncillaryForPassenger(id: string) {
    const ancillaryOffer = this.ancillariesOffers.find((item) => item.traveller.id === id);
    return ancillaryOffer && ancillaryOffer.ancillaries.length > 0;
  }

  onUpdateSeatMapPopupSelections(seatMapPopupSelections: TSeatMapPopupSelection[]) {
    this.seatMapPopupSelections = seatMapPopupSelections;
  }

  @Watch('bookingStep', { immediate: true })
  async onStepChange() {
    BasketStore.setBookingStepLoading(true);
    BasketStore.setSncfSeatPreferencesModel([]);
    BasketStore.setSncfAncillaryModel([]);
    await BasketStore.getRailSeatsAncillaries({ id: this.tripItemId });
    BasketStore.setBookingStepLoading(false);
  }
}

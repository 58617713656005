












































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators';
import { Validation } from 'vue-plugin-helper-decorator';
import _ from 'lodash';

import { router } from '@/router';
import { translate } from '@/i18n';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import SettingsStore from '@/modules/settings/settings.store';
import DelayTicketingStore from '@/modules/settings/delay-ticketing/delay-ticketing.store';
import { AirLine } from '@/api/dictionary/dictionary.model';
import DictionaryStore from '@/store/dictionary.store';
import { RuleItem } from '@/api/profile/delay-ticketing-configuration.model';

const priceRegex = value => {
  return /^(\d+)(\.\d{1,2})?$/.test(value);
};

const zeroesRegex = value => {
  return /[^0]+/.test(value);
};

@Component({})
export default class DelayTicketingConfiguration extends Vue {
  isReadOnly: boolean = false;
  isLoading: boolean = false;
  isArlineLoading: boolean = false;
  showRemovePopup: boolean = false;
  levelToDelete: number = -1;
  loadErrors: any[] = [];
  imagesConst: string = '/assets/img/loader/1.gif';
  providersOptions: any[] = [];
  airLines: AirLine[] = [];
  delayTicketing: any = {
    name: '',
    rules: [
      {
        isPriceChangeRatioEnabled: false,
        isProviderEnabled: true,
        isCarriersEnabled: false,
        visible: true,
        timeBeforeTicketing: 1,
        priceChangeRatio: null,
        providers: [],
        carriers: [],
      }
    ]
  };
  $v;
  
  get delayTicketingConfig() {
    return DelayTicketingStore.delayTicketingConfiguration;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get rootCompanyId() {
    if (!this.currentCompany) {
      return this.$route.params.id;
    }

    return this.currentCompany.rootCompanyId;
  }

  get currentConfigurationId() {
    return this.$route.params.configurationId;
  }

  get loading() {
    return DelayTicketingStore.loading;
  }

  get showError() {
    return DelayTicketingStore.canShowError;
  }

  get errMessages() {
    return DelayTicketingStore.getErrMessages;
  }

  get canShowCustomError() {
    return DelayTicketingStore.canShowCustomError;
  }

  get providers() {
    return DelayTicketingStore.delayTicketingProviders;
  }

  get allAirlines() {
    return DictionaryStore.allAirLines;
  }


  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isLoading',
  })

  async findProviders(query: string) {
    if (query && query.length > 1) {
      this.isLoading = true;
      query = query.toLowerCase();

      if (this.providers) {
        const response = this.providers.filter((option) => option.code.toLowerCase().includes(query) || option.name.toLowerCase().includes(query));

        this.providersOptions = [];

        if (response) {
          response.forEach((element) => {
            this.providersOptions.push(element);
          });
        } else {
          this.providersOptions = this.providers;
        }
      }

      this.isLoading = false;
    } else {
      this.isLoading = false;
      this.providersOptions = this.providers;
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isArlineLoading',
  })

  async findAirLines(query: string) {
    if (query && query.length > 1) {
      this.isArlineLoading = true;
      query = query.toLowerCase();

      if (this.allAirlines) {
        const response = this.allAirlines.filter((airline: AirLine) => airline.code.toLowerCase().includes(query) || airline.businessName.toLowerCase().includes(query));

        this.airLines = [];

        if (response) {
          response.forEach((element) => {
            this.airLines.push(element);
          });
        }
      }

      this.isArlineLoading = false;
    } else {
      this.isArlineLoading = false;
      this.airLines = [];
    }
  }

  toggleLevel(index) {
    this.delayTicketing.rules[index].visible = !this.delayTicketing.rules[index].visible;
  }

  showConfirmRemoval(level) {
    this.levelToDelete = level;
    this.showRemovePopup = true;
  }

  confirmRemove() {
    this.delayTicketing.rules.splice(this.levelToDelete, 1);
    for (let index = this.levelToDelete; index < this.delayTicketing.rules.length; index++) {
      this.delayTicketing.rules[index].visible = this.delayTicketing.rules[index + 1].visible;
    }
    this.showRemovePopup = false;
  }

  addNextLevel() {
    const level = new RuleItem();
    this.delayTicketing.rules.push(level);
  }

  @Validation()
  validationObject() {
    return {
      delayTicketing: {
        name: {
          required,
          maxLength: maxLength(64),
        },
        rules: {
          $each: {
            timeBeforeTicketing: {
              required,
            },
            priceChangeRatio: {
              required: requiredIf((item) => {
                return item.isPriceChangeRatioEnabled;
              }),
              priceRegex: (val, item) => {
                return val !== '' && item.isPriceChangeRatioEnabled ? priceRegex(val) : true;
              },
              zeroesRegex: (val) => {
                return val !== '' ? zeroesRegex(val) : true;
              },
              maxValue: (val) => {
                return val !== '' ? Number(val) <= 200 : true;
              }
            },
            providers: {
              required,
            },
          },
        },
      },
    };
  }

  prepareRequest() {
    const rules = this.delayTicketing.rules.map((item) => {
      return {
        timeBeforeTicketing: item.timeBeforeTicketing,
        priceChangeRatio: item.priceChangeRatio ? Number(item.priceChangeRatio) : null,
        providers: item.providers.length > 0 ? item.providers.map(provider => {
          return provider!.code;
        }) : [],
        carriers: item.carriers.length > 0 ? item.carriers.map(carrier => {
          return carrier!.code;
        }) : [],
       };
    });
    return {
      name: this.delayTicketing.name,
      rules: rules,
    };
  }

  async saveDelayTicketing() {
    this.$v.delayTicketing.$touch();
    if (this.$v.delayTicketing.$pending || this.$v.delayTicketing.$error) {
      this.delayTicketing.rules.forEach((item, index) => {
        const validation = this.$v.delayTicketing.rules.$each[index];

        if (validation.$error) {
          this.delayTicketing.rules[index].visible = true;
        } else {
          this.delayTicketing.rules[index].visible = false;
        }
      });
      return;
    }

    const request = this.prepareRequest();

    if (this.currentCompany) {
        let rootCompanyId = this.currentCompany.rootCompanyId;
        let params = {
          rootCompanyId,
          ...request
        };
        if (this.currentConfigurationId) {
          let configurationId = this.currentConfigurationId;
          await DelayTicketingStore.updateDelayTicketingConfiguration({configurationId, params});
        } else {
          await DelayTicketingStore.addDelayTicketingConfiguration({params});
        }

        if (!(this.errMessages && this.errMessages.length)) {
          this.returnToConfigurations();
        }
    }
  }

  returnToConfigurations() {
    router.push({
      name: 'delay-ticketing-configurations'
    });
  }

  async created() {
    DelayTicketingStore.getDelayTicketingProviders();
    this.isReadOnly = !!this.$route.query.readOnly;
    DelayTicketingStore.setErrMessages([]);
    DelayTicketingStore.setShowError(false);
    if (this.currentConfigurationId) {
      await DelayTicketingStore.getDelayTicketingConfiguration(this.currentConfigurationId);
      const rules = this.delayTicketingConfig.rules.map((item) => {
        return {
          isPriceChangeRatioEnabled: item.priceChangeRatio ? true : false,
          isProviderEnabled: true,
          isCarriersEnabled: item.carriers.length > 0 ? true : false,
          visible: true,
          timeBeforeTicketing: item.timeBeforeTicketing,
          priceChangeRatio: item.priceChangeRatio,
          providers: item.providers.map(value => this.providers.find(item => item.code === value)),
          carriers: item.carriers.length ? item.carriers.map(carrier => this.allAirlines!.find(item => item.code === carrier)) : [],
        };
      });
      this.delayTicketing.rules = rules;
    } else {
      this.delayTicketing.rules = this.delayTicketingConfig.rules;
    }
    this.delayTicketing.name = this.delayTicketingConfig.name;
  }
}





















































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import BasketStore from '@/modules/basket/basket.store';
import { OfferParameter } from '@/api/trip/basket-item.model';
import { Validation } from 'vue-plugin-helper-decorator';
import { requiredIf } from 'vuelidate/lib/validators';
import EventBus from '@/services/event-handler';
import { Country } from '@/api/dictionary/dictionary.model';
import DictionaryStore from '@/store/dictionary.store';

const updateValue: string = 'update:parameter.value';

@Component({
  name: 'parameter-countrycode',
})
export default class ParameterCountrycode extends Vue {
  @Prop() parameter!: OfferParameter;

  query: string = '';

  @Validation()
  validationObject() {
    return {
      paramValue: {
        required: requiredIf(() => {
          return this.parameter.isRequired;
        }),
      }
    };
  }

  get isEditable() {
    return BasketStore.status && BasketStore.status.status !== 'Draft';
  }

  get displayText() {
      return this.parameter.displayText ? this.parameter.displayText : this.parameter.key;
  }

  get countries() {
    return DictionaryStore.countries || [];
  }

  get filteredCountries() {
    const lowerCase = this.query.toLowerCase();

    return this.countries
      .filter(item => {
        return -1 < item.code.toLowerCase().indexOf(lowerCase) ||
          -1 < item.countryName.toLowerCase().indexOf(lowerCase);
      })
      .sort((a, b) => {
        if (a.code.toLowerCase() === lowerCase) {
          return -1;
        }
        if (b.code.toLowerCase() === lowerCase) {
          return 1;
        }
        return 0;
      });
  }

  get paramValue() {
    return (this.countries.find(opt => opt.code === this.parameter.value) || null) as Country | null;
  }

  @Emit()
  [updateValue](value) {
    return value;
  }

  set paramValue(value: Country | null) {
    this.parameter.value = value ? value.code : null;
    this[updateValue](this.parameter.value);
    this.$emit('update', this.parameter.value);

    BasketStore.updateItemParameterValue(this.parameter);
  }

  filterCountries(query) {
    this.query = query;
  }

  onReceive() {
    this.$v.paramValue!.$touch();
    if (this.$v.paramValue!.$pending || this.$v.paramValue!.$error) {
      BasketStore.setValidCustomParameters(false);
    }
  }

  close() {
    const autocomplete = (this.$refs.autocomplete as Vue).$el as HTMLElement;
    const el = autocomplete.getElementsByTagName('input')[0];

    setTimeout(() => {
      el.blur();
    }, 100);
  }

  created() {
    EventBus.$on('custom-parameter-form', this.onReceive);
  }

  beforeDestroy() {
    EventBus.$off('custom-parameter-form', this.onReceive);
  }
}

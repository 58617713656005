























































































































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';

import BasketStore from './basket.store';
import { ProfileApi } from '@/api/profile/profile.api';
import { ApproverModel } from '@/api/profile/profile.model';
import { Permission } from '@/const/permission.enum';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { userFullName } from '@/core/user-full-name';

@Component
export default class BasketChangeApproverPopup extends Vue {
  @Prop() show!: boolean;

  options: ApproverModel[] | null = [];
  isLoading: boolean = false;
  loaded: boolean = false;
  Form: any = {
    selectedApprover: null,
    sendEmail: true,
    approverSelectionType: null,
    approverSourceType: null,
    currentApprovalLevel: null
  };

  get optionsMapped() {
    if (!this.options) {
      return [];
    }
    return this.options.map(item => {
      return {
        ...item,
        id: item.profileId || item.id,
      };
    });
  }

  get approvalForBasket() {
    return BasketStore.approvalWorkflowForBasket;
  }

  get basketTravellers() {
    return BasketStore.basketTravellers;
  }

  get basketId() {
    return this.$router.currentRoute.params.id;
  }

  approverSelectionTypeParams() {
    if (this.Form.approverSelectionType === 'BookerSelectsApprover') {
      return {
        approverId: this.Form.selectedApprover.id ? this.Form.selectedApprover.id : this.Form.selectedApprover.profileId,
      };
    }

    return {};
  }

  confirmChangeApprover() {
    let params = {
      sendEmail: this.Form.sendEmail,
      ...this.approverSelectionTypeParams()
    };
    BasketStore.changeApprover(params);
    this.closePopup();
  }

  closePopup() {
    BasketStore.setShowChangeApproverPopup(false);
  }

  focusOnSaveButton() {
    setTimeout(() => {
      const btn = ((this.$refs.changeApproverButton as Vue).$el as HTMLInputElement);
      if (btn) {
        btn.focus();
      }
    }, 50);
  }

  userFullName(user) {
    return userFullName(user);
  }

  getAnyAllowedApproverLevelMessage(approverSourceType: string) {
    switch (approverSourceType) {
      case 'AllApproversFromRootCompany': return this.$t('basket.any-allowed-approvers-level-from-root-company');
      case 'ApproversFromList': return this.$t('basket.any-allowed-approvers-level-selected-in-configuration');
      default: return '';
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isLoading',
  })
  async findApprover(query: string) {
    this.isLoading = true;
    this.options = [];
    const approval = this.approvalForBasket;
    if (!approval) {
      this.isLoading = false;
      return;
    }
    const mainTraveller = this.basketTravellers.filter(traveller => traveller.isMainTraveller);
    const companyId = mainTraveller[0].companyId;
    const level = approval.workflowLevels
      .find(level => level.approvalLevel === approval.currentApprovalLevel);
    if (!level) {
      this.isLoading = false;
      return;
    }
    if (level.approverSourceType === 'AllApproversFromRootCompany') {
      const response = await ProfileApi.searchApprovers(companyId, query, Permission.ReadTrip);
      if (response && response.data) {
        this.options = response.data;
      }
    } else {
      this.options = level.allowedApprovers.filter(list => {
        return list.firstName.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
          (list.middleName || '').toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
          list.lastName.toLowerCase().indexOf(query.toLowerCase()) >= 0;
      });
    }
    this.isLoading = false;
  }

  applyCurrentApprover() {
    const approval = this.approvalForBasket;
    if (!approval) {
      return;
    }
    const level = approval.workflowLevels
      .find(level => level.approvalLevel === approval.currentApprovalLevel);
    if (!level) {
      return;
    }

    if (level.approverSelectionType === 'BookerSelectsApprover' && level.currentApprover) {
      this.Form.selectedApprover = {
        ...level.currentApprover,
        id: level.currentApprover.approverId,
        firstName: level.currentApprover.approverFirstName,
        middleName: level.currentApprover.approverMiddleName,
        lastName: level.currentApprover.approverLastName,
      };
    }

    this.Form.approverSelectionType = level.approverSelectionType;
    this.Form.approverSourceType = level.approverSourceType;
    this.Form.currentApprovalLevel = level.approvalLevel;
  }

  get isConfirmDisabled() {
    switch (this.Form.approverSelectionType) {
      case 'BookerSelectsApprover': return !this.Form.selectedApprover;
      case 'AnyAllowedApprover': return false;
      default: return true;
    }
  }

  @Watch('show', { immediate: true })
  async onShow(value) {
    if (!value) {
      return;
    }
    this.loaded = false;
    await BasketStore.getApprovalWorkflowForBasket(this.basketId);
    this.applyCurrentApprover();
    this.loaded = true;
  }
}






































import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { LoyaltiesApi } from '@/api/profile/loyalties.api';
import EventHandler from '@/services/event-handler';
import BasketStore from '@/modules/basket/basket.store';

@Component({})
export default class LoyaltyRemovePopup extends Vue {
  @Prop() card!: any;
  @Prop() isGuestMode!: any;
  @Prop() profileId!: any;
  cardRemovalErrorMessage: string | null = null;
  formPending: boolean = false;

  async removeDocument() {
    try {
      this.formPending = true;
      if (this.isGuestMode) {
        BasketStore.removeGuestBasketTravellerLoyalty({ id: this.profileId, loyaltyId: this.card.id });
      } else {
        await LoyaltiesApi.removeLoyaltyCard(this.card.id);
        EventHandler.$emit('reload-loyalty');
        EventHandler.$emit('profile-data-saved');
      }
      this.close();
    } catch (error) {
      if (!error.response.data.error.details) {
        this.cardRemovalErrorMessage = error.response.data.error.message;
      }
    } finally {
      this.formPending = false;
    }
  }

  @Emit()
  close() {
  }
}

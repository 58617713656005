




















































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import { translate } from '@/i18n';
import { RefundBasketItemApi } from '@/api/trip/basket-item-refund.api';
import EventBus from '@/services/event-handler';
import { userFullName } from '@/core/user-full-name';
import BasketStore from './basket.store';

const eventName = 'update:show';

@Component({})
export default class BasketAirTravelFusionRefundPopup extends Vue {
  @Prop() basketItemId!: string;
  @Prop() show!: boolean;
  @Prop() actionType!: string;
  @Prop() recomedationId!: string;

  processErrors: any[] = [];
  loading: boolean = true;
  loaded: boolean = false;
  processing: boolean = false;
  data: any = null;

  get travellers() {
    return BasketStore.basketTravellers || [];
  }

  get title() {
    return translate('refund.cancellation-process');
  }

  async confirm() {
    this.processing = true;
    try {
      await RefundBasketItemApi.startRefund(this.basketItemId);
      EventBus.$emit('refresh-basket-status');
      this.hidePopup();
    } catch (error) {
      this.processErrors = this.$handleErrors(error, true);
    }
    this.processing = false;
  }

  async created() {
    try {
      const response = await RefundBasketItemApi.getRefundConditions(this.recomedationId, 'TravelFusion');
      this.data = response.data;
      this.loaded = true;
    } catch (error) {
      const err = error as any;
      if (
        err && err.response && err.response.data && err.response.data.error &&
        err.response.data.error.code === 'PROVIDER_COMMAND_EXECUTION_FAILURE'
      ) {
        this.processErrors = [{
          message: translate('refund.cancellation-not-allowed-by-supplier'),
        }];
        return;
      }
      this.processErrors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  userFullName(user) {
    return userFullName(user);
  }

  @Emit(eventName)
  hidePopup() {
    return false;
  }
}




























































import { Vue, Prop, Component } from 'vue-property-decorator';

import BasketStore from './basket.store';
import {
 PriceChangeDetailsModel,
} from '@/api/trip/basket-item.model';
import AccountStore from '@/store/account.store';
import { CarVendorsApi } from '@/api/car-engine/car-vendors.api';

@Component
export default class BasketItemCarPriceChangePopup extends Vue {
  @Prop() title!: string;
  @Prop() show!: boolean;

  loading: boolean = false;
  loadErrors: any[] = [];
  item: PriceChangeDetailsModel | null =  null;

  get basketItem() {
    return BasketStore.lastBasketItem;
  }

  get basketItemId() {
    return BasketStore.lastBasketItemId;
  }

  get providerReferenceId() {
    return BasketStore.lastBasketItemProviderReferenceId;
  }

  get user() {
    return AccountStore.current;
  }

  get btnClasses() {
    return {
      'mobile-fr-btn': this.user!.profile.displayLanguage === 'fr',
      'mobile-it-btn': this.user!.profile.displayLanguage === 'it',
    };
  }

  rejectPrice() {
    BasketStore.rejectPrice();
  }

  acceptPrice() {
    BasketStore.acceptPrice();
  }

  async mounted() {
    if (this.providerReferenceId) {
      this.loading = true;
      this.loadErrors = [];
      try {
        const response = await CarVendorsApi.getPriceChangeDetails(this.providerReferenceId, this.basketItem ? this.basketItem.supplier : '');
        this.item = response.data;
      } catch (error) {
        this.loadErrors = this.$handleErrors(error, true);
      } finally {
        this.loading = false;
      }
    }
  }
}


import { Proposal, LegFlight, AvailableOption } from '@/api/air-engine/air-search.model';
import { TrainOfferResult } from '@/api/train-engine/train-search.model';
import { PaymentCardType } from '@/api/profile/payment-cards.model';
import { Price } from './trip.model';
import { Accommodation } from '@/api/accommodation-engine/accommodation-search.model';
import { Car } from '@/api/car-engine/car-search.model';
import { ContactsModel } from '@/api/profile/contacts.model';
import { Country, LocationModel } from '@/api/dictionary/dictionary.model';
import { IProfileLoyaltiesCard } from '@/api/profile/loyalties.model';

export interface BasketItemsRequest {
  forceAdd: boolean;
  arrangerId: string;
  travellers: Traveller[];
  type: string;
  searchId: string;
  selection: Selection;
}

export interface Selection {
  recommendationId: string;
  proposalId: string;
}

export interface WizardStepsGroup {
  steps: any[];
  code: string;
  travellerId?: string | null;
  tripItemId?: string | null;
  tripItem?: any | null;
  maxIndex?: number;
}

export interface Traveller {
  id: string;
  firstName: string;
  lastName: string;
  isMainTraveller?: boolean;
  companyId?: string;
  companyName?: string;
  businessUnitId?: string;
  businessUnitName?: string;
  dateOfBirth?: string;
  isSelected?: boolean;
  virtualTravellerId: string;
}

export interface UpdateTraveller {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export interface BasketResult {
  tripId?: string;
  addedToBasket: boolean;
  confirmationNeeded: boolean;
  searchId: string;
  basketId: string;
  prevRecommendationId: string;
  prevProposalId: string;
  newRecommendationId: string;
  newProposalId: string;
  previousRecommendation: PrevRecommendation;
}

export interface PreBookingData {
  additionalConsent: string | null;
  isApprovalEnabled: boolean;
  isApprovalPossible: boolean;
  approvalDeadline: string;
  isHoldEnabled: boolean;
  isHoldPossible: boolean;
  holdDeadline: string;
  canDeadlinesChange: boolean;
  delayedTicketingDeadline: string;
  isDelayedTicketingEnabled: boolean;
  autoApprovalWhenDeadlineReached: AutoApprovalWhenDeadlineReachedType;
}

export interface PrevRecommendation {
  id: string;
  legFlights: LegFlight[];
  proposal: Proposal;
}

export interface SelectedBookerModel {
  bookerFirstName: string;
  bookerMiddleName?: string;
  bookerLastName: string;
  bookerId: string;
  businessUnitName?: string;
  rootCompanyName?: string;
  rootCompanyCode?: string;
}

export interface BasketModel {
  booker: SelectedBookerModel;
  id?: string;
  tripCode: string;
  travellers: Traveller[];
  startDate: string;
  endDate: string;
  expirationDate: string;
  bookingDate: string | null;
  targetCity: string;
  feesConfigurationId: string | null;
  holdDeadline?: string;
  offlineRequestId?: string;
  offlineRequestDisplayId?: string;
  approvalDeadline?: string;
  approvalAvailable: boolean;
  rejectionReason?: string;
  searchCompanyId?: string | null;
  name: string;
  hotelSearchId?: string;
  missionId?: any | null;
  totalPrice: Price;
  allowRefuseContactDetails: boolean;
  notes: {
    notes: string | null,
    canEditNote: boolean,
  };
}

export interface BasketPaymentCardDetailsResponse {
  availablePaymentCards: BasketPaymentCardModel[];
  supportedPaymentCardTypes: BasketSupportedPaymentCardType[];
}

export interface BasketSupportedPaymentCardType {
  type: any | PaymentCardType;
  cardTypeFee: Price;
  isCardFeePerPassengerLeg: boolean;
}

export interface BasketPaymentCardModel {
  paymentCardId: string;
  profileId: string;
  maskedNumber: string;
  displayName: string | null;
  type: any;
  holder: string;
  holderFirstName: string;
  holderLastName: string;
  isExpired: boolean;
  isSupportedByProvider: boolean;
  $isDisabled: boolean;
  cardFee: Price | null;
  transactionId: string | null;
  paymentMethod: string;
  unsupported?: boolean;
}

export interface BasketItemSelectedPaymentCardModel {
  paymentCardId: string;
  userProfileId: string;
  maskedNumber: string;
  holder: string;
  holderFirstName: string;
  holderLastName: string;
  type: any | PaymentCardType | null;
  cardFee: Price | null;  // this value is calculated by frontend - it does not come from backend
  hasErrors: boolean | null;  // this value is calculated by frontend - it does not come from backend
  updateCardTypeInProfile: boolean | null;  // this value is calculated by frontend - it does not come from backend
  transactionId: string | null;
  paymentMethod: string;
}

export interface BasketItemModel {
  id: string;
  travelPolicy?: string;
  rail?: TrainOfferResult;
  recommendationId?: string;
  air?: Air;
  car?: Car;
  accommodation?: Accommodation;
  orderNumber: string;
  options?: Options;
  tickets?: string;
  status: string;
  providerStatus: string;
  redirectUrl?: string | null;
  priceHasChanged?: string;
  previousTotalPrice?: Price;
  totalPrice?: Price;
  hasPriceBeenConfirmed?: boolean;
  statusTag: string | null;
  bookingErrors?: any[];
  groupeErrors?: any[];
  allowedPaymentMethods: PaymentMethod[];
  selectedPaymentCard: BasketItemSelectedPaymentCardModel | null;
  isInProgress?: boolean;
  price?: Price;
  supplier?: string;
  pricePlusMarkup: Price;
  agencyMarkup: Price;
  travellers?: BasketTravellerProfile[];
  hasDiscountCodes?: string;
  agencyFee?: Price;
  type: string;
  actions: {
    cancel: BasketItemActionsEnum,
    exchange: BasketItemActionsEnum,
    modifyFlights: BasketItemActionsEnum,
    extras: BasketItemActionsEnum,
    refund: BasketItemActionsEnum,
    desynchronize: BasketItemActionsEnum,
    synchronize: BasketItemActionsEnum,
    modifyTravellersData: BasketItemActionsEnum,
  };
}

export interface BasketFlatItemModel {
  id: string;
  wmCode: string;
  type: string;
  expenseId?: string;
  travelPolicy?: string;
  status: string;
  providerStatus: string | null;
  statusTag: string | null;
  bookingErrors?: any[];
  additionalMessages?: any[];
  isInProgress?: boolean;
  price: TotalPrice;
  providerReferenceId: string;
  isExchangeAvailable: boolean;
  isCancelAvailable: boolean;
  location: string;
  startDate: string;
  endDate: string;
  missedSavingReason?: string;
  selectedPaymentCard: BasketItemSelectedPaymentCardModel | null;
  groupeErrors?: any[];
  requiresFoid?: boolean;
  supplier: string;
  createDate: string;
  deadline: string;
  travellers: Traveller[];
  tripItemPaymentInfo?: any[];
  isSplitBookingForbidden?: boolean;
  actions: {
    cancel: BasketItemActionDescription,
    exchange: BasketItemActionDescription,
    modifyFlights: BasketItemActionDescription,
    extras: BasketItemActionDescription,
    refund: BasketItemActionDescription,
    changePaymentMethod: BasketItemActionDescription,
    desynchronize: BasketItemActionDescription,
    synchronize: BasketItemActionDescription,
    modifyTravellersData: BasketItemActionDescription,
  };
}

export interface TotalPrice {
  total: Price;
  agencyFee?: Price | null;
  agencyMarkup?: Price | null;
  systemMarkup?: Price | null;
  providerPrice?: Price | null;
}

export interface BasketItemViewModel {
  id: string;
  travelPolicy: number;
  rail: TrainOfferResult;
  air: AirViewModel;
  car?: Car;
  accommodation?: Accommodation;
  orderNumber: string;
  options: Options;
  tickets?: string;
  status: string;
  statusTag?: string;
  allowedPaymentMethods: PaymentMethod[];
  selectedPaymentCard: BasketItemSelectedPaymentCardModel | null;
  bookingErrors?: any[];
  itemType?: string;
  createDate?: string;
  pricePlusMarkup?: Price;
  supplier?: string;
  isInProgress?: boolean;
  recommendationId?: string;
  providerReferences: any[];
  providerStatus: string;
}

export interface BasketStatusModel {
  isInProgress: boolean;
  items: ItemStatus[];
  status: string;
  statusTag: string | null;
  pendingActions: PendingAction[];
  displayWaitingScreen: boolean;
  lastActionDate: string;
}

interface ItemStatus {
  id: string;
  isInProgress: boolean;
  pendingActions: PendingAction[];
  providerStatus: string | null;
  startDate: string;
  status: string;
  statusTag: string | null;
  targetDestination: string;
  targetDestinationCityId: string;
  type: string;
}

interface PendingAction {
  code: string;
  availableResponses: string[];
  additionalData?: any;
}

export interface SelectedSeatsMessage {
  seatSelections: SeatSelection[];
}

export interface SeatSelection {
  flightId: string;
  profileSeatSelections: ProfileSeatSelection[];
}

export interface SeatModel {
  column: string;
  facility: string | null;
  isChargeable: boolean;
  isFree: boolean;
  noSeat: boolean;
  row: number;
  offers: SeatPriceModel[] | null;
}

export interface SeatPriceModel {
  commercialName: string;
  offerItemId: string;
  profileId: string;
  price: Price;
}

export interface ProfileSeatSelection {
  profileId: string;
  seatRow: string;
  seatColumn: string;
}

export interface SelectedAncillariesMessage {
  ancillarySelections: AncillarySelection[];
}

export interface AncillarySelection {
  flightId?: string;
  profileId: string;
  ancillaryOfferId: string;
  quantity: number;
}

export interface Options {
  additionalProp1: AdditionalProp1;
  additionalProp2: AdditionalProp1;
  additionalProp3: AdditionalProp1;
}

export interface AdditionalProp1 {
  selectedOptionId: string;
  availableOptions: AvailableOption[];
}

export interface Air {
  legFlights: LegFlight[];
  proposals: Proposal[];
  ticketingDate: string;
  isExchangeAvailable: boolean;
  id: string;
  co2Emission?: number;
}

export interface AirViewModel {
  legFlights: LegFlight[];
  proposal: Proposal;
  id: string;
  ticketingDate: string;
  isExchangeAvailable: boolean;
}

export interface CommissionModel {
  commission: Commission;
}

export interface Commission {
  amount: number;
  currency: Currency;
}

export interface Currency {
  code: string;
  symbol: string;
}

export interface SelectBasketPaymentCardMessage {
  paymentMethod?: string;
  cardId: string;
  cardTypeOverride: any | PaymentCardType | null;
  saveTypeOverrideIntoCardDefinition: boolean;
  transactionId?: string;
  recalculateFee?: boolean;
}

export interface SelectBasketPaymentCardsMessage {
  basketItemsPaymentCards: any | Map<string, SelectBasketPaymentCardMessage>;
}

export interface PagingQuery {
  start: number;
  size: number;
}

export interface AllowedApprover {
  id: string;
  firstName: string;
  middleName?: string;
  lastName: string;
}

export enum PaymentMethod {
  Unknown = 'Unknown',
  PaymentCard = 'PaymentCard',
  CentralPayment = 'CentralPayment',
}

export interface GuestTravellerMessage {
  tripId: string;
  traveller: BasketTravellerProfile;
}

export interface GuestTravellersMessage {
  tripId: string;
  travellerInfo: BasketTravellerProfile[];
}

export type TTravellersError = {
  errorCode: string;
  customState: {
    id: string;
    originalAge: number;
    updatedAge: number;
  };
  severity: string;
};

export type TTravellersResponse = {
  validationType: 'None' | 'Warning' | 'Strict';
  canContinue: boolean;
  errors: TTravellersError[];
};

export interface BasketTravellerProfile {
  profileId: string;
  contacts: ContactsModel;
  id: string;
  isMainTraveller: boolean;
  title: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string; // yyyy-MM-dd
  companyId: string;
  companyName: string;
  businessUnitId: string;
  businessUnitName: string;
  passengerTypeCode: string;
  isVirtual: boolean;
}

export interface BasketTravellerContactInfo {
  email: string;
  phone: {
    code: string;
    number: string;
    isMobile: boolean;
  };
}

export enum AutoApprovalWhenDeadlineReachedType {
  Reject = 'Reject',
  AutoApprove = 'AutoApprove',
}

export class GuestTraveller {
  profileId: string = '';
  id: string = '';
  oldId?: string;
  isMainTraveller: boolean = false;
  title?: any;
  firstName: string = '';
  middleName: string = '';
  lastName: string = '';
  dateOfBirth: string | null = null;
  companyId: string = '';
  companyName: string = '';
  businessUnitId: string = '';
  businessUnitName: string = '';
  passengerTypeCode?: string;
  isVirtual: boolean = false;
  email?: string;
  phoneCode?: any;
  phoneNumber?: string;
  contacts?: ContactsModel;
  isMobile: boolean = false;
  isGuest?: boolean = false;

  constructor(params?) {
    if (!params) {
      return;
    }
    if (params.profileId !== undefined) {
      this.profileId = params.profileId;
    }
    if (params.id !== undefined) {
      this.id = params.id;
    }
    if (params.oldId !== undefined) {
      this.oldId = params.oldId;
    }
    if (params.isMainTraveller !== undefined) {
      this.isMainTraveller = params.isMainTraveller;
    }
    if (params.title !== undefined) {
      this.title = params.title;
    }
    if (params.firstName !== undefined) {
      this.firstName = params.firstName;
    }
    if (params.middleName !== undefined) {
      this.middleName = params.middleName;
    }
    if (params.lastName !== undefined) {
      this.lastName = params.lastName;
    }
    if (params.dateOfBirth !== undefined) {
      this.dateOfBirth = params.dateOfBirth;
    }
    if (params.companyId !== undefined) {
      this.companyId = params.companyId;
    }
    if (params.companyName !== undefined) {
      this.companyName = params.companyName;
    }
    if (params.businessUnitId !== undefined) {
      this.businessUnitId = params.businessUnitId;
    }
    if (params.businessUnitName !== undefined) {
      this.businessUnitName = params.businessUnitName;
    }
    if (params.passengerTypeCode !== undefined) {
      this.passengerTypeCode = params.passengerTypeCode;
    }
    if (params.isVirtual !== undefined) {
      this.isVirtual = params.isVirtual;
    }
    if (params.email !== undefined) {
      this.email = params.email;
    }
    if (params.phoneCode !== undefined) {
      this.phoneCode = params.phoneCode;
    }
    if (params.phoneNumber !== undefined) {
      this.phoneNumber = params.phoneNumber;
    }
    if (params.contacts !== undefined && params.contacts.primary) {
      this.contacts = params.contacts;
      this.email = params.contacts.primary.email;
      this.phoneCode = params.contacts.primary.phone.code;
      this.phoneNumber = params.contacts.primary.phone.number;
    }
    if (params.isMobile !== undefined) {
      this.isMobile = params.isMobile;
    }
    if (params.isGuest !== undefined) {
      this.isGuest = params.isGuest;
    }
  }
}

export interface BasketMetadataResponse {
  metadata: BasketMetadata;
}

export interface BasketMetadata {
  basketId: string;
  tripCode: string | null;
  name: string | null;
  createDate: string | null;
  status: string;
  holdDeadline: string | null;
  approvalDeadline: string | null;
}

export enum ProviderReferenceType {
  Other = 'Other',
  Pnr = 'PNR',
  BookingConfirmationNumber = 'BookingConfirmationNumber'
}

export interface ProviderReference {
  providerRefName: string | null;
  value: string | null;
  receiveDate: string | null;
  type: ProviderReferenceType;
  remarks: string  | null;
}

export interface BasketItemIdentifiersTraveller {
  ticketNumber: string[] | null;
  virtualProfileId: string | null;
  id: string | null;
  isMainTraveller: boolean;
  firstName: string | null;
  lastName: string | null;
  dateOfBirth: string | null;
  companyId: string;
  companyName: string | null;
  businessUnitId: string;
  businessUnitName: string | null;
  passengerTypeCode: string | null;
  isVirtual: boolean;
  sortOrder: number;
}

export interface BasketItemIdentifier {
  wmCode: string;
  providerReferences: ProviderReference[] | null;
  travellers: BasketItemIdentifiersTraveller[];
}
export interface StoreBasketMetadata {
  basketId: string;
  metadata: BasketMetadata;
}

export interface LatestTripItemModel {
  id: string;
  tripId: string;
  type: string;
  price: Price;
  dateStart: string;
  dateEnd: string;
  destination: string;
}
export interface LatestBasketModel {
  id: string;
  price: Price;
  tripsCount: number;
  expirationDate: string;
  name: string;
  items: LatestTripItemModel[];
}

export interface TravellerDateOfBirthObligation {
  travellerId:  string;
  dateOfBirthObligation: string;
}

export interface TravellerDocumentsModel {
  profileId: string;
  documents: TravellerDocumentModel[];
  willAnyDocumentExpireBeforeTripEnd: boolean;
}

export interface TravellerDocumentModel {
  id: string;
  type: string | any;
  number: string;
  issueDate: Date | string;
  issueCountryCode: string;
  issueCountryThreeLetterCode: string;
  issueCountry: Country | null;
  issueCityCode: string;
  issueCityName: string;
  issueCity: LocationModel;
  expireDate: Date | string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: Gender | any;
  birthDate: Date | string;
  applicableCountryCode: string;
  applicableCountry: Country;
  applicableCountryThreeLetterCode: string;
  nationalityCountryCode: string;
  nationalityCountryThreeLetterCode: string;
  nationality: Country | null;
  willExpireBeforeTripEnd: boolean;
}

export interface TravellerLoyaltiesModel {
  profileId: string;
  loyalties: IProfileLoyaltiesCard[];
  areAllLoyaltiesValidForTrip: boolean;
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
  Unspecified = 'Unspecified',
}

export interface UpdateRailSncfSeatPreference {
  segmentId: string;
  closeToPreferences?: {
    coachNumber: string;
    seatNumber: string;
  };
  assignmentPreferences?: {
    assignments: {
      category: string;
      assignmentCode: string;
    }[];
  };
  selectedSeats?: {
    profileId: string;
    coachNumber: string;
    seatNumber: string;
  }[];
}

export type TRailBenerailPreference = { name: string, value: string | null };

export type TRailBenerailPassenger = {
  externalReference: string,
  firstName: string,
  lastName: string,
};

type TRailBenerailTrainInfo = {
  number: string,
  type: string,
  from: {
    stationName: string,
    stationCode: string,
  },
  to: {
    stationName: string,
    stationCode: string,
  },
};

export type TRailBenerailCategory = {
  name: string,
  preferences: TRailBenerailPreference[],
};

export type TRailBenerailTrain = {
  seqId: number,
  segmentId: string,
  train: TRailBenerailTrainInfo,
  options: {
    offerPartId: string,
    isOptional: boolean,
    isIncluded: boolean,
    price: Price,
    passengers: TRailBenerailPassenger[],
    categories: TRailBenerailCategory[],
  }[],
};

export type TRailBenerailLeg = {
  legNumber: number,
  trains: TRailBenerailTrain[],
};

export type TRailBenerailLegs = {
  legs: TRailBenerailLeg[],
};

export type TBasketRailBenerailOption = {
  categories: TRailBenerailCategory[],
  isOptional: boolean,
  isIncluded: boolean,
  offerPartId: string,
  passengers: TRailBenerailPassenger[],
  price: Price,
};

export type TBasketRailBenerailSeatPreference = {
  legNumber: number,
  segmentId: string,
  seqId: number,
  train: TRailBenerailTrainInfo,
  seatPreference: TRailBenerailPreference,
  options: TBasketRailBenerailOption[],
};

export type TBenerailSeatPreferencesOptionModel = {
  legNumber: number,
  offerPartId: string,
  segmentId: string,
  isIncluded: boolean,
  preferences: string[] | null,
};

export type TBenerailSeatPreferencesModel = {
  options: TBenerailSeatPreferencesOptionModel[];
};

export enum TravelPolicyResult {
  Unknown = 'Unknown',
  NonCompliant = 'NonCompliant',
  Compliant = 'Compliant',
  Empty = 'Empty',
}

export interface TravelPolicyResultChange {
  previousTravelPolicyResult: TravelPolicyResult;
  currentTravelPolicyResult: TravelPolicyResult;
  isChanged: boolean;
}

export interface RecalculateTripItemResult {
  tripItemId: string;
  totalPrice: Price;
  travelPolicyResultChange: TravelPolicyResultChange;
  isBlockedByTravelPolicyChange: boolean;
}

export interface TravelPolicyComplianceResult {
  isNotBlockedByTravelPolicyChanges: boolean;
  isAnyTravelPolicyChanged: boolean;
  recalculateTripItemResults: RecalculateTripItemResult[];
}

export interface PaymentMethodPerBasketItem {
  itemId: string;
  selectedPaymentMethod: string;
}

export interface BasketItemActionDescription {
  status: BasketItemActionsEnum;
  disabledReason: string;
}

export enum BasketItemActionsEnum {
  Enabled = 'Enabled',
  Disabled = 'Disabled'
}

export type TBasketTripNote = {
  noteContent: string;
};




































































































































import { Vue, Component } from 'vue-property-decorator';
import SettingsStore from '@/modules/settings/settings.store';
import { router } from '@/router';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import FaresMappingStore from './fare-mapping.store';
import AirFaresMappingStore from './air-fare-mapping.store';
import FareMappingAirList from './FareMappingAirList.vue';
import { AirFareMappingConfigurationSupplierEnum } from '@/api/air-engine/fare-mapping-configuration.model';

@Component({
  components: {
    FareMappingAirList,
  }
})
export default class FaresMappingConfiguration extends Vue {
  $v;
  errors: any[] = [];
  navLinks = [
    {
      value: 'Flight',
      name: translate('settings-travel-policy.flight'),
      disabled: false,
    },
    {
      value: 'Rail',
      name: translate('settings-travel-policy.rail'),
      disabled: true,
    },
    {
      value: 'Accommodation',
      name: translate('settings-travel-policy.accomodation'),
      disabled: true,
    },
    {
      value: 'Car',
      name: translate('settings-travel-policy.car'),
      disabled: true,
    }
  ];
  saving: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';

  @Validation()
  validationObject() {
    return {
      currentConfigurationName: {
        required,
        maxLength: maxLength(128)
      }
    };
  }

  get links() {
    return this.navLinks;
  }

  get loading() {
    return FaresMappingStore.loading;
  }

  get configurationId() {
    return this.$router.currentRoute.params.configurationId || '';
  }

  get storeConfigurationId() {
    return FaresMappingStore.configurationId;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get fareMappingConfig() {
    return AirFaresMappingStore.currentConfiguration.fareMappingConfigurations;
  }

  get fareMapping() {
    return AirFaresMappingStore.fareMapping;
  }

  get currentConfiguration() {
    return FaresMappingStore.configuration;
  }

  get currentConfigurationName() {
    return FaresMappingStore.configurationName;
  }

  set currentConfigurationName(value) {
    FaresMappingStore.setCurrentConfigurationName(value);
  }

  get configIsEdit() {
    return !!(this.$route.params.configurationId && this.$route.params.configurationId !== 'new');
  }

  get selectedTab() {
    return FaresMappingStore.selectedTab;
  }

  get isFromManage() {
    return FaresMappingStore.isFromManage;
  }

  get errMessages() {
    return FaresMappingStore.getErrMessages;
  }

  get errorsAir() {
    return AirFaresMappingStore.errors;
  }

  get canShowCustomError() {
    return FaresMappingStore.canShowCustomError;
  }

  changeSelectedTab(value, disabled) {
    if (disabled) {
      return;
    }
    FaresMappingStore.setSelectedTab(value);
  }

  backToConfigurationsList() {
    router.push({
      name: 'fares-mapping-configurations',
      params: {
        id: this.$router.currentRoute.params.id
      }
    });
  }

  prepareNewFlightConfigs() {

    this.fareMapping.forEach(element => {
      element.airlines = element.airlines.map(airline => airline.code);
    });

    const sabreCodes = this.fareMapping.filter(code => {
      return code.supplier === AirFareMappingConfigurationSupplierEnum.Sabre;
    });
    const lufthansaCodes = this.fareMapping.filter(code => {
      return code.supplier === AirFareMappingConfigurationSupplierEnum.Lufthansa;
    });
    const aaCodes = this.fareMapping.filter(code => {
      return code.supplier === AirFareMappingConfigurationSupplierEnum.AmericanAirlines;
    });
    const baCodes = this.fareMapping.filter(code => {
      return code.supplier === AirFareMappingConfigurationSupplierEnum.BritishAirways;
    });
    const ekCodes = this.fareMapping.filter(code => {
      return code.supplier === AirFareMappingConfigurationSupplierEnum.Emirates;
    });
    const afklmCodes = this.fareMapping.filter(code => {
      return code.supplier === AirFareMappingConfigurationSupplierEnum.AirFranceKlm;
    });
    const amadeusCodes = this.fareMapping.filter(code => {
      return code.supplier === AirFareMappingConfigurationSupplierEnum.Amadeus;
    });
    const travelFusionCodes = this.fareMapping.filter(code => {
      return code.supplier === AirFareMappingConfigurationSupplierEnum.TravelFusion;
    });
    [...sabreCodes, ...lufthansaCodes, ...afklmCodes, ...amadeusCodes, ...travelFusionCodes, ...aaCodes, ...baCodes, ...ekCodes].forEach(code => {
      delete code.status;
      delete code.supplier;
    });

    let flightConfigs = [
      {
        supplier: AirFareMappingConfigurationSupplierEnum.Sabre,
        fareMapping: sabreCodes
      },
      {
        supplier: AirFareMappingConfigurationSupplierEnum.Lufthansa,
        fareMapping: lufthansaCodes
      },
      {
        supplier: AirFareMappingConfigurationSupplierEnum.AmericanAirlines,
        fareMapping: aaCodes
      },
      {
        supplier: AirFareMappingConfigurationSupplierEnum.BritishAirways,
        fareMapping: baCodes
      },
      {
        supplier: AirFareMappingConfigurationSupplierEnum.Emirates,
        fareMapping: ekCodes
      },
      {
        supplier: AirFareMappingConfigurationSupplierEnum.AirFranceKlm,
        fareMapping: afklmCodes
      },
      {
        supplier: AirFareMappingConfigurationSupplierEnum.Amadeus,
        fareMapping: amadeusCodes
      },
      {
        supplier: AirFareMappingConfigurationSupplierEnum.TravelFusion,
        fareMapping: travelFusionCodes
      }
    ];
    return {
      rootCompanyId: router.currentRoute.params.id,
      name: this.currentConfigurationName,
      fareMappingConfigurations: flightConfigs
    };
  }

  prepareExistingFlightConfigs() {
    const flightConfigs = JSON.parse(JSON.stringify(this.fareMappingConfig));
    flightConfigs.forEach(config => {
      delete config.error;
      delete config.succeeded;

      config.fareMapping = this.fareMapping.filter(code => {
        return code.supplier === config.supplier;
      });
      config.fareMapping.forEach(element => {
        element.airlines = element.airlines.map(airline => airline.code);
      });
      config.fareMapping.forEach(element => {
        delete element.status;
        delete element.supplier;
        delete element.id;
      });
    });

    return flightConfigs;
  }

  showSaveSuccessMessage() {
    const obj = {
      type: translate('settings-nego-fare.success'),
      title: translate('common.saved'),
      message: translate('settings-fares-mapping.configuration-saved-fares')
    };
    EventHandler.$emit('show-toast', obj);
  }

  async saveFaresMappingConfiguration() {
    if (this.saving) {
      return;
    }
    this.$v.currentConfigurationName.$touch();
    if (this.$v.$error) {
      return;
    }
    this.saving = true;
    FaresMappingStore.setLoading(true);
    if (!this.storeConfigurationId) {
      try {
        await FaresMappingStore.createConfiguration(this.prepareNewFlightConfigs());
        FaresMappingStore.setCurrentConfigurationName(this.currentConfigurationName);
        router.push({
          name: 'fares-mapping-configuration',
          params: {
            id: this.$route.params.id,
            configurationId: this.storeConfigurationId
          }
        });
        this.showSaveSuccessMessage();
      } catch (error) {
        FaresMappingStore.setErrMessages(error);
      }
    } else {
      const flightConfigs = this.prepareExistingFlightConfigs();
      try {
        await AirFaresMappingStore.updateConfiguration({
          configurationId: this.storeConfigurationId,
          name: this.currentConfigurationName,
          fareMappingConfigurations: flightConfigs
        });
        this.showSaveSuccessMessage();
      } catch (error) {
        FaresMappingStore.setErrMessages(error);
      }
    }
    this.saving = false;
  }

  async created() {
    FaresMappingStore.clearErrMessages();
    if (this.configurationId && this.configurationId !== 'new' && !this.isFromManage) {
      FaresMappingStore.setLoading(true);
      await AirFaresMappingStore.getAirFareMappingConfiguration(this.configurationId);
      FaresMappingStore.setLoading(false);
    }
  }
}

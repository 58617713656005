import { SearchInitModes, TravellersSelectionModes } from './profile.model';

export class CreateCompanyMessage {
  name: string;
  code: string;
  address: string;
  phoneNumber: string;
  faxNumber: string;
  emailAddress: string;
  parentId: string | null;
  agencyAssigned?: any | null;
  isDisabled: boolean;
  shortDateFormat?: string | null;
  timeFormat?: string | null;
  defaultLanguage?: string | null;
  defaultSearchMode?: SearchInitModes;
  invoicingCode?: string | null;
  travellersSelectionMode?: TravellersSelectionModes;
  isAgency: boolean;

  public constructor() {
    this.name = '';
    this.code = '';
    this.address = '';
    this.phoneNumber = '';
    this.faxNumber = '';
    this.emailAddress = '';
    this.parentId = null;
    this.agencyAssigned = null;
    this.isDisabled = false;
    this.shortDateFormat = null;
    this.timeFormat = null;
    this.defaultLanguage = null;
    this.defaultSearchMode = SearchInitModes.EnterNames;
    this.invoicingCode = null;
    this.travellersSelectionMode = TravellersSelectionModes.ByScope;
    this.isAgency = false;
  }
}

export class UpdateCompanyMessage {
  name: string | null;
  code: string | null;
  address: string;
  phoneNumber: string;
  faxNumber: string;
  emailAddress: string;
  parentId: string | null;
  isDisabled: any;
  agencyAssigned?: any | null;
  shortDateFormat?: string | null;
  timeFormat?: string | null;
  defaultLanguage?: string | null;
  defaultSearchMode?: SearchInitModes;
  invoicingCode?: string | null;
  travellersSelectionMode?: TravellersSelectionModes;

  public constructor() {
    this.name = '';
    this.code = '';
    this.address = '';
    this.phoneNumber = '';
    this.faxNumber = '';
    this.emailAddress = '';
    this.parentId = null;
    this.isDisabled = false;
    this.agencyAssigned = null;
    this.shortDateFormat = null;
    this.timeFormat = null;
    this.defaultLanguage = null;
    this.defaultSearchMode = SearchInitModes.EnterNames;
    this.invoicingCode = null;
    this.travellersSelectionMode = TravellersSelectionModes.ByScope;
  }
}

export class CompanyChangeStatusMessage {
  deactivate: boolean;

  public constructor() {
    this.deactivate = false;
  }
}

export class GetCompaniesMessage {
  phrase: string;
  limit: number | null;
  includeDisabled: boolean;
  rootId: string;

  public constructor() {
    this.phrase = '';
    this.limit = null;
    this.includeDisabled = false;
    this.rootId = '';
  }
}

export class CompanyModel {
  name: string;
  code: string;
  id: string;
  isDisabled: any;
  parentId: string | null;
  rootId: string;
  isSelected: boolean;
  phoneNumber: string;
  faxNumber: string;
  address: string;
  emailAddress: string;
  isAgency?: boolean;
  isRemovable?: boolean;
  shortDateFormat?: string | null;
  timeFormat?: string | null;
  defaultLanguage?: string | null;
  defaultSearchMode?: SearchInitModes;
  travellersSelectionMode?: TravellersSelectionModes;

  public constructor() {
    this.name = '';
    this.code = '';
    this.id = '';
    this.isDisabled = false;
    this.parentId = null;
    this.rootId = '';
    this.isSelected = false;
    this.phoneNumber = '';
    this.faxNumber = '';
    this.address = '';
    this.emailAddress = '';
    this.isAgency = false;
    this.isRemovable = false;
    this.shortDateFormat = null;
    this.timeFormat = null;
    this.defaultLanguage = null;
    this.defaultSearchMode = SearchInitModes.EnterNames;
    this.travellersSelectionMode = TravellersSelectionModes.ByScope;
  }
}

export interface CompanyConfiguration {
  id: string;
  rootCompanyId: string;
  configurationType: string;
  name: string;
  inheritConfiguration?: boolean;
}

export interface RootCompanyModel {
  companyId: string;
  companyName: string;
  companyCode: string;
  rootCompanyId: string;
  rootCompanyName: string;
  rootCompanyCode: string;
  isRoot: boolean;
  phoneNumber: string;
  faxNumber: string;
  address: string;
  emailAddress: string;
}

export class CompanyDataModel {
  id: string;
  rootId: string;
  parentId: string | null;
  name: string;
  code: string;
  phoneNumber: string;
  emailAddress: string;
  address: string;
  faxNumber: string;
  invoicingCode: string;
  parentCompanyName: string;
  parentCompanyCode: string;
  parentCompanyIsDisabled: boolean;
  gdsRecordLocator?: string;
  isAdministrativeDataModificationEnabled?: boolean;
  isDisabled: any;
  isAgency: boolean;
  shortDateFormat: string | null;
  longDateFormat: string | null;
  timeFormat: string | null;
  defaultSearchMode?: SearchInitModes;
  availableParentCompanies: ShortUnitInfo[];
  defaultLanguage: string | null;
  travellersSelectionMode?: TravellersSelectionModes;

  public constructor() {
    this.name = '';
    this.code = '';
    this.id = '';
    this.isDisabled = false;
    this.parentId = null;
    this.rootId = '';
    this.parentCompanyName = '';
    this.parentCompanyCode = '';
    this.parentCompanyIsDisabled = false;
    this.phoneNumber = '';
    this.faxNumber = '';
    this.invoicingCode = '';
    this.address = '';
    this.emailAddress = '';
    this.isAgency = false;
    this.shortDateFormat = null;
    this.longDateFormat = null;
    this.timeFormat = null;
    this.availableParentCompanies = [];
    this.defaultLanguage = null;
    this.defaultSearchMode = SearchInitModes.EnterNames;
    this.travellersSelectionMode = TravellersSelectionModes.ByScope;
  }
}
export interface ShortUnitInfo {
  id: string;
  code: string;
  name: string;
}

export interface CanDisableOrDeleteCompanyUnitResponse {
  affectedCompanies: ShortUnitInfo[];
}

export enum AvailableDateFormats {
  AvailableDateFormat1 = 'DD/MM/YYYY',
  AvailableDateFormat2 = 'MM/DD/YY',
  AvailableDateFormat3 = 'YYYY-MM-DD',
  AvailableDateFormat4 = 'DD-MM-YYYY',
}

export enum AvailableTimeFormats {
  AvailableTimeFormat1 = 'HH:mm',
  AvailableTimeFormat2 = 'h:mm a',
}

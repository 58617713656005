



























































import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class GlobalSettingsIntro extends Vue {
  showQuitConfirm: boolean = false;

  quitGlobalSettings() {
    this.showQuitConfirm = true;
  }

  quitGlobalSettingsNow() {
    this.showQuitConfirm = false;
    this.$router.push('/settings');
  }
}

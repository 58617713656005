

































import settings from '@/settings';
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import EventHandler from '@/services/event-handler';
import Croppie from 'croppie';
import 'croppie/croppie.css';
import accountStore from '@/store/account.store';
import { translate } from '@/i18n';

const IMAGE_BOUNDARY_OFFSET = 50;

@Component({})
export default class ChangeLogoPopup extends Vue {
  @Prop({ default: false }) showPopup: boolean = false;
  url: string = ``;
  image?: any = null;
  croppie?: Croppie = null;
  errors: any[] = [];
  base64StringSize: number = settings.companyLogo.base64SizeLimitInB;

  selectFile() {
    let input = this.$refs.logoUploadInput as HTMLElement;
    input.click();
  }

  setupCroppie() {
    let placeholder = this.$refs.logoPlaceholder as HTMLElement;
    if (placeholder) {
      this.croppie = new Croppie(placeholder, {
        viewport: {
          width: settings.companyLogo.width,
          height: settings.companyLogo.height,
          type: 'square'
        },
        boundary: {
          width: settings.companyLogo.width + IMAGE_BOUNDARY_OFFSET,
          height: settings.companyLogo.height + IMAGE_BOUNDARY_OFFSET,
        },
        showZoomer: true,
        enableOrientation: true,
      });    
      this.croppie.bind({
        url: this.image,
      });
    }
  }

  onFileChange(e) {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      return;
    }
    this.errors = [];
    this.createImage(files[0]);
  }

  createImage(file) {
    let image = new Image();
    let reader = new FileReader();
    reader.onload = (e: ProgressEvent) => {
      if (e.target) {
        let fileReader = e.target as FileReader;
        this.image = fileReader.result;
      }
      EventHandler.$emit('tmc-logo-created', this.image);
    };
    reader.readAsDataURL(file);
  }

  async upload() {
    this.errors = [];
    if (!this.image) {
      this.errors.push({ message: translate('settings.no-photo') });
      return;
    }
    let result = await this.croppie.result({
      type: settings.companyLogo.returnType,
      format: settings.companyLogo.returnFormat,
      size: { width: settings.companyLogo.width, height: settings.companyLogo.height },
    });
    EventHandler.$emit('tmc-logo-uploaded', result);
    this.closeLogoPopup();
  }

  onUpload(data) {
    this.image = data;
    if (this.croppie) {
      this.croppie.destroy();
    }
    this.setupCroppie();
  }

  closeLogoPopup() {
    this.image = null;
    EventHandler.$emit('close-logo-popup');
  }

  tmcLogoCreated(data) {
    this.onUpload(data);
  }

  mounted() {
    EventHandler.$on('tmc-logo-created', this.tmcLogoCreated);
  }

  beforeDestroy() {
    EventHandler.$off('tmc-logo-created', this.tmcLogoCreated);
    if (this.croppie) {
      this.croppie.destroy();
    }
  }
}

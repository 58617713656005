






























































































































import { Vue, Component, Watch } from 'vue-property-decorator';

import { router } from '@/router';
import SettingsStore from '@/modules/settings/settings.store';
import { ServerError } from '@/api/home/home.model';
import { ConfigurationRow } from '@/api/profile/configurations.model';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import { DisplayPreferencesApi } from '@/api/profile/display-preferences.api';
import DisplayPreferencesFlightStore from '@/modules/settings/display-preferences/display-preferences-flight.store';
import DisplayPreferencesCarStore from '@/modules/settings/display-preferences/display-preferences-car.store';
import DisplayPreferencesStore from '@/modules/settings/display-preferences/display-preferences.store';
import DisplayPreferencesFlight from './DisplayPreferencesFlight.vue';
import DisplayPreferencesCar from './DisplayPreferencesCar.vue';
import EventBus from '@/services/event-handler';
import {
  GeographicalAreaTypes,
  RailGeoPointType
} from '@/api/dictionary/dictionary.model';
import { translate } from '@/i18n';

@Component({
  components: {
    DisplayPreferencesFlight,
    DisplayPreferencesCar,
  }
})
export default class DisplayPreferencesConfiguration extends Vue {
  isReadOnly: boolean = false;
  configurationData: ConfigurationRow | null = null;
  formHasError: boolean = false;
  emptyGuid: string = '00000000-0000-0000-0000-000000000000';
  formPending: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';

  navLinks = [
    {
      value: 'Flight',
      name: translate('settings-travel-policy.flight'),
    }, {
      value: 'Car',
      name: translate('settings-travel-policy.car'),
    },
  ];

  serverErrors: ServerError[] = [];
  $v;

  @Validation()
  validationObject() {
    return {
      configurationName: {
        required,
        maxLength: maxLength(64)
      }
    };
  }

  get loadErrors() {
    return DisplayPreferencesStore.getErrors;
  }

  get loadingList() {
    return DisplayPreferencesStore.loading;
  }
  

  get displayPreferencesFlight() {
    return DisplayPreferencesFlightStore.displayPreferences;
  }

  get configurationName() {
    return DisplayPreferencesStore.currentConfigName;
  }

  set configurationName(value) {
    DisplayPreferencesStore.setCurrentConfigName(value);
  }

  get currentConfigName() {
    return DisplayPreferencesStore.currentConfigName;
  }

  get currentConfigurationName() {
    return this.$route.params.configurationName;
  }

  get currentConfigurationId() {
    return this.$route.params.configurationId;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get rootCompanyId() {
    if (!this.currentCompany) {
      return this.emptyGuid;
    }
    return this.currentCompany.rootCompanyId;
  }

  get links() {
    return this.navLinks;
  }

  get currentSelectedItem() {
    return DisplayPreferencesStore.currentSelectedItem;
  }

  get canShowCustomError() {
    return DisplayPreferencesStore.canShowCustomError;
  }

  rowClicked(path) {
    if (path) {
      router.push({
        name: path,
        params: {
          configurationId: this.currentConfigurationId,
        },
      });
    }
  }

  linkClicked(e) {
    e.preventDefault();
  }

  changeConfiguration(name) {
    DisplayPreferencesStore.setCurrentSelectedItem(name);
  }

  returnToConfigurations() {
    router.push({
      name: 'display-preferences-configurations'
    });
  }

  prepareRailRoutings(routings: any[] | null) {
    if (!routings) {
      return;
    }
    const routingsMapped = routings.map(e => {
      return (e.from && e.to) ?
      {
        from : {
          type: RailGeoPointType[e.from.type],
          id: e.from.id,
        },
        to: {
          type: RailGeoPointType[e.to.type],
          id: e.to.id,
        }
      }
      : null;
    });
    const routingsFiltered = routingsMapped.filter(Boolean);
    return routingsFiltered.length ? routingsFiltered : null;
  }

  prepareAirRoutings(routings: any[] | null) {
    if (!routings) {
      return;
    }
    const routingsMapped = routings.map(e => {
      return (e.from && e.to) ?
      {
        from: {
          code: e.from.type === 'CityAirport' ? (e.from.airportCode || e.from.code) : (e.from.cityCode || e.from.code),
          type: e.from.type,
        },
        to: {
          code: e.to.type === 'CityAirport' ? (e.to.airportCode || e.to.code) : (e.to.cityCode || e.to.code),
          type: e.to.type,
        }
      }
      : null;
    });
    const routingsFiltered = routingsMapped.filter(Boolean);
    return routingsFiltered;
  }

  prepareGeographicalAreas(areas: any[] | null) {
    if (!areas) {
      return;
    }
    const areasMapped = areas.map(e => {
      return (e.from && e.to) ?
      { 
        From: {
          code: e.from.code,
          type: GeographicalAreaTypes[e.from.type]
        },
        to: {
          code: e.to.code,
          type: GeographicalAreaTypes[e.to.type]
        }
      } : null;
    });
    const areasFiltered = areasMapped.filter(Boolean);
    return areasFiltered;
  }

  fligthRequest() {
    let displayPreferences = [] as  any;
    this.displayPreferencesFlight.displayPreferences.forEach(element => {
      let rule = {
        operatingAirlines: element.operatingAirlines || [],
        airportRoutings: this.prepareAirRoutings(element.airportRoutings),
        geographicalRoutings: this.prepareGeographicalAreas(element.geographicalRoutings),
        isMixedConnection: element.isMixedConnection,
        ruleName: element.ruleName,
        ruleWeight: element.ruleWeight,
        supplier: element.supplier,
        withCompliantTravelPolicy: element.withCompliantTravelPolicy,
        withDiscountCard: element.withDiscountCard,
        withFlexibleRates: element.withFlexibleRates,
      };

      if (this.currentConfigurationId) {
        let id;
        if (element.status) {
          id = element.status === DisplayPreferencesFlightStore.draftEditStatus ? element.id : element.id || this.emptyGuid;
        } else {
          id = element.id;
        }

        displayPreferences.push({id, ...rule});
      } else {
        displayPreferences.push(rule);
      }
    });

    return {
      name: this.configurationName,
      withCompliantTravelPolicy: DisplayPreferencesFlightStore.getFlightDisplayPreferences.withCompliantTravelPolicy,
      hideDuplicatedGdsFares: DisplayPreferencesFlightStore.getFlightDisplayPreferences.hideDuplicatedGdsFares,
      defaultSortingMethod: DisplayPreferencesFlightStore.getFlightDisplayPreferences.defaultSortingMethod,
      displayPreferences,
    };
  }

  toastShow() {
    let obj = {
      type: translate('settings-travel-policy.success'),
      title: translate('common.saved'),
      message: translate('settings-display-preferences.preferences-saved')
    };
    EventBus.$emit('show-toast', obj);
  }

  errorToast() {
    let obj = {
      type: 'error',
      title: translate('common-error.error'),
      message: translate('settings-display-preferences.preferences-saving-error')
    };
    EventBus.$emit('show-toast', obj);
  }

  async saveNew(flightRequest) {
    try {
      this.formPending = true;
      let result = await DisplayPreferencesApi.createConfiguration({
        ...flightRequest,
        rootCompanyId: this.rootCompanyId,
      });

      if (result && result.data) {
        router.push({
          name: 'display-preferences-configuration',
          params: {
            configurationId: result.data.configurationId
          },
        });
        DisplayPreferencesFlightStore.setFlightRules([]);
        await DisplayPreferencesFlightStore.getFlightRulesConfiguration(result.data.configurationId);
        await DisplayPreferencesCarStore.saveCarRulesConfiguration({
          configurationId: result.data.configurationId,
          rootCompanyId: this.rootCompanyId,
        });
      
        this.toastShow();
      } else {
        this.errorToast();
      }
    } catch (error) {
      DisplayPreferencesStore.setErrors(error);
    } finally {
      this.formPending = false;
    }
  }

  async update(flightRequest) {
    try {
      this.formPending = true;
      await DisplayPreferencesApi.updateConfiguration(this.currentConfigurationId, flightRequest);
      DisplayPreferencesFlightStore.setFlightRules([]);
      await DisplayPreferencesFlightStore.getFlightRulesConfiguration(this.currentConfigurationId);
      await DisplayPreferencesCarStore.updateCarRulesConfiguration(this.currentConfigurationId);

      this.toastShow();
    } catch (error) {
      DisplayPreferencesStore.setErrors(error);
    } finally {
      this.formPending = false;
    }
  }

  save() {
    this.checkForm();
    if (this.displayPreferencesFlight.displayPreferences && !this.formHasError) {
      const flightRequest = this.fligthRequest();
      if (this.currentConfigurationId) {
        this.update(flightRequest);
      } else {
        this.saveNew(flightRequest);
      }
    }
  }

  checkForm() {
    this.formHasError = false;
    this.$v.configurationName.$touch();
    if (this.$v.configurationName.$pending || this.$v.configurationName.$error) {
      this.formHasError = true;
    }
  }

  @Watch('$route', {deep: true,  immediate: true})
  onRouteChange() {
    EventBus.$emit('refresh-data');
  }

  async created() {
    DisplayPreferencesStore.setCustomError(false);
    this.isReadOnly = !!this.$route.query.readOnly;
    await DisplayPreferencesFlightStore.getFlightRulesConfiguration(this.currentConfigurationId);
    await DisplayPreferencesCarStore.getCarRulesConfiguration(this.currentConfigurationId);
  }
}

import RailBenerailSeatSelection from '@/modules/basket/basket-rail-seats-extras/step/RailBenerailSeatSelection.vue';
import RailNtvSeatSelection from '@/modules/basket/basket-rail-seats-extras/step/RailNtvSeatSelection.vue';
import RailSncfAncillariesAndSeatSelection from '@/modules/basket/basket-rail-seats-extras/step/RailSncfAncillariesAndSeatSelection.vue';
import RailTrenitaliaSeatSelection from '@/modules/basket/basket-rail-seats-extras/step/RailTrenitaliaSeatSelection.vue';

const StepComponent = {
  RAIL_BENERAIL_SEAT_SELECTION: RailBenerailSeatSelection,
  RAIL_NTV_SEAT_SELECTION: RailNtvSeatSelection,
  RAIL_SNCF_ANCILLARIES_AND_SEAT_SELECTION: RailSncfAncillariesAndSeatSelection,
  RAIL_TRENITALIA_SEAT_SELECTION: RailTrenitaliaSeatSelection,
};

type TStepComponent = typeof StepComponent;

export const getComponentByCode = (code: keyof TStepComponent): TStepComponent[keyof TStepComponent] => {
  return StepComponent[code];
};



























































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required } from 'vuelidate/lib/validators';

import BasketStore from '@/modules/basket/basket.store';
import AirResultsRow from './AirResultsRow.vue';
import { BasketItemApi } from '@/api/trip/basket-item.api';

@Component({
  components: {
    AirResultsRow,
  },
})
export default class BasketAirMissedSavingsPopup extends Vue {
  reason: string = '';
  errors: any[] = [];
  loadingData: boolean = false;
  savingData: boolean = false;
  info: any = null;
  $v;



  @Validation()
  validationObject() {
    return {
      reason: {
        required,
      },
    };
  }



  get showing() {
    return BasketStore.showingMissedSavingsModal;
  }

  get tripItemId() {
    return BasketStore.missedSavingsTripItemId;
  }

  get loading() {
    return this.loadingData;
  }

  get reasonObject() {
    return this.reasonOptions.find(item => item.value === this.reason) || null;
  }

  set reasonObject(value) {
    if (!value) {
      this.reason = '';
      return;
    }
    this.reason = value.value;
  }

  get reasonOptions() {
    if (!this.missedSavingReasonCodes) {
      return [];
    }
    return this.missedSavingReasonCodes
      .map(item => ({
        value: item.reasonCode,
        label: item.displayValue,
      }));
  }

  get missedSavingsInfo() {
    return this.info;
  }

  get cheapestRecommendation() {
    if (!this.missedSavingsInfo) {
      return null;
    }
    return this.missedSavingsInfo.cheapestRecommendation;
  }

  get chosenRecommendation() {
    if (!this.missedSavingsInfo) {
      return null;
    }
    return this.missedSavingsInfo.choosenRecommendation;
  }

  get missedSavingReasonCodes() {
    if (!this.missedSavingsInfo) {
      return null;
    }
    return this.missedSavingsInfo.availableReasonCodes;
  }

  get missedSavingValue() {
    if (
      !this.missedSavingsInfo ||
      !this.missedSavingsInfo.missedSavingValue
    ) {
      return null;
    }
    return this.missedSavingsInfo.missedSavingValue;
  }

  get cheapestOffer() {
    if (!this.cheapestRecommendation) {
      return null;
    }

    return this.cheapestRecommendation.airRecommendation;
  }

  get chosenOffer() {
    if (!this.chosenRecommendation) {
      return null;
    }

    return this.chosenRecommendation.airRecommendation;
  }



  @Watch('showing')
  onShowingChanged(value) {
    if (!value) {
      this.reason = '';
      this.errors = [];
      this.$v.$reset();
    } else {
      this.loadData();
    }
  }

  async loadData() {
    if (this.loadingData) {
      return;
    }

    this.loadingData = true;
    try {
      const response = await BasketItemApi.getMissedSavings(this.tripItemId);

      if (response && response.data) {
        this.info = response.data;
        this.reason = this.info.reasonCode ? this.info.reasonCode.reasonCode : '';
      }
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    }

    this.loadingData = false;
  }

  closePopup() {
    BasketStore.toggleMissedSavingsModal(false);
  }

  async save() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    this.savingData = true;
    try {
      await BasketItemApi.updateMissedSavings(this.tripItemId, {
        reasonCode: this.reasonObject.value,
        displayValue: this.reasonObject.label,
      });

      BasketStore.updateMissedSavingsReason({
        tripItemId: this.tripItemId,
        value: this.reasonObject.label,
      });

      this.closePopup();
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    }
    this.savingData = false;
  }
}

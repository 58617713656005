






































































































































































































import { Component, Watch, } from 'vue-property-decorator';

import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { BaseTableParams } from '@/core/base-table-params';
import { VirtualCardsConfigurationsDataRow } from './virtualCardsConfigurations.model';
import { ProfileConfigurationsApi } from '@/api/profile/configurations.api';
import { ConfigurationRow, companyConfigurationType } from '@/api/profile/configurations.model';
import SettingsStore from '@/modules/settings/settings.store';
import VirtualCardsStore from './virtual-cards.store';
import EventHandler from '@/services/event-handler';
import { Permission } from '@/const/permission.enum';
import { translate } from '@/i18n';
import { sortByName } from '@/core/utils';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Component({})
export default class VirtualCardsConfigurations extends BaseTable<VirtualCardsConfigurationsDataRow> {
  useQueryParams: boolean = true;
  params: BaseTableParams = new BaseTableParams({
    size: 10,
    page: 1,
    usePageParam: true,
  });
  fields = {
    name: {
      sortable: false,
      label: translate('common.configuration-name')
    },
    actions: {
      sortable: false,
      label: ''
    }
  };
  serverErrorsMessages = {
    FIELD_VALUE_TOOLONG: translate('common-error.field-too-long')
  };
  serverErrors: any = [];
  items: ConfigurationRow[] = [];
  company = this.currentCompany;
  menuActive: boolean = false;
  configuration: ConfigurationRow | null = null;
  removePopup: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  loading: boolean = false;
  errorsOnPopup: any[] = [];
  Form: any = {
    searchName: '',
  };


  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get isRootCompany() {
    return this.currentCompany!.isRoot;
  }

  get count() {
    return this.items.length;
  }

  get addNewConfigurationUrl() {
    return sanitizeUrl('/settings/' + this.currentCompany!.rootCompanyId + '/virtual-cards-configurations/new/configuration');
  }

  editConfigurationUrl(item) {
    return sanitizeUrl('/settings/' + this.currentCompany!.companyId + '/virtual-cards-configurations/' + item.id + '/configuration');
  }

  rowClicked(item, preventRedirect = false) {
    if (item) {
      this.resetData();
      this.configuration = item;
      VirtualCardsStore.setConfigurationName(item.name);
      VirtualCardsStore.setConfigurationId(item.id);
      if (!preventRedirect) {
        if (this.$hasAccess('CanEditVirtualCards')) {
          router.push({
            name: 'virtual-cards-configuration',
            params: {
              configurationId: item.id
            }
          });
        } else {
          router.push({
            name: 'virtual-cards-configuration',
            params: {
              configurationId: item.id
            },
            query: {
              readOnly: 'true'
            }
          });
        }
      }
    }
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  resetData() {
    VirtualCardsStore.clearData();
    VirtualCardsStore.clearErrors();
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }

  @Debounce({ delay: DebounceConst.defaultDelay })
  getNames() {
    this.submitForm();
  }

  async submitForm() {
    this.resetList();
    this.reload();
  }

  @Watch('currentCompany')
  companyChange(value) {
    this.company = value;
    this.getItems();
  }

  async removeConfiguration() {
    try {
      this.loading = true;
      await ProfileConfigurationsApi.deleteConfiguration(this.configuration!.id, Permission.CanEditVirtualCards);
      this.getItems();

      EventHandler.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-virtual-cards.configuration-removed'),
        message: translate('settings-virtual-cards.configuration-removed-message')
      });
    } catch (error) {
      this.errorsOnPopup = this.$handleErrors(error, true);
      this.loading = false;
    } finally {
      if (!this.errorsOnPopup.length) {
        this.removePopup = false;
        this.reload();
      }
    }
  }

  async getItems() {
    this.serverErrors = [];
    let request = {
      searchName: this.Form.searchName,
    };

    try {
      this.loading = true;

      const result = await ProfileConfigurationsApi.getByRootCompanyId(this.company!.rootCompanyId, companyConfigurationType.virtualCardConfiguration, this.params.size, this.params.page, Permission.CanEditVirtualCards, request.searchName);
      if (result && result.data) {
        this.items = result.data.results;

        return {
          page: result.data.page,
          results: sortByName(result.data.results),
        };
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  toggleVirtualCardsConfigurationMenu(item) {
    if (!this.configuration || this.configuration.id !== item.id) {
      this.configuration = item;
      this.menuActive = true;
    } else {
      this.configuration = item;
      this.menuActive = !this.menuActive;
    }
  }

  created() {
    this.configuration = null;
  }
}


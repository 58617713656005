
























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import cloneDeep from 'lodash/cloneDeep';
import {
  TTravelfusionItemsModel,
  TTravelfusionParameter,
  TTravelfusionSelectedParameter,
} from '@/api/trip/basket-air-seats-extras.model';
import ParameterBoolean from '@/modules/basket/parameters/parameter-boolean.vue';
import ParameterFormattedtext from '@/modules/basket/parameters/parameter-formattedtext.vue';
import ParameterText from '@/modules/basket/parameters/parameter-text.vue';
import ParameterCountrycode from '@/modules/basket/parameters/parameter-countrycode.vue';
import ParameterSingleselect from '@/modules/basket/parameters/parameter-singleselect.vue';
import ParameterMultiselect from '@/modules/basket/parameters/parameter-multiselect.vue';
import BasketStore from '@/modules/basket/basket.store';

@Component({
  components: {
    ParameterBoolean,
    ParameterFormattedtext,
    ParameterText,
    ParameterCountrycode,
    ParameterSingleselect,
    ParameterMultiselect,
  },
})
export default class TravelfusionExtrasTile extends Vue {
  @Prop() value!: TTravelfusionItemsModel;
  @Prop() parameters!: TTravelfusionParameter[];
  @Prop() profileId!: string;

  selectedParameter: TTravelfusionSelectedParameter | null = null;
  cloneParameters: TTravelfusionParameter[] = [];

  get itemId() {
    return BasketStore.bookingStepDef.tripItemId;
  }

  get selectedItems() {
    const selectedItems = this.value.items.filter(({ profileId }) => profileId === this.profileId);
    return this.selectParameters.filter((parameter) => {
      const selectedItem = selectedItems.find((item) => {
        const isKey: boolean = item.key === parameter.key;
        const isValue: boolean = (item.value || '')
          .split(';')
          .some((splitValue: string) => splitValue === parameter.value);
        return isKey && isValue;
      });
      return !!selectedItem;
    });
  }

  get componentParameters() {
    return this.cloneParameters.filter((item) => {
      const isSelect = item.type === 'SingleSelect' || item.type === 'MultiSelect';
      return item.isRequired || !isSelect;
    });
  }

  get selectParameters() {
    return this.cloneParameters.reduce((prev, next) => {
      const isSelect = next.type === 'SingleSelect' || next.type === 'MultiSelect';
      if (!next.isRequired && isSelect) {
        const groupLabel = {
          optionText: next.displayText,
          $isDisabled: true,
        };
        const options = (next.options || []).map((item) => ({
          ...next,
          value: item.key,
          optionText: item.displayText,
          totalPrice: item.price,
        }));
        return prev.concat([groupLabel, ...options]);
      }
      return prev;
    }, [] as Partial<TTravelfusionSelectedParameter>[]);
  }

  getComponentByType(type: string) {
    return `parameter-${type.toLowerCase()}`;
  }

  onParameterUpdate(value: string, { key }: TTravelfusionParameter) {
    const param = this.value.items.filter((item) => {
      const isKey = item.key === key;
      const isProfileId = item.profileId === this.profileId;
      const isItemId = item.itemId === this.itemId;
      return !(isKey && isProfileId && isItemId);
    });
    this.$emit('input', {
      items: param.concat({
        itemId: this.itemId,
        profileId: this.profileId,
        key,
        value,
      }),
    });
  }

  onAddClick(parameter: TTravelfusionSelectedParameter | null) {
    if (!parameter) {
      return;
    }
    const selectedItem = this.value.items.find((item) => {
      const isKey = item.key === parameter.key;
      const isProfileId = item.profileId === this.profileId;
      const isItemId = item.itemId === this.itemId;
      return isKey && isProfileId && isItemId;
    });
    if (selectedItem && parameter.type === 'MultiSelect') {
      this.$emit('input', {
        items: this.value.items.map((item) => {
          if (item.key === parameter.key) {
            const value = (item.value || '')
              .split(';')
              .filter((itemValue: string) => itemValue !== parameter.value)
              .concat(parameter.value as string)
              .join(';');
            return { ...item, value };
          }
          return item;
        }),
      });
    } else {
      this.$emit('input', {
        items: this.value.items
          .filter((item) => {
            const isKey = item.key === parameter.key;
            const isProfileId = item.profileId === this.profileId;
            const isItemId = item.itemId === this.itemId;
            return !(isKey && isProfileId && isItemId);
          })
          .concat({
            itemId: this.itemId,
            profileId: this.profileId,
            key: parameter.key,
            value: parameter.value,
          }),
      });
    }
    this.selectedParameter = null;
  }

  onSelectedItemRemove(parameter: Partial<TTravelfusionSelectedParameter>) {
    if (parameter.type === 'MultiSelect') {
      this.$emit('input', {
        items: this.value.items
          .map((item) => {
            const isKey = item.key === parameter.key;
            const isProfileId = item.profileId === this.profileId;
            const isItemId = item.itemId === this.itemId;
            if (isKey && isProfileId && isItemId) {
              const value = (item.value || '')
                .split(';')
                .filter((itemValue: string) => itemValue !== parameter.value)
                .join(';');
              return { ...item, value };
            }
            return item;
          })
          .filter((item) => item.value),
      });
    } else {
      this.$emit('input', {
        items: this.value.items.filter((item) => {
          const isKey = item.key === parameter.key;
          const isProfileId = item.profileId === this.profileId;
          const isItemId = item.itemId === this.itemId;
          return !(isKey && isProfileId && isItemId);
        }),
      });
    }
  }

  mounted() {
    const parameters = this.parameters
      .filter((item) => {
        const isTypeRemark = item.type === 'Remark';
        const isTypeOther = item.type === 'Other';
        const isKeyDateOfBirth = item.key === 'DateOfBirth';
        return !isTypeRemark && !isTypeOther && !isKeyDateOfBirth;
      })
      .sort((a, b) => {
        return a.displayText.localeCompare(b.displayText);
      });
    this.cloneParameters = cloneDeep(parameters);
  }
}















































import { Vue, Prop, Component } from 'vue-property-decorator';

import { BasketItemApi } from '@/api/trip/basket-item.api';
import BasketStore from './basket.store';
import BasketProcessingItem from './BasketProcessingItem.vue';

@Component({
  components: {
    BasketProcessingItem,
  },
})
export default class BasketLufthansa3DSPopup extends Vue {
  @Prop() show!: boolean;

  errors: any = [];

  get statusInfo() {
    return BasketStore.status;
  }

  get item() {
    if (!this.statusInfo || !this.statusInfo.items) {
      return null;
    }
    return this.statusInfo.items.find(item => item.pendingActions && !!item.pendingActions.find(action => action.code === 'REDIRECT_TO_URL')) || null;
  }

  get pendingAction() {
    if (!this.item) {
      return null;
    }
    return this.item.pendingActions.find(action => action.code === 'REDIRECT_TO_URL');
  }

  get paymentMethodId() {
    if (!this.pendingAction || !this.pendingAction.additionalData) {
      return '';
    }
    return this.pendingAction.additionalData.paymentMethodId;
  }

  async stopPaymentProcess() {
    this.errors = [];
    try {
      await BasketItemApi.cancel3dsChallange(this.item!.id, this.paymentMethodId);

      this.checkStatus();
    } catch (errors) {
      this.errors = this.$handleErrors(errors);
    }
  }

  checkStatus() {
    BasketStore.setProcessing(true);
    BasketStore.setShowing3DSInterruptedPopup(false);
    BasketStore.postponeProcess();
    this.errors = [];
  }
}



























































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';

import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { 
  CustomFieldDictionaryItemEntry,
} from '@/api/custom-fields/custom-fields.model';
import { CustomFieldDictionariesApi } from '@/api/custom-fields/custom-fields-dictionary.api';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import SettingsStore from '@/modules/settings/settings.store';
import $handleErrors from '@/core/errors/handle-errors.service';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength, } from 'vuelidate/lib/validators';
import CustomFieldsStore from './custom-fields.store';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';

const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

@Component({})
export default class CustomFieldDictionaryItems extends BaseTable<CustomFieldDictionaryItemEntry> {
  @Prop({}) readonly!: boolean;
  @Prop({}) canAddNewItem!: boolean;
  @Prop({ default: '' }) dictionaryId!: string;

  $v;
  params: BaseTableParams = new BaseTableParams({
    sortBy: 'Value',
  });
  loading: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  selected: CustomFieldDictionaryItemEntry = new CustomFieldDictionaryItemEntry();
  selectedIndex: number = -1;
  menuActive: boolean = false;
  removePopup: boolean = false;
  addPopup: boolean = false;
  isEdit: boolean = false;
  errors: any[] = [];
  errorsOnPopup: any[] = [];
  formHasErrors: boolean = false;
  fields = {
    name: {
      sortable: false,
      label: translate('settings-custom-fields.dictionary-item-name'),
    },
    description: {
      sortable: false,
      label: translate('settings-custom-fields.description'),
    },
    code: {
      sortable: false,
      label: translate('settings-custom-fields.code'),
    },
    isActive: {
      sortable: false,
      label: translate('settings-custom-fields.active'),
    },
    actions: {
      sortable: false,
      label: ''
    }
  };
  query: string = '';
  searchQuery: string = '';
  queryInProgress: boolean = false;

  @Validation()
  validationObject() {
    return {
      selected: {
        name: {
          required,
          maxLength: maxLength(96)
        },
        description: {
          maxLength: maxLength(1024)
        },
        code: {
          required,
          maxLength: maxLength(64),
        }
      }
    };
  }

  get entries() {
    return this.dictionary.items;
  }

  get dictionary() {
    return CustomFieldsStore.dictionaryDefinition;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return EMPTY_GUID;
    }
  }

  get isRootCompany() {
    return this.currentCompany!.isRoot;
  }

  get count() {
    return this.entriesFiltered.length;
  }

  get entriesFiltered() {
    const queryLowercase = this.searchQuery.toLowerCase();

    return this.entries
      .sort((a, b) => a.code.localeCompare(b.code))
      .filter(item => {
        if (!queryLowercase) {
          return true;
        }

        return item.name.toLowerCase().indexOf(queryLowercase) > -1 ||
          item.code.toLowerCase().indexOf(queryLowercase) > -1;
      });
  }

  edit(item) {
    this.errorsOnPopup = [];
    this.selected = new CustomFieldDictionaryItemEntry(item.item);
    this.selectedIndex = item.index + ((this.params.page - 1) * this.params.size);
    this.menuActive = false;
    this.isEdit = true;
    this.addPopup = true;
  }

  remove(row) {
    this.selectedIndex = row.index;
    this.errorsOnPopup = [];
    this.menuActive = false;
    this.removePopup = true;
  }

  addNew() {
    this.selected = new CustomFieldDictionaryItemEntry();
    this.isEdit = false;
    this.errorsOnPopup = [];
    this.$v.selected.$reset();
    this.addPopup = true;
  }

  addDictionaryItem() {
    this.checkForm();
    if (!this.formHasErrors) {
      if (this.isEdit) {
        CustomFieldsStore.modifyDictionaryItem({ item: this.selected, index: this.selectedIndex });
      } else {
        CustomFieldsStore.addDictionaryItem(this.selected);
      }
      this.reload();
      this.addPopup = false;
    }
  }

  resetData() {
    CustomFieldsStore.clearData();
  }

  async getItems(query: BaseTableParams) {
    return {
      page: {
        moreResultsAvailable: query.page * query.size < this.count,
        pageNo: query.page,
        pageSize: query.size,
      },
      results: this.entriesFiltered
        .slice((query.page - 1) * query.size, query.page * query.size),
    };
  }

  async removeItem() {
    if (this.selected.id) {
      try {
        this.loading = true;
        await CustomFieldDictionariesApi.deleteCustomFieldDictionaryItem(this.selected.id);
        const obj = {
          type: translate('common.success'),
          title: translate('settings-custom-fields.dictionary-entry-removed'),
          message: translate('settings-gds.info-removed')
        };
        EventHandler.$emit('show-toast', obj);
      } catch (error) {
        this.errorsOnPopup = $handleErrors(error, true);
      } finally {
        if (!this.errorsOnPopup.length) {
          const id = CustomFieldsStore.dictionaryDefinition && CustomFieldsStore.dictionaryDefinition.id ? CustomFieldsStore.dictionaryDefinition.id : EMPTY_GUID;
          await CustomFieldsStore.getCustomFieldDictionaryEntries(id);
          this.reload();
          this.removePopup = false;
        }
        this.loading = false;
      }
    } else {
      CustomFieldsStore.removeDictionaryItem(this.selectedIndex);
      this.reload();
      this.removePopup = false;
    }
    
  }

  checkForm() {
    this.formHasErrors = false;
    this.$v.selected.$touch();
    if (this.$v.selected.$pending || this.$v.selected.$error) {
      this.formHasErrors = true;
    }
  }

  toggleMenu(item) {
    if (!this.menuActive || (this.selected !== item.item)) {
      this.selected = item.item;
      this.selectedIndex = item.index;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'queryInProgress',
  })
  updateSearchQuery() {
    this.searchQuery = this.query;
    this.$nextTick(() => {
      this.currentPage = 1;
      this.queryInProgress = false;
      this.reload();
    });
  }

  @Watch('query')
  onQueryChange() {
    this.updateSearchQuery();
  }

  async mounted() {
    let dictionaryId = this.dictionaryId;

    if (
      !dictionaryId &&
      CustomFieldsStore.dictionaryDefinition &&
      CustomFieldsStore.dictionaryDefinition.id
    ) {
      dictionaryId = CustomFieldsStore.dictionaryDefinition.id;
    }
    if (!dictionaryId) {
      return;
    }

    await CustomFieldsStore.getCustomFieldDictionaryEntries(dictionaryId);
    this.reload();
  }
}

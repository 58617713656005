








































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import { translate } from '@/i18n';
import AccountStore from '@/store/account.store';
import { DictionaryApi } from '@/api/dictionary/dictionary.api';
import UiCustomMessageError from '@/controls/UiCustomMessageError.vue';

const errorsIcon = {
  Unknown: 'error',
  Error: 'error',
  Warning: 'warning',
  Info: 'info',
};

@Component({
  components: {
    UiCustomMessageError,
  }
})
export default class BasketBookingError extends Vue {
  @Prop() error!: any;
  @Prop() type!: string;

  loading: boolean = false;
  errors: any = [];
  showTranslation: boolean = false;
  translation: string = '';
  isOpened: boolean = false;
  hasMore: boolean = false;
  hasMoreCheckId = -1;



  get classes() {
    return {
      'error': this.type === 'Error',
      'warning-item': this.type === 'Warning',
      'info-item': this.type === 'Info',
      'unknown-item': this.type === 'Unknown',
    };
  }

  get canShowMore() {
    return this.error.expanded !== true && 
      ((this.error.errorDetails &&
      this.error.errorDetails.length > 0) ||
      this.hasMore);
  }

  get isOpenedNow() {
    if (this.error.expanded === true) {
      return true;
    }
    return this.isOpened;
  }

  get serviceTag() {
    if (!this.error.serviceTag) {
      return '';
    }

    return this.error.serviceTag;
  }



  isTranslated(item) {
    return !!item.translatedErrorMessage;
  }

  errorMessageDisplay(error) {
    const translations = {
      ORDER_MODIFIED_OUTSIDE_NDC: translate('common-error.modified-outside-ndc'),
      QUOTATIONS_NOT_RETURNED_FOR_SOME_ANCILLARY_BOOKING_CANNOT_BE_CONTINUED: translate('common-error.quotations-not-returned-for-some-ancillary-booking-cannot-be-continued'),
      SELECT_OFFER_AGAIN: translate('common-error.select-offer-again'),
      NOT_ALL_SEGMENTS_CONFIRMED: translate('common-error.not-all-segments-confirmed'),
      'PROHIBITED TICKETING CARRIER - RE-ENTER TICKETING CARRIER': translate('common-error.prohibited-ticketing-carrier'),
    };
 
    if (error && error.errorCode && translations[error.errorCode]) {
      return translations[error.errorCode];
    } else if (error && error.errorMessage && translations[error.errorMessage]) {
      return translations[error.errorMessage];
    }

    return error.errorMessage;
  }

  isErrorMessageLonger() {
    const message = this.$refs.errorMessage as HTMLElement;
    const wrapper = this.$refs.errorWrapper as HTMLElement;

    return message && wrapper && message.offsetWidth > wrapper.offsetWidth;
  }

  checkIfHasMore() {
    this.$nextTick(() => {
      this.hasMore = this.isErrorMessageLonger();
    });
  }

  showErrorInfo() {
    this.isOpened = !this.isOpened;
  }

  async loadTranslation() {
    this.loading = true;
    this.showTranslation = false;
    try {
      const response = await DictionaryApi.getErrorCodeTranslation(
        this.serviceTag,
        AccountStore.currentLanguage,
      );
      if (response.data) {
        this.translation = response.data.message;
        this.showTranslation = true;
      }

    } catch (error) {
      this.errors = this.$handleErrors(error);
    }
    this.loading = false;
    this.checkIfHasMore();
  }

  @Watch('error', { deep: true, immediate: true })
  onErrorChange(value) {
    if (value && value.serviceTag) {
      this.loadTranslation();
    } else {
      this.checkIfHasMore();
    }
  }

  created() {
    this.hasMoreCheckId = setInterval(() => {
      if (this.isOpened) {
        return;
      }
      this.checkIfHasMore();
    }, 500);
  }

  beforeDestroy() {
    clearInterval(this.hasMoreCheckId);
  }

}




























































































































import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required } from 'vuelidate/lib/validators';
import { translate } from '@/i18n';

import { userFullName } from '@/core/user-full-name';
import { TravellerModel } from '@/api/home/home.model';
import { AvailableDateFormats } from '@/api/profile/company.model';
import { TErrorDetails } from '@/types/error.model';
import { ProfileCompanyApi } from '@/api/profile/company.api';
import SearchStore from '@/modules/search/search.store';
import AccountStore from '@/store/account.store';
import searchConst from '@/const/search.const';

type TPopup = {
  details: TErrorDetails<{ TravellerIds: string[] }>[];
  resolve: (value: boolean) => void;
};
type TForm = {
  profiles: {
    profileId: string;
    dateOfBirth?: string;
    age?: number | null;
  }[];
};

@Component({})
export default class SearchValidationErrorPopup extends Vue {
  popup: TPopup | null = null;
  Form: TForm = { profiles: [] };
  isLoading = false;
  errors: any[] = [];
  ageOptions: any = {};
  $v;

  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }

  get dateOfBirthField() {
    return this.popup && this.popup.details.find((item) => item.field === 'DateOfBirth');
  }

  get popupTitle() {
    if (this.popup && this.ageField) {
      return translate('search.validation-error-popup-title-age');
    } else if (this.popup && this.dateOfBirthField) {
      return translate('search.validation-error-popup-title');
    }
    return '';
  }

  get popupDescription() {
    if (this.popup && this.ageField) {
      return translate('search.validation-error-popup-description-age');
    } else if (this.popup && this.dateOfBirthField) {
      return translate('search.validation-error-popup-description');
    }
    return '';
  }

  get ageField() {
    return this.popup && this.popup.details.find((item) => item.field === 'Age');
  }

  get hasNewTravellers() {
    const travellersState = (SearchStore.getTravellersState && SearchStore.getTravellersState.travellers) || [];

    return travellersState.some((traveller) => !traveller.id);
  }

  get existingTravellers() {
    const travellersState = (SearchStore.getTravellersState && SearchStore.getTravellersState.travellers) || [];

    if (this.popup && this.ageField) {
      return this.ageField.params.TravellerIds.reduce((items, id) => {
        const traveller = travellersState.find((item) => item.id === id);
        if (traveller) {
          traveller.age = null;
        }
        return traveller ? items.concat(traveller) : items;
      }, [] as TravellerModel[]);
    } else if (this.popup && this.dateOfBirthField) {
      return this.dateOfBirthField.params.TravellerIds.reduce((items, id) => {
        const traveller = travellersState.find((item) => item.id === id);
        return traveller ? items.concat(traveller) : items;
      }, [] as TravellerModel[]);
    }
    return [];
  }

  get newTravellers() {
    const travellersState = (SearchStore.getTravellersState && SearchStore.getTravellersState.travellers) || [];

    if (this.popup && this.ageField) {
      return travellersState.map(item => {
        return {
          ...item,
          age: null,
        };
      });
    } else if (this.popup && this.dateOfBirthField) {
      return travellersState.map(item => {
        return {
          ...item,
          dateOfBirth: null,
        };
      });
    }
    return [];
  }

  get travellers() {
    if (this.hasNewTravellers) {
      return this.newTravellers;
    }

    return this.existingTravellers;
  }

  get minimalBirthDate() {
    let date = new Date();
    date.setFullYear( date.getFullYear() - 150 );
    return date;
  }

  @Validation()
  validationObject() {
    let $each: any = {};
    if (this.dateOfBirthField) {
      $each.dateOfBirth = { required };
    } else if (this.ageField) {
      $each.age = { required };
    }
    return {
      Form: {
        profiles: {
          $each,
        },
      },
    };
  }

  ageOptionsForOption(option: string) {
    return this.ageOptions[option] || [];
  }

  prepareAgeOptions() {
    searchConst.guestTravellerOptions
      .forEach(option => {
        if (!option.trainAge) {
          return;
        }
        this.ageOptions[option.code] = [];

        for (let i = option.ageOptions[0]; i <= option.ageOptions[1]; i++) {
          let label = i === 1 ?
            translate('search-hotel.singular-years-old', {
              number: i,
            }) :
            translate('search-hotel.plural-years-old', {
              number: i,
            });
          
          this.ageOptions[option.code].push({
            label: i === 65 ? translate('search-train.sixty-five-and-over') : label,
            value: i,
          });
        }
      });
  }

  guestAge(index: number, type: string) {
    return this.ageOptionsForOption(type)
      .find(item => item.value === this.Form.profiles[index].age);
  }

  setGuestAge(index: number, value: any) {
    this.Form.profiles[index].age = value ? value.value : null;
  }

  isValid() {
    this.$v.$touch();
    return !this.$v.$error;
  }

  setInitForm({ details }: Pick<TPopup, 'details'>) {
    const dateOfBirthField = details.find((item) => item.field === 'DateOfBirth');
    const ageField = details.find((item) => item.field === 'Age');
    if (dateOfBirthField) {
      this.Form = {
        profiles: dateOfBirthField.params.TravellerIds.map((item) => ({
          profileId: item,
          dateOfBirth: '',
        })),
      };
    } else if (ageField) {
      this.Form = {
        profiles: ageField.params.TravellerIds.map((item) => ({
          profileId: item,
          age: null,
        })),
      };
    } 
  }

  open(payload: Pick<TPopup, 'details'>): Promise<boolean> {
    this.$v.$reset();
    this.setInitForm(payload);
    return new Promise((resolve) => {
      this.popup = {
        ...payload,
        resolve,
      } as TPopup;
    });
  }

  resolve(result: boolean) {
    if (this.popup) {
      this.popup.resolve(result);
    }
    this.popup = null;
  }

  businessUnitName(traveller) {
    if (this.newTravellers) {
      return SearchStore.skipTravellersCompany.name;
    }
    return traveller.businessUnitName || '';
  }

  userFullName(traveller) {
    if (!traveller.firstName && !traveller.lastName && traveller.passengerTypeCode) {
      return translate('passenger-types.' + traveller.passengerTypeCode.toLowerCase());
    }
    return userFullName(traveller);
  }

  async commitDateOfBirths() {
    try {
      this.isLoading = true;
      const profiles = this.Form.profiles.map((item) => ({
        profileId: item.profileId,
        dateOfBirth: moment(item.dateOfBirth).format('YYYY-MM-DD'),
      }));
      await ProfileCompanyApi.updateDateOfBirth({ profiles });
      this.resolve(true);
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.isLoading = false;
    }
  }

  commitAges() {
    const travellers = SearchStore.getTravellersState.travellers;
    travellers.forEach((traveller, index) => {
      if (this.hasNewTravellers) {
        traveller.age = this.Form.profiles[index].age === 0 ? 0 : this.Form.profiles[index].age || null;
      } else {
        const profile = this.Form.profiles.find((item) => item.profileId === traveller.id);
        if (profile) {
          traveller.age = profile.age === 0 ? 0 : profile.age || null;
        }
      }
    });
    this.resolve(true);
  }

  async confirmClick() {
    const isValid = this.isValid();
    if (!isValid) {
      return;
    }
    if (this.dateOfBirthField) {
      await this.commitDateOfBirths();
    } else if (this.ageField) {
      this.commitAges();
    }
  }

  created() {
    this.prepareAgeOptions();
  }
}









































































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { userFullName } from '@/core/user-full-name';
import priceConst from '@/const/price.const';
import { Traveller } from '@/api/trip/basket.model';
import {
  SeatType,
  TCoach,
  TRailSeatSelection,
  TRailSeatSelectionsModel,
  TSeatMap,
  TSeatMapItem,
} from '@/modules/basket/basket-rail-seats-extras/basket-rail-seats-extras.model';
import TrenitaliaCoachMap from '@/modules/basket/railseatmap/TrenitaliaCoachMap.vue';
import TrainWagon from '@/modules/basket/railseatmap/TrainWagon.vue';
import TrainLoco from '@/modules/basket/railseatmap/TrainLoco.vue';
import TrenitaliaSeatMapLegend from '@/modules/basket/railseatmap/TrenitaliaSeatMapLegend.vue';
import EventBus from '@/services/event-handler';

const HooverOffset = {
  x: 20,
  y: 30,
};

@Component({
  components: {
    TrenitaliaSeatMapLegend,
    TrainLoco,
    TrainWagon,
    TrenitaliaCoachMap,
  }
})
export default class SeatMapTrenitaliaPopup extends Vue {
  @Prop() show!: boolean;
  @Prop() value!: TRailSeatSelectionsModel;
  @Prop() traveller!: Traveller;
  @Prop() segmentId!: string;
  @Prop() seatMap!: TSeatMap;
  @Prop() coaches!: TCoach[];

  userFullName = userFullName;
  selectedCoach: TCoach | null = null;
  selectedSeat: TRailSeatSelection | null = null;
  hoverSeat: TSeatMapItem | null = null;

  get selectedCoachNumber() {
    return this.selectedCoach && this.selectedCoach.number;
  }

  get selectedSeatNumber() {
    return this.selectedSeat && this.selectedSeat.seatNumber;
  }

  get selectedAlternateNumber() {
    return this.selectedSeat && this.selectedSeat.alternateNumber;
  }

  get hooverSeatPrice() {
    const hooverSeatPrice = (this.hoverSeat && this.hoverSeat.prices || []).find((item) => item.travellerId === this.traveller.id);
    return (hooverSeatPrice && hooverSeatPrice.price) || priceConst.priceModel;
  }

  get seatSelectionsModel() {
    if (this.selectedSeat) {
      return this.value.seatSelections
        .filter((item) => !(item.profileId === this.traveller.id && item.segmentId === this.segmentId))
        .concat(this.selectedSeat);
    }
    return [...this.value.seatSelections];
  }

  @Emit('update:show')
  closePopup() {
    return false;
  }

  onContinueClick() {
    if (this.selectedSeatNumber && this.selectedCoachNumber) {
      const seatSelections = this.value.seatSelections
        .filter((item) => !(item.profileId === this.traveller.id && item.segmentId === this.segmentId))
        .concat({
          alternateNumber: this.selectedAlternateNumber,
          seatNumber: this.selectedSeatNumber,
          profileId: this.traveller.id,
          segmentId: this.segmentId,
          coachNumber: this.selectedCoachNumber,
        });
      this.$emit('input', { seatSelections });
      this.closePopup();
    }
  }

  onCoachClick(coach: TCoach) {
    if (coach.available) {
      this.selectedCoach = coach;
    }
  }

  onSelectSeat(seat: TSeatMapItem) {
    if (this.selectedCoachNumber) {
      this.selectedSeat = {
        alternateNumber: seat.alternateNumber,
        seatNumber: seat.seatNumber,
        profileId: this.traveller.id,
        segmentId: this.segmentId,
        coachNumber: this.selectedCoachNumber,
      };
    }
  }

  onMouseEnterSeat({ item, e }: { item: TSeatMapItem, e: { layerX: number, layerY: number } }) {
    const selectedSeats = this.seatSelectionsModel.filter(({ coachNumber }) => coachNumber === this.selectedCoachNumber);
    const isSelected = selectedSeats.some(({ seatNumber }) => seatNumber === item.seatNumber);
    if (item.seatAvailability === SeatType.open && !isSelected) {
      this.hoverSeat = {
        ...item,
        x: e.layerX - HooverOffset.x,
        y: e.layerY + HooverOffset.y,
      };
    }
  }

  onMouseLeaveSeat() {
    this.hoverSeat = null;
  }

  selectCoach() {
    this.selectedSeat = null;
    const seatSelected = this.value.seatSelections.find((item) => item.profileId === this.traveller.id && item.segmentId === this.segmentId);
    if (seatSelected) {
      this.selectedCoach = this.seatMap.coaches.find((item) => item.number === seatSelected.coachNumber) || null;
      return;
    }
    this.selectedCoach = this.coaches.find((item) => item.available) || null;
  }

  created() {
    EventBus.$on('select-seat', this.onSelectSeat);
    EventBus.$on('mouseenter-seat', this.onMouseEnterSeat);
    EventBus.$on('mouseleave-seat', this.onMouseLeaveSeat);
  }

  beforeDestroy() {
    EventBus.$off('select-seat', this.onSelectSeat);
    EventBus.$off('mouseenter-seat', this.onMouseEnterSeat);
    EventBus.$off('mouseleave-seat', this.onMouseLeaveSeat);
  }

  @Watch('show', { immediate: true })
  onShow(isShow: boolean) {
    if (isShow) {
      this.selectCoach();
    }
  }
}

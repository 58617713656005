























































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import { translate } from '@/i18n';
import AccountStore from '@/store/account.store';

@Component({})
export default class UiGuestTravellersTravellerRoom extends Vue {
  @Prop() room!: any;
  @Prop() index!: number;
  @Prop() length!: number;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) addDisabled!: boolean;
  

  optionsConst: any[] = [
    {
      label: 'search-hotel.travellers-type-adults',
      code: 'ADT',
      value: 1,
      tooltip: '',
    },
    {
      label: 'search-hotel.travellers-type-children',
      code: 'CHD',
      value: 0,
      tooltip: '',
    },
  ];
  ageOptionsPrototype: any[] = [];



  get options() {
    return this.optionsConst.map((opt, index) => {
      return {
        ...opt,
        name: translate(opt.label),
        value: this.room.profiles
          .filter(i => i.passengerTypeCode === opt.code)
          .length,
        min: index === 0 ? 1 : 0,
      };
    });
  }
  
  get user() {
    return AccountStore.current;
  }

  get ageOptions() {
    return this.ageOptionsPrototype
      .map(item => {
        let label = item.value === 1 ?
          translate('search-hotel.singular-years-old', {
            number: item.value,
          }) :
          translate('search-hotel.plural-years-old', {
            number: item.value,
          });
        if (
          this.user &&
          this.user.profile &&
          this.user.profile.displayLanguage === 'pl' &&
          item.value > 1 &&
          item.value < 5
        ) {
          label += 'a';
        }
        return {
          name: label,
          label,
          value: item.value,
        };
      });
  }

  get childrenProfiles() {
    return this.room.profiles
      .filter(i => i.passengerTypeCode === 'CHD');
  }

  get shouldShowChildrenAgeSelect() {
    return !!this.childrenProfiles.length;
  }

  get shouldShowChildrenAgeInfo() {
    const children = this.room.profiles.filter(i => i.passengerTypeCode === 'CHD');
    const withAge = children.filter(i => !!i.age);

    return withAge.length !== children.length;
  }

  get shouldShowRemoveRoom() {
    return this.length > 1;
  }

  get shouldShowAddRoom() {
    return this.length < 4 && this.index === this.length - 1;
  }



  @Emit()
  addRoom() {
    return true;
  }

  @Emit()
  removeRoom() {
    return this.index;
  }

  childAge(child) {
    if (child.age === null) {
      return null;
    }
    return this.ageOptions.find(i => i.value === child.childAge) || null;
  }

  setChildAge(child, chosen) {
    child.childAge = chosen.value;
    child.age = chosen.value;
  }

  updateValue(option, value) {
    const otherProfiles = this.room.profiles
      .filter(i => i.passengerTypeCode !== option.code);
    const optionProfiles = this.room.profiles
      .filter(i => i.passengerTypeCode === option.code);
    
    if (value > optionProfiles.length) {
      for (let i = optionProfiles.length; i < value; i++) {
        this.room.profiles.push({
          isVirtual: true,
          passengerTypeCode: option.code,
          childAge: null,
          age: null,
        });
      }
    } else if (value < optionProfiles.length) {
      const howMany = optionProfiles.length - value;
      optionProfiles.splice(-howMany, howMany);
      this.room.profiles = [
        ...optionProfiles,
        ...otherProfiles,
      ];
    }
  }

  created() {
    this.ageOptionsPrototype = [];
    for (let i = 0; i < 18; i++) {
      this.ageOptionsPrototype.push({
        value: i,
      });
    }
  }
}

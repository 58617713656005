




















































































































import { Vue, Component, Emit, Watch } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { ProfileApi } from '@/api/profile/profile.api';
import { ProfileModel } from '@/api/profile/profile.model';
import consts from '@/const/table.const';
import accountStore from '@/store/account.store';
import { translate } from '@/i18n';

@Component({})
export default class SwitchProfile extends Vue {
  perPageOptions = consts.perPageOptions;
  perPage = consts.defaultPerPage;
  params = {
    sort: '',
    size: 10,
    start: 0,
  };
  filter = '';
  loading = false;
  currentPage = 1;
  fields = {
    profileName: {
      label: translate('switch-profile.profile-name'),
      class: 'switch-profile__profile-name-column',
    },
    action: {
      label: '',
      class: 'switch-profile__action-column',
    },
  };
  Form = {
    searchUser: ''
  };
  serverErrors: any[] = [];
  users: ProfileModel[] = [];

  get count() {
    return this.users.length;
  }

  get currentProfileId() {
    return accountStore.CurrentUser!.profile.id;
  }

  get isSwitched() {
    return accountStore.CurrentUser!.isSwitched;
  }



  userFullName(user) {
    return userFullName(user);
  }

  async created() {
    this.loading = true;
    const response = await ProfileApi.getProfiles('', this.params.size);
    if (response && response.data) {
      this.users = response.data.results;
      this.params.size = response.data.page.pageSize;
      this.loading = false;
    }
  }

  async switchToProfile(id) {
    await ProfileApi.switchProfile(id);
    location.replace('/');
  }

  async switchToMyself() {
    await ProfileApi.backToMainProfile();
    location.replace('/');
  }

  @Debounce({ delay: DebounceConst.defaultDelay })
  getResults() {
    this.submitForm();
  }

  async submitForm() {
    this.loading = true;
    this.serverErrors = [];

    try {
      const response = await ProfileApi.getProfiles(this.Form.searchUser, this.params.size);
      if (response && response.data) {
        this.users = response.data.results;
        this.params.size = response.data.page.pageSize;
        this.loading = false;
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  @Watch('params.size')
  onSizeChange() {
    this.submitForm();
  }

  @Emit()
  close() {}

  mounted() {
    let input = (this.$refs.switchProfileUserName as any);
    if (input) {
      setTimeout(() => {
        input.$refs.input.focus();
      }, 50);
    }
  }
}


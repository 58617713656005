













































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, numeric, requiredIf } from 'vuelidate/lib/validators';
import moment from 'moment';
import { LoyaltiesApi } from '@/api/profile/loyalties.api';
import { ILoyaltyCard, LoyaltyForm, LoyaltyType } from '@/api/profile/loyalties.model';
import { BasketItemType } from '@/api/trip/trip.model';
import { AccommodationSubscriber } from '@/const/loyalty-const';
import { Debounce } from '@/core/decorators/debounce.decorator';
import UiAutocomplete from '@/controls/UiAutocomplete.vue';

const CardNumberFormat = {
  [AccommodationSubscriber.ibis]: '3081432',
  [AccommodationSubscriber.favorite]: '3085163',
};
const CardNumberFormatRegex = (value: string, subscriber: string) => {
  const regex = new RegExp(`^${CardNumberFormat[subscriber]}.*`);
  return regex.test(value);
};

@Component
export default class LoyaltyAccommodationForm extends Vue {
  @Prop() value!: LoyaltyForm;
  @Prop() card!: any;
  @Prop() dirty!: boolean;

  AccommodationSubscriber = AccommodationSubscriber;
  CardNumberFormat = CardNumberFormat;
  selectedProgramName: ILoyaltyCard | null = null;
  programNameList: ILoyaltyCard[] = [];
  programNameLoading: boolean = false;
  serverErrors: any[] = [];
  $v;

  @Validation()
  validationObject() {
    return {
      value: {
        loyaltyId: { required },
        issueDate: {
          required: requiredIf(() => this.programName && this.programName.isIssueDateRequired),
        },
        expireDate: {
          required: requiredIf(() => this.programName && this.programName.isExpirationRequired),
        },
        cardNumber: {
          required,
          numeric: this.programName && this.programName.cardNumberWithOnlyDigits ? numeric : {},
          cardNumberFormat: (value) => {
            if (this.programName && (this.programName.subscriber === AccommodationSubscriber.favorite || this.programName.subscriber === AccommodationSubscriber.ibis)) {
              return value ? CardNumberFormatRegex(value, this.programName.subscriber) : true;
            }
            return true;
          },
        },
      },
    };
  }

  get programName() {
    return this.selectedProgramName;
  }
  set programName(value) {
    this.selectedProgramName = value;
    this.$emit('input', { loyaltyId: value && value.id });
    this.autocompleteBlur();
  }
  get issueDate() {
    if (this.value.issueDate) {
      const momentDate = moment(this.value.issueDate, 'YYYY-MM-DD');
      return new Date(momentDate.toISOString());
    }
    return null;
  }
  set issueDate(value) {
    this.$emit('input', {
      ...this.value,
      issueDate: value ? moment(value).format('YYYY-MM-DD') : null,
    });
  }
  get expireDate() {
    if (this.value.expireDate) {
      const momentDate = moment(this.value.expireDate, 'YYYY-MM-DD');
      return new Date(momentDate.toISOString());
    }
    return null;
  }
  set expireDate(value) {
    this.$emit('input', {
      ...this.value,
      expireDate: value ? moment(value).format('YYYY-MM-DD') : null,
    });
  }
  get validFromDate() {
    if (this.value.validFromDate) {
      const momentDate = moment(this.value.validFromDate, 'YYYY-MM-DD');
      return new Date(momentDate.toISOString());
    }
    return null;
  }
  set validFromDate(value) {
    this.$emit('input', {
      ...this.value,
      validFromDate: value ? moment(value).format('YYYY-MM-DD') : null,
    });
  }

  get tomorrow() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    return tomorrow;
  }

  get expireDateMinDate() {
    if (this.validFromDate) {
      const date = new Date(this.validFromDate);
      date.setDate(date.getDate() + 1);
      date.setHours(0, 0, 0, 0);
      return date;
    }
    return this.tomorrow;
  }

  get validFromDateMaxDate() {
    if (this.expireDate) {
      const date = new Date(this.expireDate);
      date.setDate(date.getDate() - 1);
      date.setHours(0, 0, 0, 0);
      return date;
    }
    return new Date();
  }

  get issueDateMaxDate() {
    return this.validFromDate || new Date();
  }

  isValid() {
    this.$v.$touch();
    return !this.$v.$error;
  }

  @Debounce({
    delay: 50,
  })
  autocompleteBlur() {
    const programTypeRef = this.$refs.programTypeRef as UiAutocomplete;
    const input = document.getElementById(programTypeRef.currentId);
    if (input) {
      input.blur();
    }
  }

  async onReset() {
    await this.getLoyaltiesByService('');
  }

  async getLoyaltiesByService(phrase: string) {
    try {
      let loyaltiesResult = await LoyaltiesApi.getLoyaltiesByService(
        BasketItemType.Accommodation,
        LoyaltyType.Loyalty,
        phrase,
      );

      if (loyaltiesResult && loyaltiesResult.data) {
        this.programNameList = loyaltiesResult.data;
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.programNameLoading = false;
    }
  }

  @Debounce({
    delay: 200,
    flag: 'programNameLoading',
  })
  async loadProgramName(phrase: string) {
    if (phrase.length < 1) {
      this.programNameLoading = false;
      return;
    }
    await this.getLoyaltiesByService(phrase);
  }

  async created() {
    await this.getLoyaltiesByService('');

    if (this.card) {
      this.selectedProgramName = this.card.loyalty;
    }
    if (this.dirty) {
      this.$v.$touch();
    }
  }
}






























import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator';

@Component
export default class UiSlider extends Vue {
  @Prop() value: any;
  @Prop({ default: 0 }) min!: number;
  @Prop({ default: 100 }) max!: number;
  @Prop({ default: 1 }) step!: number;
  @Prop({ default: false }) single!: boolean;
  @Prop({ default: false }) disabled!: boolean;

  isPressed: boolean = false;
  isMouse: boolean = true;
  dx: number = 0;
  dv: number = 0;
  dragLeft: boolean = false;
  dragRight: boolean = false;

  get singlePercentageValue() {
    if (!this.single) {
      const valL = this.value[0] as number;
      const valR = this.value[1] as number;
      return [
        100 * (valL - this.min) / (this.max - this.min),
        100 * (valR - this.min) / (this.max - this.min),
      ];
    }

    const val = this.value as number;

    return 100 * (val - this.min) / (this.max - this.min);
  }

  get selectedStyles() {
    if (this.single) {
      return {
        opacity: .3,
        left: '0%',
        width: this.singlePercentageValue + '%',
      };
    }

    const val = this.value[0] as number;
    const l = this.singlePercentageValue[0];
    const r = this.singlePercentageValue[1];

    return {
      left: l + '%',
      width: (r - l) + '%',
    };
  }

  get leftStyles() {
    if (this.single) {
      return {
        left: this.singlePercentageValue + '%',
      };
    }

    return {
      left: this.singlePercentageValue[0] + '%',
    };
  }

  get rightStyles() {
    return {
      left: this.singlePercentageValue[1] + '%',
    };
  }

  get normalized() {
    const newValue = this.min + 
      this.step * Math.floor(this.dv * (this.max - this.min) / this.step);
    
    return Math.max(
      this.min,
      Math.min(
        this.max,
        newValue
      )
    );
  }

  pressDown(isMouse, $event) {
    const rect = (this.$refs.uiSlider as HTMLElement).getBoundingClientRect();

    this.dragLeft = $event.target === this.$refs.uiSliderHandLeft;
    this.dragRight = $event.target === this.$refs.uiSliderHandRight;

    $event.preventDefault();
    this.isPressed = true;
    this.isMouse = isMouse;

    this.dx = 0;

    let clientX = isMouse ? $event.clientX : $event.touches[0].clientX;

    if (this.dragLeft) {
      const handLeftRect = (this.$refs.uiSliderHandLeft as HTMLElement).getBoundingClientRect();

      this.dv = this.single ? this.value : this.value[0];
      this.dx = clientX - (handLeftRect.left + handLeftRect.width / 2);
    } else if (this.dragRight) {
      const handRightRect = (this.$refs.uiSliderHandRight as HTMLElement).getBoundingClientRect();

      this.dv = this.value[1];
      this.dx = clientX - (handRightRect.left + handRightRect.width / 2);
    } else {
      this.dv = (clientX - rect.left) / rect.width;
      if (
        (
          !this.single &&
          Math.abs(this.normalized - 
            this.value[0]) < Math.abs(this.normalized - this.value[1])
        ) || this.single
      ) {
        this.dragLeft = true;
        this.dragRight = false;
        if (this.single) {
          this.input(this.normalized);
        } else {
          this.input([this.normalized, this.value[1]]);
        }
      } else if (!this.single) {
        this.dragRight = true;
        this.dragLeft = false;
        this.input([this.value[0], this.normalized]);
      }
    }
  }

  pressUp($event) {
    if (!this.isPressed) {
      return;
    }

    this.isPressed = false;
    this.dragLeft = false;
    this.dragRight = false;
  }

  pressMove($event) {
    if (!this.isPressed) {
      return;
    }
    const rect = (this.$refs.uiSlider as HTMLElement).getBoundingClientRect();

    let clientX = this.isMouse ? $event.clientX : $event.touches[0].clientX;
    this.dv = (clientX - rect.left - this.dx) / rect.width;

    if (this.single) {
      this.input(this.normalized);
    } else {
      if (this.dragRight && this.normalized < this.value[0]) {
        this.dragLeft = true;
        this.dragRight = false;
        this.input([this.normalized, this.value[0]]);
      } else if (this.dragLeft && this.normalized > this.value[1]) {
        this.dragLeft = false;
        this.dragRight = true;
        this.input([this.value[1], this.normalized]);
      } else {
        if (this.dragLeft) {
          this.input([this.normalized, this.value[1]]);
        } else if (this.dragRight) {
          this.input([this.value[0], this.normalized]);
        }
      }
    }
  }

  @Emit('input')
  input(v) {
    this.$emit('change', {
      data: v,
    });
    return v;
  }

  mounted() {
    document.addEventListener('mousemove', this.pressMove);
    document.addEventListener('mouseup', this.pressUp);
    document.addEventListener('touchmove', this.pressMove);
    document.addEventListener('touchend', this.pressUp);
  }

  beforeDestroy() {
    document.removeEventListener('mousemove', this.pressMove);
    document.removeEventListener('mouseup', this.pressUp);
    document.removeEventListener('touchmove', this.pressMove);
    document.removeEventListener('touchend', this.pressUp);
  }
}
















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { AxiosResponse } from 'axios';
import { translate } from '@/i18n';
import { OffersApi } from '@/api/air-engine/offers.api';
import { Recommendation } from '@/api/air-engine/air-search.model';
import { IDetailsModel, IOfferDetails } from '@/api/air-engine/offers.model';
import { TravellerModel } from '@/api/home/home.model';
import { userFullName } from '@/core/user-full-name';
import { FareConditionSuppliers } from '@/const/fare-condition-suppliers.const';
import searchConst from '@/const/search.const';
import AirSearchStore from '@/modules/search/air/air-search.store';
import SearchStore from '@/modules/search/search.store';
import AirFareConditionsDetails from '@/modules/search/air/AirFareConditionsDetails.vue';

@Component({
  components: {
    AirFareConditionsDetails,
  }
})
export default class AirFareConditions extends Vue {
  @Prop() offer!: Recommendation;
  @Prop() proposal?: any;
  @Prop() searchModeType?: string;
  @Prop({ default: false }) isInBasket!: boolean;

  loading: boolean = false;
  offerDetails: IOfferDetails[] = [];
  selectedOfferDetails: number = 0;
  fareConditionsNotAvailable: boolean = false;
  error: any[] = [];
  customError: boolean = false;
  imagesErrorInfoConst = '/assets/img/ic_error.png';

  get travellers() {
    return SearchStore.getTravellersState.travellers;
  }

  get fareUnavailableCode() {
    return AirSearchStore.fareUnavailableErrorParams;
  }

  get supplier() {
    if (this.offer && this.offer.supplier) {
      return this.offer.supplier;
    }
    return this.proposal ? this.proposal.supplier : this.offer.proposal.supplier;
  }

  get providerSearchId() {
    return this.proposal ? this.proposal.providerSearchId : this.offer.providerSearchId;
  }

  get offerId() {
    return this.proposal ? this.proposal.id : this.offer.id;
  }

  get offerExpired() {
    return AirSearchStore.offerExpired;
  }

  get isOfferExpired() {
    return this.offerExpired && this.offerExpired.length && this.offerExpired.find(expire => expire.recommendationId === this.offer.id);
  }

  getTravellers(ids: string[] | null) {
    if (ids) {
      return ids.reduce((prev, id) => {
        const traveller = this.travellers.find((item) => item.id === id);
        if (traveller) {
          return [...prev, traveller];
        }
        return prev;
      }, [] as TravellerModel[]);
    }
    return [];
  }

  userFullName(user: TravellerModel) {
    return userFullName(user);
  }

  getTravLabel(guestCode: string) {
    let option = searchConst.guestTravellerOptions.find(option => {
      return option.code === guestCode;
    });
    if (option) {
      return `${translate('search.guest')} (${translate(option.label)})`;
    }
  }

  offerDetailsTabSelect(index: number) {
    this.selectedOfferDetails = index;
  }

  async created() {
    try {
      if (
        !this.offer.supplier &&
        !FareConditionSuppliers.some(fareSupplier => fareSupplier === this.supplier)
      ) {
        this.fareConditionsNotAvailable = true;
        return;
      }

      this.loading = true;
      this.customError = false;

      if (['AirFranceKlm', 'Lufthansa', 'AmericanAirlines', 'Emirates'].indexOf(this.supplier) > -1) {
        if (this.isInBasket) {
          const { data } = await OffersApi.getBasketOfferConditionsV2(this.offer.id);
          this.offerDetails = data.offerDetails;
        } else {
          const { data } = await OffersApi.getOfferDetailsV2(
            this.supplier,
            this.offerId,
            this.providerSearchId,
            this.$route.params.searchId,
          );
          this.offerDetails = data.offerDetails;
        }
      } else {
        if (this.isInBasket) {
          const { data } = await OffersApi.getBasketOfferConditions(this.offer.id);
          this.offerDetails = [data];
        } else {
          const { data } = await OffersApi.getOfferDetails(
            this.supplier,
            this.offerId,
            this.providerSearchId,
            this.$route.params.searchId,
          );
          this.offerDetails = [data];
        }
      }
    } catch (error) {
      if (error && error.response && error.response.status === 418 && error.response.data.error.code === 'OFFER_EXPIRED') {
        AirSearchStore.setOfferExpired({
          recommendationId: this.offer.id
        });
      } else if (error && error.response && error.response.status === 500) {
        this.customError = true;
      } else {
        this.fareConditionsNotAvailable = true;
        this.error = this.$handleErrors(error, false, await AirSearchStore.translateErrorParams(this.fareUnavailableCode));
      }
    } finally {
      this.loading = false;
    }
  }
}

import { Price } from '@/api/trip/trip.model';
import { TRailBenerailCategory, TRailBenerailPassenger, Traveller } from '@/api/trip/basket.model';

export const SeatType = {
  table: 'Table',
  luggage: 'Luggage',
  exit: 'Exit',
  window: 'Window',
  bulkhead: 'Bulkhead',
  lavatory: 'Lavatory',
  largeSeat: 'LargeSeat',
  normalSeat: 'NormalSeat',
  open: 'Open',
};

type TTrainToFrom = {
  cityId: string;
  cityName: string;
  countryId: string;
  stationCode: string;
  stationCodeUni: string | null;
  stationId: string;
  stationName: string;
};

export type TTrain = {
  arrival: string;
  co2Emission: number;
  co2Unit: string;
  departure: string;
  distance: number;
  duration: number;
  from: TTrainToFrom;
  to: TTrainToFrom;
  price: Price | null;
  seatMapAvailable: boolean;
  segmentId: string;
  segmentKey: string;
  speedTrainType: string;
  trainInfo: {
    carrierCode: string,
    equipment: unknown | null,
    number: string,
    regionalIdentity: unknown | null,
    remarks: unknown[],
    serviceOperator: unknown | null,
    trainCode: string,
    trainType: string,
    transportationMode: unknown | null,
  };
  travelClass: unknown | null;
  type: string;
};

export type TSncfAssignmentOption = {
  code: string | null;
  label: string;
  category: string | null;
  $isDisabled?: Boolean;
};

export type TBenerailAssignmentOption = {
  value: string | null;
  name: string;
  category: string | null;
  $isDisabled?: Boolean;
};

export type TPreferencesCategory = {
  name: string;
  preferences: TPreference[];
};

export type TPreference = {
  code: string | null;
  label: string;
};

export type TSncfSeatsPreference = {
  preferencesCategories: TPreferencesCategory[];
  train: TTrain;
};

export type TAncillary = {
  availableForTravellers: string[];
  price: Price;
  product: {
    code: string,
    forAllPassengers: boolean,
    forAllSegments: boolean,
    label: string,
  };
  remainingSlots: number;
  segments: TTrain[],
};

export type TAncillaryOffer = {
  ancillaries: TAncillary[];
  traveller: {
    age: number | null,
    availablePostSales: unknown[],
    businessUnitId: string,
    businessUnitName: string,
    companyCode: unknown | null,
    companyId: string,
    companyName: string,
    dateOfBirth: string,
    emailAddress: string,
    firstName: string,
    id: string,
    infantSeatPlace: unknown | null,
    isAdultType: boolean,
    isGuest: boolean,
    isInfant: boolean,
    isInfantOnSeat: boolean,
    isInfantOrChildType: boolean,
    isInfantTraveller: boolean,
    isInfantType: boolean,
    isMainTraveller: boolean,
    isVirtual: boolean,
    lastName: string,
    loyalties: unknown | null,
    middleName: string,
    passengerTypeCode: string,
    travellerCategories: unknown | null,
    virtualTravellerId: string,
  };
};

export type TSncfSeatsAncillaries = {
  seatsPreferencesOptions: TSncfSeatsPreference[];
  ancillariesOffers: TAncillaryOffer[];
};

export type TEventDetail = {
  detail: {
    coach: number;
    placings: {
      extraPrice: number;
      seatNumber: string;
    }[];
  };
};

export type TSeatMapPopupSelection = {
  coachNumber: string;
  passengerRank: number;
  seatNumber: string;
  segmentId: string;
  price: Price;
  traveller: Traveller;
};

export type TSeatMapPreferencesAssignment = {
  coachNumber: string;
  passengerRank: number;
  seatNumber: string;
};

export type TSeatMapPreferences = {
  assignments: TSeatMapPreferencesAssignment[];
};

export type TAssignmentsPreferencesAssignment = {
  category: string;
  assignmentCode: string | null;
};

export type TAssignmentsPreferences = {
  assignments: TAssignmentsPreferencesAssignment[];
};

export type TCloseToPreferences = {
  coachNumber: string;
  seatNumber: string;
};

export type TSncfSeatPreferencesModel = {
  segmentId: string;
  seatMapPreferences?: TSeatMapPreferences;
  assignmentsPreferences?: TAssignmentsPreferences;
  closeToPreferences?: TCloseToPreferences;
};

export type TSncfAncillaryModel = {
  ancillaryCode: string;
  segmentId: string;
  travellerId: string;
};

export type TSeatMapItem = {
  alternateNumber: string;
  angle: number;
  height: number;
  prices: { price: Price, travellerId: string }[] | null;
  propertyList: object;
  seatAvailability: string;
  seatNumber: string;
  seatType: string;
  width: number;
  x: number;
  y: number;
};

export type TCoach = {
  available: boolean;
  availableSeats: number;
  length: number;
  number: string;
  originLayout: string;
  propertyList: object | null;
  seatMapItems: TSeatMapItem[];
  width: number;
};

export type TSeatMap = {
  assignedSeats: {
    alternateNumber: string | null,
    coachNumber: string | null,
    seatNumber: string | null,
    travellerId: string,
  }[];
  carrier: string;
  coaches: TCoach[];
  departureDate: string;
  from: string;
  isSeatSelectionAvailable: boolean;
  messages: string[];
  segmentId: string;
  to: string;
  trainNumber: string;
};

export type TRailSeatSelection = {
  alternateNumber?: string | null;
  coachNumber: string;
  profileId: string;
  seatNumber: string;
  segmentId: string;
};

export type TRailSeatSelectionsModel = {
  seatSelections: TRailSeatSelection[];
};

export type TTrainTile = {
  legNumber: number;
  options?: {
    offerPartId: string,
    isOptional: boolean,
    isIncluded: boolean,
    price: Price,
    passengers: TRailBenerailPassenger[],
    categories: TRailBenerailCategory[],
  }[];
  seatMapAvailable?: boolean;
  trainNumber: string;
  to: string;
  departureDate?: string;
  from: string;
  segmentId: string;
  type?: string;
};

















































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import _  from 'lodash';

import AccountStore from '@/store/account.store';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { Validation } from 'vue-plugin-helper-decorator';
import { requiredIf } from 'vuelidate/lib/validators';
import { DictionaryApi } from '@/api/dictionary/dictionary.api';
import { CurrencyApi } from '@/api/currency/currency.api';
import TravelPolicyAccomodationStore from '@/modules/settings/travel-policy/travel-policy-accomodation.store';
import TravelPolicyStore from '@/modules/settings/travel-policy/travel-policy.store';
import SettingsStore from '@/modules/settings/settings.store';
import { LanguageCode, RegionModel } from '@/api/dictionary/dictionary.model';
import { router } from '@/router';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import { TravellerGroupOptions } from '@/const/profile-personal.const';
import { TravellerCategoriesApi } from '@/api/profile/traveller-categories.api';

const priceRegex = value => {
  return /^(\d+)(\.\d{1,2})?$/.test(value);
};

const zeroesRegex = value => {
  return /[^0]+/.test(value);
};

const GEO_AREA_ICON = {
  Continent: 'public',
  Country: 'flag',
  Adm1: 'terrain',
  Adm2: 'terrain',
  Adm3: 'terrain',
  Adm4: 'terrain',
  City: 'location_city',
};

@Component({})
export default class TravelPolicyAccomodationManageRule extends Vue {
  GEO_AREA_ICON = GEO_AREA_ICON;
  isLoading: boolean = false;
  formPending: boolean = false;
  regionsOptions: RegionModel[] = [];
  form: any = {
    id: this.generateUUID(),
    rulePriority: 1,
    priceAmount: null,
    priceCurrency: 'EUR',
    mealTypes: [],
    categories: [],
    numberOfPeopleInRoom: [],
    providers: [],
    geoAreas: [],
    travellerGroups: [],
    isMealTypesEnabled: false,
    isPriceAmountEnabled: false,
    isCategoriesEnabled: false,
    isNumberOfPeopleInRoomEnabled: false,
    isProviderEnabled: false,
    isGeoAreaEnabled: false,
    isTravellerCategoriesEnabled: false,
    status: '',
    isRefundable: false,
  };
  formHasError: boolean = false;
  serverErrors: any[] = [];
  travellerCategoriesOptions: any[] = [];
  providerOptions = [
    'Sabre',
    'Expedia',
    'Koedia',
    'Amadeus',
    'Teldar',
    'HCorpo',
    'WonderHotel',
  ];
  destinationQueryLength: number = 0;
  $v;
  imagesConst: string = '/assets/img/loader/1.gif';
  loading: boolean = false;
  currencyCodes: any[] = [];
  loadErrors: any[] = [];

  mealTypesOptions = [{
    value: 'None',
    name: translate('settings-travel-policy.no-meal'),
  }, {
    value: 'Breakfast',
    name: translate('settings-travel-policy.breakfast'),
  }, {
    value: 'Halfboard',
    name: translate('settings-travel-policy.half-board'),
  }, {
    value: 'Fullboard',
    name: translate('settings-travel-policy.full-board'),
  }];
  categoriesOptions = [
    {
      value: '0',
      name: translate('settings-travel-policy.no-category'),
    },
    {
      value: '1',
      name: '1 ' + translate('settings-travel-policy.star'),
    },
    {
      value: '2',
      name: '2 ' + translate('settings-travel-policy.stars'),
    },
    {
      value: '3',
      name: '3 ' + translate('settings-travel-policy.stars'),
    },
    {
      value: '4',
      name: '4 ' + translate('settings-travel-policy.stars'),
    },
    {
      value: '5',
      name: '5 ' + translate('settings-travel-policy.stars'),
    },
  ];

  numberOfPeopleInRoomOptions = [
    {
      value: 1,
      name: '1 ' + translate('settings-travel-policy.person-in-room'),
    },
    {
      value: 2,
      name: '2 ' + translate('settings-travel-policy.people-in-room'),
    },
    {
      value: 3,
      name: '3 ' + translate('settings-travel-policy.people-in-room'),
    },
    {
      value: 4,
      name: '4 ' + translate('settings-travel-policy.people-in-room'),
    },
    {
      value: 5,
      name: '5 ' + translate('settings-travel-policy.people-in-room'),
    },
    {
      value: 6,
      name: '6 ' + translate('settings-travel-policy.people-in-room'),
    },
  ];

  get isReadOnly() {
    return this.$route.query.readOnly === 'true';
  }

  get currentLanguage() {
    return AccountStore.currentLanguage;
  }

  get selectedRule() {
    return TravelPolicyAccomodationStore.currentSelectedRules;
  }

  get allRules() {
    return TravelPolicyAccomodationStore.getAccomodationRules;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get rootCompanyId() {
    if (!this.currentCompany) {
      return this.$route.params.id;
    }

    return this.currentCompany.rootCompanyId;
  }

  get currentConfigurationId() {
    return this.$route.params.configurationId;
  }

  get currentConfigName() {
    return TravelPolicyStore.currentConfigName;
  }

  get ruleExist() {
    if (this.selectedRule) {
      return Object.keys(this.selectedRule).length === 0 && this.selectedRule.constructor === Object;
    }
  }

  get destinationQueryTooShort() {
    return this.destinationQueryLength < 2;
  }

  get travellerGroupOptions() {
    return TravellerGroupOptions;
  }

  getSubOption({ adm1Name, adm2Name, adm3Name, adm4Name, countryName, type }: RegionModel) {
    const typeMap: { [key: string]: string[] } = {
      'Adm1': [adm2Name, adm3Name, adm4Name, countryName],
      'Adm2': [adm1Name, adm3Name, adm4Name, countryName],
      'Adm3': [adm1Name, adm2Name, adm4Name, countryName],
      'Adm4': [adm1Name, adm2Name, adm3Name, countryName],
      'Country': [adm1Name, adm2Name, adm3Name, adm4Name],
    };
    const regionNames = typeMap[type] || [adm1Name, adm2Name, adm3Name, adm4Name, countryName];
    return regionNames
      .filter((item) => !!item)
      .join(', ');
  }

  linkClicked(e) {
    e.preventDefault();
  }

  returnToConfiguration() {
    TravelPolicyStore.setIsFromManage(true);
    TravelPolicyStore.setCurrentSelectedItem('Accomodation');
    router.push({
      name: 'travel-policy-configuration',
      params: {
        configurationId: this.currentConfigurationId,
      },
    });
  }

  isFormFilled() {
    return this.form.isRefundable ||
      (this.form.isGeoAreaEnabled && this.form.geoAreas && this.form.geoAreas.length) ||
      (this.form.isPriceAmountEnabled && this.form.priceAmount) ||
      (this.form.isMealTypesEnabled && this.form.mealTypes && this.form.mealTypes.length) ||
      (this.form.isCategoriesEnabled && this.form.categories && this.form.categories.length) ||
      (this.form.isNumberOfPeopleInRoomEnabled && this.form.numberOfPeopleInRoom && this.form.numberOfPeopleInRoom.length) ||
      (this.form.isProviderEnabled && this.form.providers && this.form.providers.length) ||
      (this.form.isTravellerCategoriesEnabled && this.form.travellerCategories && this.form.travellerCategories.length);
  }

  isFormSufficient() {
    return (
      this.form.isRefundable ||
      (this.form.isGeoAreaEnabled ? !!(this.form.geoAreas && this.form.geoAreas.length) : false) ||
      (this.form.isPriceAmountEnabled ? !!(this.form.priceAmount) : false) ||
      (this.form.isMealTypesEnabled ? !!(this.form.mealTypes && this.form.mealTypes.length) : false) ||
      (this.form.isCategoriesEnabled ? !!(this.form.categories && this.form.categories.length) : false) ||
      (this.form.isNumberOfPeopleInRoomEnabled ? !!(this.form.numberOfPeopleInRoom && this.form.numberOfPeopleInRoom.length) : false) ||
      (this.form.isProviderEnabled ? !!(this.form.providers && this.form.providers.length) : false) ||
      (this.form.isTravellerCategoriesEnabled ? !!(this.form.travellerCategories && this.form.travellerCategories.length) : false)
    );
  }

  @Validation()
  validationObject() {
    if (this.form.isPriceAmountEnabled) {
      return {
        form: {
          geoAreas: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          priceAmount: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
            priceRegex,
            zeroesRegex: (val) => {
              return val !== '' ? zeroesRegex(val) : true;
            },
            maxValue: (val) => {
              return val !== '' ? Number(val) < 100000000 : true;
            }
          },
          mealTypes: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          categories: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          numberOfPeopleInRoom: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          travellerCategories: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            })
          },
          sufficient: () => {
            return this.isFormSufficient();
          }
        }
      };
    } else {
      return {
        form: {
          geoAreas: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          priceAmount: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          mealTypes: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          categories: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          numberOfPeopleInRoom: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          travellerCategories: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            })
          },
          sufficient: () => {
            return this.isFormSufficient();
          }
        }
      };
    }
  }

  colorStyles(option) {
    return {
      backgroundColor: option && option.color ? option.color : '',
    };
  }

  checkForm() {
    this.formHasError = false;
    this.$v.form.$touch();
    if (this.$v.form.$pending || this.$v.form.$error) {
      this.formHasError = true;
    }
  }

  generateUUID() {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  locationCountriesMaper(locations) {
    let countries: any[] = [];

    locations.map(location => {
      if (location) {
        if (location.type === 'Country') {
          countries.push(location);
          return countries;
        }
      }
    });

    return countries;
  }

  locationCitiesMaper(locations) {
    let cities: any[] = [];

    locations.map(location => {
      if (location) {
        if (location.type === 'City') {
          cities.push(location);
          return cities;
        }
      }
    });

    return cities;
  }

  mealTypesMaper(lists) {
    let mealTypesList: any = [];
    this.mealTypesOptions.forEach((mealType) => {
      lists.map((list) => {
        if (mealType.value === (list.value && list.value.toString() ? list.value.toString() : list.toString())) {
          mealTypesList.push(mealType);
        }
      });
    });

    return mealTypesList;
  }

  categoriesMaper(lists) {
    let categoryList: any = [];
    this.categoriesOptions.forEach(category => {
      lists.map(list => {
        if (category.value === (list.value && list.value.toString() ? list.value.toString() : list.toString())) {
          categoryList.push(category);
        }
      });
    });

    return categoryList;
  }

  numberOfPeopleInRoomMaper(lists) {
    let numberOfPeopleInRoomList: any = [];
    this.numberOfPeopleInRoomOptions.forEach(option => {
      lists.map(list => {
        if (option.value === (list.value && list.value ? list.value : list)) {
          numberOfPeopleInRoomList.push(option);
        }
      });
    });

    return numberOfPeopleInRoomList;
  }

  locationAllMaper(cities, countries) {
    let list: any = [];

    cities.map(city => {
      list.push({
        cityId: city.id ? city.id : city.cityId,
        cityName: city.name ? city.name : city.cityName,
        countryCode: city.countryCode,
        countryName: city.countryName,
        type: 'City'
      });
    });
    countries.map(county => {
      list.push({
        countryCode: county.code ? county.code : county.countryCode,
        countryName: county.name ? county.name : county.countryName,
        type: 'Country'
      });
    });

    return list;
  }

  mapFormData(data?: any) {
    this.form = {
      id: data && data.id ? data.id : this.generateUUID(),
      rulePriority: data && data.rulePriority ? data.rulePriority : 1,
      priceAmount: data && data.priceAmount ? data.priceAmount : null,
      mealTypes: data && data.mealTypes ? this.mealTypesMaper(data.mealTypes) : [],
      categories: data && data.categories ? this.categoriesMaper(data.categories) : [],
      numberOfPeopleInRoom: data && data.numberOfPeopleInRoom ? this.numberOfPeopleInRoomMaper(data.numberOfPeopleInRoom) : [],
      priceCurrency: data && data.priceCurrency ? data.priceCurrency : 'EUR',
      providers: data && data.providers ? data.providers : [],
      geoAreas: data && data.geoAreas ? data.geoAreas : [],
      isRefundable: data && data.isRefundable ? data.isRefundable : false,
      isMealTypesEnabled: !!(data && data.mealTypes && data.mealTypes.length),
      isCategoriesEnabled: !!(data && data.categories && data.categories.length),
      isNumberOfPeopleInRoomEnabled: !!(data && data.numberOfPeopleInRoom && data.numberOfPeopleInRoom.length),
      isPriceAmountEnabled: !!(data && data.priceAmount && data.priceAmount !== ''),
      isProviderEnabled: !!(data && data.providers && data.providers.length),
      isGeoAreaEnabled: !!(data && data.geoAreas && data.geoAreas.length),
      status: data && data.status === '' ? TravelPolicyAccomodationStore.draftEditStatus : TravelPolicyAccomodationStore.draftNewStatus,
      isTravellerCategoriesEnabled: !!(data && data.travellerCategories && data.travellerCategories.length),
      travellerCategories: data && data.travellerCategories && data.travellerCategories.length ? data.travellerCategories.map(e => {
        let index = this.travellerCategoriesOptions.findIndex(elem => {
          return elem.value === e;
        });
        if (-1 < index) {
          return this.travellerCategoriesOptions[index];
        }
      }) : []
    } as any;
  }

  createRequest() {
    let onlyNumberOfPeopleInRoom: number[] = [];

    if (this.form.numberOfPeopleInRoom && this.form.numberOfPeopleInRoom.length) {
      this.form.numberOfPeopleInRoom.forEach((c: any) => {
        onlyNumberOfPeopleInRoom.push(c.value ? c.value : c);
      });
    } else {
      onlyNumberOfPeopleInRoom = [];
    }

    let request = {
      id: this.form.id,
      rulePriority: this.form.rulePriority,
      priceAmount: this.form.isPriceAmountEnabled ? this.form.priceAmount : null,
      priceCurrency: this.form.priceCurrency,
      mealTypes: this.form.isMealTypesEnabled ? this.form.mealTypes : [],
      categories: this.form.isCategoriesEnabled ? this.form.categories : [],
      numberOfPeopleInRoom: this.form.isNumberOfPeopleInRoomEnabled ? onlyNumberOfPeopleInRoom : [],
      geoAreas: this.form.isGeoAreaEnabled ? this.form.geoAreas : [],
      isRefundable: this.form.isRefundable ? true : null,
      providers: this.form.isProviderEnabled ? this.form.providers : [],
      travellerCategories: this.form.isTravellerCategoriesEnabled ? this.form.travellerCategories.map(e => e.value) : [],
      status: TravelPolicyAccomodationStore.draftNewStatus,
    };

    if (this.allRules && this.allRules.length) {
      let isEdit = _.filter(this.allRules, (rule) => {
        return rule.id === request.id;
      });

      if (isEdit.length) {
        TravelPolicyAccomodationStore.editAccomodationRule(request);
      } else {
        TravelPolicyAccomodationStore.addAccomodationRule(request);
      }
    } else {
        TravelPolicyAccomodationStore.addAccomodationRule(request);
    }
  }

  confirmAndBack() {
    this.checkForm();
    if (!this.formHasError) {
      this.formPending = true;
      this.createRequest();
      this.returnToConfiguration();
    }
  }

  confirmAndAddNext() {
    this.checkForm();
    if (!this.formHasError) {
      this.formPending = true;
      this.createRequest();
      this.mapFormData();
      this.$v.form.$reset();
      this.formPending = false;

      let obj = {
        type: translate('settings-travel-policy.success'),
        title: '',
        message: translate('settings-travel-policy.rule-added')
      };
      EventBus.$emit('show-toast', obj);
    }
  }

  @Watch('selectedRule', {deep: true,  immediate: true})
  existSelectedRule() {
    if (!this.selectedRule) {
      router.go(-1);
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isLoading',
  })

  async findGeoArea(query: string) {
    this.destinationQueryLength = query.length;
    if (query && query.length > 1) {
      try {
        const result = await DictionaryApi.findRegions(query, this.currentLanguage.toUpperCase() as LanguageCode);
        this.regionsOptions = result.data;
        this.isLoading = false;
      } catch (error) {
        this.serverErrors = this.$handleErrors(error);
      }
    } else {
      this.isLoading = false;
      this.regionsOptions = [];
    }
  }

  async created() {
    try {
      const response = await TravellerCategoriesApi.getCompanyTravellerCategories(this.rootCompanyId);

      this.travellerCategoriesOptions = response.data.map(e => {
        return {
          label: e.name,
          value: e.name,
        };
      });
    } catch (error) {
      this.loadErrors = this.$handleErrors(error, true);
      return;
    }
    if (this.selectedRule) {
      let data = JSON.parse(JSON.stringify(this.selectedRule));
      this.mapFormData(data);
    } else {
      this.mapFormData();
    }
    this.loading = true;
    this.loadErrors = [];
    try {
      const response = await CurrencyApi.getCurrencies();
      this.currencyCodes = response.data.map(item => item.code);
    } catch (error) {
      this.loadErrors = this.$handleErrors(error, true);
    }
    this.loading = false;
  }
}

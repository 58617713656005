





























































































































































import { Component, Watch, Emit, Prop } from 'vue-property-decorator';

import { router } from '@/router';
import { userFullName } from '@/core/user-full-name';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import SettingsStore from '@/modules/settings/settings.store';
import ExpensePolicyStore from './expense-policy.store';
import { WorkflowSettingModel, AssignedManualApprover } from '@/api/expense/expense-workflow.model';
import { translate } from '@/i18n';
import { Permission } from '@/const/permission.enum';
import { ProfileApi } from '@/api/profile/profile.api';
import MissionApprovalResponsibilities from './MissionApprovalResponsibilities.vue';

const updateApproverView = 'update:isApproverView';

@Component({
  components: {
    MissionApprovalResponsibilities,
  }
})
export default class ReportApproval extends BaseTable<WorkflowSettingModel> {
  @Prop({}) readonly!: boolean;
  @Prop() isApproverView!: boolean;

  params: BaseTableParams = new BaseTableParams({
    sortBy: 'name',
  });
  currentPage: number = 1;
  loading: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  selectedApprover: any = null;
  menuActive: boolean = false;
  removePopup: boolean = false;
  showAddApproverView: boolean = false;
  approverList: any[] = [];
  fields = {
    name: {
      sortable: false,
      label: translate('settings-expense.profile-name'),
    },
    description: {
      sortable: false,
      label: translate('settings-expense.description'),
    },
    order: {
      sortable: false,
      label: translate('settings-expense.order'),
    },
    actions: {
      sortable: false,
      label: ''
    }
  };

  get missionData() {
    return ExpensePolicyStore.expenseWorkflowSetting;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configuration() {
    return ExpensePolicyStore.configuration;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get count() {
    return this.missionData.assignedManualApprovers.length || 0;
  }

  get manualApprovalEnabled() {
    return this.missionData.manualApprovalEnabled;
  }

  set manualApprovalEnabled(value) {
    this.missionData.manualApprovalEnabled = value;
    this.$forceUpdate();
  }

  approverName(item) {
    let approver = this.approverList.find(app => app.profileId === item.profileId);
    if (approver) {
      return userFullName(approver);
    }
  }

  addApprover() {
    this.selectedApprover = new AssignedManualApprover();
    this.showAddApproverView = !this.showAddApproverView;
  }

  @Emit()
  [updateApproverView](val) {
    return val;
  }

  @Watch('showAddApproverView')
  onViewChange(value) {
    this[updateApproverView](value);
  }

  getApprover(item) {
    if (this.approverList.length) {
      return this.approverList.find(app => app.profileId === item.profileId);
    }
    return null;
  }

  toggleMenu(item) {
    if (this.selectedApprover !== item) {
      this.selectedApprover = item;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  edit(item) {
    this.selectedApprover = new AssignedManualApprover(item);
    this.showAddApproverView = !this.showAddApproverView;
    this.menuActive = false;
  }

  removeField() {
    const index = this.missionData.assignedManualApprovers.indexOf(this.selectedApprover);
    if (index > -1) {
      this.missionData.assignedManualApprovers.splice(index, 1);
    }
    this.removePopup = false;
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.removePopup = false;
  }

  async getApprovers(value) {
    this.approverList = [];
    let companyId = this.currentCompany!.companyId;
    const response = await ProfileApi.searchApprovers( companyId, value, Permission.ReadApprovalWorkflow );
    if (response && response.data) {
      this.approverList = response.data;
    } else {
      this.approverList = [];
    }
  }

  created() {
    this.getApprovers('');
  }
}








































































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { Validation } from 'vue-plugin-helper-decorator';
import { requiredIf } from 'vuelidate/lib/validators';
import TravelPolicyRailStore from '@/modules/settings/travel-policy/travel-policy-rail.store';
import TravelPolicyStore from '@/modules/settings/travel-policy/travel-policy.store';
import SettingsStore from '@/modules/settings/settings.store';
import { 
  SpeedTrainType,
  RailProviderType,
} from '@/api/travel-policy/travel-policy.model';
import { router } from '@/router';
import EventBus from '@/services/event-handler';
import _  from 'lodash';
import { translate } from '@/i18n';
import { DictionaryApi } from '@/api/dictionary/dictionary.api';
import { RailGeoPointType } from '@/api/dictionary/dictionary.model';
import { TravellerGroupOptions } from '@/const/profile-personal.const';
import { CurrencyApi } from '@/api/currency/currency.api';
import { TravellerCategoriesApi } from '@/api/profile/traveller-categories.api';

const railwayStationIconMap = {
  Unknown: 'done',
  City: 'location_city',
  RailStation: 'train',
  Airport: 'local_airport',
  Country: 'location_on',
  Continent: 'public',
};

const priceRegex = value => {
  return /^(\d+)(\.\d{1,2})?$/.test(value);
};

const zeroesRegex = value => {
  return /[^0]+/.test(value);
};

@Component({})
export default class TravelPolicyRailManageRule extends Vue {
  isRoutingFromLoading: boolean = false;
  fromLocationOptions: any[] = [];
  isRoutingToLoading: boolean = false;
  toLocationOptions: any[] = [];
  formPending: boolean = false;
  rentalCompanies: any[] = [];
  form = {
    id: this.generateUUID(),
    priority: 1,
    maxPrice: null,
    maxPriceCurrency: 'EUR',
    trainTypes: [],
    routings: [{ from: null, to: null }],
    providers: [],
    travellerCategories: [],
    isTrainTypeEnabled: false,
    isRoutingEnabled: false,
    isTripTypeEnabled: false,
    tripTypes: [],
    isTicketsClassesEnabled: false,
    isProviderEnabled: false,
    isTravellerCategoriesEnabled: false,
    isMaxPriceEnabled: false,
    status: '',
    ticketsClasses: [{ class: '', provider: '' }],
    isLegTimeEnabled: false,
    operator: {
      code: '',
      name: '',
    },
    ruleLegTimeHour: '',
    ruleLegTimeMin: '',
  };
  formHasError: boolean = false;
  loading: boolean = false;
  serverErrors: any[] = [];
  currencyCodes: any[] = [];
  loadErrors: any[] = [];
  currentService: string = 'Rail';
  dropdownClassFix: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  loadingDictionaryData: boolean = true;
  ticketsClasses: any[] = [];
  travellerCategoriesOptions: any[] = [];

  allProviders: any[] = [
    {
      name: 'Ntv',
      label: translate('supplier.Ntv'),
      value: 2
    },
    {
      name: 'Trenitalia',
      label: translate('supplier.Trenitalia'),
      value: 3
    },
    {
      name: 'Sncf',
      label: translate('supplier.Sncf'),
      value: 4
    },
    {
      name: 'Ouigo',
      label: translate('supplier.Ouigo'),
      value: 5
    },
    {
      name: 'Benerail',
      label: translate('supplier.Benerail'),
      value: 6
    }
  ];
  routingTypes: any[] = [
    {
      label: translate('settings-travel-policy.rail-domestic'),
      value: 'Domestic',
    },
    {
      label: translate('settings-travel-policy.rail-international'),
      value: 'International',
    },
  ];
  allTrainTypes: any[] = [
    {
      label: 'Normal',
      value: 0
    },
    {
      label: 'HighSpeed',
      value: 1
    }
  ];
  LegTimeOperator = [
    {
      code: '<=',
      name: translate('settings-travel-policy.up-to')
    },
    {
      code: '>=',
      name: translate('settings-travel-policy.from')
    }
  ];
  $v;

  get isReadOnly() {
    return this.$route.query.readOnly === 'true';
  }

  get tripTypeFilled() {
    return this.form.tripTypes.length > 0;
  }

  get selectedRule() {
    return TravelPolicyRailStore.currentSelectedRules;
  }

  get allRules() {
    return TravelPolicyRailStore.getRailRules;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get rootCompanyId() {
    if (!this.currentCompany) {
      return this.$route.params.id;
    }

    return this.currentCompany.rootCompanyId;
  }

  get currentConfigurationId() {
    return this.$route.params.configurationId;
  }

  get currentConfigName() {
    return TravelPolicyStore.currentConfigName;
  }

  get ruleExist() {
    if (this.selectedRule) {
      return Object.keys(this.selectedRule).length === 0 && this.selectedRule.constructor === Object;
    }
  }

  get iconMap() {
    return railwayStationIconMap;
  }

  get travellerGroupOptions() {
    return TravellerGroupOptions;
  }

  get routingsFilled() {
    return !!(this.form.routings[this.form.routings.length - 1].from && this.form.routings[this.form.routings.length - 1].to);
  }

  get ticketsClassesFilled() {
    return !!(this.form.ticketsClasses[this.form.ticketsClasses.length - 1].class && this.form.ticketsClasses[this.form.ticketsClasses.length - 1].provider);
  }



  filteredClassOptions(item) {
    if (!item || !item.provider) {
      return [];
    }

    return this.ticketsClasses
      .filter(p => {
        return p.provider.toLowerCase() === item.provider.toLowerCase();
      });
  }

  providerOfTicketsClasses(item) {
    if (!item || !item.provider) {
      return null;
    }

    return this.allProviders.find(p => p.name.toLowerCase() === item.provider.toLowerCase());
  }

  setProviderOfTicketsClasses(item, $event) {
    if (!$event) {
      item.provider = '';
      item.class = '';
      return;
    }
    item.provider = $event.name;
    item.class = '';
  }

  classOfTicketsClasses(item) {
    if (!item || !item.class) {
      return null;
    }

    return this.ticketsClasses
      .find(p => p.class === item.class && item.provider.toLowerCase() === p.provider.toLowerCase());
  }

  setClassOfTicketsClasses(item, $event) {
    if (!$event) {
      item.class = '';
      return;
    }
    item.class = $event.class;
    item.provider = this.classOfTicketsClasses(item).provider;
  }

  linkClicked(e) {
    e.preventDefault();
  }

  addRouting() {
    if (this.routingsFilled && this.form.isRoutingEnabled) {
      this.form.routings.push({ from: null, to: null });
    }
  }

  removeRouting(index) {
    this.form.routings.splice(index, 1);
  }

  addTicketsClass() {
    if (!this.ticketsClassesFilled || !this.form.isTicketsClassesEnabled) {
      return;
    }
    this.form.ticketsClasses.push({ class: '', provider: '' });
  }

  removeTicketsClass(index) {
    this.form.ticketsClasses.splice(index, 1);
  }

  returnToConfiguration() {
    TravelPolicyStore.setIsFromManage(true);
    TravelPolicyStore.setCurrentSelectedItem('Train');
    router.push({
      name: 'travel-policy-configuration',
      params: {
        configurationId: this.currentConfigurationId,
      },
    });
  }

  @Validation()
  validationObject() {
    if (this.form.isMaxPriceEnabled) {
      return {
        form: {
          maxPrice: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
            priceRegex,
            zeroesRegex: (val) => {
              return val !== '' ? zeroesRegex(val) : true;
            },
            maxValue: (val) => {
              return val !== '' ? Number(val) < 10000000 : true;
            }
          },
          trainTypes: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          routings: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          providers: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          travellerCategories: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          ticketsClasses: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          operator: {
            code: {
              required: requiredIf(() => {
                return !this.isFormFilled();
              }),
            }
          },
          ruleLegTimeHour: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          ruleLegTimeMin: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          sufficient: () => {
            return this.isFormSufficient();
          }
        }
      };
    } else {
      return {
        form: {
          maxPrice: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          trainTypes: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          routings: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          providers: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          travellerCategories: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          operator: {
            code: {
              required: requiredIf(() => {
                return !this.isFormFilled();
              }),
            }
          },
          ruleLegTimeHour: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          ruleLegTimeMin: {
            required: requiredIf(() => {
              return !this.isFormFilled();
            }),
          },
          sufficient: () => {
            return this.isFormSufficient();
          }
        }
      };
    }
  }

  checkForm() {
    this.formHasError = false;
    this.$v.form.$touch();
    if (this.$v.form.$pending || this.$v.form.$error) {
      this.formHasError = true;
    }
  }

  colorStyles(option) {
    return {
      backgroundColor: option && option.color ? option.color : '',
    };
  }

  isFormSufficient() {
    return ((this.form.isMaxPriceEnabled ? !!(this.form.maxPrice) : false) ||
      (this.form.isTicketsClassesEnabled ? !!(this.ticketsClassesFilled) : false) ||
      (this.form.isTrainTypeEnabled ? !!(this.form.trainTypes && this.form.trainTypes.length) : false) ||
      (this.form.isRoutingEnabled ? !!(this.mapRoutingsForRequest(this.form.routings)) : false) ||
      (this.form.isTripTypeEnabled ? !!(this.tripTypeFilled) : false) ||
      (this.form.isProviderEnabled ? !!(this.form.providers && this.form.providers.length) : false) ||
      (this.form.isLegTimeEnabled && (this.form.operator && this.form.operator.code && this.form.operator.name) && (this.form.ruleLegTimeHour || this.form.ruleLegTimeMin)) ||
      (this.form.isTravellerCategoriesEnabled ? !!(this.form.travellerCategories && this.form.travellerCategories.length) : false));
  }

  isFormFilled() {
    return (this.form.isMaxPriceEnabled && this.form.maxPrice) ||
      (this.form.isTicketsClassesEnabled ? !!(this.ticketsClassesFilled) : false) ||
      (this.form.isTrainTypeEnabled ? !!(this.form.trainTypes && this.form.trainTypes.length) : false) ||
      (this.form.isRoutingEnabled ? !!(this.form.routings && this.mapRoutingsForRequest(this.form.routings)) : false) ||
      (this.form.isTripTypeEnabled ? !!(this.tripTypeFilled) : false) ||
      (this.form.isProviderEnabled ? !!(this.form.providers && this.form.providers.length) : false) ||
      (this.form.isLegTimeEnabled && (this.form.operator && this.form.operator.code && this.form.operator.name) && (this.form.ruleLegTimeHour || this.form.ruleLegTimeMin)) ||
      (this.form.isTravellerCategoriesEnabled ? !!(this.form.travellerCategories && this.form.travellerCategories.length) : false);
  }

  generateUUID() {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  mapRoutingsForRequest(routings: any[]) {
    const routingsMapped = routings.map(e => {
      return (e.from && e.to) ?
      {
        from : {
          type: RailGeoPointType[e.from.type],
          id: e.from.id,
        },
        to: {
          type: RailGeoPointType[e.to.type],
          id: e.to.id,
        }
      }
      : null;
    });
    const routingsFiltered = routingsMapped.filter(Boolean);
    return routingsFiltered.length ? routingsFiltered : null;
  }
  
  mapRoutings(routings: any[] | null) {
    if (!routings || !routings.length) {
      return [{
        from : null,
        to: null,
      }];
    }
    return routings;
  }

  mapTrainType(trainType: SpeedTrainType) {
    const type = this.allTrainTypes.find(e => {
      return typeof(trainType) === 'number' ? e.value === trainType : e.label === trainType;
    });
    return type ? type : null;
  }

  mapProviders(provider: RailProviderType) {
    const type = this.allProviders.find(e => {
      return typeof(provider) === 'number' ? e.value === provider : e.name === provider;
    });
    return type ? type : null;
  }

  mapFormData(data?: any) {
    let hour;
    let minutes;
    if (data && data.ruleLegTime) {
      hour = Math.floor(data.ruleLegTime / 60);
      minutes = data.ruleLegTime % 60;
    }

    let trainTypes = new Array();
    let providers = new Array();
    let routings = this.mapRoutings([]);
    let isTripTypeEnabled = false;
    const ticketsClasses = data && data.ticketsClasses && data.ticketsClasses.length ? data.ticketsClasses : [{ class: '', provider: '' }];

    if (data) {
      if (data.trainTypes) {
        data.trainTypes.forEach(type => {
          let trainType = this.mapTrainType(type);
          trainTypes.push(trainType);
        });
      }
      if (data.providers) {
        data.providers.forEach(provider => {
          let providerMapped = this.mapProviders(provider);
          providers.push(providerMapped);
        });
      }
      if (data.routings) {
        routings = this.mapRoutings(data.routings);
      }
      if (data && data.tripTypes && data.tripTypes.length) {
        isTripTypeEnabled = true;
      }
    }

    this.form = {
      id: data && data.id ? data.id : this.generateUUID(),
      priority: data && data.rulePriority ? data.rulePriority : 1,
      isMaxPriceEnabled: !!(data && data.maxPrice && data.maxPrice !== ''),
      isProviderEnabled: !!(data && data.providers && data.providers.length),
      isTrainTypeEnabled: !!(data && data.trainTypes && data.trainTypes.length),
      isTripTypeEnabled,
      tripTypes: (data && this.routingTypes.filter(t => (data.tripTypes || []).includes(t.value))) || [],
      operator: {
        code: data && data.ruleLegTimeOperator ? data.ruleLegTimeOperator : '',
        name: data && data.ruleLegTimeOperator ? (data.ruleLegTimeOperator === '<=' ? translate('settings-travel-policy.up-to') + ' ' : translate('settings-travel-policy.from') + ' ') : ''
      },
      ruleLegTimeHour: hour ? hour : '',
      ruleLegTimeMin: minutes ? minutes : '',
      trainTypes: trainTypes,
      isRoutingEnabled: !!(data && data.routings && data.routings.length),
      routings: routings,
      maxPrice: data && data.maxPrice ? data.maxPrice : null,
      maxPriceCurrency: data && data.maxPriceCurrency ? data.maxPriceCurrency : 'EUR',
      ticketsClasses,
      isTicketsClassesEnabled: data && data.ticketsClasses && !!data.ticketsClasses.length,
      providers: providers,
      isTravellerCategoriesEnabled: !!(data && data.travellerCategories && data.travellerCategories.length),
      isLegTimeEnabled: !!(data && data.ruleLegTimeOperator && data.ruleLegTimeOperator !== ''),
      travellerCategories: data && data.travellerCategories && data.travellerCategories.length ? data.travellerCategories.map(e => {
        let index = this.travellerCategoriesOptions.findIndex(elem => {
          return elem.value === e;
        });
        if (-1 < index) {
          return this.travellerCategoriesOptions[index];
        }
      }) : [],
      status: data && data.status === '' ? TravelPolicyRailStore.draftEditStatus : TravelPolicyRailStore.draftNewStatus,
    } as any;
  }

  createRequest() {
    let time;
    if (this.form && parseInt(this.form.ruleLegTimeHour) > 0) {
      if (parseInt(this.form.ruleLegTimeMin) > 0) {
        time = parseInt(this.form.ruleLegTimeHour) * 60 + parseInt(this.form.ruleLegTimeMin);
      } else {
        time = parseInt(this.form.ruleLegTimeHour) * 60;
      }
    } else {
      time = parseInt(this.form.ruleLegTimeMin);
    }

    let request = {
      id: this.form.id,
      rulePriority: this.form.priority,
      maxPrice: this.form.isMaxPriceEnabled ? this.form.maxPrice : null,
      ruleLegTime: time + '',
      tripTypes: this.form.isTripTypeEnabled ? this.form.tripTypes.map((e: any) => e.value) : null,
      ruleLegTimeOperator: this.form.isLegTimeEnabled ? this.form.operator.code : null,
      maxPriceCurrency: this.form.maxPriceCurrency,
      trainTypes: this.form.isTrainTypeEnabled ? this.form.trainTypes.map((e: any) => e.value) : [],
      routings: this.form.isRoutingEnabled ? this.form.routings : null,
      providers: this.form.isProviderEnabled ? this.form.providers.map((e: any) => e.value) : [],
      travellerCategories: this.form.isTravellerCategoriesEnabled ? this.form.travellerCategories.map((e: any) => e.value) : [],
      status: TravelPolicyRailStore.draftNewStatus,
      ticketsClasses: this.form.isTicketsClassesEnabled ? this.form.ticketsClasses : [],
    };

    TravelPolicyRailStore.legTime(request);

    if (this.allRules && this.allRules.length) {
      let isEdit = _.filter(this.allRules, (rule) => {
        return rule.id === request.id;
      });

      if (isEdit.length) {
        TravelPolicyRailStore.editRailRule(request);
      } else {
        TravelPolicyRailStore.addRailRule(request);
      }
    } else {
        TravelPolicyRailStore.addRailRule(request);
    }
  }

  confirmAndBack() {
    this.checkForm();
    if (!this.formHasError) {
      this.formPending = true;
      this.createRequest();
      this.returnToConfiguration();
    }
  }

  confirmAndAddNext() {
    this.checkForm();
    if (!this.formHasError) {
      this.formPending = true;
      this.createRequest();
      this.mapFormData();
      this.$v.form.$reset();
      this.formPending = false;

      let obj = {
        type: translate('settings-travel-policy.success'),
        title: '',
        message: translate('settings-travel-policy.rule-added')
      };
      EventBus.$emit('show-toast', obj);
    }
  }

  @Watch('selectedRule', {deep: true,  immediate: true})
  existSelectedRule() {
    if (!this.selectedRule) {
      router.go(-1);
    }
  }

  @Watch('form.isTicketsClassesEnabled')
  onIsTicketsClassesEnabledChange(value) {
    if (!value) {
      this.form.ticketsClasses = [{ provider: '', class: '' }];
    }
  }

  routingsEnabled(val) {
    if (val === false) {
      this.form.routings = [];
    }
  }

  clearRoutingFromLocations() {
    this.dropdownClassFix = false;
    this.fromLocationOptions = [];
  }

  clearRoutingToLocations() {
    this.dropdownClassFix = false;
    this.toLocationOptions = [];
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isRoutingFromLoading',
  })
  async findRoutingFromLocation(query: string) {
    if (query && query.length > 2) {
      this.dropdownClassFix = true;
      this.isRoutingFromLoading = true;
      const response = await DictionaryApi.getRailwayStations(query);

      if (response && response.data) {
        this.fromLocationOptions = [];
        this.fromLocationOptions = response.data;
      }

      this.isRoutingFromLoading = false;
    } else {
      this.isRoutingFromLoading = false;
      this.fromLocationOptions = [];
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isRoutingToLoading',
  })
  async findRoutingToLocation(query: string) {
    if (query && query.length > 2) {
      this.dropdownClassFix = true;
      this.isRoutingToLoading = true;
      const response = await DictionaryApi.getRailwayStations(query);

      if (response && response.data) {
        this.toLocationOptions = [];
        this.toLocationOptions = response.data;
      }

      this.isRoutingToLoading = false;
    } else {
      this.isRoutingToLoading = false;
      this.toLocationOptions = [];
    }
  }

  async loadTrainTicketsClasses() {
    try {
      const response = await DictionaryApi.getTrainTicketsClasses();
      this.ticketsClasses = response.data;
      this.loadingDictionaryData = false;
    } catch (error) {
      this.serverErrors = this.$handleErrors(error);
    }
  }

  async created() {
    this.loadTrainTicketsClasses();
    try {
      const response = await TravellerCategoriesApi.getCompanyTravellerCategories(this.rootCompanyId);

      this.travellerCategoriesOptions = response.data.map(e => {
        return {
          label: e.name,
          value: e.name,
        };
      });
    } catch (error) {
      this.loadErrors = this.$handleErrors(error, true);
      return;
    }
    if (this.selectedRule) {
      let data = JSON.parse(JSON.stringify(this.selectedRule));
      this.mapFormData(data);
    } else {
      this.mapFormData();
    }
    this.loading = true;
    this.loadErrors = [];
    try {
      const response = await CurrencyApi.getCurrencies();
      this.currencyCodes = response.data.map(item => item.code);
    } catch (error) {
      this.loadErrors = this.$handleErrors(error, true);
    }
    this.loading = false;
  }
}
















import { Vue, Component, Prop } from 'vue-property-decorator';
import AirSearchStore from '@/modules/search/air/air-search.store';
import TrainSearchStore from '@/modules/search/train/train-search.store';
import { translate } from '@/i18n';

@Component({})
export default class UiCo2 extends Vue {
  @Prop() value: any;
  @Prop({ default: 'right' }) side!: string;
  @Prop({ default: false }) isForAir!: boolean;
  @Prop({ default: false }) isForTrain!: boolean;

  get displayImg() {
    return '/assets/img/co2.png';
  }

  get showTooltipWithLowerCo2Emission() {

    let averageCo2Emission;

    if (this.isForAir) {
      averageCo2Emission = AirSearchStore.averageCo2Emission;  
    } else if (this.isForTrain) {
      averageCo2Emission = TrainSearchStore.averageCo2Emission;
    }

    return averageCo2Emission 
      && typeof averageCo2Emission === 'number' 
      && typeof this.value === 'number' 
      && averageCo2Emission > 0
      && this.value > 0 
      && this.value < averageCo2Emission;
  }

  tooltipWithLowerCo2Emission() {
    if (this.isForAir) {
      return [
        translate('search-filter.flight-with-lower-co2-emission-tooltip-part-1'),
        translate('search-filter.flight-with-lower-co2-emission-tooltip-part-2'),
      ].join('<br/>');
    } else if (this.isForTrain) {
      return [
        translate('search-filter.train-with-lower-co2-emission-tooltip-part-1'),
        translate('search-filter.train-with-lower-co2-emission-tooltip-part-2'),
      ].join('<br/>');
    }

    return null; 
  }
}

































































































































































































































































































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import moment from 'moment';
import _ from 'lodash';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, email, numeric, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators';

import { userFullName } from '@/core/user-full-name';
import { translate, i18n } from '@/i18n';
import EventBus from '@/services/event-handler';
import { ProfileApi } from '@/api/profile/profile.api';
import { ContactsApi } from '@/api/profile/contacts.api';
import DictionaryStore from '@/store/dictionary.store';
import BasketStore from './basket.store';
import SearchStore from '@/modules/search/search.store';
import AccountStore from '@/store/account.store';
import { Permission } from '@/const/permission.enum';
import { PhoneCode } from '@/api/profile/contacts.model';
import { ProfileDocument } from '@/api/profile/documents.model';
import DocumentEditPopup from '@/modules/profile/documents/DocumentEditPopup.vue';
import BasketTravellerDocuments from './BasketTravellerDocuments.vue';
import BasketTravellerLoyaltyPrograms from './BasketTravellerLoyaltyPrograms.vue';
import LoyaltyPopup from '@/modules/profile/loyalty-programs/LoyaltyPopup.vue';
import LoyaltyRemovePopup from '@/modules/profile/loyalty-programs/LoyaltyRemovePopup.vue';
import { DateOfBirthObligation } from '@/api/home/home.model';

@Component({
  components: {
    DocumentEditPopup,
    BasketTravellerDocuments,
    BasketTravellerLoyaltyPrograms,
    LoyaltyPopup,
    LoyaltyRemovePopup,
  }
})
export default class BasketTravellerDataForm extends Vue {
  @Prop({}) traveller: any;
  @Prop({}) index: any;
  @Prop({}) allLoaded!: boolean;

  $v;
  Form: any = null;
  FormClean: any = null;
  showDocuments: boolean = true;
  showLoyalties: boolean = true;
  loading: boolean = true;
  shouldValidate: boolean = false;
  errors: any[] = [];
  showDocumentEditPopup: boolean = false;
  deleteDocumentPopup: boolean = false;
  editingDocument: ProfileDocument | null = null;
  documentToRemove: ProfileDocument | null = null;
  documentFormPending: boolean = false;
  documentRemovalErrorMessage: string | null = null;

  showLoyaltyCardPopup: boolean = false;
  deleteLoyaltyCardPopup: boolean = false;
  editingLoyaltyCard: any = null;
  Obligations = DateOfBirthObligation;


  phoneCodeEmptyValue: PhoneCode = {
    phoneCode: '',
    countryName: '',
    code: '',
    threeLetterCode: '',
    shortPhoneCodeDisplayName: translate('common.phone-code'),
    fullPhoneCodeDisplayName: translate('common.none'),
  };
  titleOptions = [
    {
      value: '',
      name: '',
    },
    {
      value: 'Mr',
      name: translate('common.mr'),
    },
    {
      value: 'Mrs',
      name: translate('common.mrs'),
    },
    {
      value: 'Miss',
      name: translate('common.miss'),
    },
  ];

  get travellerValidationError() {
    return BasketStore.travellerValidationError;
  }

  get travellerAgeIncompatibility() {
    const errors = (this.travellerValidationError && this.travellerValidationError.errors) || [];
    return errors.find((item) => {
      const { errorCode, customState } = item;
      return errorCode === 'AGE_INCOMPATIBILITY' && customState.id === this.traveller.id;
    });
  }

  get originalAge() {
    const customState = this.travellerAgeIncompatibility && this.travellerAgeIncompatibility.customState;
    if (customState) {
      return i18n.tc('basket-modify-travellers.validation-message-age-difference', customState.originalAge);
    }
    return '';
  }

  get profile() {
    return AccountStore.current!.profile;
  }

  get formError() {
    return this.$v.$invalid;
  }

  get allCountries() {
    return DictionaryStore.allCountries || [];
  }

  get minimalBirthDate() {
    let date = new Date();
    date.setFullYear( date.getFullYear() - 150 );
    return date;
  }

  get allPhoneCountryCodes() {
    if (!DictionaryStore.allCountries) {
      return [];
    }

    const allPhoneCodes = this.allCountries
      .filter(country => country && country.phoneCode!.length > 0)
      .map((country) => {
        return { 
          ...country,
          shortPhoneCodeDisplayName: '+' + country.phoneCode,
          fullPhoneCodeDisplayName: country.countryName + ' +' + country.phoneCode
        };
      });

    return [this.phoneCodeEmptyValue].concat(allPhoneCodes as Array<PhoneCode>);
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get allowRefuseContactDetails() {
    return BasketStore.basket && BasketStore.basket.allowRefuseContactDetails;
  }

  get skipTravellers() {
    return SearchStore.skipTravellers;
  }

  get guestsData() {
    return BasketStore.guestBasketTravellersData;
  }

  get bookingStepDef() {
    return BasketStore.bookingStepDef;
  }

  get availableLoyalties() {
    if (this.traveller.isVirtual) {
      let desiredTraveller = this.guestsData.find(e => {
        return e.id === this.Form.id;
      });
      if (desiredTraveller) {
        return desiredTraveller.loyalties;
      } else { 
        return [];
      }
    } else {
      return _.uniqBy(this.Form.loyalties, 'loyalty.serviceType');
    }
  }

  get isFormClean() {
    let form = {
      ...this.Form,
      dateOfBirth: moment(this.Form.dateOfBirth).format('yyyy-MM-DD'),
    };

    return _.isEqual(form, this.FormClean);
  }

  get userHasMandatoryData() {
    const isUserProvidingContactDetails = this.FormClean.refusedProvidingContactDetails ||
      (this.FormClean.email &&
      !!(this.FormClean.selectedPrimaryPhoneCode && this.FormClean.selectedPrimaryPhoneCode.code) &&
      this.FormClean.phone.number);
    return !!(
      (this.traveller.dateOfBirthObligation !== DateOfBirthObligation.Required || !this.isApisDOBRequired || this.FormClean.dateOfBirth) &&
      isUserProvidingContactDetails &&
      ((this.FormClean.passengerTypeCode !== 'CHD' && this.FormClean.passengerTypeCode !== 'INF') ? !!(this.FormClean.title && this.FormClean.title.value) : true)
    );
  }

  get isApisDOBRequired() {
    return !!BasketStore.currentMandatoryApisRules
      .find(item => item.dobRule);
  }

  get shouldShowApisRulesValidationErrors() {
    return BasketStore.shouldShowApisRulesValidationErrors;
  }


  async onRefusedProvidingContactDetailsChange(isRefused: boolean) {
    this.Form = {
      ...this.Form,
      phone: {
        ...this.Form.phone,
        number: isRefused ? null : this.FormClean.phone.number,
        code: isRefused ? null : this.FormClean.phone.code,
      },
      selectedPrimaryPhoneCode: isRefused ? null : this.FormClean.selectedPrimaryPhoneCode,
      email: isRefused ? null : this.FormClean.email,
    };
  }

  userFullName(user) {
    return userFullName(user);
  }

  @Watch('formError')
  onFormErrorChange(value) {
    EventBus.$emit('traveller-validation-changed', { id: this.Form.id, isValid: !value });
  }

  @Watch('loading')
  onLoadingChange(value) {
    EventBus.$emit('traveller-data-loaded', { id: this.Form.id, isLoading: value });
  }

  @Validation()
  validationObject() {
    return {
      Form: {
        title: {
          requiredIf: (titleObject) => {
            return !!(titleObject && titleObject.value);
          }
        },
        email: this.Form && this.Form.refusedProvidingContactDetails || {
          required,
          maxLength: maxLength(320),
          email,
        },
        selectedPrimaryPhoneCode: this.Form && this.Form.refusedProvidingContactDetails || {
          required,
          validPhoneCode: (codeObject) => {
            return (!!codeObject && !!codeObject.phoneCode);
          },
        },
        dateOfBirth: {
          requiredIf: requiredIf(() => {
            return this.traveller &&
              (this.traveller.dateOfBirthObligation === DateOfBirthObligation.Required ||
              this.isApisDOBRequired);
          }),
        },
        phone: {
          number: this.Form && this.Form.refusedProvidingContactDetails || {
            required,
            minLength: minLength(3),
            numeric,
          },
        },
      }
    };
  }

  onPrimaryPhoneCodeSelect(selected, index) {
    if (selected && !selected.phoneCode) {
      this.Form.phone.number = '';
    } else if (selected && selected.phoneCode) {
      this.Form.phone.code = selected.phoneCode;
    }
    setTimeout(() => {
      const el = (this.$refs.primaryNumber as Vue).$refs.input as HTMLElement;
      el.focus();
    });
  }

  getSuggestedPhoneCode(onlyCode: boolean = false) {
    const profileLang = this.profile ? this.profile.displayLanguage : null;
    let codeFound: any = null;
    switch (profileLang) {
      case 'en':
        codeFound = this.allPhoneCountryCodes.find(code => {
          return code.code === 'US';
        });
        break;
      case 'it':
        codeFound = this.allPhoneCountryCodes.find(code => {
          return code.code === 'IT';
        });
        break;
      case 'fr':
        codeFound = this.allPhoneCountryCodes.find(code => {
          return code.code === 'FR';
        });
        break;
      default: 
        codeFound = this.phoneCodeEmptyValue;
        break;
    }
    return onlyCode ? codeFound.phoneCode : codeFound;
  }

  documentsOfGivenType(type) {
    if (this.Form.isVirtual) {
      const data = this.guestsData.find(d => {
        return d.id === this.Form.id;
      });
      return data.documents.filter((doc: any) => doc.type === type.value);
    } else {
      return this.Form.documents.filter((doc: any) => doc.type === type.value);
    }
  }

  async removeDocument() {
    if (document) {
      try {
        this.documentFormPending = true;
        if (this.Form.isVirtual) {
          BasketStore.removeGuestBasketTravellerDocument({ id: this.Form.id, documentId: this.documentToRemove!.tempId });
        } else {
          this.Form.documents = this.Form.documents.filter((doc: any) => doc.id !== this.documentToRemove!.id);
        }
        this.deleteDocumentPopup = false;
      } catch (error) {
        if (!error.response.data.error.details) {
          this.documentRemovalErrorMessage = error.response.data.error.message;
        }
      } finally {
        this.documentFormPending = false;
      }
    }
  }

  getCountryName(code: string) {
    const country = this.allCountries.find(c => { return c.code === code; });
    if (country) {
      return country.countryName;
    }
    return code;
  }

  goToProfile(travellerId) {
    const routeData = this.$router.resolve({
      name: 'personal',
      params: {
        id: travellerId
      }
    });
    window.open(routeData.href, '_blank');
  }

  async getPersonal(id) {
    const personal = await ProfileApi.getById(id, Permission.ReadProfilePersonal);
    let personalData = personal.data;
    return personalData;
  }

  async getContacts(id) {
    const contactsResponse = await ContactsApi.getContacts(id);
    let contactsData = contactsResponse.data;
    if (!contactsData) {
      return null;
    }
    let contacts = contactsData.primary;
    if (contacts && contacts.phone && contacts.phone.code) {
      contacts.selectedPrimaryPhoneCode = this.allPhoneCountryCodes
        .find(phoneCode => {
          return phoneCode.phoneCode === contacts.phone.code;
        });
    }

    return {
      ...contacts,
      refusedProvidingContactDetails: contactsData.refusedProvidingContactDetails && this.allowRefuseContactDetails,
    };
  }

  async mapFormData() {
    try {
      this.loading = true;
      let trav: any = {
        ...this.traveller,
        dateOfBirth: this.traveller.dateOfBirth ? moment(this.traveller.dateOfBirth).toDate() : null,
      };
      let personalData = await this.getPersonal(this.traveller.profileId || this.traveller.id);
      if (personalData) {
        trav.title = this.titleOptions.find(e => {
          return e.value === (personalData.title ? personalData.title.name : '');
        });
        if (personalData.dateOfBirth) {
          trav.dateOfBirth = moment(personalData.dateOfBirth).toDate();
        }
      } else {
        trav.title = this.titleOptions[0];
      }
      let contacts = await this.getContacts(this.traveller.profileId || this.traveller.id);
      if (!contacts || !contacts.phone) {
        if (!contacts) {
          contacts = {};
        }
        contacts.phone = {
          code: this.getSuggestedPhoneCode(true),
          number: '',
          isMobile: false,
        };
        trav.selectedPrimaryPhoneCode = this.getSuggestedPhoneCode(false);
      }
      Object.assign(trav, contacts);

      this.FormClean = {
        ...JSON.parse(JSON.stringify(trav)),
        dateOfBirth: trav.dateOfBirth,
      };

      this.Form = trav;

    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.loading = false;
      this.shouldValidate = true;
    }
  }

  assignTravellerData() {
    this.$v.$touch();
    if (this.formError) {
      BasketStore.setGuestBasketTravellersDataError(true);
      return;
    }
    const request = {
      title: this.Form.title,
      id: this.Form.id,
      profileId: this.Form.profileId,
      isVirtual: this.Form.isVirtual,
      dateOfBirth: this.Form.dateOfBirth ? moment(this.Form.dateOfBirth).format('yyyy-MM-DD') : null,
      refusedProvidingContactDetails: this.Form.refusedProvidingContactDetails,
      contactInfo: {
        email: this.Form.email,
        phone: this.Form.phone,
      }
    };
    const result = {
      request,
      profileId: this.Form.profileId,
      id: this.Form.id,
      isVirtual: this.Form.isVirtual,
      isClean: this.isFormClean,
    };
    BasketStore.setGuestBasketTravellerData(result);
  }

  onProcessingError(error) {
    this.errors = this.$handleErrors(error, true);
  }

  async mounted() {
    BasketStore.resetGuestBasketTravellerData();
    BasketStore.setGuestBasketTravellersDataError(true);
    EventBus.$on('assign-travellers-data', this.assignTravellerData);
    EventBus.$on('traveller-data-error', this.onProcessingError);
    await this.mapFormData();
    this.$v.$touch();
    
    if (this.Form) {
      EventBus.$emit('traveller-validation-changed', { id: this.Form.id, isValid: !this.formError });
    }
  }

  beforeDestroy() {
    EventBus.$off('assign-travellers-data', this.assignTravellerData);
    EventBus.$off('traveller-data-error', this.onProcessingError);
  }
}

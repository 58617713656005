import CreateAgencyCustomer from '@/modules/settings/CreateAgencyCustomer.vue';

export default {
  path: 'create-agency-customer',
  name: 'create-agency-customer',
  component: CreateAgencyCustomer,
  displayName: 'settings-sidebar.create-company',
  meta: {
    introClass: 'intro-settings',
    permission: 'CreateAgencyCustomers'
  }
};

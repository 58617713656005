
















































































import { Vue, Component, Watch } from 'vue-property-decorator';

import SearchStore from '../search.store';
import AirSearchStore from './air-search.store';
import { SupplierName } from '@/services/supplier-name.service';

@Component({})
export default class SplitBookingParameter extends Vue {
  showSplitHint: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  get airSearchParameters() {
    return SearchStore.getAirDefaultState;
  }

  get splitBookingPermission() {
    return this.$hasAccess('CanDecideOnSplitSearch');
  }

  get currentCompanyId() {
    if (SearchStore.skipTravellers) {
      return SearchStore.skipTravellersCompany.id;
    }

    const mainTraveller = SearchStore.editedTravellers.travellers.find((item) => !!item.isMainTraveller);
    if (mainTraveller && !mainTraveller.isVirtual) {
      return mainTraveller.businessUnitId;
    }
    
    return SearchStore.editedTravellers.travellers[0].businessUnitId;
  }

  get loaderSplitProvidersForCompany() {
    return AirSearchStore.loaderSplitProvidersForCompany;
  }

  get splitProvidersForCompany() {
    return AirSearchStore.splitProvidersForCompany;
  }

  get errSplitProvidersForCompanyMessages() {
    return AirSearchStore.errSplitProvidersForCompanyMessages;
  }

  get permissionErrSplitProvidersForCompanyMessages() {
    return AirSearchStore.permissionErrSplitProvidersForCompanyMessages;
  }

  getSupplierName(name) {
    return SupplierName(name);
  }

   onMouseOver(e) {
    if (window.innerWidth < 800) {
      return;
    }
    this.showSplitHint = true;
  }

  @Watch('showSplitHint', { immediate: true })
  async onShowSplitHint(val) {
    if (val) {
      if (this.currentCompanyId) {
        await AirSearchStore.getSplitProvidersForCompany(this.currentCompanyId);
      }
    }
  }
}

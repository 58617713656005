


















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { translate } from '@/i18n';
import { BasketFlatItemModel } from '@/api/trip/basket.model';
import { TripApi } from '@/api/trip/trip.api';
import BasketStore from '@/modules/basket/basket.store';

const RADIO = {
  restart: 'RestartSynchronization',
  desynchronized: 'Desynchronized',
  noAction: 'NoAction',
};
const RADIO_BUTTONS = [{
  value: RADIO.restart,
  get label() {
    return translate('basket.try-to-run-synchronization-again');
  }
}, {
  value: RADIO.desynchronized,
  get label() {
    return translate('basket.just-mark-this-booking-as-modified-offline');
  }
}, {
  value: RADIO.noAction,
  get label() {
    return translate('basket.resign-from-this-operation');
  }
}];

@Component({})
export default class BasketSyncAirBookingErrorPopup extends Vue {
  @Prop() show!: boolean;
  @Prop() item!: BasketFlatItemModel;

  RADIO = RADIO;
  RADIO_BUTTONS = RADIO_BUTTONS;
  errors: any[] = [];
  loading: boolean = false;
  selectedOption: string = RADIO.restart;

  @Emit('update:show')
  closePopup() {
    return false;
  }

  async continueSynchronization(selectedOption: string) {
    try {
      this.loading = true;
      await TripApi.failedSynchronization(this.item.id, selectedOption);
      this.closePopup();
      await BasketStore.processBasket();
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.loading = false;
    }
  }
}

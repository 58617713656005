





























































































import { Vue, Component, Watch, Prop, Emit } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import { HomeApi } from '@/api/home/home.api';
import { PreferencesApi } from '@/api/profile/preferences.api';
import { PreferencesModel, FlightPreferences } from '@/api/profile/preferences.model';
import { AirLine } from '@/api/dictionary/dictionary.model';
import DictionaryStore from '@/store/dictionary.store';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';

@Component({})
export default class CarPreferencesPopup extends Vue {
  @Prop() preferences!: any;
  serverErrors: any[] = [];
  hotelChainsLoading: boolean = false;
  hotelChainsOption: any = [];
  formPending: boolean = false;
  Form: PreferencesModel = {
    carPreferences: {
      vendors: [],
      generalInformations: '',
      carTypes: [],
      extras: [],
    },
  };
  $v;

  @Validation()
  validationObject() {
    return {
      Form: {
        carPreferences: {
          generalInformations: {
            maxLength: maxLength(2048),
          }
        }
      },
    };
  }

  updateFormData(data) {
    if (data) {
      this.Form = {
        carPreferences: {
          vendors: data.vendors,
          generalInformations: data.generalInformations,
          carTypes: data.carTypes,
          extras: data.extras,
        }
      };
    }
  }

  @Emit()
  close() {
  }

  async save() {
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error) {
      return;
    }

    try {
      if (this.preferences) {
        let profileId = this.$route.params.id;
        let request = {
          travellerType: this.preferences.travellerType,
          currencyCode: this.preferences.currencyCode,          
          flightPreferences: {
            ...this.preferences.flightPreferences,
            carriers: this.preferences.flightPreferences!.carriers.map((carrier: any) => carrier.code),
            departureAirports: this.preferences.flightPreferences!.departureAirports.map(departure => departure.code),
            arrivalAirports: this.preferences.flightPreferences!.arrivalAirports.map(arrival => arrival.code),
          },
          railPreferences: {
            ...this.preferences.railPreferences,
          },
          accommodationPreferences: {
            ...this.preferences.accommodationPreferences,
            roomTypes: this.preferences.accommodationPreferences!.roomTypes.map(room => room.code ? room.code : room),
          },
          carPreferences: {
            vendors: this.Form.carPreferences!.vendors,
            generalInformations: this.Form.carPreferences!.generalInformations,
            carTypes: this.Form.carPreferences!.carTypes,
            extras: this.Form.carPreferences!.extras,
          },
        };
        await PreferencesApi.updatePreferences(profileId, request);
      }
      let obj = {
        type: 'success',
        title: translate('profile-personal.data-saved'),
        message: translate('profile-personal.car-saved')
      };
      EventHandler.$emit('show-toast', obj);
      EventHandler.$emit('reload-loyalty');
      EventHandler.$emit('profile-data-saved');
      this.close();
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  created() {
    if (this.preferences && this.preferences.carPreferences) {
      this.updateFormData(this.preferences.carPreferences);
    }
  }

}

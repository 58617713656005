



































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { translate } from '@/i18n';
import { Traveller } from '@/api/trip/basket.model';
import { TAncillary, TSncfAncillaryModel } from '@/modules/basket/basket-rail-seats-extras/basket-rail-seats-extras.model';
import AlertPopup from '@/modules/basket/basket-rail-seats-extras/AlertPopup.vue';
import BasketStore from '@/modules/basket/basket.store';

@Component({
  components: {
    AlertPopup,
  }
})
export default class ExtrasSncfContent extends Vue {
  @Prop() value!: TSncfAncillaryModel[];
  @Prop() traveller!: Traveller;
  @Prop() segmentId!: string;

  selectedAncillary: TAncillary | null = null;

  get ancillariesOffers() {
    return BasketStore.sncfSeatsAncillaries.ancillariesOffers;
  }

  get ancillaries() {
    const ancillaryOffer = this.ancillariesOffers.find((item) => item.traveller.id === this.traveller.id);
    return ancillaryOffer ? ancillaryOffer.ancillaries : [];
  }

  get passengers() {
    return (BasketStore.basket && BasketStore.basket.travellers) || [];
  }

  get options() {
    return this.ancillaries.filter((item) => item.segments.find((item) => item.segmentId === this.segmentId));
  }

  get selectedItems() {
    const selections = this.value.filter((item) => item.travellerId === this.traveller.id && item.segmentId === this.segmentId);
    return this.options.filter((item) => selections.find(({ ancillaryCode }) => ancillaryCode === item.product.code));
  }

  get isSelectedAncillaryAlreadySelected() {
    return this.selectedItems.find((item) => item.product.code === (this.selectedAncillary && this.selectedAncillary.product.code));
  }

  isForAllPassengers(ancillary: TAncillary) {
    return ancillary.product.forAllPassengers && this.passengers.length > 1;
  }

  async onAddClick(ancillary: TAncillary) {
    if (this.isSelectedAncillaryAlreadySelected) {
      return;
    }
    let value = [{
      ancillaryCode: ancillary.product.code,
      segmentId: this.segmentId,
      travellerId: this.traveller.id,
    }];
    let result = true;
    const isForAllPassengers = this.isForAllPassengers(ancillary);
    if (isForAllPassengers) {
      const alertPopup = this.$refs.alertPopup as AlertPopup;
      result = await alertPopup.open({
        title: translate('basket-seats-extras.alert'),
        content: translate('basket.for-all-passengers'),
      });
      value = this.passengers.map((item) => ({
        ancillaryCode: ancillary.product.code,
        segmentId: this.segmentId,
        travellerId: item.id,
      }));
    }
    if (result) {
      this.selectedAncillary = null;
      this.$emit('input', this.value.concat(value));
    }
  }

  async onSelectedItemRemove(ancillary: TAncillary) {
    let result = true;
    const isForAllPassengers = this.isForAllPassengers(ancillary);
    if (isForAllPassengers) {
      const alertPopup = this.$refs.alertPopup as AlertPopup;
      result = await alertPopup.open({
        title: translate('basket-seats-extras.alert'),
        content: translate('basket.removed-for-all-passengers'),
      });
    }
    if (result) {
      this.$emit('input', this.value.filter((item) => {
        const isCode = item.ancillaryCode === ancillary.product.code;
        const isTraveller = item.travellerId === this.traveller.id;
        const isSegment = item.segmentId === this.segmentId;
        return !(isCode && isTraveller && isSegment);
      }));
    }
  }
}



















import { Vue, Component, Prop } from 'vue-property-decorator';
import { OfferParameter } from '@/api/trip/basket-item.model';

@Component({
  name: 'parameter-remark'
})
export default class ParameterRemark extends Vue {
  @Prop() parameter!: OfferParameter;
}

import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { AgencyModel, CreateAgencyCustomerMessage, AgencyCustomerModel, AvailableAgencies } from './agency.model';

class ProfileAgencyApiClass {

  public getAgencyStructure(rootId: string, limitTo: number, phrase: string, agencyId: string, showNullableAgenciesOnly: boolean): Promise<AxiosResponse<AgencyModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/agencies/{rootId}/structure?limitTo={limitTo}&phrase={phrase}&agencyId={agencyId}&showNullableAgenciesOnly={showNullableAgenciesOnly}',
      method: RequestMethod.GET,
    }, {
      rootId,
      limitTo,
      phrase,
      agencyId,
      showNullableAgenciesOnly
    }, {});
  }

  public getAgencies(phrase: string) {
    return http.execute({
      path: settings.apiProfile + '/api/agencies?size=100&phrase={phrase}',
      method: RequestMethod.GET,
    }, { phrase }, {});
  }

  public createAgencyCustomer(params: CreateAgencyCustomerMessage): Promise<AxiosResponse<AgencyCustomerModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/agencies/customer',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public getAvailableAgencies(companyId): Promise<AxiosResponse<AvailableAgencies[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/agencies/{companyId}/autocomplete',
      method: RequestMethod.GET,
    }, { companyId }, {});
  }
}

export const ProfileAgencyApi: ProfileAgencyApiClass = new ProfileAgencyApiClass();

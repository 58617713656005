

















































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import _ from 'lodash';

import EventBus from '@/services/event-handler';
import AirSearchStore from '@/modules/search/air/air-search.store';
import BasketStore from '@/modules/basket/basket.store';
import SearchStore from '@/modules/search/search.store';
import AirCompareLocalStore from './air-compare.local-store';
import { OffersApi } from '@/api/air-engine/offers.api';
import { OffersModel } from '@/api/air-engine/offers.model';
import { Recommendation } from '@/api/air-engine/air-search.model';
import FlightTimeline from './FlightTimeline.vue';
import FlightTimelineDetails from './FlightTimelineDetails.vue';
import AirFareConditions from './AirFareConditions.vue';
import AirRefundPopup from './AirRefundPopup.vue';
import AirExchangeOfferDetails from './AirExchangeOfferDetails.vue';
import AirResultsFeeEditedOffer from './AirResultsFeeEditedOffer.vue';
import AirResultFlightError from './AirResultFlightError.vue';
import AirPriceDetails from './AirPriceDetails.vue';
import AirSeatMap from './AirSeatMap.vue';
import { translate } from '@/i18n';
import formatPrice from '@/filters/format-price.filter';
import { SupplierName } from '@/services/supplier-name.service';
import SearchConst from '@/const/search.const';


@Component({
  components: {
    FlightTimeline,
    FlightTimelineDetails,
    AirFareConditions,
    AirSeatMap,
    AirRefundPopup,
    AirExchangeOfferDetails,
    AirResultsFeeEditedOffer,
    AirResultFlightError,
    AirPriceDetails,
  },
  filters: {
    momentDate(date) {
      return moment(date).format('ddd, Do MMM');
    },
    dayFrom(date) {
      return moment(date).format('dddd, MMMM D');
    },
    dayToShort(date) {
      return moment(date).format('D');
    },
    dayToLong(date) {
      return moment(date).format('MMMM D');
    },
    momentDateTime(date) {
      return moment(date, 'YYYY-MM-DD HH:mm').format('DD MMM YYYY HH:mm');
    },
  }
})
export default class AirMobileExchangeResultsRow extends Vue {
  @Prop() offer!: Recommendation;
  @Prop() item?: any;
  @Prop({ default: false }) isInBasket!: boolean;
  @Prop({ default: false }) enableFilterByFlightNumber!: boolean;
  @Prop({ default: false }) showingInPopup!: boolean;
  @Prop() searchModeType!: string;
  @Prop({ default: false }) modifyMode!: boolean;
  @Prop({ default: true }) showPrice!: boolean;
  @Prop({ default: true }) loading!: boolean;

  showDetails: boolean = false;
  offerDetails: OffersModel | any = null;
  currentSsid: string = '';
  ndcImagePath: string = '/assets/img/ndc.png';
  showOffer: boolean = false;

  get timelinesCollapsed() {
    return false;
  }

  get basket() {
    return BasketStore.basket;
  }

  get templateOffers(): any[] {
    return AirSearchStore.templateOffers;
  }

  get localStore() {
    return AirCompareLocalStore.localStore;
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get totalFareTax() {
    if (!this.modifyMode) {
      return 0;
    }
    if ((this.offer.proposal as any).taxDifference.amount < 0) {
      return (this.offer.proposal as any).fareDifference.amount;
    }
    return Math.round(
      100 * ((this.offer.proposal as any).fareDifference.amount + 
      (this.offer.proposal as any).taxDifference.amount)
    ) / 100;
  }

  get flagClasses() {
    return {
      'flag__public': 'Public' === this.offer.proposal.fareType,
      'flag__nego': 'Nego' === this.offer.proposal.fareType,
      'flag__private': 'Private' === this.offer.proposal.fareType,
    };
  }

  get isLCC() {
    return this.offer.isLowCost;
  }

  get classesTooltip() {
    switch (this.offer.proposal.fareType) {
      case 'Public':
        return translate('search-air.public-fare');
      case 'Nego':
        return translate('search-air.nego-fare');
      case 'Private':
        return translate('search-air.private-fare');
      default:
        return translate('search-air.public-fare');
    }
  }

  get providerClass() {
    if (this.offer && this.offer.proposal.supplier) {
      return 'flag flag__' + (this.offer.proposal.supplier).toLowerCase();
    }
  }

  get travellers() {
    return BasketStore.basketTravellers;
  }

  get hasEditFeePermission() {
    return this.$hasAccess('CanEditBookingFee');
  }

  get hasAdvancedOfferInfo() {
    return this.$hasAccess('CanReadAdvancedOfferInfo');
  }

  get isPriceDetailedComponentsVisible() {
    return AirSearchStore.priceDetailedComponentsVisible;
  }

  get isNewFlightDisplay() {
    return AirSearchStore.newFlightDisplay;
  }

  get offerExpired() {
    return AirSearchStore.offerExpired;
  }

  get isOfferExpired() {
    return this.offerExpired && this.offerExpired.length && this.offerExpired.find(expire => expire.recommendationId === this.offer.id);
  }

  get airSearchParameters() {
    return SearchStore.getAirDefaultState;
  }

  get isSabreSupplier() {
    return this.offer.proposal.supplier === 'Sabre';
  }

  get isAmadeusSupplier() {
    return this.offer.proposal.supplier === 'Amadeus';
  }



  toggleOffer() {
    this.showOffer = !this.showOffer;
  }

  isNdc(proposal) {
    return -1 < SearchConst.ndcProviders.indexOf(proposal.supplier) || proposal.isNDC;
  }

  fareLabelInfo(name) {
    return translate('search-air.fare-basis-label') + '<br>' + name;
  }

  getSupplierName(name) {
    return SupplierName(name);
  }

  carriers(leg) {
    return leg.flights
      .filter(flight => flight.flightInfo)
      .map(flight => flight.flightInfo.carrier)
      .filter((value, index, self) => {
        return self.findIndex((iv) => iv.name === value.name && iv.code === value.code) === index;
      });
  }

  legsUniqueFareLabels(leg) {
    let fareLabels = leg.flights.filter(entry => {
      return entry.type === 'Flight';
    }).map(flight => {
      return flight.fareInfo ? flight.fareInfo.fareBasisLabel : null;
    }).filter(Boolean);

    return fareLabels.length > 1 ? _.uniq(fareLabels) : fareLabels;
  }

  @Watch('loading')
  onLoading(value) {
    if (!value) {
      this.showDetails = false;
      this.showOffer = true;
    }
  }

  toggleDetails() {
    if (this.isOfferExpired) {
      return;
    }
    this.showDetails = !this.showDetails;
    
    this.reloadOfferDetails();
  }

  reloadOfferDetails() {
    if (this.showDetails) {
      this.loadOfferDetails();
    }
  }

  flightSelected(e) {
    this.$emit('flightSelected', e);
  }

  filterBySsid(templates) {
    return templates.filter((temp) => {
      return temp.ssid === this.currentSsid;
    });
  }

  removeElem(arr, value) {
    return arr.filter((ele) => {
      return ele.proposal.id !== value;
    });
  }

  shouldFadeLeg(legIndex) {
    if (-1 === [
      'airModification',
      'airModificationTravellers'
    ].indexOf(this.$route.name || '')) {
      return false;
    }
    if (legIndex >= AirSearchStore.exchangeLegsSelectedForEdit.length) {
      return false;
    }
    return !AirSearchStore.exchangeLegsSelectedForEdit[legIndex];
  }

  async loadOfferDetails() {

    if (this.offer.proposal.supplier !== 'AirFranceKlm' && this.offer.proposal.supplier !== 'BritishAirways') {
      return;
    }
    if (this.offer.proposal.supplier === 'AirFranceKlm' && this.modifyMode) {
      return;
    }

    try {
      let searchId = this.$route.params.searchId;
      const offerDetailsResponse = await OffersApi.getShortOfferDetails(this.offer.proposal.supplier, this.offer.id, this.offer.providerSearchId, searchId);
      if (offerDetailsResponse.data) {
        this.offerDetails = offerDetailsResponse.data;
      }

      EventBus.$emit('offer-details-reloaded', this.offer.proposal.id);
    } catch (error) {
      this.offerDetails = {
        error: error.response.data,
      };
    }
  }

  legOfferDetails(index) {
    if (this.offerDetails) {
      return this.offerDetails.error ? this.offerDetails : this.offerDetails.legDetails[index];
    }

    return null;
  }

  mounted() {
    this.currentSsid = this.$route.params.searchId;
    if (this.isInBasket && this.offerDetails == null) {
      this.loadOfferDetails();
    }
  }

  formatPrice(price) {
    return formatPrice(price);
  }

  getSystemMarkupPart(proposal) {
    let tooltip = '';
    
    if (proposal.rawProviderPrice) {
      tooltip += `<span>${translate('basket.raw-provider-price')} ${proposal.rawProviderPrice.currency.symbol || proposal.rawProviderPrice.currency.code} ${formatPrice(proposal.rawProviderPrice.amount)}</span>`;
    }
    if (proposal.systemMarkup) {
      tooltip += `<span class="mb-3">${translate('basket.system-markup')} ${proposal.systemMarkup.currency.symbol || proposal.systemMarkup.currency.code} ${formatPrice(proposal.systemMarkup.amount)}</span>`;
    }

    return tooltip;
  }

  getAdvancedOfferInfoPart(proposal) {
    let tooltip = '';

    if ((this.hasAdvancedOfferInfo || this.hasEditFeePermission) && proposal.providerPrice) {
      tooltip += `<span>${translate('basket.incl')} ${proposal.providerPrice.currency.symbol || proposal.providerPrice.currency.code} ${formatPrice(proposal.providerPrice.amount)} ${translate('search-air.provider-price')}`;

      if (proposal.taxPrice !== undefined && proposal.taxPrice != null) {
        tooltip += ` (${proposal.taxPrice.currency.symbol || proposal.taxPrice.currency.code} ${formatPrice(proposal.taxPrice.amount)} ${translate('search-air.taxes')})`;
      }
      tooltip += `</span>`;
    }

    return tooltip;
  }

  getAgencyMarkupAndFeePart(proposal) {
    let tooltip = '';

    if (proposal.agencyMarkup) {
      tooltip += `<span>${translate('basket.incl')} ${proposal.agencyMarkup.currency.symbol || proposal.agencyMarkup.currency.code} ${formatPrice(proposal.agencyMarkup.amount)} ${translate('basket.agency-markup-smallcaps')}</span>`;
    }
    if ((this.isPriceDetailedComponentsVisible || this.hasEditFeePermission || this.hasAdvancedOfferInfo) && proposal.agencyFee !== undefined && proposal.agencyFee !== null) {
      tooltip += `<span>${translate('basket.incl')} ${proposal.agencyFee.currency.symbol || proposal.agencyFee.currency.code} ${formatPrice(proposal.agencyFee.amount)} ${translate('search-air.fee')}</span>`;
    }

    return tooltip;
  }

  tooltip(proposal) {
    return [
      this.getSystemMarkupPart(proposal),
      this.getAdvancedOfferInfoPart(proposal),
      this.getAgencyMarkupAndFeePart(proposal),
    ].join('');
  }

  getLufthansaPart(proposal) {
    let tooltip = ``;
    if (this.hasAdvancedOfferInfo && proposal.providerPrice) {
      tooltip += `<span>${translate('basket.incl')} ${proposal.providerPrice.currency.symbol || proposal.providerPrice.currency.code}  ${formatPrice(proposal.providerPrice.amount)} ${translate('search-air.provider-price')}`;

      if (proposal.lufthansaProposal.taxPrice !== undefined && proposal.lufthansaProposal.taxPrice !== null) {
        tooltip += ` (${proposal.lufthansaProposal.taxPrice.currency.symbol || proposal.lufthansaProposal.taxPrice.currency.code}  ${formatPrice(proposal.lufthansaProposal.taxPrice.amount)} ${translate('search-air.taxes')})`;
      }
      tooltip += `</span>`;
    }

    return tooltip;
  }

  lufthansaModificationTooltip() {
    const proposal = this.offer.proposal;

    return [
      this.getSystemMarkupPart(proposal),
      this.getLufthansaPart(proposal),
      this.getAgencyMarkupAndFeePart(proposal),
    ].join('');
  }

  getPriceChangeText() {
    if (this.offer) {
      return (this.offer.proposal.grandTotalDifference !== undefined && this.offer.proposal.grandTotalDifference.amount < 0) ? translate('search-air.residual') + ':' : translate('search-air.change-cost');
    } else {
      return '';
    }
  }
}






























































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import { sanitizeUrl } from '@braintree/sanitize-url';

import { userFullName } from '@/core/user-full-name';
import formatTime from '@/filters/format-time.filter';
import { translate } from '@/i18n';
import AccountStore from '@/store/account.store';
import { AvailableDateFormats, AvailableTimeFormats } from '@/api/profile/company.model';
import { TrainCancellationsApi } from '@/api/train-engine/train-cancellations.api';
import BasketStore from '@/modules/basket/basket.store';

@Component({
  filters: {
    dateFilter(date) {
      return moment.parseZone(date).format('Do MMMM YYYY ') + formatTime(date) + ' (GMT ' + moment.parseZone(date).format('Z') + ')';
    },
  },
})
export default class TrainSncfCancellationPolicy extends Vue {
  @Prop() tripId!: string;
  @Prop() bookingId!: string;
  @Prop() supplier!: string;
  @Prop() orderId!: string;

  imagesConst = '/assets/img/loader/1.gif';
  errors: any[] = [];
  message: any = {};
  loaded: boolean = false;
  loading: boolean = false;
  cancellatioLoading: boolean = false;
  step: number = 1;
  selectedOption: string = 'total';
  optionsResponse: any = null;
  selectedTickets: string[] = [];
  ticketRecords: any[] = [];
  isPartialRefundAllowed: boolean = false;
  isRefundAllowed: boolean = false;
  cancellationOptions: any = null;

  stepFields = [
    {
      checked: {
        label: ''
      },
      number: {
        label: translate('rail-cancellation.ticket-number')
      },
      departureDate: {
        label: translate('rail-cancellation.departure-date')
      },
      segment: {
        label: translate('rail-cancellation.segment')
      },
      bookingReference: {
        label: translate('rail-cancellation.booking-reference')
      },
    },
    {
      checked: {
        label: ''
      },
      number: {
        label: translate('rail-cancellation.ticket-number')
      },
      departureDate: {
        label: translate('rail-cancellation.departure-date')
      },
      segment: {
        label: translate('rail-cancellation.segment')
      },
      bookingReference: {
        label: translate('rail-cancellation.booking-reference')
      },
      refundEligibility: {
        label: translate('rail-cancellation.refund-eligibility')
      },
    },
    {
      number: {
        label: translate('rail-cancellation.ticket-number')
      },
      departureDate: {
        label: translate('rail-cancellation.departure-date')
      },
      segment: {
        label: translate('rail-cancellation.segment')
      },
      bookingReference: {
        label: translate('rail-cancellation.booking-reference')
      },
      initialPrice: {
        label: translate('rail-cancellation.initial-price')
      },
      penaltyAmount: {
        label: translate('rail-cancellation.penalty-amount')
      },
      refundAmount: {
        label: translate('rail-cancellation.refund-amount')
      },
    }
  ];

  get postSaleTravellers() {
    if (!this.cancellationOptions) {
      return [];
    }

    return this.cancellationOptions.travellers || [];
  }

  get travellers() {
    if (!this.cancellationOptions) {
      return [];
    }

    return BasketStore.basketTravellers.filter(traveller => {
      return !!this.cancellationOptions.travellers.find(item => item.id === traveller.id);
    }) || [];
  }

  get tickets() {
    if (this.step === 3 && this.message && this.message.cancellationOptionsPerTicket) {
      return this.message.cancellationOptionsPerTicket.sort(this.compareDates);
    }
    return this.ticketRecords.map(item => {
      return {
        ...item,
        checked: this.selectedTickets.find(element => element === item.number),
        disabled: !item.isRefundable,
        travellerProfileIds: [item.travellerProfileId]
      };
    }).sort(this.compareDates);
  }

  get isPartialEnabled() {
    return this.isPartialRefundAllowed;
  }

  get isTotalEnabled() {
    return this.isRefundAllowed;
  }

  get continueVisible() {
    return this.step < 3 && this.isRefundAllowed;
  }

  get isContinueDisabled() {
    if (this.step === 1 && !this.isTotalEnabled && !this.isPartialEnabled) {
      return true;
    }
    if (this.step === 2) {
      return !this.selectedTickets.length;
    }

    return false;
  }

  get fields() {
    return this.stepFields[this.step - 1];
  }

  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }

  get currentTimeFormat() {
    return AccountStore.current!.profile.timeFormat || AvailableTimeFormats.AvailableTimeFormat1;
  }

  userFullName(user) {
    return userFullName(user);
  }

  ticketsForTraveller(traveller) {
    return this.tickets.filter(ticket => Array.isArray(ticket.travellerProfileIds) ? ticket.travellerProfileIds.length > 0 && ticket.travellerProfileIds[0] === traveller.id : false);
  }

  compareDates(ticket1, ticket2) {
    const d1 = new Date(ticket1.segments[0].departureDate);
    const d2 = new Date(ticket2.segments[0].departureDate);

    return d1.getTime() - d2.getTime();
  }

  formatDateNow(date) {
    return [
      moment(date).format(this.currentDateFormat),
      moment(date).format(this.currentTimeFormat)
    ].join(' ');
  }

  sanitizeUrl(url) {
    return sanitizeUrl(url);
  }

  async back() {
    this.errors = [];
    if (this.step === 1) {
      this.$emit('close');
    } else if (this.step === 3) {
      try {
        await TrainCancellationsApi.abortTrainCancellations(this.message.cancellationId);
      } catch (error) {

      }
      this.$emit('close');
    } else {
      this.step--;
    }
  }

  selectTicket(item) {
    const index = this.selectedTickets.indexOf(item.number);
    if (index === -1) {
      this.selectedTickets.push(item.number);
    } else {
      this.selectedTickets.splice(index, 1);
    }
  }

  async confirm() {
    this.cancellatioLoading = true;
    try {
      const response = await TrainCancellationsApi.confirmTrainCancellations(this.message.cancellationId);
      this.$emit('confirm');
      if (response && response.status === 204) {
        this.cancellatioLoading = false;
      }
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    }
  }

  async loadData(ticketsToCancel) {
    this.loading = true;
    this.loaded = false;
    this.message = '';
    let params = {
      tripId: this.tripId,
      bookingId: this.bookingId,
      orderId: this.orderId,
      railSupplier: this.supplier,
      ticketsToCancel,
    };

    try {
      const response = await TrainCancellationsApi.initTrainCancellations(params);
      this.message = response.data;
      this.step = 3;
      this.loaded = true;
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.loaded = true;
      this.errors = this.$handleErrors(error, true);
    }
  }

  async loadOptions() {
    this.loading = true;
    this.errors = [];

    try {
      const response = await TrainCancellationsApi.getCancellationOptions(this.orderId, this.supplier);
      this.isPartialRefundAllowed = response.data.isPartialRefundAllowed;
      this.isRefundAllowed = response.data.isRefundAllowed;
      this.ticketRecords = response.data.tickets;
      this.cancellationOptions = response.data;

      if (this.isPartialEnabled && !this.isTotalEnabled) {
        this.selectedOption = 'partial';
      }
      this.loaded = true;
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.errors = this.$handleErrors(error, true);
    }
  }

  async continueNow() {
    this.errors = [];
    if (this.step === 2) {
      await this.loadData(this.selectedTickets);
    } else if (this.selectedOption === 'total') {
      await this.loadData([]);
    } else {
      this.selectedTickets = [];
      this.step = 2;
    }
  }

  mounted() {
    this.loadOptions();
  }
}



























































































































































































































































































































































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import moment from 'moment';

import { translate } from '@/i18n';
import { userFullName } from '@/core/user-full-name';
import AccountStore from '@/store/account.store';
import { AvailableDateFormats, AvailableTimeFormats } from '@/api/profile/company.model';
import { BasketFlatItemModel } from '@/api/trip/basket.model';
import { TrainExchangeApi } from '@/api/train-engine/train-exchange.api';
import {
  SncfPostSaleOptionsResponse,
} from '@/api/train-engine/train-exchange.model';
import RailSncfLegSelectionRow from './RailSncfLegSelectionRow.vue';
import TrainSearchStore from '@/modules/search/train/train-search.store';

@Component({
  components: {
    RailSncfLegSelectionRow,
  },
})
export default class RailSncfExchangeModifyForm extends Vue {
  @Prop() item!: BasketFlatItemModel;
  @Prop() basketId!: string;

  loading: boolean = true;
  loaded: boolean = false;
  errors: any[] = [];
  searchOptions: any | null = null;
  searchData: any = null;
  exchangeLocations: any[] = [];
  exchangeFromLocation: any[] = [];
  exchangeToLocation: any[] = [];
  sendingRequest: boolean = false;
  step: number = 1;
  selectedServiceItem: string = '';
  selectedJourneyNumber: number = 0;
  selectedOption: string = '';
  selectedSegments: string[] = [];
  postSaleData: SncfPostSaleOptionsResponse | null = null;
  step1Fields = {
    radio: {
      label: '',
      tdClass: 'mobile-radio-td',
    },
    departureDate: {
      label: translate('rail-exchange.departure-date'),
      tdClass: 'exchange-td',
    },
    segment: {
      label: translate('rail-exchange.segment'),
      tdClass: 'exchange-td',
    },
    bookingReference: {
      label: translate('rail-exchange.booking-reference'),
      tdClass: 'exchange-td',
    },
    exchangeEligibility: {
      label: translate('rail-exchange.exchange-eligibility'),
      tdClass: 'exchange-td',
    },
  };
  step2Fields = {
    checked: {
      label: '',
      tdClass: 'mobile-radio-td',
    },
    departureDate: {
      label: translate('rail-exchange.departure-date'),
      tdClass: 'exchange-td',
    },
    segment: {
      label: translate('rail-exchange.segment'),
      tdClass: 'exchange-td',
    },
    bookingReference: {
      label: translate('rail-exchange.booking-reference'),
      tdClass: 'exchange-td',
    },
    exchangeEligibility: {
      label: translate('rail-exchange.exchange-eligibility'),
      tdClass: 'exchange-td',
    },
  };

  get serviceItems() {
    if (!this.postSaleData) {
      return [];
    }
    return this.postSaleData.serviceItems
      .map(serviceItem => {
        return {
          ...serviceItem,
          journeys: serviceItem.journeys.map(journey => {
            return {
              ...journey,
              segments: journey.segments.sort((a, b) => {
                return new Date(a.departureDate).getTime() - new Date(b.departureDate).getTime();
              }),
            };
          }),
        };
      });
  }

  get selectedService() {
    if (!this.postSaleData) {
      return null;
    }
    return this.serviceItems.find(service => service.serviceItemId === this.selectedServiceItem) || null;
  }

  get travellers() {
    if (!this.postSaleData) {
      return [];
    }

    return this.postSaleData.travellers || [];
  }

  get selectedJourney() {
    if (!this.selectedService) {
      return null;
    }

    return this.selectedService.journeys.find(journey => journey.legNumber === this.selectedJourneyNumber);
  }

  get selectedServiceJourneys() {
    if (!this.selectedService) {
      return [];
    }
    return this.selectedService.journeys.map(journey => {
      return {
        ...journey,
        segments: journey.segments
          .map(segment => {
            return {
              ...segment,
              checked: this.isSegmentSelected(segment.segmentId),
            };
          }),
      };
    });
  }

  get isTotalExchangeAvailable() {
    if (!this.selectedService) {
      return false;
    }

    return this.selectedService.totalExchangeAvailable;
  }

  get isPartialExchangeAvailable() {
    if (!this.selectedService) {
      return false;
    }

    return this.selectedService.partialExchangeAvailable;
  }

  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }

  get currentTimeFormat() {
    return AccountStore.current!.profile.timeFormat || AvailableTimeFormats.AvailableTimeFormat1;
  }

  get noLegSelected() {
    if (!this.searchOptions) {
      return true;
    }
    return !this.searchData.legs.some(leg => {
      return leg.isForExchange;
    });
  }

  get isEligible() {
    if (!this.postSaleData) {
      return false;
    }

    return !!this.postSaleData.serviceItems.find(serviceItem => {
      return serviceItem.totalExchangeAvailable || serviceItem.partialExchangeAvailable;
    });
  }



  userFullName(user) {
    return userFullName(user);
  }

  compareDates(ticket1, ticket2) {
    const d1 = new Date(ticket1.segments[0].departureDate);
    const d2 = new Date(ticket2.segments[0].departureDate);

    return d1.getTime() - d2.getTime();
  }

  formatDateNow(date) {
    return [
      moment(date).format(this.currentDateFormat),
      moment(date).format(this.currentTimeFormat)
    ].join(' ');
  }

  async loadData() {
    this.loading = true;
    this.loaded = false;
    this.errors = [];
    this.searchOptions = null;
    try {
      const response = await TrainExchangeApi.postSalesOptions(this.item.providerReferenceId || '', {
        railTripItemId: this.item.id,
        supplier: this.item.supplier || '',
        travellers: this.travellers,
      });

      this.postSaleData = response.data as SncfPostSaleOptionsResponse;

      const firstEligible = this.serviceItems.find(service => service.partialExchangeAvailable || service.totalExchangeAvailable);
      if (firstEligible) {
        this.selectedServiceItem = firstEligible.serviceItemId;

        if (this.isTotalExchangeAvailable) {
          this.selectedOption = 'total';
        } else {
          this.selectedOption = 'partial';
        }
      }

    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
      this.loaded = true;
    }
  }

  @Watch('selectedServiceItem', { immediate: true })
  onServiceItemChange() {
    if (!this.selectedService) {
      return;
    }

    const firstEnabled = this.selectedService.journeys.find(j => !!j.segments.find(s => s.exchangeAvailable));

    if (firstEnabled) {
      this.selectedJourneyNumber = 0;
      this.$nextTick(() => {
        this.selectedJourneyNumber = firstEnabled.legNumber;
      });
    }
  }

  @Watch('selectedJourneyNumber', { immediate: true })
  onJourneyChange() {
    if (!this.selectedJourney) {
      this.selectedSegments = [];
      return;
    }
    this.selectedSegments = [
      ...this.selectedJourney.segments
        .filter(segment => segment.exchangeAvailable)
        .map(segment => segment.segmentId)
    ];
  }

  async continueNow() {
    if (this.step === 1 && !this.isEligible) {
      return;
    }
    if (this.step === 1 && this.selectedOption === 'partial') {
      this.step = 2;
    } else {
      let request: any = {
        serviceItemId: this.selectedServiceItem,
      };
      if (this.selectedOption === 'partial') {
        request = {
          ...request,
          legNumber: this.selectedJourney!.legNumber,
          segmentsIds: this.selectedSegments,
        };
      }

      this.loading = true;
      this.loaded = false;

      try {
        const response = await TrainExchangeApi.exchangeAvailabilities(this.postSaleData!.id, this.item.supplier, request);
        this.searchOptions = response.data;
        this.searchData = {
          parentOrderId: this.searchOptions.parentOrderId,
          railTripItemId: this.searchOptions.railTripItemId,
          travellers: this.searchOptions.travellers,
          supplier: this.searchOptions.supplier,
          legs: this.searchOptions.legs!
            .sort((a, b) => a.legNumber - b.legNumber)
            .map(leg => {
              return {
                legNumber: leg.legNumber,
                isExchangable: true,
                isForExchange: true,
                from: leg.from,
                to: leg.to,
                date: leg.departureDateDisplay as string,
                departureTimeSpan: {
                  from: leg.departureTimeSpan ? leg.departureTimeSpan.from : 840,
                  to: leg.departureTimeSpan ? leg.departureTimeSpan.to : 960,
                },
              };
            }),
        };
        this.loaded = true;
        this.step = 3;
      } catch (error) {
        this.errors = this.$handleErrors(error, true);
      } finally {
        this.loading = false;
      }
    }
  }

  goBack() {
    if (this.step > 1) {
      if (2 === this.step) {
        this.selectedJourneyNumber = 0;
      }
      if (this.step === 3 && this.selectedOption === 'total') {
        this.step = 1;
        return;
      }
      this.step--;

    } else {
      this.hideForm();
    }
  }

  selectSegmentId(id, journey) {
    const index = this.selectedSegments.findIndex(item => item === id);
    let idx = journey.segments.findIndex(segment => segment.segmentId === id);

    if (-1 === index) {
      this.selectedSegments.push(id);

      idx++;
      while (this.selectedSegments.findIndex(item => item === journey.segments[idx].segmentId) === -1) {
        if (journey.segments[idx].exchangeAvailable) {
          this.selectedSegments.push(journey.segments[idx].segmentId);
        }
        idx++;
      }
    } else {
      this.selectedSegments.splice(index, 1);
      idx--;
      while (idx >= 0 && this.selectedSegments.findIndex(item => item === journey.segments[idx].segmentId) > -1) {
        const fndIndex = this.selectedSegments.findIndex(item => item === journey.segments[idx].segmentId);
        if (fndIndex > -1) {
          this.selectedSegments.splice(fndIndex, 1);
        }
        idx--;
      }
    }
  }

  isSegmentSelected(id) {
    return -1 < this.selectedSegments.findIndex(item => item === id);
  }

  focusOnNextLeg(leg, index) {
    const nextIndex = index + 1;
    if (nextIndex >= this.searchData.legs.length) {
      ((this.$refs.checkAvailabilityButton as Vue).$el as HTMLElement).focus();
      return;
    }

    ((this.$refs['leg' + nextIndex] as any[])[0] as RailSncfLegSelectionRow).focusOnFirstElement();
  }

  onLegDeselect(leg) {
    if (!this.searchOptions) {
      return;
    }
    const originalLeg = this.searchOptions.legs.find(l => l.legNumber === leg.legNumber);
    if (!originalLeg) {
      return;
    }

    const dataLeg = this.searchData.legs.find(l => l.legNumber === leg.legNumber);
    if (!dataLeg) {
      return;
    }
    dataLeg.from = originalLeg.from;
    dataLeg.to = originalLeg.to;
    dataLeg.date = originalLeg.departureDateDisplay;

    this.checkForWrongDates();
  }

  checkForWrongDates() {
    this.searchData.legs.forEach((leg, index) => {
      if (
        leg.date > this.legMaxDate(leg, index) ||
        leg.date < this.legMinDate(leg, index)
      ) {
        leg.date = null;
      }
    });
  }

  isLegDisabledForChosenOption(leg, index) {
    if (!this.searchOptions || this.sendingRequest) {
      return true;
    }

    return false;
  }

  legMinDate(leg, index) {
    if (index === 0 || !leg.isForExchange) {
      return new Date();
    }

    if (!this.searchData.legs[index - 1].isForExchange && this.searchOptions) {
      return this.searchOptions.legs[index - 1].date;
    }

    return index > 0 ? this.searchData.legs[index - 1].date : new Date();
  }

  legMaxDate(leg, index) {
    if (!leg.isForExchange) {
      return null;
    }

    if (
      this.searchOptions &&
      index + 1 < this.searchData.legs.length &&
      !this.searchData.legs[index + 1].isForExchange
    ) {
      return this.searchOptions.legs[index + 1].date;
    }

    return index < this.searchData.legs.length - 1 ? this.searchData.legs[index + 1].date : null;
  }

  @Emit('close')
  hideForm() {
  }

  async submitForm() {
    if (!this.searchOptions) {
      return;
    }
    this.errors = [];

    // validate
    const wrongLeg = this.searchData.legs.find(leg => !leg.date);
    if (wrongLeg) {
      this.errors = [{
        message: this.$t('exchange.date-empty'),
      }];
      return;
    }
    this.sendingRequest = true;

    try {
      const response = await TrainExchangeApi.initExchange(this.basketId, {
        ...this.searchData,
        exchangeOptionsId: this.postSaleData!.id,
      });

      const firstLeg = this.searchData.legs.find(leg => {
        return leg.isForExchange;
      });

      TrainSearchStore.setExchangeLoaded(false);
      TrainSearchStore.setProvidersErrors([]);

      this.$router.push({
        name: 'trainModification',
        params: {
          searchId: response.data.searchId,
          basketId: response.data.basketId,
          leg: firstLeg.legNumber,
        },
      });
      this.hideForm();
    } catch (error) {
      this.sendingRequest = false;
      this.errors = this.$handleErrors(error, true);
    }
  }

  tooltipContent(value) {
    return [
      '<div class="rail-exchange-edit-popup__tooltip-wrapper">',
      value,
      '</div>',
    ].join('');
  }

  mounted() {
    this.loadData();
  }
}


import { TTravellersResponse } from '@/api/trip/basket.model';
import { Vue } from 'vue-property-decorator';
import { i18n } from '@/i18n';
import BasketTravellerValidationPopup from '@/modules/basket/BasketTravellerValidationPopup.vue';

export function BasketTravellerValidationPopupOpen(details: TTravellersResponse) {
  return new Promise((resolve) => {
    const Wrapper = Vue.extend(BasketTravellerValidationPopup);
    const dialog = new Wrapper({ i18n }).$mount();
    document.body.appendChild(dialog.$el);
    (dialog as BasketTravellerValidationPopup).open(details);
    dialog.$on('close', (value) => {
      dialog.$destroy();
      dialog.$el.remove();
      resolve(value);
    });
  });
}


























































































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { VclCode } from 'vue-content-loading';
import { router } from '@/router';
import settings from '@/settings';
import { BasketApi } from '@/api/trip/basket.api';
import { AirApi } from '@/api/air-engine/air-search.api';
import accountStore from '@/store/account.store';
import SearchStore from '@/modules/search/search.store';
import BasketStore from '@/modules/basket/basket.store';
import LayoutStore from '@/modules/layout/layout.store';
import AirCompareLocalStore from './air-compare.local-store';
import { Traveller } from '@/api/air-engine/air-search.model';
import EventBus from '@/services/event-handler';
import AirSearchStore from './air-search.store';
import AirTemplateOffers from './AirTemplateOffers.vue';
import AirResultsRow from './AirResultsRow.vue';
import AirResultsRowView from './AirResultsRowView.vue';
import SkyScannerWidget from './SkyScannerWidget.vue';
import BasketAirOffer from '@/modules/basket/BasketAirOffer.vue';
// COMMENTED OUT: https://wondermiles.atlassian.net/browse/WMI-15729?focusedCommentId=59022
// import AirExchangeOfferDetails from './AirExchangeOfferDetails.vue';
// import AirLufthansaExchangeOfferDetails from './AirLufthansaExchangeOfferDetails.vue';
// import AirAirFranceExchangeOfferDetails from './AirAirFranceExchangeOfferDetails.vue';
import AirAirFranceExchangeOfferExtrasTypeSelector from './AirAirFranceExchangeOfferExtrasTypeSelector.vue';
import AirAirFranceExchangeOfferAncillaries from './AirAirFranceExchangeOfferAncillaries.vue';
import AirAirFranceExchangeOfferSeats from './AirAirFranceExchangeOfferSeats.vue';
import { TripApi } from '@/api/trip/trip.api';
import ExchangePaymentMethods from './ExchangePaymentMethods.vue';
import { OffersApi } from '@/api/air-engine/offers.api';
import AirMissedSavingsPopup from './AirMissedSavingsPopup.vue';
import { AirFranceExtrasType, AirFranceExchangeSteps } from '@/modules/search/air/air-airfrance-exchange.model';
import BasketApproval from '@/modules/basket/BasketApproval.vue';
import AirMobileExchangeResultsRow from './AirMobileExchangeResultsRow.vue';

const sortTextsUppercased = (a, b) => {
  const A = a.toLowerCase();
  const B = b.toLowerCase();
  if (A < B) {
    return -1;
  }
  if (A > B) {
    return 1;
  }

  return 0;
};

@Component({
  components: {
    AirResultsRow,
    AirResultsRowView,
    AirMobileExchangeResultsRow,
    VclCode,
    AirTemplateOffers,
    SkyScannerWidget,
    // COMMENTED OUT: https://wondermiles.atlassian.net/browse/WMI-15729?focusedCommentId=59022
    // AirExchangeOfferDetails,
    // AirLufthansaExchangeOfferDetails,
    // AirAirFranceExchangeOfferDetails,
    ExchangePaymentMethods,
    AirAirFranceExchangeOfferAncillaries,
    AirAirFranceExchangeOfferSeats,
    AirAirFranceExchangeOfferExtrasTypeSelector,
    BasketAirOffer,
    AirMissedSavingsPopup,
    BasketApproval,
  }
})
export default class AirResults extends Vue {
  searchId: string | null = null;
  selectedOffer: { offer: any; proposal: any } | null = null;
  loadingOffers: boolean = true;
  loadingFilters: boolean = true;
  exchangePaymentMethods: any = null;
  forcing: boolean = false;
  enableFilterByFlightNumber: boolean = true;
  sortOptions = {
    BEST_FARE_DEPARTURE_TIME: 'Ascending'
  };
  showTemplateOffersModal: boolean = false;
  skyParams: boolean = false;
  showHopperModal: boolean = false;
  openModifyPopup: boolean = false;
  modifyLoading: boolean = false;
  serverErrors: any[] = [];
  exchangeErrors: any[] = [];
  exchangePaymentInfo: any = null;
  airExchangedOffer: any = null;
  airFranceExchangeWithExtras: any = {
    currentStep: null,
    extrasType: null,
    seats: null,
    ancillaries: null
  };
  checkExchangeConsent: boolean = false;
  clickedFirstTime: boolean = false;
  travelPolicyResult: any = null;
  approvalResult: any = null;
  displayApproverEmpty: boolean = false;


  get proposal() {
    return this.selectedOffer && this.selectedOffer.offer && this.selectedOffer.offer.proposal;
  }

  get tpCompliantUnchanged() {
    if (!this.travelPolicyResult) {
      return false;
    }

    return !this.travelPolicyResult.travelPolicyResultChange.isChanged &&
      this.travelPolicyResult.travelPolicyResultChange.currentTravelPolicyResult === 'Compliant';
  }

  get searchMode() {
    if (!this.airSearchParameters) {
      return 'OneWay';
    }
    return this.airSearchParameters.searchMode;
  }

  get tpNonCompliantUnchanged() {
    if (!this.travelPolicyResult) {
      return false;
    }

    return !this.travelPolicyResult.travelPolicyResultChange.isChanged &&
      this.travelPolicyResult.travelPolicyResultChange.currentTravelPolicyResult === 'NonCompliant';
  }

  get tpTurnedIntoNotCompliant() {
    if (!this.travelPolicyResult) {
      return true;
    }

    return this.travelPolicyResult.travelPolicyResultChange.isChanged &&
      this.travelPolicyResult.travelPolicyResultChange.currentTravelPolicyResult === 'NonCompliant';
  }

  get tpIsBlocked() {
    if (!this.travelPolicyResult) {
      return false;
    }
    return this.travelPolicyResult.isNotBlockedByTravelPolicyChanges === false;
  }

  get isPostSales() {
    return this.$route.name === 'airModification';
  }

  get isPostSalesApprovalVisible() {
    return this.isPostSales &&
      this.approvalResult &&
      this.approvalResult.isApprovalEnabled &&
      this.approvalResult.isApprovalPossible;
  }

  get hasMissingSelectedApprover() {
    return BasketStore.hasMissingSelectedApprover;
  }

  get recommendationsAirCount() {
    return AirSearchStore.recommendationsAirCount;
  }

  get visibleOffers() {
    return this.offers.slice(0, this.offersVisible);
  }

  get exchangedOfferItem() {
    return AirSearchStore.exchangedOfferItem;
  }

  // COMMENTED OUT: https://wondermiles.atlassian.net/browse/WMI-15729?focusedCommentId=59022
  // get currentTravellers() {
  //   return AirSearchStore.getExchangeTravellers;
  // }

  get basketId() {
    return AirSearchStore.basketId;
  }

  get prevSelectedOffer() {
    return AirSearchStore.prevSelectedOffer;
  }

  get showModal() {
    return AirSearchStore.showModal;
  }

  get showErrorBE() {
    return AirSearchStore.showErrorBE;
  }

  get errorMessageBe() {
    return AirSearchStore.errorMessageBe;
  }

  get showError() {
    return AirSearchStore.showError;
  }

  get errMessages() {
    return AirSearchStore.errMessages;
  }

  get canShowExternalSkyscanner() {
    return 'true' === settings.enableExternalSkyscanner;
  }

  get canShowExternalHopper() {
    return 'true' === settings.enableExternalHopper;
  }

  get canShowWidget() {
    return this.skyParams && this.skyScannerData && this.canShowExternalSkyscanner;
  }

  get loading() {
    return AirSearchStore.loading;
  }

  get templateOffers() {
    return AirSearchStore.templateOffers;
  }

  get airSearchParameters() {
    return SearchStore.airSearchCurrentState;
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get currentUser() {
    return accountStore.CurrentUser;
  }

  get offers() {
    return AirSearchStore.offers;
  }

  get filters() {
    return AirSearchStore.filters;
  }

  get showCustomExchangeLufthansaPopup() {
    return AirSearchStore.showCustomExchangeLufthansaPopup;
  }

  get customExchangeLufthansaError() {
    return AirSearchStore.customExchangeLufthansaError;
  }

  get customExchangeLufthansaErrorServiceTag() {
    const error = this.customExchangeLufthansaError;

    if (error && error.error && error.error.serviceTag) {
      return error.error.serviceTag;
    }

    return '';
  }

  get offersVisible() {
    return AirSearchStore.offersVisible;
  }

  get renderShowMoreOffers() {
    return this.offers && this.offers.length > this.offersVisible;
  }

  get skyScannerData() {
    return this.airSearchParameters && this.airSearchParameters.from ? {
      fromCity: this.airSearchParameters.from.cityName,
      targetCity: this.airSearchParameters.to!.cityName,
      startDate: this.airSearchParameters.departureDate,
      endDate: this.airSearchParameters.returnDate,
      isOneWay: this.searchMode === 'OneWay'
    } : null;
  }

  get popupButtonsClasses() {
    return {
      'disabled': this.forcing,
    };
  }

  get popupForceButtonClasses() {
    return {
      'disabled': this.forcing,
      'disabled-active': this.forcing,
    };
  }

  get getExchangedOffer() {
    return AirSearchStore.getExchangedOffer;
  }

  get basketItemId() {
    return BasketStore.basketItemId;
  }

  get currentBasketId() {
    return BasketStore.basketId;
  }

  get filterLoadingView() {
    return AirSearchStore.filterLoadingView;
  }

  showHopperPopup() {
    this.showHopperModal = true;
  }

  get hopperUrl() {
    if (
      this.airSearchParameters &&
      this.airSearchParameters.from &&
      this.airSearchParameters.to
    ) {
      let from = this.airSearchParameters.from.cityCode;
      let to = this.airSearchParameters.to.cityCode;
      return `https://www.hopper.com/deals/best/from/${from}/to/${to}`;
    }
  }

  get searchErrors() {
    return AirSearchStore.serverErrors;
  }

  get hasExchangeSearchErrors() {
    return !!AirSearchStore.exchangeErrors.length && this.searchModifyState;
  }

  get exchangeSearchErrors() {
    return AirSearchStore.exchangeErrors;
  }

  get searchModifyState() {
    return (-1 < [
      'airModification',
      'airModificationTravellers'
    ].indexOf(this.$route.name || '')) || AirSearchStore.modifyMode;
  }

  get retrievesExchangeOffer() {
    return AirSearchStore.retrievesExchangeOffer;
  }

  get fareUnavailableCode() {
    return AirSearchStore.fareUnavailableErrorParams;
  }

  get isNewFlightDisplay() {
    return AirSearchStore.newFlightDisplay;
  }

  get selectedOfferSupplier() {
    if (!this.selectedOffer) {
      return null;
    }

    if (this.isNewFlightDisplay) {
      return this.selectedOffer.proposal.supplier;
    } else {
      return this.selectedOffer.offer.proposal.supplier;
    }

  }

  get offersProposalsList() {
    return AirSearchStore.offersProposalsList;
  }

  get basketItems() {
    return BasketStore.basketItems;
  }

  get airExchangedOfferItem() {
    return this.basketItems.find(basketItem => {
      return basketItem.id === this.basketItemId;
    });
  }

  get localTemplateOffersStore() {
    return AirCompareLocalStore.localStore;
  }

  get exchangedOfferCabinClasses() {
    if (!this.getExchangedOffer) {
      return [];
    }

    return this.getExchangedOffer.legFlights
      .reduce((cabinClasses, legFlight) => {
        return [
          ...cabinClasses,
          ...legFlight.flights
            .filter(item => {
              return !!(item.fareInfo && item.fareInfo.cabinClass);
            })
            .reduce((p, c) => {
              return [...p, c.fareInfo.cabinClass];
            }, []),
        ];
      }, []);
  }

  get uniqueSortedExchangedOfferCabinClasses() {
    return this.exchangedOfferCabinClasses
      .filter((item, idx, array) => {
        const index = array.indexOf(item);
        const lastIndex = array.lastIndexOf(item);
        return idx === index || index === lastIndex;
      })
      .sort(sortTextsUppercased);
  }

  get selectedOfferCabinClasses() {
    if (!this.selectedOffer || !this.selectedOffer.offer) {
      return [];
    }

    return this.selectedOffer.offer.legFlights
      .reduce((cabinClasses, legFlight) => {
        return [
          ...cabinClasses,
          ...legFlight.flights
            .filter(item => {
              return !!(item.fareInfo && (item.fareInfo.cabinClass || item.fareInfo.cabinClasses));
            })
            .reduce((p, c) => {
              return [...p, c.fareInfo.cabinClass || c.fareInfo.cabinClasses];
            }, []),
        ];
      }, []);
  }

  get uniqueSortedSelectedOfferCabinClasses() {
    return this.selectedOfferCabinClasses
      .filter((item, idx, array) => {
        const index = array.indexOf(item);
        const lastIndex = array.lastIndexOf(item);
        return idx === index || index === lastIndex;
      })
      .sort(sortTextsUppercased);
  }

  get cabinClassesHaveChanged() {
    if (this.exchangedOfferCabinClasses.length !== this.selectedOfferCabinClasses.length) {
      return !!this.uniqueSortedExchangedOfferCabinClasses.find((item, index) => {
        return item.toLowerCase() !== this.uniqueSortedSelectedOfferCabinClasses[index].toLowerCase();
      });
    }

    return !!this.exchangedOfferCabinClasses.find((item, index) => {
      return item.toLowerCase() !== this.selectedOfferCabinClasses[index].toLowerCase();
    });
  }

  get isExchangePaymentMethods() {
    const isHeldStatus = this.airExchangedOfferItem && this.airExchangedOfferItem.status === 'Held';
    const isProperExchangeOffer = this.selectedOffer && -1 < [
      'Lufthansa',
      'AirFranceKlm',
      'AmericanAirlines',
      'BritishAirways',
      'Amadeus',
      'Emirates'
    ].indexOf(this.selectedOfferSupplier);
    return !isHeldStatus && isProperExchangeOffer;
  }

  reloadOfferDetails() {
    if (this.$refs && this.$refs.airResultRows && !this.isNewFlightDisplay) {
      (this.$refs.airResultRows as AirResultsRow[]).forEach(element => {
        element.reloadOfferDetails();
      });
    }
  }

  showMoreOffer() {
    let curr = this.offersVisible;
    curr += 10;
    AirSearchStore.setOffersVisible(curr);
    this.$nextTick(this.refreshFlightNumberHashFilter);
  }

  async initView() {
    AirSearchStore.loader(true);
    this.skyParams = false;
    this.searchId = this.$route.params.searchId;
    AirSearchStore.clear();
    if (this.searchModifyState && this.searchId !== '-') {
      AirSearchStore.loader(true);
      await this.getExchangeSearchSession(this.searchId);
      this.getExchangeOffers(this.searchId);
    } else {
      if (this.searchId && this.searchId !== '-') {
        this.checkTemplate();
        if (this.$route.params.searchId !== this.searchId) {
          return;
        }
        AirSearchStore.loader(true);
        await AirSearchStore.getSearchSession(this.searchId);
        if (this.$route.params.searchId !== this.searchId) {
          return;
        }
        AirSearchStore.getOffers(this.searchId);
        this.skyParams = true;
      }
    }
  }

  async getExchangeSearchSession(searchId) {
    const basketId = this.$route.params.basketId;
    AirSearchStore.updateModificationBasketId(basketId);

    try {
      const response = await AirApi.getExchangeAirSearchSession({ searchId });

      let travellers = {
        travellers: response.data.request.travellers
      };

      SearchStore.updateSearchModeStatus(response.data.request.searchMode);
      SearchStore.updateAirCurrentState(response.data.request);
      SearchStore.updateTravellersDefaultState(travellers);
      AirSearchStore.updateSearchStateId(response.data.metadata.stateId);
      AirSearchStore.setPriceDetailedComponentsVisible(response.data.metadata.hasDetailedPriceComponentAccess);

      if (basketId !== response.data.metadata.basketId) {
        this.$router.push({
          name: 'airModification',
          params: {
            searchId,
            basketId: response.data.metadata.basketId,
          }
        });
        return;
      }

      const isCurrentBasketLoaded = BasketStore.basketId === basketId;

      BasketStore.setBasketId(response.data.metadata.basketId);

      if (!isCurrentBasketLoaded) {
        const itemsResponse = await BasketApi.getTripItems(basketId);
        let item;
        if (itemsResponse && itemsResponse.data) {
          BasketStore.setBasketItems(itemsResponse.data);
          BasketStore.setBasketItemId(itemsResponse.data.find(l => l.providerReferenceId === response.data.metadata.parentRecommendationId)!.id);
          item = itemsResponse.data.find(l => l.providerReferenceId === response.data.metadata.parentRecommendationId);
          AirSearchStore.setModificationBasketItemStatus(item.status);
         
        }
        const responseDetails = await OffersApi.getOffer(response.data.metadata.parentRecommendationId);
        
        const basketItem = {
          ...responseDetails.data,
          tripItemId: itemsResponse.data.find(l => l.providerReferenceId === response.data.metadata.parentRecommendationId)!.id,
          legFlights: responseDetails.data.legFlights.map(leg => {
                return {
                  ...leg,
                  selectedForEdit: !!response.data.request.flightLegs.find(l => l.isForExchange && l.flightLegNumber === leg.legNumber),
                };
          }),
          proposal: {
                ...responseDetails.data,
                pricePlusMarkup: responseDetails.data.price.totalPrice,
                price: responseDetails.data.price.totalPrice,
                taxPrice: responseDetails.data.price.taxPrice,
                supplier: item.supplier,
                isPreferredAirline: item.isPreferredAirline,
          },
          travelPolicy: item.travelPolicy,
        };

        const flightLegs = response.data.request.flightLegs;
        const exchangeDateRange = flightLegs.map(flightItem => {
          return flightItem.date;
        }).filter(date => date !== null);
        const exchangeLocations = {
          from: flightLegs.map(flightLeg => flightLeg.from).filter(from => from != null),
          to: flightLegs.map(flightLeg => flightLeg.to).filter(to => to != null),
        };

        AirSearchStore.setExchangeLocations(exchangeLocations);
        AirSearchStore.setExchangeDate(exchangeDateRange);
        AirSearchStore.setExchangeTravellers(response.data.request.travellers as Traveller[]);
        AirSearchStore.setExchangedOffer(basketItem);
        AirSearchStore.setExchangedOfferItem(basketItem);
        AirSearchStore.setModifyState(true);
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  getExchangeOffers(searchId) {
    return AirSearchStore.getExchangeOffers(searchId);
  }

  showConfirmationModal(item) {
    AirSearchStore.setPrevSelectedOffer(item);
    AirSearchStore.setShowModal(true);
  }

  AddOfferToCartForce() {
    if (this.forcing) {
      return;
    }
    this.forcing = true;
    this.AddOfferToCart(true);
    window.scrollTo(0, 0);
  }

  GoToBasketWithoutChanges() {
    AirSearchStore.stopSearch();
    AirSearchStore.setLoadingRestProposals(false);
    router.push({
      name: 'basket',
      params: { id: this.basketId }
    });
  }

  goToBasketWithoutModyfication() {
    AirSearchStore.loader(false);
    AirSearchStore.setLoadingRestProposals(false);
    AirSearchStore.stopSearch();
    router.push({
      name: 'basket',
      params: { id: this.currentBasketId }
    });
  }

  BackToResults() {
    AirSearchStore.loader(false);
    AirSearchStore.setShowModal(false);
    AirSearchStore.setLoadingRestProposals(false);
    AirSearchStore.clear();
  }

  BackToCriteria() {
    AirSearchStore.clearErrors();
    router.push({
      name: 'search'
    });
  }

  backToModificationResults() {
    this.openModifyPopup = false;
    this.checkExchangeConsent = false;
    this.clickedFirstTime = false;
    AirSearchStore.loader(false);
    AirSearchStore.setLoadingRestProposals(false);
    AirSearchStore.clear();
  }

  onPaymentValidationErrors() {
    AirSearchStore.loader(false);
  }

  onPaymentValidationSuccess() {
    if (this.selectedOfferSupplier === 'AirFranceKlm') {
      this.continueAirFranceExchange();
    }
    else {
      this.continueModification(this.selectedOffer ? this.selectedOffer.offer : null);
    }
  }

  continueExchange() {
    if (this.isPostSales && this.hasMissingSelectedApprover) {
      this.displayApproverEmpty = true;
    }
    if (!this.clickedFirstTime && !this.checkExchangeConsent) {
      this.clickedFirstTime = true;
    }
    if (
      !this.checkExchangeConsent ||
      (this.isPostSales && BasketStore.hasMissingSelectedApprover)
    ) {
      return;
    }
    if (this.isExchangePaymentMethods) {
      AirSearchStore.loader(true);
      EventBus.$emit('exchange-payment-method-validation');

      return;
    }
    if (this.selectedOfferSupplier === 'AirFranceKlm') {
      this.continueAirFranceExchange();
    } else {
      this.continueModification(this.selectedOffer ? this.selectedOffer.offer : null);
    }
  }

  async continueModification(offer) {
    AirSearchStore.loader(true);
    let request: any = {
      tripItemId: this.basketItemId,
      solutionId: offer.id,
    };
    this.exchangeErrors = [];
    if (this.isExchangePaymentMethods) {
      request.paymentInfo = {
        paymentMethod: this.exchangePaymentInfo.paymentMethod,
        cardId: this.exchangePaymentInfo.cardId,
        transactionId: this.exchangePaymentInfo.transactionId,
      };
    }
    try {
      if (this.isPostSalesApprovalVisible) {
        await TripApi.setExchangeDetailsApproval(this.$route.params.basketId, this.basketItemId, {
          approvers: BasketStore.selectedApprovers.map(approver => ({
            approverId: approver.approver.id,
            approvalLevel: approver.level,
          })),
        });
      }
      let searchId = this.$route.params.searchId;
      await AirApi.exchangeBasketItem(searchId, request);
      AirSearchStore.stopSearch();
      router.push({
        name: 'basket',
        params: { id: this.currentBasketId }
      });
    } catch (error) {
      this.exchangeErrors = this.$handleErrors(error, true);
    } finally {
      AirSearchStore.loader(false);
    }
  }

  checkTemplate() {
    if (this.templateOffers.length) {
      let templates = this.templateOffers.filter((temp: any) => {
        return temp.ssid === this.searchId;
      });
      AirSearchStore.setTemplateOffers(templates);
    }
  }

  @Watch('$route.params.searchId', { immediate: true })
  routeHandler() {
    AirSearchStore.setFiltersChanging(false);
    AirSearchStore.updateFilters([]);
    AirSearchStore.setProvidersErrors([]);
    AirSearchStore.updateOffers([]);
    this.selectedOffer = null;
    AirSearchStore.getDefaultOffersVisible();
    this.initView();
  }

  hideCustomExchangeLufthansaPopup() {
    AirSearchStore.setCustomExchangeLufthansaError(null);
    AirSearchStore.setShowCustomExchangeLufthansaPopup(false);
  }

  refreshFlightNumberHashFilter() {
    const currentLegFilters = this.filters;
    if (currentLegFilters) {
      const filterData: any = currentLegFilters.find((f: any) => f.code === 'FLIGHT_NUMBERS');
      if (filterData && this.$refs && this.$refs.airResultRows) {
        (this.$refs.airResultRows as any[]).forEach(element => {
          element.refreshFlightNumberHashState(filterData.values);
        });
      }
      if (filterData && this.$refs && this.$refs.airResultsRowViews) {
        (this.$refs.airResultsRowViews as any[]).forEach(element => {
          element.refreshFlightNumberHashState(filterData.values);
        });
      }
    }
  }

  flightSelected({ legHash, selected }) {
    this.scrollToElem();
    AirSearchStore.hashFilterUpdated(
      {
        code: 'FLIGHT_NUMBERS',
        data: {
          legHash,
          selected
        },
        searchId: this.searchId,
      },
    );
    EventBus.$emit('update-hashfilter', this.$refs);
  }

  clearHashFilters() {
    AirSearchStore.clearHashFilters();
  }

  getAlternativeParentId(offer) {
    return offer.parentId ? offer.parentId : this.retrievesExchangeOffer.parentId;
  }

  async SelectProposal(offer, proposal) {
    this.airExchangedOffer = null;
    AirSearchStore.setShowError(false);
    if (AirSearchStore.isSelected) {
      return;
    }
    this.travelPolicyResult = null;
    AirSearchStore.select({ offerId: offer.id, proposalId: proposal.id });

    if (this.isNewFlightDisplay) {
      let filteredProposals = offer.offerProposals.proposals.filter(offerProposal => offerProposal.id === proposal.id);
      let offerData = Object.assign({}, offer);
      offerData.offerProposals.selectedProposals = [];
      offerData.offerProposals.selectedProposals = filteredProposals;
      offerData.legs.forEach(leg => {
        leg.flights.map(flight => {
          flight.fareInfo = {
            brandName: filteredProposals[0].brandNames[0],
            cabinClasses: filteredProposals[0].cabinClasses[0],
            fareType: filteredProposals[0].fareType
          };
        });
      });
      this.selectedOffer = {
        offer: {
          id: offerData.id,
          legFlights: offerData.legs,
          proposal: offerData.offerProposals.selectedProposals[0]
        },
        proposal,
      };
    } else {
      this.selectedOffer = {
        offer,
        proposal,
      };
    }

    let supplier = this.isNewFlightDisplay ? proposal.supplier : offer.proposal.supplier;

    if (this.searchModifyState && ['Lufthansa', 'AirFranceKlm', 'AmericanAirlines', 'BritishAirways', 'Emirates'].indexOf(supplier) > -1) {
      let request = {
        id: this.isNewFlightDisplay ? proposal.id : offer.id,
        supplier: supplier,
        providerSearchId: this.isNewFlightDisplay ? proposal.providerSearchId : offer.providerSearchId,
      };
      await AirSearchStore.retrievesExchangeOfferPrice(request);

      if (this.retrievesExchangeOffer) {
        this.selectedOffer = {
          offer: this.retrievesExchangeOffer,
          proposal: this.retrievesExchangeOffer.proposal
        };
      } else {
        AirSearchStore.clear();
        return;
      }
    } else if (this.searchModifyState && supplier === 'Sabre') {
      await AirSearchStore.exchangeCandidate({
        recommendationId: this.isNewFlightDisplay ? proposal.id : offer.id,
        sessionId: this.$route.params.searchId,
      });
    }

    if (this.searchModifyState) {

      let parentId = proposal && proposal.parentId ? proposal.parentId : this.getAlternativeParentId(offer);

      const response = await OffersApi.getOffer(parentId);
      if (response && response.data) {
        this.airExchangedOffer = {
          ...response.data,
          travelPolicy: this.getExchangedOffer.travelPolicy,
        };
        this.airExchangedOffer.proposal = {
          ...this.airExchangedOffer.proposal,
          agencyFee: offer && offer.proposal ? offer.proposal.agencyFee : null,
        };
        if (this.isNewFlightDisplay) {
          if (this.selectedOffer.proposal && this.selectedOffer.proposal.agencyFee !== undefined) {
            this.airExchangedOffer.proposal = {
              ...this.airExchangedOffer.proposal,
              agencyFee: this.selectedOffer.proposal.agencyFee,
            };
          }
        } else {
          if (offer.proposal && offer.proposal.agencyFee !== undefined) {
            this.airExchangedOffer.proposal = {
              ...this.airExchangedOffer.proposal,
              agencyFee: offer.proposal.agencyFee,
            };
          }
        }
      }

      if (this.isExchangePaymentMethods) {
        try {
          const basketId = this.$route.params.basketId;
          const { data } = await TripApi.getPaymentMethods(basketId);
          this.exchangePaymentMethods = data.find(element => {
            return element.tripItemId === this.exchangedOfferItem.tripItemId;
          });
          this.exchangedOfferItem.paymentMethods = this.exchangePaymentMethods;
        } catch (error) {
          AirSearchStore.setErrMessages({ error });
          AirSearchStore.setShowError(true);
        }
      }
      try {
        const response = await TripApi.getExchangeDetailsSummary(
          this.$route.params.basketId,
          this.basketItemId
        );
        this.travelPolicyResult = response.data;
      } catch (error) {
        AirSearchStore.setErrMessages({ error });
        AirSearchStore.setShowError(true);
      }

      BasketStore.resetSelectedApprovers();
      try {
        const response = await TripApi.getExchangeDetailsApproval(
          this.$route.params.basketId,
          this.basketItemId
        );
        this.approvalResult = response.data;
        BasketStore.setSelectApproverData(response.data);
        BasketStore.setApprovalWorkflowForBasket(response.data.tripApprovalWorkflowResult);
        response.data.tripApprovalWorkflowResult.workflowLevels.forEach(level => {
          if (level.currentApprover) {
            BasketStore.setSelectedApprover({
              approver: {
                businessUnitName: level.currentApprover.businessUnitName,
                companyName: level.currentApprover.rootCompanyName,
                firstName: level.currentApprover.approverFirstName,
                middleName: level.currentApprover.approverMiddleName,
                lastName: level.currentApprover.approverLastName,
                profileId: level.currentApprover.approverId,
                id: level.currentApprover.approverId,
              },
              level: level.approvalLevel,
            });
          }
        });
      } catch (error) {
        AirSearchStore.setErrMessages({ error });
        AirSearchStore.setShowError(true);
      }

      this.modifyLoading = true;

      this.exchangeErrors = [];
      this.checkExchangeConsent = false;
      this.clickedFirstTime = false;
      this.openModifyPopup = true;
      setTimeout(() => {
        this.modifyLoading = false;
      }, 100);
      return;
    }

    this.AddOfferToCart();
    window.scrollTo(0, 0);
  }

  async AddOfferToCart(force = false) {
    this.loadingOffers = true;

    let basketItemsRequest = {
      forceAdd: force,
      recommendationId: this.isNewFlightDisplay ? this.selectedOffer!.proposal.id : this.selectedOffer!.offer.id,
    };

    AirSearchStore.CheckIfCanAddOfferToCart(basketItemsRequest);
  }

  selectedOfferProposal(data) {
    AirSearchStore.setLoadingRestProposals(true);
    this.SelectProposal(data.offer, data.proposal);
  }

  onReceive(data) {
    AirSearchStore.setShowTemplateOffersModal(true);
    this.showTemplateOffersModal = data;
  }

  closeTemplatePopup() {
    AirSearchStore.setShowTemplateOffersModal(false);
    this.showTemplateOffersModal = false;
  }

  scrollToError() {
    this.$nextTick(() => {
      if (!this.$refs.uiError) {
        return;
      }
      ((this.$refs.uiError as Vue).$el as HTMLElement).scrollIntoView({
        behavior: 'smooth'
      });
    });
  }

  @Watch('errMessages', { deep: true, immediate: true })
  onErrorShow(val) {
    this.scrollToError();
    if (val && val.length) {
      this.$nextTick(() => {
        AirSearchStore.loader(false);
      });
    }
  }

  templateInfo(data) {
    if (data) {
      if (this.isNewFlightDisplay) {
        this.offersProposalsList.forEach(offer => {
          offer.proposals.forEach(proposal => {
            proposal.prepareOfferCheck = false;
          });
        });
      } else {
        this.offers.forEach((offer) => {
          offer.prepareOfferCheck = false;
        });
      }
    }
  }

  templateInfoFromStore() {
    if (this.localTemplateOffersStore && this.localTemplateOffersStore.length) {
      let templateOffers: any[] = [];
      this.localTemplateOffersStore.forEach(template => {
        this.offers.forEach(offer => {
          if (template.offerId === offer.id) {
            templateOffers.push({
              ...offer,
              proposalId: template.proposalId
            });
            offer.prepareOfferCheck = true;
          }
        });
      });
      if (templateOffers && templateOffers.length) {
        AirSearchStore.setTemplateOffers(templateOffers);
      }
    }
  }

  scrollToElem() {
    this.$nextTick(() => {
      LayoutStore.scrollToElementAlways(this.$refs.top as HTMLElement);
    });
  }

  hashRefresh() {
    this.$nextTick(this.refreshFlightNumberHashFilter);
  }

  created() {
    EventBus.$on('prepare-offer', this.onReceive);
    EventBus.$on('template-offer', this.templateInfo);
    EventBus.$on('hash-refresh', this.hashRefresh);
    EventBus.$on('clear-filters', this.clearHashFilters);
    EventBus.$on('selected-offer-proposal', this.selectedOfferProposal);
    let newFlightDisplayCookie = this.$cookies.get('NewFlightDisplay');
    if (newFlightDisplayCookie) {
      AirSearchStore.setNewFlightDisplay(JSON.parse(newFlightDisplayCookie));
    }
  }

  beforeDestroy() {
    AirSearchStore.stopSearch();
    EventBus.$off('prepare-offer', this.onReceive);
    EventBus.$off('template-offer', this.templateInfo);
    EventBus.$off('hash-refresh', this.hashRefresh);
    EventBus.$off('clear-filters', this.clearHashFilters);
    EventBus.$off('selected-offer-proposal', this.selectedOfferProposal);
  }

  @Watch('offers', { immediate: true })
  onOffersChanged() {
    AirCompareLocalStore.getLocalStore();
    this.reloadOfferDetails();
    this.templateInfoFromStore();
  }

  @Watch('airExchangedOffer', { immediate: true })
  onAirExchangedOfferChanged() {

    if (this.selectedOfferSupplier !== 'AirFranceKlm') {
      this.airFranceExchangeWithExtras = {
        currentStep: null,
        extrasType:  AirFranceExtrasType.None,
        seats: null,
        ancillaries: null
      };
      return;
    }

    if (this.airExchangedOffer !== null) {

      if (this.airExchangedOffer.hasSeatSelected && this.airExchangedOffer.hasAncillarySelected) {
        this.airFranceExchangeWithExtras = {
          currentStep: AirFranceExchangeSteps.ViewExchangeOfferAndChooseExtrasTypeToReselect,
          extrasType:  AirFranceExtrasType.None,
          seats: null,
          ancillaries: null
        };
        return;
      } 

      if (this.airExchangedOffer.hasSeatSelected) {
        this.airFranceExchangeWithExtras = {
          currentStep: AirFranceExchangeSteps.ViewExchangeOfferAndChooseExtrasTypeToReselect,
          extrasType: AirFranceExtrasType.Seats,
          seats: null,
          ancillaries: null
        };
        return;
      }

      if (this.airExchangedOffer.hasAncillarySelected) {
        this.airFranceExchangeWithExtras = {
          currentStep: AirFranceExchangeSteps.ViewExchangeOfferAndChooseExtrasTypeToReselect,
          extrasType: AirFranceExtrasType.Ancillaries,
          seats: null,
          ancillaries: null
        };
        return;
      }
      
    }

    this.airFranceExchangeWithExtras = {
      currentStep: AirFranceExchangeSteps.ViewExchangeOffer,
      extrasType: AirFranceExtrasType.None,
      seats: null,
      ancillaries: null
    };
  }

  continueAirFranceExchange() {

    if (AirFranceExchangeSteps.ViewExchangeOfferAndChooseExtrasTypeToReselect === this.airFranceExchangeWithExtras.currentStep 
      && this.airFranceExchangeWithExtras.extrasType === AirFranceExtrasType.Seats) {
      this.airFranceExchangeStartSeatsReselection();
      return;
    }

    if (AirFranceExchangeSteps.ViewExchangeOfferAndChooseExtrasTypeToReselect === this.airFranceExchangeWithExtras.currentStep
      && this.airFranceExchangeWithExtras.extrasType === AirFranceExtrasType.Ancillaries) {
      this.airFranceExchangeStartAncillariesReselection();
      return;
    }

    if (this.airFranceExchangeWithExtras.currentStep === AirFranceExchangeSteps.ReselectSeats) {
      this.airFranceExchangeEndSeatsReselection();
      return;
    } 

    if (this.airFranceExchangeWithExtras.currentStep === AirFranceExchangeSteps.ReselectAncillaries) {
      this.airFranceExchangeEndAncillariesReselection();
      return;
    }

    this.airFranceExchangeAccept();
  }

  async airFranceExchangeStartSeatsReselection() {

    if (this.selectedOffer === null) {
      this.airFranceExchangeWithExtras.currentStep = AirFranceExchangeSteps.AcceptExchangeOffer;
      return;
    }

    this.airFranceExchangeWithExtras.currentStep = AirFranceExchangeSteps.ReselectSeats;

    AirSearchStore.loader(true);
    try {
      const { data: items } = await BasketApi.getExchangeOfferSeatsMap(this.basketItemId, this.selectedOffer.offer.id);
      this.airFranceExchangeWithExtras.seats = items;
    } catch (error) {
      this.exchangeErrors = this.$handleErrors(error, true);
    } finally {
      AirSearchStore.loader(false);
    }
  }

  async airFranceExchangeStartAncillariesReselection() {

    if (this.selectedOffer === null) {
      this.airFranceExchangeWithExtras.currentStep = AirFranceExchangeSteps.AcceptExchangeOffer;
      return;
    }

    this.airFranceExchangeWithExtras.currentStep = AirFranceExchangeSteps.ReselectAncillaries;

    AirSearchStore.loader(true);
    try {
      const { data } = await BasketApi.getExchangeOfferAncillaries(this.basketItemId, this.selectedOffer.offer.id);
      this.airFranceExchangeWithExtras.ancillaries = data; 
      BasketStore.selectAlreadyBookedAncillaries(data.passengerAncillaries);
      BasketStore.setBasketAncillariesFlights(data.flights);
      BasketStore.setBasketAncillariesMessages(data.messages);
      BasketStore.setMaxOneAncillaryPerSegment(data.maxOneAncillaryPerSegment);
      BasketStore.setMinimumAncillaryCount(data.minimumAncillaryCount);
    } catch (error) {
      this.exchangeErrors = this.$handleErrors(error, true);
    } finally {
      AirSearchStore.loader(false);
    }
  }

  async airFranceExchangeEndAncillariesReselection() {

    if (this.selectedOffer === null) {
      this.airFranceExchangeWithExtras.currentStep = AirFranceExchangeSteps.AcceptExchangeOffer;
      return;
    }

    let ancillariesSelections: any[] = [];
    ancillariesSelections = BasketStore.selectedAncillaries
      .filter((item: any) => {
        return !item.isAlreadyBooked;
      })
      .map(item => {
        return {
          profileId: item.profileId,
          ancillaryOfferId: item.id,
          quantity: 1,
        };
      });

    AirSearchStore.loader(true);
    try {
      await BasketApi.setExchangeOfferAncillaries(this.basketItemId, this.selectedOffer.offer.id, {
        ancillarySelections: ancillariesSelections,
      });
    } catch (error) {
      this.exchangeErrors = this.$handleErrors(error, true);
    } finally {
      AirSearchStore.loader(false);
    }

    this.airFranceExchangeAccept();
  }

  async airFranceExchangeEndSeatsReselection() {

    if (this.selectedOffer === null) {
      this.airFranceExchangeWithExtras.currentStep = AirFranceExchangeSteps.AcceptExchangeOffer;
      return;
    }

    let flights: any[] = [];
    BasketStore.selectedSeats.seats.forEach(seat => {
      flights.push({
        flightId: seat.flightId,
        profileSeatSelections: [{
          profileId: seat.travellerId,
          seatRow: seat.row,
          seatColumn: seat.column,
          offerItemId: seat.offerItemId
        }]
      });
    });

    AirSearchStore.loader(true);
    try {
      await BasketApi.setExchangeOfferSeats(this.basketItemId, this.selectedOffer.offer.id, {
        seatSelections: flights,
      });
    } catch (error) {
      this.exchangeErrors = this.$handleErrors(error, true);
    } finally {
      AirSearchStore.loader(false);
    }

    this.airFranceExchangeAccept();
  }

  airFranceExchangeAccept() {
    this.airFranceExchangeWithExtras.currentStep = AirFranceExchangeSteps.AcceptExchangeOffer;
    this.continueModification(this.selectedOffer ? this.selectedOffer.offer : null);
  }

  get showAirFranceExchangeOfferExtrasTypeSelector () {
    return this.airFranceExchangeWithExtras.currentStep === AirFranceExchangeSteps.ViewExchangeOfferAndChooseExtrasTypeToReselect;
  }

  get showAirFranceExchangeOfferAncillaries() {
    return this.airFranceExchangeWithExtras.currentStep === AirFranceExchangeSteps.ReselectAncillaries
      && this.airFranceExchangeWithExtras.ancillaries;
  }

  get showAirFranceExchangeOfferSeats() {
    return this.airFranceExchangeWithExtras.currentStep === AirFranceExchangeSteps.ReselectSeats
      && this.airFranceExchangeWithExtras.seats;
  }

}

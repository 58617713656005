































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { requiredIf } from 'vuelidate/lib/validators';
import { translate } from '@/i18n';
import priceConst from '@/const/price.const';
import {
  Traveller,
  TBenerailSeatPreferencesModel,
  TRailBenerailCategory,
  TBasketRailBenerailOption,
} from '@/api/trip/basket.model';

const Option = {
  noPreference: {
    get name() {
      return translate('basket.no-preference');
    },
    value: null,
  },
  selectAssignment: {
    get name() {
      return translate('basket.seat-assignment');
    },
    value: 'selectAssignment',
  },
  withoutSeatReservation: {
    get name() {
      return translate('basket.without-seat-reservation');
    },
    value: 'withoutSeatReservation',
  },
};
const COLS = 12;
const MD_COLS = 3;

@Component({})
export default class SeatsBenerailContent extends Vue {
  @Prop() value!: TBenerailSeatPreferencesModel;
  @Prop() segmentId!: string;
  @Prop() legNumber!: number;
  @Prop() traveller!: Traveller;
  @Prop() options!: TBasketRailBenerailOption[];

  $v;
  Option = Option;
  defaultPrice = priceConst.priceModel;
  category: { name: string, value: string | null } = Option.noPreference;

  @Validation()
  validationObject() {
    return {
      valueOptionModel: {
        preferences: {
          required: requiredIf(() => this.category.value === Option.selectAssignment.value)
        },
      },
    };
  }

  get option() {
    return this.options.find((option) => option.passengers.some((item) => item.externalReference === this.traveller.id));
  }

  get offerPartId() {
    return this.option && this.option.offerPartId;
  }

  get categories() {
    return (this.option && this.option.categories) || [];
  }

  get isOptional() {
    return this.option && this.option.isOptional;
  }

  get categoryOptions() {
    return [
      {...Option.noPreference},
      this.categories.length > 0 ? {...Option.selectAssignment} : null,
      this.isOptional ? {...Option.withoutSeatReservation} : null,
    ].filter((item) => item);
  }

  get colMdSize() {
    const preferences = (this.option && this.option.categories) || [];
    const divider = preferences.length > MD_COLS ? MD_COLS : preferences.length;
    if (divider > 0) {
      return COLS / (divider > MD_COLS ? MD_COLS : divider);
    }
    return COLS;
  }

  get valueOptionModel() {
    return this.value.options.find((item) => item.offerPartId === this.offerPartId);
  }

  emitModel(preferences: string[] | null = null) {
    this.$emit('input', {
      options: this.value.options
        .filter((item) => item.offerPartId !== this.offerPartId)
        .concat({
          isIncluded: !(this.isOptional && this.category.value === Option.withoutSeatReservation.value),
          legNumber: this.legNumber,
          offerPartId: this.offerPartId as string,
          preferences,
          segmentId: this.segmentId,
        }),
    });
  }

  getPreference(payload: TRailBenerailCategory) {
    const option = this.valueOptionModel;
    if (!option) {
      return Option.noPreference;
    }
    const preference = payload.preferences.find((item) => !!item.value && (option.preferences || []).includes(item.value));
    return preference || Option.noPreference;
  }

  setPreference(payload: { category: string, value: string }) {
    const option = this.valueOptionModel;
    let preferences: string[] = [];
    if (option) {
      const category = this.categories.find((item) => item.name === payload.category);
      preferences = (option.preferences || []).filter((item) => {
        const categoryPreferences = ((category && category.preferences) || []).map((item) => item.value);
        return !categoryPreferences.includes(item);
      });
    }
    const preferencesModel = preferences.concat(payload.value);
    this.emitModel(preferencesModel);
  }

  isValid() {
    this.$v.$touch();
    return !this.$v.$error;
  }
}

































































































import { Vue, Component, Watch } from 'vue-property-decorator';

import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import rolesProfileStore from './roles.store';
import profileStore from '@/modules/profile/profile.store';
import { ProfileRoleModel } from '@/api/roles/roles.model';
import AssignProfileRole from './AssignProfileRole.vue';

@Component({
  components: {
    AssignProfileRole,
  }
})
export default class ProfileRoles extends Vue {
  showAssignRoleModal: boolean = false;
  deleteRolePopup: boolean = false;
  roleId: string | null = null;
  cardRemovalErrorMessage = null;
  selectedRole = null;
  roleToRemove: ProfileRoleModel | null = null;
  formPending: boolean = false;

  fields = {
    roleName: {
      sortable: true,
    },
    scope: {
      sortable: true,
    },
    details: {
      sortable: false,
    }
  };

  get isAgency() {
    return this.profile && this.profile.company && this.profile.company.isAgency;
  }
  
  get profile() {
    return profileStore.userProfile;
  }

  get profileRoles(): ProfileRoleModel[] {
    return rolesProfileStore.ProfileRoles;
  }

  get showError() {
    return rolesProfileStore.showError;
  }

  get errMessages() {
    return rolesProfileStore.serverErrors;
  }

  get closePopup() {
    return rolesProfileStore.closePopup;
  }

  @Watch('closePopup')
  onClosePopup(val) {
    if (val) {
      let self = this;
      this.$nextTick().then(() => {
        self.showAssignRoleModal = false;
      });
    }
  }

  @Watch('$route', { deep: true, immediate: true})
  onRouteChange() {
    rolesProfileStore.setCurrentProfileRoles(null);
    rolesProfileStore.init();
  }

  addNew() {
    rolesProfileStore.setShowError(false);
    this.selectedRole = null;
    this.showAssignRoleModal = true;
  }

  removePopupRole(role) {
    this.deleteRolePopup = true;
    this.roleToRemove = role;
  }

  async removeRole() {
    let roleToRemove = this.roleToRemove;
    try {
      this.formPending = true;
      await rolesProfileStore.removeRole(roleToRemove as ProfileRoleModel);
      this.close();
    } catch (error) {
      if (!error.response.data.error.details) {
        this.cardRemovalErrorMessage = error.response.data.error.message;
      }
    } finally {
      this.formPending = false;
    }
  }

  close() {
    this.roleToRemove = null;
    this.deleteRolePopup = false;
  }

  updateRole(role) {
    this.selectedRole = role;
    this.showAssignRoleModal = true;
  }
}

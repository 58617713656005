





























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';

@Component({})
export default class UiPlusMinusValue extends Vue {
  @Prop({ default: 0 }) min!: number;
  @Prop() max!: number;
  @Prop({ default: 1 }) step!: number;
  @Prop({ default: null }) unit!: string;
  @Prop({ default: true }) unitAsPrefix!: boolean;
  @Prop() value!: number;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) addDisabled!: boolean;
  @Prop({ default: false }) reduceDisabled!: boolean;

  get valueInput() {
    if (this.unit) {
      return this.unitAsPrefix ? `${this.unit} ${this.value}` : `${this.value} ${this.unit}`;
    }
    return this.value;
  }
  set valueInput(value: string | number) {
    const stringNumber = this.getStringNumberFromInput(value);
    const isFloat = this.isFloat(stringNumber);
    if (isFloat) {
      const floatValue = parseFloat(stringNumber);
      if (floatValue > this.max) {
        this.$emit('input', this.max);
        return;
      }
      if (floatValue < this.min) {
        this.$emit('input', this.min);
        return;
      }
      this.$emit('input', floatValue);
    }
  }

  valueInc() {
    let newValue = this.value + this.step;
    if (newValue > this.max) {
      newValue = this.max;
    }
    this.$emit('input', newValue);
  }

  valueDec() {
    let newValue = this.value - this.step;
    if (newValue < this.min) {
      newValue = this.min;
    }
    this.$emit('input', newValue);
  }

  isFloat(value: string) {
    const parsedValue = parseFloat(value);
    return !isNaN(parsedValue) && parsedValue.toString() === value.toString();
  }

  getStringNumberFromInput(value: string | number) {
    const splitValue = `${value}`.split(`${this.unit}`).map((item) => item.trim().replace(',', '.'));
    const splitIndex = this.unitAsPrefix ? splitValue.length - 1 : 0;
    return splitValue[splitIndex];
  }

  valueInputFocusOut() {
    this.$forceUpdate();
  }
}























































































































































































import { Vue, Component } from 'vue-property-decorator';
import { VueContentLoading } from 'vue-content-loading';
import TrainResultsShortRow from './TrainResultsShortRow.vue';
import TrainSearchStore from './train-search.store';
import SearchStore from '@/modules/search/search.store';
import AccountStore from '@/store/account.store';
import _ from 'lodash';
import { router } from '@/router';

@Component({
  components: {
    VueContentLoading,
    TrainResultsShortRow,
  }
})
export default class TrainSort extends Vue {
  imagesConst = '/assets/img/loader/1.gif';
  imagesErrorInfoConst = '/assets/img/ic_error.png';
  toggleSortOptions: boolean = false;
  isProviderErrorsVisible: boolean = false;

  get loading() {
    return TrainSearchStore.loading;
  }

  get offers() {
    return TrainSearchStore.offers;
  }

  get isFranceDomesticDepatureAndOuigoAvailable() {
    return TrainSearchStore.isFranceDomesticDepatureAndOuigoAvailable;
  }

  get showPlaceholder() {
    return !this.filters ||
      (this.loading && this.filters.length === 0) ||
      (this.loading && this.offers.length === 0);
  }

  get modifyMode() {
    return -1 < [
      'trainModification',
    ].indexOf(this.$route.name || '');
  }

  get isDisabled() {
    return TrainSearchStore.selectingOffer;
  }

  get sortOptions() {
    return TrainSearchStore.sortOptions;
  }

  get currentLeg() {
    const value = this.$route.params.leg;
    return isNaN(Number(value)) ? 1 : Number(value);
  }

  get availableActions() {
    return TrainSearchStore.availableMoreActions;
  }

  get earlierAvailable() {
    return this.availableActions[this.currentLeg] &&
      this.availableActions[this.currentLeg].indexOf('Previous') > -1;
  }

  get laterAvailable() {
    return this.availableActions[this.currentLeg] &&
      this.availableActions[this.currentLeg].indexOf('Next') > -1;
  }

  get anyNavigationOptionAvailable() {
    return this.earlierAvailable || this.laterAvailable;
  }

  get priceSorterEnabled() {
    return this.sortOptions!.PRICE !== undefined;
  }

  get priceSorterStatus() {
    return this.sortOptions!.PRICE === 'Ascending';
  }

  get departureTimeSorterEnabled() {
    return this.sortOptions!.DEPARTURE_TIME !== undefined;
  }

  get departureTimeSorterStatus() {
    return this.sortOptions!.DEPARTURE_TIME === 'Ascending';
  }
  
  get currentSortType() {
    if (this.priceSorterEnabled) {
      return 'sort-by-cheapest-offer';
    }
    return 'sort-by-earliest-departure';
  }

  get totalOffers() {
    return TrainSearchStore.totalOffers;
  }

  get filters() {
    return TrainSearchStore.filters;
  }

  get recommendationsRailCount() {
    return TrainSearchStore.recommendationsRailCount;
  }

  get isNotFiltered() {
    return this.totalOffers === this.recommendationsRailCount;
  }

  get isSearchCompleted() {
    return TrainSearchStore.searchCompleted;
  }

  get selectedOffer() {
    return TrainSearchStore.selectedOffer;
  }

  get legNumber() {
    return this.$route.params.leg;
  }

  get isRoundTrip() {
    return SearchStore.trainSearchParameters.searchMode === 'RoundTrip';
  }

  get providersErrors() {
    return TrainSearchStore.providersErrors;
  }

  get groupedProvidersErrors() {
    return _.groupBy(TrainSearchStore.providersErrors, 'supplier');
  }

  get canSeeProviderWarnings() {
    return AccountStore.HasPermission('CanSeeProviderWarnings');
  }


  toggleSort(sorterCode) {
    if (this.isDisabled) {
      return;
    }
    if (this.sortOptions[sorterCode] === undefined) {
      TrainSearchStore.setNewSort({ sorterCode, isAscending: true });

      const searchId = this.$route.params.searchId;
      const legNumber = this.$route.params.leg ? this.$route.params.leg : 1;
      TrainSearchStore.setFiltersChanged(true);
      TrainSearchStore.getOffers({searchId, legNumber});
    }
    this.toggleSortOptions = false;
  }

  toggleSortDropdown() {
    if (this.isDisabled) {
      return;
    }
    this.toggleSortOptions = !this.toggleSortOptions;
  }

  showProviderErrors() {
    this.isProviderErrorsVisible = true;
  }

  hideProviderErrors() {
    this.isProviderErrorsVisible = false;
  }

  beforeDestroy() {
    TrainSearchStore.setSelectedOffer([]);
  }

  showMobileFilters() {
    router.push({
      name: 'trainFilters',
      params: {
        searchId: this.$route.params.searchId,
        leg: this.$route.params.leg,
      }
    });
  }

}


















































import { Component, Vue } from 'vue-property-decorator';
import { TTravellersResponse } from '@/api/trip/basket.model';

@Component({})
export default class BasketTravellerValidationPopup extends Vue {
  popup: TTravellersResponse | null = null;

  get errorCodes() {
    const errors = (this.popup && this.popup.errors) || [];
    return [...new Set(errors.map(({ errorCode, severity }) => {
      if (errorCode === 'AGE_INCOMPATIBILITY') {
        return errorCode;
      }
      return severity;
    }))];
  }

  open(payload: TTravellersResponse) {
    this.popup = payload;
  }

  resolve(result: boolean) {
    if (this.popup) {
      this.$emit('close', result);
    }
    this.popup = null;
  }
}

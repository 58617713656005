












































































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  TAirSeatSelectionsModel,
  TSeatMap,
  TPassenger,
  TFlight,
  TProfileSeatSelection,
} from '@/api/trip/basket-air-seats-extras.model';
import { getSeatPrice } from '@/modules/basket/basket-air-seats-extras/BasketAirSeatsExtras.helper';
import SeatMapPopup from '@/modules/basket/basket-air-seats-extras/SeatMapPopup.vue';
import AirCabin from '@/modules/basket/seatmap/AirCabin.vue';
import BasketStore from '@/modules/basket/basket.store';

@Component({
  components: {
    AirCabin,
    SeatMapPopup,
  },
})
export default class SeatsContent extends Vue {
  @Prop() value!: TAirSeatSelectionsModel;
  @Prop() seatMap!: TSeatMap;
  @Prop() passenger!: TPassenger;
  @Prop() flight!: TFlight;

  showSeatMapPopup: boolean = false;

  get passengerSeats() {
    return this.value.seatSelections.reduce((prev, next) => {
      if (next.flightId === this.flight.id) {
        return [...prev, ...next.profileSeatSelections];
      }
      return prev;
    }, [] as TProfileSeatSelection[]);
  }

  set passengerSeats(seats: TProfileSeatSelection[]) {
    const seatSelections = this.value.seatSelections.filter(({ flightId }) => flightId !== this.flight.id);
    const newSeatSelections = seats.map((item) => ({
      flightId: this.flight.id,
      profileSeatSelections: [item],
    }));
    this.$emit('input', {
      seatSelections: seatSelections.concat(newSeatSelections),
    });
  }

  get isTravelfusion() {
    return BasketStore.bookingStepTripItem && BasketStore.bookingStepTripItem.supplier === 'TravelFusion';
  }

  get passengerId() {
    return this.passenger.id || this.passenger.profileId;
  }

  get selectedSeat() {
    return (this.seatMap.selectedSeats || []).find((item) => item.profileId === this.passengerId);
  }

  get passengerSeat() {
    return this.passengerSeats.find((item) => item.profileId === this.passengerId);
  }

  get price() {
    if (!this.passengerSeat) {
      return;
    }
    const { seatRow, seatColumn } = this.passengerSeat;
    return getSeatPrice({
      seatMaps: [this.seatMap],
      profileId: this.passengerId,
      flightId: this.flight.id,
      row: seatRow,
      column: seatColumn,
    });
  }

  get validPaymentCards() {
    if (this.seatMap.validPaymentCards && this.seatMap.validPaymentCards.length > 0) {
      const cards = this.seatMap.validPaymentCards.map((item) => item.replace(/([A-Z])/g, ' $1').trim());
      return cards.join(', ');
    }
    return null;
  }

  get isMandatoryError() {
    if (this.isTravelfusion) {
      const availableSeatsForTravellers = this.seatMap.availableSeatsForTravellers || [];
      return BasketStore.basketSeatsMapError && this.passengerSeats.length !== availableSeatsForTravellers.length;
    }
    return BasketStore.basketSeatsMapError && this.seatMap.isMandatory && this.passengerSeats.length === 0;
  }

  onSelectedItemRemove() {
    this.$emit('input', {
      seatSelections: this.value.seatSelections.filter((item) => {
        const profileSeat = item.profileSeatSelections.find(({ profileId }) => profileId === this.passengerId);
        return !(profileSeat && item.flightId === this.flight.id);
      }),
    });
  }

  openSeatMapPopup() {
    this.showSeatMapPopup = true;
  }
}

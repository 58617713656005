




















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import AirExchangeModifySearchForm from './AirExchangeModifySearchForm.vue';
import { Recommendation, Traveller } from '@/api/air-engine/air-search.model';

const eventName = 'update:show';

@Component({
  components: {
    AirExchangeModifySearchForm,
  },
})
export default class AirExchangeModifyPopup extends Vue {
  @Prop() title!: string;
  @Prop() offer!: Recommendation;
  @Prop() travellers!: Traveller[];
  @Prop() basketId!: string;
  @Prop() show!: boolean;
  @Prop() loadErrors!: any[];
  @Prop() status!: any;

  @Emit(eventName)
  hidePopup() {
    return false;
  }
}























import { Component, Vue } from 'vue-property-decorator';

type TPopup = {
  title: string;
  content: string;
  resolve: (value: boolean) => void;
};

@Component({})
export default class AlertPopup extends Vue {
  popup: TPopup | null = null;

  open(payload: { title: string, content: string }): Promise<boolean> {
    return new Promise((resolve) => {
      this.popup = {
        ...payload,
        resolve,
      } as TPopup;
    });
  }

  resolve(result: boolean) {
    if (this.popup) {
      this.popup.resolve(result);
    }
    this.popup = null;
  }
}







































































































import { Vue, Component, Prop } from 'vue-property-decorator';

import settings from '@/settings';
import { translate } from '@/i18n';
import FlightTimeline from './FlightTimeline.vue';
import formatPrice from '@/filters/format-price.filter';
import AirSearchStore from '@/modules/search/air/air-search.store';
import AccountStore from '@/store/account.store';
import { SupplierName } from '@/services/supplier-name.service';

@Component({
  components: {
    FlightTimeline,
  }
})
export default class AirResultsFeeEditedOffer extends Vue {
  @Prop() offer: any;
  @Prop() item!: any;
  @Prop() proposal?: any;
  @Prop() isInBasket!: any;
  @Prop() totalPrice: any;
  @Prop() agencyFee: any;
  @Prop() agencyMarkup: any;

  get canShowExternalAgencyMarkup() {
    return 'true' === settings.enableExternalAgencyMarkup;
  }

  get isNewFlightDisplay() {
    return AirSearchStore.newFlightDisplay;
  }

  get flagClasses() {
    if (this.offer.proposal) {
      return {
        'flag__public': 'Public' === this.offer.proposal.fareType,
        'flag__nego': 'Nego' === this.offer.proposal.fareType,
        'flag__private': 'Private' === this.offer.proposal.fareType,
      };
    } else if (this.proposal) {
      return {
        'flag__public': 'Public' === this.proposal.fareType,
        'flag__nego': 'Nego' === this.proposal.fareType,
        'flag__private': 'Private' === this.proposal.fareType,
      };
    }
  }

  get basketFlagClasses() {
    return {
      'flag__public': 'Public' === this.offer.fareType,
      'flag__nego': 'Nego' === this.offer.fareType,
      'flag__private': 'Private' === this.offer.fareType,
    };
  }

  get classesTooltip() {
    if (!this.isInBasket) {
      if (this.offer && this.offer.fareType) {
        switch (this.offer.fareType) {
          case 'Public':
            return translate('search-air.public-fare');
          case 'Nego':
            return translate('search-air.nego-fare');
          case 'Private':
            return translate('search-air.private-fare');
          default:
            return translate('search-air.public-fare');
        }
      } else if (this.proposal && this.proposal.fareType) {
        switch (this.proposal.fareType) {
          case 'Public':
            return translate('search-air.public-fare');
          case 'Nego':
            return translate('search-air.nego-fare');
          case 'Private':
            return translate('search-air.private-fare');
          default:
            return translate('search-air.public-fare');
        }
      }
    } else {
      switch (this.offer.fareType) {
        case 'Public':
          return translate('search-air.public-fare');
        case 'Nego':
          return translate('search-air.nego-fare');
        case 'Private':
          return translate('search-air.private-fare');
        default:
          return translate('search-air.public-fare');
      }
    }
  }

  get hasEditFeePermission() {
    return this.$hasAccess('CanEditBookingFee');
  }

  get hasAdvancedOfferInfo() {
    return this.$hasAccess('CanReadAdvancedOfferInfo');
  }

  get hasFeeConfiguration() {
    return !!AirSearchStore.feeConfigurationId;
  }

  get isPriceDetailedComponentsVisible() {
    return AirSearchStore.priceDetailedComponentsVisible;
  }

  priceFormat(price) {
    return formatPrice(price);
  }

  getSupplierName() {
    if (!this.isInBasket) {
      return this.offer && this.offer.proposal ? SupplierName(this.offer.proposal.supplier) : SupplierName(this.proposal && this.proposal.supplier);
    } else {
      return SupplierName(this.item.supplier);
    }
  }

  shouldFadeLeg(legIndex) {
    if (-1 === [
      'airModification',
      'airModificationTravellers'
    ].indexOf(this.$route.name || '')) {
      return false;
    }
    if (legIndex >= AirSearchStore.exchangeLegsSelectedForEdit.length) {
      return false;
    }
    return !AirSearchStore.exchangeLegsSelectedForEdit[legIndex];
  }
  
  formatPrice(price) {
    if (!price) {
      return '';
    }
    return `${price.currency.symbol ? price.currency.symbol : price.currency.code} ${(Math.round(100 * price.amount) / 100).toFixed(2)}`;
  }

  get formatTotalPrice() {
    if (!this.totalPrice) {
      return '';
    }
    return `${this.totalPrice.currency.symbol ? this.totalPrice.currency.symbol : this.totalPrice.currency.code} ${((Math.round(100 * this.totalPrice.amount) / 100)).toFixed(2)}`;
  }

  getProperTaxPriceObject() {
    if (!this.isInBasket) {
      return this.offer && this.offer.proposal && this.offer.proposal.taxPrice ? this.offer.proposal.taxPrice : this.proposal && this.proposal.taxPrice;
    } else {
      return this.offer.price.taxPrice;
    }
  }

  getProperProviderPriceObject() {
    if (!this.isInBasket) {
      return this.isNewFlightDisplay ? this.proposal && this.proposal.providerPrice : this.offer.proposal.providerPrice;
    } else {
      return this.item && this.item.price && this.item.price.providerPrice ? this.item.price.providerPrice : null;
    }
  }

  getSystemMarkupPart() {
    let tooltip = '';
    
    if (this.item && this.item.price && this.item.price.rawProviderPrice) {
      tooltip += `<span>${translate('basket.raw-provider-price')} ${this.item.price.rawProviderPrice.currency.symbol || this.item.price.rawProviderPrice.currency.code} ${formatPrice(this.item.price.rawProviderPrice.amount)}</span>`;
    }
    if (this.item && this.item.price && this.item.price.systemMarkup) {
      tooltip += `<span class="mb-3">${translate('basket.system-markup')} ${this.item.price.systemMarkup.currency.symbol || this.item.price.systemMarkup.currency.code} ${formatPrice(this.item.price.systemMarkup.amount)}</span>`;
    }
    
    if (this.offer && this.offer.proposal && this.offer.proposal.rawProviderPrice) {
      tooltip += `<span>${translate('basket.raw-provider-price')} ${this.offer.proposal.rawProviderPrice.currency.symbol || this.offer.proposal.rawProviderPrice.currency.code} ${formatPrice(this.offer.proposal.rawProviderPrice.amount)}</span>`;
    }
    if (this.offer && this.offer.proposal && this.offer.proposal.systemMarkup) {
      tooltip += `<span class="mb-3">${translate('basket.system-markup')} ${this.offer.proposal.systemMarkup.currency.symbol || this.offer.proposal.systemMarkup.currency.code} ${formatPrice(this.offer.proposal.systemMarkup.amount)}</span>`;
    }

    return tooltip;
  }

  getAdvancedOfferInfoPart() {
    let tooltip = '';

    if (this.hasAdvancedOfferInfo && this.getProperProviderPriceObject()) {
      tooltip += `<span>${translate('basket.incl')} ${this.formatPrice(this.getProperProviderPriceObject())} ${translate('search-air.provider-price')}`;

      if (this.getProperTaxPriceObject()) {
        tooltip += ` (${this.formatPrice(this.getProperTaxPriceObject())} ${translate('search-air.taxes')})`;
      }
      tooltip += `</span>`;
    }

    return tooltip;
  }

  getAgencyMarkupAndFeePart() {
    let tooltip = '';

    if (this.item && this.item.price && this.item.price.agencyMarkup) {
      tooltip += `<span>${translate('basket.incl')} ${this.agencyMarkup.currency.symbol || this.agencyMarkup.currency.code} ${formatPrice(this.agencyMarkup.amount)} ${translate('basket.agency-markup-smallcaps')}</span>`;
    }
    if (this.offer && this.offer.proposal && this.offer.proposal.agencyMarkup) {
      tooltip += `<span>${translate('basket.incl')} ${this.agencyMarkup.currency.symbol || this.agencyMarkup.currency.code} ${formatPrice(this.agencyMarkup.amount)} ${translate('basket.agency-markup-smallcaps')}</span>`;
    }
    if ((this.isPriceDetailedComponentsVisible || this.hasEditFeePermission || this.hasAdvancedOfferInfo) && (this.agencyFee && this.agencyFee.amount && this.agencyFee.currency)) {
      tooltip += `<span>${translate('basket.incl')} ${this.formatPrice(this.agencyFee)} ${translate('search-air.fee')}</span>`;
    }

    return tooltip;
  }

  tooltip() {
    return [
      this.getSystemMarkupPart(),
      this.getAdvancedOfferInfoPart(),
      this.getAgencyMarkupAndFeePart(),
    ].join('');
  }
}

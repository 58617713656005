































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import _ from 'lodash';
import { VclCode } from 'vue-content-loading';

import AccountStore from '@/store/account.store';
import SearchConst from '@/const/search.const';
import { OffersApi } from '@/api/air-engine/offers.api';
import { BasketItemApi } from '@/api/trip/basket-item.api';
import { OffersModel } from '@/api/air-engine/offers.model';
import FlightTimeline from '@/modules/search/air/FlightTimeline.vue';
import FlightTimelineDetails from '@/modules/search/air/FlightTimelineDetails.vue';
import AirFareConditions from '@/modules/search/air/AirFareConditions.vue';
import AirSearchStore from '@/modules/search/air/air-search.store';
import BasketStore from '@/modules/basket/basket.store';
import EventBus from '@/services/event-handler';
import { router } from '@/router';
import settings from '@/settings';
import AirRefundPopup from '@/modules/search/air/AirRefundPopup.vue';
import AirExchangeOfferDetails from '@/modules/search/air/AirExchangeOfferDetails.vue';
import AirResultsFeeEditedOffer from '@/modules/search/air/AirResultsFeeEditedOffer.vue';
import AirResultFlightError from '@/modules/search/air/AirResultFlightError.vue';
import { translate } from '@/i18n';
import formatPrice from '@/filters/format-price.filter';
import AirSeatMap from '@/modules/search/air/AirSeatMap.vue';
import { AirSeats } from '@/modules/search/air/air-seats.model';
import ExpenseStore from '@/modules/expense/expense.store';
import AirLegTimeline from './AirLegTimeline.vue';
import BasketAirLegTimelineDetails from './BasketAirLegTimelineDetails.vue';
import BasketAirLegTimelineDetailsMobile from './BasketAirLegTimelineDetailsMobile.vue';
import { SupplierName } from '@/services/supplier-name.service';
import BasketPriceDetails from './BasketPriceDetails.vue';

@Component({
  components: {
    AirLegTimeline,
    BasketAirLegTimelineDetails,
    BasketAirLegTimelineDetailsMobile,
    FlightTimeline,
    FlightTimelineDetails,
    AirFareConditions,
    AirSeatMap,
    AirRefundPopup,
    AirExchangeOfferDetails,
    VclCode,
    AirResultsFeeEditedOffer,
    BasketPriceDetails,
    AirResultFlightError,
  },
  filters: {
    momentDate(date) {
      return moment(date).format('ddd, Do MMM');
    },
    dayFrom(date) {
      return moment(date).format('dddd, MMMM D');
    },
    dayToShort(date) {
      return moment(date).format('D');
    },
    dayToLong(date) {
      return moment(date).format('MMMM D');
    },
    momentDateTime(date) {
      return moment(date, 'YYYY-MM-DD HH:mm').format('DD MMM YYYY HH:mm');
    },
  }
})
export default class BasketAirOffer extends Vue {
  @Prop() offer!: any;
  @Prop() item?: any;
  @Prop({ default: false }) enableFilterByFlightNumber!: boolean;
  @Prop({ default: false }) showingInPopup!: boolean;
  @Prop({ default: false }) shouldHide!: boolean;
  @Prop({ default: true }) searchMode!: boolean;
  @Prop({ default: '' }) basketStatus!: string;
  @Prop({ default: '' }) basketProviderStatus!: string;
  @Prop({ default: false }) modifyMode!: boolean;
  @Prop({ default: false }) bookingInProgress!: boolean;
  @Prop() basketItemId!: string;
  @Prop({ default: false }) disableAgencyMarkup!: boolean;
  @Prop({ default: true }) showPrice!: boolean;
  @Prop({ default: true }) loading!: boolean;
  @Prop({ default: false }) disableFeeEdit!: boolean;
  @Prop({ default: false }) disablePriceDetails!: boolean;
  @Prop({ default: 0 }) priceChange!: number;
  @Prop() searchModeType!: string;
  @Prop({ default: false }) showOfferPrice!: boolean;
  @Prop({ default: true }) showPriceTooltip!: boolean;
  @Prop({ default: true }) showCurrentFlights!: boolean;
  @Prop({ default: false }) useOfferPassengerData!: boolean;
  @Prop({ default: false }) notLoadOfferDetails!: boolean;



  showDetails: boolean = false;
  seatsViewPopup: boolean = false;
  errors: any[] = [];
  selectedSeats: AirSeats | null = null;
  canShow = this.searchMode;
  offerDetails: OffersModel | any = null;
  showFullConditionsPopup: boolean = false;
  showEditSegmentPopup: boolean = false;
  showExchangeDetailsPopup: boolean = false;
  disabledInfo: string = translate('search-air.max-selected');
  currentTemplateOffers: any[] = [];
  currentSsid: string = '';
  loaderImagePath: string = '/assets/img/loader/1.gif';
  ndcImagePath: string = '/assets/img/ndc.png';
  imagesErrorInfoConst = '/assets/img/ic_error.png';
  data: any | null = null;
  height: any | null = null;
  showEditFeePopup: boolean = false;
  agencyFeeValue: number = 0;
  initialAgencyFeeValue: number = this.agencyFeeValue;
  showEditAgencyMarkupPopup: boolean = false;
  agencyMarkupValue: number = 0;
  initialAgencyMarkupValue: number = this.agencyMarkupValue;
  editFeeErrors: any[] = [];
  editFeeLoading: boolean = false;
  showOffer: boolean = false;
  showPriceDetailsPopup: boolean = false;


  passengerTypeMap = {
    ADT: translate('passenger-types.adt'),
    YTH: translate('passenger-types.yth'),
    CHD: translate('passenger-types.chd'),
    CNN: translate('passenger-types.chd'),
    INF: translate('passenger-types.inf'),
    SRC: translate('passenger-types.src'),
    SEA: translate('passenger-types.sea'),
    MLT: translate('passenger-types.mlt'),
  };

  get isAgency() {
    return AccountStore.current!.profile.isAgency;
  }

  get styles() {
    if (this.height) {
      return {
        height: this.height + 'px',
      };
    }
    return {};
  }

  get itemTotalPrice() {
    if (this.modifyMode) {
      return this.totalPriceWithCustomFee;
    }
    return {
      ...this.item.price.total,
      amount: this.item.price.total.amount + this.priceChange,
    };
  }

  get timelinesCollapsed() {
    return false;
  }

  get basket() {
    return BasketStore.basket;
  }

  get basketItems() {
    return BasketStore.basketItems;
  }

  get isInWizard() {
    return BasketStore.isInWizard;
  }

  get bookingStepCode() {
    return BasketStore.bookingStepDef && BasketStore.bookingStepDef.code ? BasketStore.bookingStepDef.code : '';
  }

  get canUserBookTrip() {
    return AccountStore.HasPermission('BookTrip');
  }

  get canAddOfferToTrip() {
    if ('airModification' === this.$route.name) {
      return this.canExchangeFlightBooking;
    }
    return AccountStore.HasPermission('AddOfferToTrip');
  }

  get canExchangeFlightBooking() {
    return AccountStore.HasPermission('ExchangeFlightBooking');
  }

  get canExchangeFlightSearch() {
    return AccountStore.HasPermission('ExchangeFlightSearch');
  }

  get templateOffers(): any[] {
    return AirSearchStore.templateOffers;
  }

  get hasFlyingBlue() {
    const programs = this.offer.loyaltyProgram || [];

    return programs.some(program => program === 'FlyingBlue');
  }

  get basePriceForFee() {
    return {
      amount: this.item.price.total.amount - this.initialAgencyFeeValue,
      currency: {
        ...this.item.price.total.currency,
      }
    };
  }

  get basePriceForMarkups() {
    return {
      amount: this.item.price.total.amount - this.initialAgencyMarkupValue,
      currency: {
        ...this.item.price.total.currency,
      }
    };
  }

  get timelinesExpanded() {
    return true;
  }

  get totalFareTax() {
    if (!this.modifyMode) {
      return 0;
    }
    if (this.offer) {
      if ((this.offer.proposal as any).taxDifference.amount < 0) {
        return (this.offer.proposal as any).fareDifference.amount;
      }
      return Math.round(
        100 * ((this.offer.proposal as any).fareDifference.amount +
        (this.offer.proposal as any).taxDifference.amount)
      ) / 100;
    }
  }

  get isDisabled() {
    return AirSearchStore.isSelected;
  }

  get isLCC() {
    return this.offer.isLowCost;
  }

  get hasLoyaltyPoints() {
    return this.offer.loyaltyPoints &&
      this.offer.loyaltyPoints.loyaltyPointsAvailable;
  }

  get loyaltyPointsTooltip() {
    if (!this.offer.loyaltyPoints || !this.offer.loyaltyPoints.loyaltyPointsAvailable) {
      return '';
    }
    return translate('basket.gain', {
      amount: '<strong>' + this.offer.loyaltyPoints.loyaltyUnitAmount +
        ' ' + this.offer.loyaltyPoints.loyaltyUnitName + '</strong>'
    });
  }

  get flagClasses() {
    return {
      'flag__public': 'Public' === this.offer.fareType,
      'flag__nego': 'Nego' === this.offer.fareType,
      'flag__private': 'Private' === this.offer.fareType,
    };
  }

  get classesTooltip() {
    switch (this.offer.fareType) {
      case 'Public':
        return translate('search-air.public-fare');
      case 'Nego':
        return translate('search-air.nego-fare');
      case 'Private':
        return translate('search-air.private-fare');
      default:
        return translate('search-air.public-fare');
    }
  }

  get cancelledFlights() {
    if (!this.offer || !this.offer.cancelledFlights) {
      return [];
    }

    return this.offer.cancelledFlights.map(item => {
      return {
        containsStopWithAirportChange: false,
        duration: 0,
        legNumber: 0,
        flights: [item],
      };
    });
  }

  get isThisOfferSelected() {
    return AirSearchStore.isSelected && this.offer.id === AirSearchStore.currentOfferId;
  }

  get sameAsExchangedOffer() {
    return AirSearchStore.getExchangedOffer ? AirSearchStore.getExchangedOffer.id === this.offer.id : false;
  }

  get travellers() {
    return BasketStore.basketTravellers;
  }

  priceFormat(price) {
    return formatPrice(price);
  }

  get currentTravellers() {
    return AirSearchStore.getExchangeTravellers;
  }

  get modificationBasketId() {
    return AirSearchStore.modificationBasketId;
  }

  get canShowExternalAgencyMarkup() {
    return 'true' === settings.enableExternalAgencyMarkup;
  }

  get passengerTickets() {
    return this.travellers.map(traveller => {
      return {
        ...traveller,
        passengerPrice: (this.offer.passengers && this.offer.passengers.length) ?
        this.offer.passengers.find(passenger => traveller.id === passenger.profileId) : null,
      };
    });
  }

  get shouldShowCurrentFlights() {
    return this.showCurrentFlights && this.item && 'AirFranceKlm' === this.item.supplier && 'NotificationFromAirline' === this.item.statusTag;
  }

  get sameMonth() {
    if (this.offer && this.offer.legFlights.length) {
      let startDate = this.offer.legFlights[0].flights[0].departure;
      const lastLeg: any = _.last(this.offer.legFlights);
      const lastFlight: any = _.last(lastLeg.flights);
      let endDate = lastFlight.arrival;
      return moment(this.startDate).isSame(this.endDate, 'month');
    }
  }

  get startDate() {
    if (this.offer && this.offer.legFlights.length) {
      return this.offer.legFlights[0].flights[0].departure;
    }
  }

  get endDate() {
    if (this.offer && this.offer.legFlights.length) {
      const lastLeg: any = _.last(this.offer.legFlights);
      const lastFlight: any = _.last(lastLeg.flights);
      return lastFlight.arrival;
    }
  }

  get canCompareOffers() {
    return AccountStore.HasPermission('CanSendOfferProposal');
  }

  get policyId() {
    return ExpenseStore.policyId;
  }

  get user() {
    return AccountStore.current;
  }

  get expenseIdForBasket() {
    return BasketStore.expenseIdForBasket;
  }

  get basketId() {
    return router.currentRoute.params.id;
  }

  get totalPriceWithCustomFee() {
    if (this.modifyMode) {
      return this.item && this.item.price ? this.item.price.total : {
        amount: this.offer.price.totalPrice.amount - this.initialAgencyFeeValue + (this.offer.proposal.agencyFee ? this.offer.proposal.agencyFee.amount : 0),
        currency: this.offer.price.totalPrice.currency
      };
    }

    if (this.offer && !this.item) {
      return {
        amount: this.offer.price.totalPrice.amount - this.initialAgencyFeeValue + this.agencyFeeValue,
        currency: this.offer.price.totalPrice.currency
      };
    }

    return {
      amount: this.priceChange + this.item.price.total.amount - this.initialAgencyFeeValue + this.agencyFeeValue,
      currency: this.item.price.total.currency
    };
  }

  get totalPriceWithCustomAgencyMarkup() {
    if (this.modifyMode) {
      return this.item && this.item.price ? this.item.price.total : {
        amount: this.offer.price.totalPrice.amount - this.initialAgencyMarkupValue + (this.offer.proposal.agencyMarkup ? this.offer.proposal.agencyMarkup.amount : 0),
        currency: this.offer.price.totalPrice.currency
      };
    }

    if (this.offer && !this.item) {
      return {
        amount: this.offer.price.totalPrice.amount - this.initialAgencyMarkupValue + this.agencyMarkupValue,
        currency: this.offer.price.totalPrice.currency
      };
    }

    return {
      amount: this.item.price.total.amount - this.initialAgencyMarkupValue + this.agencyMarkupValue,
      currency: this.item.price.total.currency
    };
  }

  get agencyMarkupCurrency() {
    if (this.offer && !this.item) {
      if (this.offer.price && this.offer.price.agencyMarkup && this.offer.price.agencyMarkup.currency) {
        return this.offer.price.agencyMarkup.currency.symbol ? this.offer.price.agencyMarkup.currency.symbol : this.offer.price.agencyMarkup.currency.code;
      }
    } else {
      if (this.item.price && this.item.price.agencyMarkup && this.item.price.agencyMarkup.currency) {
        return this.item.price.agencyMarkup.currency.symbol ? this.item.price.agencyMarkup.currency.symbol : this.item.price.agencyMarkup.currency.code;
      }
    }
    return '\u20AC';
  }

  get feeCurrency() {
    if (this.offer && !this.item) {
      if (this.offer.price && this.offer.price.agencyFee && this.offer.price.agencyFee.currency) {
        return this.offer.price.agencyFee.currency.symbol ? this.offer.price.agencyFee.currency.symbol : this.offer.price.agencyFee.currency.code;
      }
    } else {
      if (this.item.price && this.item.price.agencyFee && this.item.price.agencyFee.currency) {
        return this.item.price.agencyFee.currency.symbol ? this.item.price.agencyFee.currency.symbol : this.item.price.agencyFee.currency.code;
      }
    }
    return '\u20AC';
  }

  get canShowFeePriceAddon() {
    if (this.modifyMode) {
      return this.offer && this.offer.proposal.agencyFee !== undefined && this.offer.proposal.agencyFee !== null;
    } else {
      return this.item && this.item.price.agencyFee !== undefined && this.item.price.agencyFee !== null;
    }
  }

  get modifiedItemProviderPrice() {
    let item = this.basketItems.find(basketItem => {
      return basketItem.id === this.offer.tripItemId;
    });
    return item && item.price.providerPrice ? item.price.providerPrice : false;
  }

  get hasEditAgencyMarkupPermission() {
    return this.$hasAccess('CanEditAgencyMarkup');
  }

  get hasEditFeePermission() {
    return this.$hasAccess('CanEditBookingFee');
  }

  get hasAdvancedOfferInfo() {
    return this.$hasAccess('CanReadAdvancedOfferInfo');
  }

  get hasCanSeePriceDetails() {
    return this.$hasAccess('CanSeePriceDetails');
  }

  get showPriceDetailsLink() {
    return this.hasCanSeePriceDetails && !this.modifyMode && this.item.status !== 'Cancelled' && !this.showingInPopup;
  }

  get hasFeeConfiguration() {
    return !!AirSearchStore.feeConfigurationId;
  }

  get isPriceDetailedComponentsVisible() {
    return AirSearchStore.priceDetailedComponentsVisible;
  }

  get canShowEditBookingFee() {
    return this.hasEditFeePermission &&
      this.searchMode &&
      this.basketStatus === 'Draft';
  }

  get canShowEditAgencyMarkup() {
    return this.hasEditAgencyMarkupPermission &&
      this.searchMode &&
      this.basketStatus === 'Draft';
  }

  get isSabreSupplier() {
    return this.offer.supplier === 'Sabre';
  }

  get isAmadeusSupplier() {
    return this.offer.supplier === 'Amadeus';
  }

  get classesOneWay() {
    return {
      'one-way-div': this.offer && this.offer.legFlights && this.offer.legFlights.length === 1,
    };
  }



  baggageLabelInfoDetails(detail) {
    const pieces = detail.freeItems === 1 ? translate('search-air.piece') : translate('search-air.pieces');
    const weight = this.isWeightGiven(detail) ? ' ' + translate('search-air.weight-up') + ' ' + detail.allowedQuantity + ' ' + detail.unit : ' ';

    return translate('search-air.passenger-type') + ' ' + '"' + this.getTranslatedPassengerType(detail.passengerType) + '":' + ' ' + detail.freeItems + ' ' + pieces + ' ' + translate('search-air.of-baggage') + weight;
  }

  baggageLabelInfo(baggage) {
    const pieces = baggage.allowedQuantity === 1 ? translate('search-air.piece') : translate('search-air.pieces');
    const weight = baggage.unit !== null && baggage.unit !== 'pc';

    if (weight && baggage.weight) {
      return baggage.allowedQuantity + ' ' + pieces + ' ' + translate('search-air.of-baggage') + ' ' + translate('search-air.weight-up') + ' ' + baggage.weight + ' ' + baggage.unit + ' ' + translate('search-air.per-passenger');
    } else if (weight) {
      return baggage.allowedQuantity + ' ' + baggage.unit + ' ' + translate('search-air.of-baggage') + ' ' + translate('search-air.per-passenger');
    }
    return baggage.allowedQuantity + ' ' + pieces + ' ' + translate('search-air.of-baggage') + ' ' + translate('search-air.per-passenger');
  }

  get isAnyAirportDifferent() {
    if (!this.offer) {
      return false;
    }

    if (this.offer.legFlights.length === 2) {
      const firstLeg = this.offer.legFlights[0];
      const lastLeg = this.offer.legFlights[this.offer.legFlights.length - 1];
      const firstFlightOfFirstLeg = firstLeg.flights[0].from.airportCode;
      const lastFlightOfFirstLeg = firstLeg.flights[firstLeg.flights.length - 1].to.airportCode;
      const firstFlightOfLastLeg = lastLeg.flights[0].from.airportCode;
      const lastFlightOfLastLeg =  lastLeg.flights[lastLeg.flights.length - 1].to.airportCode;

      if (firstFlightOfFirstLeg !== lastFlightOfLastLeg) {
        return true;
      }

      if (lastFlightOfFirstLeg !== firstFlightOfLastLeg) {
        return true;
      }
    }

    return false;
  }



  isWeightGiven(detail) {
    if (-1 < ['kg', 'lb'].indexOf(detail.unit)) {
      return true;
    }

    return false;
  }

  getProperFeeCurrencySymbol() {
    return !this.modifyMode ?
      this.item.price.agencyFee.currency.symbol || this.item.price.agencyFee.currency.code :
      this.offer.proposal.agencyFee.currency.symbol || this.offer.proposal.agencyFee.currency.code;
  }

  toggleOffer() {
    this.showOffer = !this.showOffer;
  }

  fareLabelInfo(name) {
    return translate('search-air.fare-basis-label') + '<br>' + name;
  }

  isNdc(offer) {
    return -1 < SearchConst.ndcProviders.indexOf(offer.supplier) || offer.isNdc;
  }

  getSupplierName(name) {
    return SupplierName(name);
  }

  carriers(leg) {
    return leg.flights
      .filter(flight => flight.flightInfo)
      .map(flight => flight.flightInfo.marketingCarrier)
      .filter((value, index, self) => self.findIndex((iv) => iv.name === value.name && iv.code === value.code) === index);
  }

  legsUniqueFareLabels(leg) {
    let fareLabels = leg.flights.filter(entry => {
      return entry.type === 'Flight';
    }).map(flight => {
      return flight.fareInfo ? flight.fareInfo.fareBasisLabel : null;
    }).filter(Boolean);

    return fareLabels.length > 1 ? _.uniq(fareLabels) : fareLabels;
  }

  async registerAsExpense() {
    let itemId = '';
    let name = '';
    if (this.offer) {
      name = translate('basket.flight-to') + this.offer.legFlights[0].flights[this.offer.legFlights[0].flights.length - 1].to.cityName;
    }

    let request = {
      reporterId: this.user!.profile.id,
      policyId: this.policyId,
      companyId: this.user!.profile.rootCompanyId,
      businessUnitId: this.user!.profile.companyId,
      missionId: this.basket!.missionId,
      tripItem: {
        id: this.item.id,
        tripId: this.basketId,
        type: this.item.itemType === 'Air' ? 'Flight' : this.item.itemType,
        creationDate: this.item.createDate ? moment(this.item.createDate).format('YYYY-MM-DD') : null,
        name: name,
        supplier: this.item.supplier
      }
    };

    await BasketStore.registerAsExpense(request);

    if (this.expenseIdForBasket) {
      await BasketStore.saveConnectionTripExpense({itemId: this.item.id, expenseId: this.expenseIdForBasket});
      router.push({
        name: 'modify-expense',
        params: {
          expenseId: this.expenseIdForBasket,
        }
      });
    }
  }

  displayExpenseForItem(id) {
    router.push({
      name: 'modify-expense',
      params: {
        expenseId: id,
      }
    });
  }

  toggleDetails() {
    this.showDetails = !this.showDetails;

    this.reloadOfferDetails();
  }

  reloadOfferDetails() {
    if (this.showDetails) {
      this.loadOfferDetails();
    }
  }

  refreshFlightNumberHashState(hashes) {
    this.offer.legFlights.forEach(legFlight => {
      const selected = hashes.includes(legFlight.flightNumberHash);
      const timelineKey = `leg${legFlight.legNumber}`;
      const timelineRefs = this.$refs.flightTimelines as FlightTimeline[];
      const timeline = timelineRefs.find((x: any) => x.$vnode.key === timelineKey);

      if (timeline) {
        timeline.setFlightSelectedState(selected);
      }
    });
  }

  showFullConditions() {
    this.showFullConditionsPopup = true;
  }

  async showUpdateFeePopup() {
    this.editFeeErrors = [];
    if (!this.offer) {
      await this.loadOfferDetails();
    }
    this.showEditFeePopup = true;
  }

  closeUpdateFeePopup() {
    this.agencyFeeValue = this.item.price.agencyFee.amount;
    this.showEditFeePopup = false;
  }

  async showUpdateAgencyMarkupPopup() {
    this.editFeeErrors = [];
    if (!this.offer) {
      await this.loadOfferDetails();
    }
    this.showEditAgencyMarkupPopup = true;
  }

  closeUpdateAgencyMarkupPopup() {
    this.agencyMarkupValue = this.item.price.agencyMarkup.amount;
    this.showEditAgencyMarkupPopup = false;
  }

  filterBySsid(templates) {
    return templates.filter((temp) => {
      return temp.ssid === this.currentSsid;
    });
  }

  removeElem(arr, value) {
    return arr.filter((ele) => {
      return ele.proposal.id !== value;
    });
  }

  existInArray(offer) {
    let unique = true;
    this.templateOffers.forEach((template: any) => {
      if (template.proposal.id === offer.proposal.id) {
        unique = false;
      }
    });

    return unique;
  }

  checkPrepareoffer(offer: any) {
    if (offer.prepareOfferCheck) {
      if (this.existInArray(offer)) {
        offer.ssid = this.$route.params.searchId;
        this.templateOffers.push(offer);
      }
      let templates = this.filterBySsid(this.templateOffers);
      AirSearchStore.setTemplateOffers(templates);
    } else {
      let templates = this.filterBySsid(this.templateOffers);
      this.currentTemplateOffers = this.removeElem(templates, offer.proposal.id);
      AirSearchStore.setTemplateOffers(this.currentTemplateOffers);
    }
  }

  prepareOffers(offer) {
    if (!offer.prepareOfferCheck) {
      offer.prepareOfferCheck = true;
      (this.$refs.prepareOffer as HTMLInputElement).checked = true;
    }
    this.checkPrepareoffer(offer);
    EventBus.$emit('prepare-offer', true);
  }

  shouldFadeLeg(legIndex) {
    if (-1 === [
      'airModification',
      'airModificationTravellers'
    ].indexOf(this.$route.name || '')) {
      return false;
    }
    if (legIndex >= AirSearchStore.exchangeLegsSelectedForEdit.length) {
      return false;
    }
    return !AirSearchStore.exchangeLegsSelectedForEdit[legIndex];
  }

  async loadOfferDetails() {
    if (this.notLoadOfferDetails) {
      return;
    }
    if (!this.offer) {
      return;
    }
    if (this.offer.supplier !== 'AirFranceKlm' && this.offer.supplier !== 'BritishAirways') {
      return;
    }
    if (this.offer.supplier === 'AirFranceKlm' && this.modifyMode) {
      return;
    }

    try {
      let searchId = this.$route.params.searchId;
      const offerDetailsResponse = await OffersApi.getShortOfferDetails(this.offer.supplier, this.offer.id, this.offer.providerSearchId, searchId);
      if (offerDetailsResponse.data) {
        this.offerDetails = offerDetailsResponse.data;
      }
    } catch (error) {
      this.offerDetails = {
        error: error.response.data,
      };
    }
  }

  showPriceDetails() {
    this.showPriceDetailsPopup = true;
  }

  legOfferDetails(index) {
    if (this.offerDetails) {
      return this.offerDetails.error ? this.offerDetails : this.offerDetails.legDetails[index];
    }

    return null;
  }

  mounted() {
    this.currentSsid = this.$route.params.searchId;
    if (this.offerDetails == null) {
      this.loadOfferDetails();
    }
  }

  openModifyModal() {
    this.showEditSegmentPopup = true;
  }

  openRefundModal() {
    EventBus.$emit('open-refund-popup', this.basketItemId);
  }

  showExchangeDetails() {
    this.showExchangeDetailsPopup = true;
  }

  showMobileOfferDetails(offer) {
    router.push({
      name: 'airResultDetails',
      params: {
        searchId: this.$route.params.searchId,
        recomendationId: offer.id,
      }
    });
  }

  showSeatsView() {
    this.seatsViewPopup = true;
  }

  hideSeatsView() {
    this.seatsViewPopup = false;
  }

  commitSeatsView() {
    this.seatsViewPopup = false;
  }

  formatPrice(price) {
    return formatPrice(price);
  }

  tooltip() {
    let tooltip = '';
    if (this.item && this.item.status !== 'Cancelled' && this.showPriceTooltip) {

      if (this.item.price.rawProviderPrice) {
        tooltip += `<span>${translate('basket.raw-provider-price')} ${this.item.price.rawProviderPrice.currency.symbol || this.item.price.rawProviderPrice.currency.code} ${formatPrice(this.item.price.rawProviderPrice.amount)}</span>`;
      }
      if (this.item.price.systemMarkup) {
        tooltip += `<span class="mb-3">${translate('basket.system-markup')} ${this.item.price.systemMarkup.currency.symbol || this.item.price.systemMarkup.currency.code} ${formatPrice(this.item.price.systemMarkup.amount)}</span>`;
      }

      if (this.hasAdvancedOfferInfo && this.item && this.modifiedItemProviderPrice) {
        tooltip = `<span>${translate('basket.incl')} ${this.modifiedItemProviderPrice.currency.symbol || this.modifiedItemProviderPrice.currency.code} ${formatPrice(this.modifiedItemProviderPrice.amount)} ${translate('search-air.provider-price')}`;

        if (this.offer && this.offer.price.taxPrice != null) {
          tooltip += ` (${this.offer.price.taxPrice.currency.symbol || this.offer.price.taxPrice.currency.code} ${formatPrice(this.offer.price.taxPrice.amount)} ${translate('search-air.taxes')})`;
        }
        tooltip += `</span>`;
      } else if ((this.hasAdvancedOfferInfo || this.hasEditFeePermission) && this.offer.price.totalPrice) {
        tooltip += `<span>${translate('basket.incl')} ${this.offer.price.totalPrice.currency.symbol || this.offer.price.totalPrice.currency.code} ${formatPrice(this.offer.price.totalPrice.amount)} ${translate('search-air.provider-price')}`;

        if (this.offer && this.offer.price.taxPrice != null) {
          tooltip += ` (${this.offer.price.taxPrice.currency.symbol || this.offer.price.taxPrice.currency.code} ${formatPrice(this.offer.price.taxPrice.amount)} ${translate('search-air.taxes')})`;
        }
        tooltip += `</span>`;
      }

      if (this.item.price.agencyMarkup) {
        tooltip += `<span>${translate('basket.incl')} ${this.item.price.agencyMarkup.currency.symbol || this.item.price.agencyMarkup.currency.code} ${formatPrice(this.item.price.agencyMarkup.amount)} ${translate('basket.agency-markup-smallcaps')}</span>`;
      }

      if ((this.isPriceDetailedComponentsVisible || this.hasEditFeePermission || this.hasAdvancedOfferInfo) && (this.modifyMode ? this.offer.proposal.agencyFee : this.item.price.agencyFee)) {
        tooltip += `<span>${translate('basket.incl')} ${this.modifyMode ? (this.offer.price.totalPrice.currency.symbol || this.offer.price.totalPrice.currency.code) : (this.item.price.total.currency.symbol || this.item.price.total.currency.code)} ${formatPrice(this.modifyMode ? this.offer.proposal.agencyFee.amount : this.item.price.agencyFee.amount)} ${translate('search-air.fee')}</span>`;
      }
    }
    return tooltip;
  }

  getTranslatedPassengerType(type) {
    if (!this.passengerTypeMap[type]) {
      return type;
    }

    return translate(this.passengerTypeMap[type]);
  }

  async agencyFeeChanged(value) {
    this.agencyFeeValue = value;
  }

  onItemFeeChange() {
    this.agencyFeeValue = this.item.price.agencyFee ? this.item.price.agencyFee.amount : 0;
    this.initialAgencyFeeValue = this.agencyFeeValue;
  }

  async saveOfferFee() {
    if (this.editFeeLoading) {
      return;
    }
    try {
      this.editFeeLoading = true;
      let agencyFee = {
        amount: this.agencyFeeValue,
        currency: this.item.price.agencyFee.currency,
      };
      const response = await BasketItemApi.updateFeeOnItem({ basketId: this.basketId, tripItemId: this.item.id, agencyFee });
      if (response && response.data && response.data.items) {
        BasketStore.updatePricesForBasketItemsAndPaymentMethods(response.data.items);
        await BasketStore.reloadWizardSteps();
        EventBus.$emit('show-toast', {
          type: translate('common.success'),
          message: translate('settings-fees.prices-updated')
        });
        this.initialAgencyFeeValue = this.agencyFeeValue;
      }
      this.showEditFeePopup = false;
    } catch (error) {
      this.editFeeErrors = this.$handleErrors(error, true);
      this.agencyFeeValue = this.item.price.agencyFee.amount;
    } finally {
      this.editFeeLoading = false;
    }
  }

  initiateAgencyFeeValue() {
    if (this.modifyMode && this.offer.proposal && this.offer.proposal.agencyFee) {
      this.agencyFeeValue = this.offer.proposal.agencyFee.amount;
    } else if (!this.modifyMode && this.item && this.item.price.agencyFee) {
      this.agencyFeeValue = this.item.price.agencyFee.amount;
    }
    this.initialAgencyFeeValue = this.agencyFeeValue;
  }

  async agencyMarkupChanged(value) {
    this.agencyMarkupValue = value;
  }

  onItemMarkupChange() {
    this.agencyMarkupValue = this.item.price.agencyFee ? this.item.price.agencyMarkup.amount : 0;
    this.initialAgencyMarkupValue = this.agencyMarkupValue;
  }

  async saveOfferAgencyMarkup() {
    if (this.editFeeLoading) {
      return;
    }
    try {
      this.editFeeLoading = true;
      let agencyMarkup = {
        amount: this.agencyMarkupValue,
        currency: this.item.price.agencyMarkup.currency,
      };
      const response = await BasketItemApi.updateAgencyMarkupOnItem({
        basketId: this.basketId,
        tripItemId: this.item.id,
        agencyMarkup,
      });
      if (response && response.data && response.data.items) {
        BasketStore.updatePricesForBasketItemsAndPaymentMethods(response.data.items);
        await BasketStore.reloadWizardSteps();
        EventBus.$emit('show-toast', {
          type: translate('common.success'),
          message: translate('settings-fees.prices-updated')
        });
        this.initialAgencyMarkupValue = this.agencyMarkupValue;
      }
      this.showEditAgencyMarkupPopup = false;
    } catch (error) {
      this.editFeeErrors = this.$handleErrors(error, true);
      this.agencyMarkupValue = this.item.price.agencyMarkup.amount;
    } finally {
      this.editFeeLoading = false;
    }
  }

  initiateAgencyMarkupValue() {
    if (this.modifyMode && this.offer.proposal && this.offer.proposal.agencyMarkup) {
      this.agencyMarkupValue = this.offer.proposal.agencyMarkup.amount;
    } else if (!this.modifyMode && this.item && this.item.price.agencyMarkup) {
      this.agencyMarkupValue = this.item.price.agencyMarkup.amount;
    }
    this.initialAgencyMarkupValue = this.agencyMarkupValue;
  }

  @Watch('loading')
  onLoading(value) {
    if (!value) {
      this.showDetails = false;
      this.showOffer = true;

      if (!this.height) {
        this.$nextTick(() => {
          const rect = (this.$refs.mainCard as HTMLElement).getBoundingClientRect();
          this.height = rect.height;
        });
      }
    }
  }

  created() {
    this.initiateAgencyFeeValue();
    this.initiateAgencyMarkupValue();
    EventBus.$on('basket-item-fee-changed', this.onItemFeeChange);
  }

  beforeDestroy() {
    EventBus.$off('basket-item-fee-changed', this.onItemFeeChange);
  }
}






































































import { Component, Prop, Vue } from 'vue-property-decorator';
import priceConst from '@/const/price.const';
import { Traveller } from '@/api/trip/basket.model';
import { TRailSeatSelectionsModel } from '@/modules/basket/basket-rail-seats-extras/basket-rail-seats-extras.model';
import SeatMapTrenitaliaPopup from '@/modules/basket/basket-rail-seats-extras/popup/SeatMapTrenitaliaPopup.vue';
import BasketStore from '@/modules/basket/basket.store';

@Component({
  components: {
    SeatMapTrenitaliaPopup,
  }
})
export default class SeatsTrenitaliaContent extends Vue {
  @Prop() value!: TRailSeatSelectionsModel;
  @Prop() traveller!: Traveller;
  @Prop() segmentId!: string;

  showSeatMapPopup: boolean = false;

  get seatMap() {
    return BasketStore.basketRailSeatsMap.find((item) => item.segmentId === this.segmentId);
  }

  get seatSelectionModel() {
    return this.value.seatSelections.find((item) => item.profileId === this.traveller.id && item.segmentId === this.segmentId);
  }

  get coachNumberModel() {
    return this.seatSelectionModel && this.seatSelectionModel.coachNumber;
  }

  get seatNumberModel() {
    return this.seatSelectionModel && this.seatSelectionModel.seatNumber;
  }

  get seatSelectionPrice() {
    const coach = this.seatMap && this.seatMap.coaches.find((coach) => coach.number === this.coachNumberModel);
    if (!coach) {
      return priceConst.priceModel;
    }
    const seatMapItem = coach.seatMapItems.find((item) => item.seatNumber === this.seatNumberModel);
    if (!seatMapItem) {
      return priceConst.priceModel;
    }
    const seatPrice = (seatMapItem.prices || []).find((item) => item.travellerId === this.traveller.id);
    return (seatPrice && seatPrice.price) || priceConst.priceModel;
  }

  onSelectedItemRemove() {
    const seatSelections = this.value.seatSelections
      .filter((item) => !(item.profileId === this.traveller.id && item.segmentId === this.segmentId));
    this.$emit('input', { seatSelections });
  }

  openSeatMapPopup() {
    this.showSeatMapPopup = true;
  }

  onPopupInput(value) {
    this.$emit('input', value);
  }

  selectAssignedSeat() {
    const seatSelection = this.value.seatSelections.find((item) => item.profileId === this.traveller.id && item.segmentId === this.segmentId);
    if (seatSelection) {
      return;
    }
    if (!this.seatMap) {
      return;
    }
    const seat = this.seatMap.assignedSeats.find((item) => item.travellerId === this.traveller.id);
    if (seat && seat.seatNumber && seat.coachNumber && seat.travellerId === this.traveller.id) {
      this.value.seatSelections = this.value.seatSelections.concat({
        alternateNumber: seat.alternateNumber,
        coachNumber: seat.coachNumber,
        profileId: this.traveller.id,
        seatNumber: seat.seatNumber,
        segmentId: this.segmentId,
      });
    }
  }

  mounted() {
    this.selectAssignedSeat();
  }
}



















































































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { router } from '@/router';
import { BasketFlatItemModel } from '@/api/trip/basket.model';
import { IRawBasketAirOffer, IBasketAirOffer } from '@/api/air-engine/air-search.model';
import { OffersApi } from '@/api/air-engine/offers.api';
import { TripApi } from '@/api/trip/trip.api';
import BasketAirOffer from '@/modules/basket/BasketAirOffer.vue';
import BasketStore from '@/modules/basket/basket.store';

@Component({
  components: {
    BasketAirOffer,
  },
})
export default class BasketSyncAirBookingSuccessPopup extends Vue {
  @Prop() show!: boolean;
  @Prop() item!: BasketFlatItemModel;
  @Prop() isDisableBookingDataInBackOffice!: boolean;

  errors: any[] = [];
  loading: boolean = false;
  needUpdateTripBookingDataInBackOffice: boolean = false;
  needSendTripItineraryEmailToTravellers: boolean = false;
  previousOffer: IBasketAirOffer | null = null;
  currentOffer: IBasketAirOffer | null = null;

  @Emit('update:show')
  closePopup() {
    return false;
  }

  async continueSynchronization({
    needUpdateTripBookingDataInBackOffice,
    needSendTripItineraryEmailToTravellers,
  }: {
    needUpdateTripBookingDataInBackOffice: boolean,
    needSendTripItineraryEmailToTravellers: boolean,
  }) {
    try {
      this.loading = true;
      await TripApi.postConfirmSynchronization(this.item.id, router.currentRoute.params.id, {
        needUpdateTripBookingDataInBackOffice,
        needSendTripItineraryEmailToTravellers,
      });
      this.closePopup();
      await BasketStore.processBasket();
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.loading = false;
    }
  }

  getMappedOffer(offer: IRawBasketAirOffer): IBasketAirOffer {
    return {
      ...offer,
      proposal: {
        ...offer,
        price: offer.price.totalPrice,
        pricePlusMarkup: offer.price.totalPrice,
      },
      travelPolicy: this.item.travelPolicy,
    };
  }

  @Watch('show', { immediate: true })
  async onShow(show: boolean) {
    if (show) {
      try {
        this.loading = true;
        await BasketStore.updateBasket();
        const { data } = await OffersApi.getOffersLatestCompare(this.item.supplier, this.item.providerReferenceId);
        this.previousOffer = this.getMappedOffer(data.previousOffer);
        this.currentOffer = this.getMappedOffer(data.currentOffer);
      } catch (error) {
        this.errors = this.$handleErrors(error);
      } finally {
        this.loading = false;
      }
    }
  }
}










































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { requiredIf } from 'vuelidate/lib/validators';

import { translate } from '@/i18n';
import BasketStore from '@/modules/basket/basket.store';
import { OfferParameter, SupplierParameterOption } from '@/api/trip/basket-item.model';
import { Validation } from 'vue-plugin-helper-decorator';
import EventBus from '@/services/event-handler';

const updateValue: string = 'update:parameter.value';

@Component({
  name: 'parameter-singleselect'
})
export default class ParameterSingleselect extends Vue {
  @Prop() parameter!: OfferParameter;

  @Validation()
  validationObject() {
    return {
      paramValue: {
        required: requiredIf(() => {
          return this.parameter.isRequired;
        }),

        key: {
          required: requiredIf(() => {
            return this.parameter.isRequired;
          }),
        },
      }
    };
  }

  get isEditable() {
    return BasketStore.status && BasketStore.status.status !== 'Draft';
  }

  get displayText() {
      return this.parameter.displayText ? this.parameter.displayText : this.parameter.key;
  }

  get paramValue() {
    return this.parameterOptions.find(opt => opt.key === this.parameter.value) as SupplierParameterOption | null;
  }

  get parameterOptions() {
    return [{
      displayText: translate('basket.ancillary-none'),
      key: null,
      price: null,
    }, ...this.parameter.options];
  }

  @Emit()
  [updateValue](value) {
    return value;
  }

  set paramValue(value: SupplierParameterOption | null) {
    this.parameter.value = value ? value.key : null;
    this[updateValue](this.parameter.value);
    this.$emit('update', this.parameter.value);

    BasketStore.updateItemParameterValue(this.parameter);
  }

  onReceive() {
    this.$v.paramValue!.$touch();
    if (this.$v.paramValue!.$pending || this.$v.paramValue!.$error) {
      BasketStore.setValidCustomParameters(false);
    }
  }

  created() {
    EventBus.$on('custom-parameter-form', this.onReceive);
  }

  beforeDestroy() {
    EventBus.$off('custom-parameter-form', this.onReceive);
  }
}

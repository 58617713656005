




























































































































import { Vue, Component, Watch, Prop, Emit } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { maxLength } from 'vuelidate/lib/validators';
import { PreferencesApi } from '@/api/profile/preferences.api';
import { PreferencesModel } from '@/api/profile/preferences.model';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';

@Component({})
export default class AccommodationPreferencesPopup extends Vue {
  @Prop() preferences!: any;
  serverErrors: any[] = [];
  hotelChainsLoading: boolean = false;
  hotelChainsOption: any = [];
  formPending: boolean = false;
  Form: PreferencesModel = {
    accommodationPreferences: {
      mealPlan: null,
      generalInformations: '',
      hotelChains: [],
      roomTypes: [],
    },
  };
  $v;
  mealPlanOptions = [
    {
      value: 'RoomOnly',
      name: translate('profile-preferences.room-only'),
    },
    {
      value: 'Breakfast',
      name: translate('profile-preferences.breakfast'),
    },
  ];

  roomTypesOption = [
    {
      code: 'SINGLE',
      name: translate('profile-preferences.single-room'),
    },
    {
      code: 'DOUBLE',
      name: translate('profile-preferences.double-room'),
    },
    {
      code: 'TWIN',
      name: translate('profile-preferences.twin-room'),
    },
  ];

  @Validation()
  validationObject() {
    return {
      Form: {
        accommodationPreferences: {
          generalInformations: {
            maxLength: maxLength(2048),
          }
        }
      },
    };
  }

  roomTypeMaper(rooms) {
    let obj: any[] = [];
    if (rooms && rooms.length) {
      rooms.forEach(room => {
        this.roomTypesOption.forEach((type: any) => {
          if (type.code === room) {
            obj.push(type);
          }
        });
      });

    }
    return obj;
  }

  updateFormData(data) {
    if (data) {
      this.Form = {
        accommodationPreferences: {
          mealPlan: data.mealPlan !== 'Undefined' ? this.mealPlanOptions.find(type => type.value === data.mealPlan) : null,
          generalInformations: data.generalInformations,
          hotelChains: data.hotelChains,
          roomTypes: this.roomTypeMaper(data.roomTypes),
        }
      };
    }
  }

  focusOnNext() {
    setTimeout(() => {
      const btn = ((this.$refs.saveButton as Vue).$el as HTMLInputElement);
      btn.focus();
    }, 50);
  }

  @Emit()
  close() {
  }

  async save() {
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error) {
      return;
    }

    try {
      if (this.preferences) {
        let profileId = this.$route.params.id;
        let request = {
          travellerType: this.preferences.travellerType,
          currencyCode: this.preferences.currencyCode,
          flightPreferences: {
            seatType: this.preferences.flightPreferences!.seatType,
            generalInformations: this.preferences.flightPreferences!.generalInformations,
            carriers: this.preferences.flightPreferences!.carriers.map((carrier: any) => carrier.code),
            departureAirports: this.preferences.flightPreferences!.departureAirports.map(departure => departure.code),
            arrivalAirports: this.preferences.flightPreferences!.arrivalAirports.map(arrival => arrival.code),
          },
          railPreferences: {
            ...this.preferences.railPreferences,
            seatType: this.preferences.railPreferences!.seatType,
          },
          accommodationPreferences: {
            mealPlan: this.Form.accommodationPreferences!.mealPlan ? this.Form.accommodationPreferences!.mealPlan.value : null,
            generalInformations: this.Form.accommodationPreferences!.generalInformations,
            hotelChains: this.Form.accommodationPreferences!.hotelChains,
            roomTypes: this.Form.accommodationPreferences!.roomTypes ? this.Form.accommodationPreferences!.roomTypes.map(room => room.code ? room.code : room) : [],
          },
          carPreferences: {
            ...this.preferences.carPreferences
          },
        };

        await PreferencesApi.updatePreferences(profileId, request);
      }
      let obj = {
        type: 'success',
        title: translate('profile-personal.data-saved'),
        message: translate('profile-personal.accommodation-saved')
      };
      EventHandler.$emit('show-toast', obj);
      EventHandler.$emit('reload-loyalty');
      EventHandler.$emit('profile-data-saved');
      this.close();
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  created() {
    if (this.preferences && this.preferences.accommodationPreferences) {
      this.updateFormData(this.preferences.accommodationPreferences);
    }
  }

}























































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import SettingsStore from '@/modules/settings/settings.store';
import { router } from '@/router';
import { translate } from '@/i18n';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import NegoFaresStore from '@/modules/settings/nego-fare/nego-fares.store';
import { AirNegoFareConfigurationSupplierEnum } from '@/api/nego-fares/nego-fares.model';
import { CorporateCodeOwnerEnum, CorporateCodeTypeEnum, AirlineIdEnum, LeasureFareTypeEnum } from '@/api/air-engine/nego-fares-configuration.model';
import { AirLine } from '@/api/dictionary/dictionary.model';
import NegoFaresFlightStore from '@/modules/settings/nego-fare/nego-fares-flight.store';
import DictionaryStore from '@/store/dictionary.store';
import _ from 'lodash';
import { SupplierName } from '@/services/supplier-name.service';
import NegoFarePnrRemarksList from './NegoFarePnrRemarksList.vue';
import NegoFarePnrSsrCodes from './NegoFarePnrSsrCodes.vue';

const incorrectCode = (value) => {
  return /\b[a-zA-Z]{3}\d{2}\b/.test(value);
};

@Component({
  components: {
    NegoFarePnrRemarksList,
    NegoFarePnrSsrCodes,
  }
})
export default class NegoFareAirCodeForm extends Vue {
  $v;
  validated: boolean = false;
  formMapped: boolean = false;
  allAirline: any[] = [];
  airlines: any[] = [];

  providerOptions = [
    {
      label: SupplierName(AirNegoFareConfigurationSupplierEnum.Sabre),
      value: AirNegoFareConfigurationSupplierEnum.Sabre,
    },
    {
      label: SupplierName(AirNegoFareConfigurationSupplierEnum.Lufthansa),
      value: AirNegoFareConfigurationSupplierEnum.Lufthansa,
    },
    {
      label: SupplierName(AirNegoFareConfigurationSupplierEnum.AirFranceKlm),
      value: AirNegoFareConfigurationSupplierEnum.AirFranceKlm,
    },
    {
      label: SupplierName(AirNegoFareConfigurationSupplierEnum.Amadeus),
      value: AirNegoFareConfigurationSupplierEnum.Amadeus,
    },
    {
      label: SupplierName(AirNegoFareConfigurationSupplierEnum.AmericanAirlines),
      value: AirNegoFareConfigurationSupplierEnum.AmericanAirlines,
    },
    {
      label: SupplierName(AirNegoFareConfigurationSupplierEnum.BritishAirways),
      value: AirNegoFareConfigurationSupplierEnum.BritishAirways,
    },
    {
      label: SupplierName(AirNegoFareConfigurationSupplierEnum.Emirates),
      value: AirNegoFareConfigurationSupplierEnum.Emirates,
    },
  ];

  codeOwnerOptions = [
    {
      label: translate('settings-nego-fare.uppercase-tmc-type'),
      value:  CorporateCodeOwnerEnum.Tmc,
    },
    {
      label: translate('settings-nego-fare.client-type'),
      value: CorporateCodeOwnerEnum.Client,
    },
  ];

  leasureFareTypeOptions = [
    {
      label: translate('settings-nego-fare.tour-operator-deferred'),
      value: LeasureFareTypeEnum.Tod,
    },
    {
      label: translate('settings-nego-fare.tour-operator-semi-deferred'),
      value: LeasureFareTypeEnum.Tosd,
    },
    {
      label: translate('settings-nego-fare.contract-bulk'),
      value: LeasureFareTypeEnum.Cb,
    },
    {
      label: translate('settings-nego-fare.private-fare-adult'),
      value: LeasureFareTypeEnum.Pfa,
    },
    {
      label: translate('settings-nego-fare.humanitarian'),
      value: LeasureFareTypeEnum.Ht,
    },
  ];

  Form: any = {
    supplier: null,
    code: '',
    codeType: null,
    description: '',
    codeOwner: null,
    useSpecialTypesOfPax: false,
    companyIndex: '',
    anyAirline: false,
    osiMessage: '',
    pnrRemarks: [],
    pnrSsrCodes: [],
  };

  @Validation()
  validationObject() {

    let code: any = {
      maxLength: this.codeSupplierValidator
    };

    // attn.: leasure fare type is mapped into code
    if (this.isCodeRequired || this.shouldShowLeasureFareType) {
      code.required = required;
    }

    if (this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Sabre && this.Form.codeType && this.Form.codeType.value === CorporateCodeTypeEnum.CorporateId) {
      code.incorrectCode = incorrectCode;
    }

    return {
      Form: {
        supplier: {
          required,
        },
        code,
        codeType: {
          required: requiredIf(() => {
            return this.shouldShowCodeType;
          })
        },
        airlines: {
          required: requiredIf(() => {
            return this.shouldShowAirlinesRequired;
          })
        },
        codeOwner: {
          required: requiredIf(() => {
            return this.shouldShowCodeOwner;
          }),
        },
        description: {
          maxLength: maxLength(246),
        },
        companyIndex: {
          required: requiredIf(() => {
            return this.shouldShowCompanyIndex;
          }),
        },
        osiMessage: {
          maxLength: maxLength(70)
        }
      }
    };
  }

  get supplier() {
    return this.providerOptions.find(provider => provider.value === this.Form.supplier) || null;
  }

  get codeSupplierValidator() {
    let sabre = this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Sabre;
    let amadeus = this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Amadeus;
    let america = this.Form.supplier === AirNegoFareConfigurationSupplierEnum.AmericanAirlines;
    let british = this.Form.supplier === AirNegoFareConfigurationSupplierEnum.BritishAirways;
    let emirates = this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Emirates;

    if (sabre) {
      return this.Form.codeType && this.Form.codeType.value === CorporateCodeTypeEnum.CorporateId ? maxLength(5) : maxLength(20);
    }

    if (amadeus) {
      if (this.Form.codeType && this.Form.codeType.value === CorporateCodeTypeEnum.OsiMessage) {
        return maxLength(67);
      }

      return this.Form.codeType && this.Form.codeType.value === CorporateCodeTypeEnum.AccountCode ? maxLength(6) : maxLength(20);
    }

    if (america) {
      return this.Form.codeType && this.Form.codeType.value === CorporateCodeTypeEnum.AccountCode ? maxLength(6) : maxLength(20);
    }

    if (emirates) {
      return this.Form.codeType && this.Form.codeType.value === CorporateCodeTypeEnum.AccountCode ? maxLength(6) : maxLength(20);
    }

    if (british) {
      if (this.Form.codeType && this.Form.codeType.value === CorporateCodeTypeEnum.Leasure) {
        return maxLength(20);
      }

      return maxLength(6);
    }

    return maxLength(40);
  }

  set supplier(value) {
    if (value == null) {
      this.Form.supplier = null;
      return;
    }
    this.Form.supplier = value.value;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get currentConfigurationName() {
    return NegoFaresStore.configurationName;
  }

  get service() {
    return this.$route.params.service;
  }

  get selectedCode() {
    return NegoFaresFlightStore.currentFlightCode;
  }

  get codeExists() {
    return this.selectedCode && Object.keys(this.selectedCode).length > 0;
  }

  get providers() {
    return AirNegoFareConfigurationSupplierEnum;
  }

  get isCodeRequired() {
    return this.shouldShowCode && !this.shouldShowUseSpecialTypesOfPax && (this.Form.codeType !== null && this.Form.codeType.value !== 'Vfr');
  }

  get getMaxLength() {
    if (this.Form.supplier === this.providerOptions[0].value) {
      return this.Form.codeType && this.Form.codeType.value === CorporateCodeTypeEnum.CorporateId ? 5 : 20;
    }

    if (this.Form.supplier === this.providerOptions[3].value) {
      if (this.Form.codeType && this.Form.codeType.value === CorporateCodeTypeEnum.OsiMessage) {
        return 67;
      }

      return this.Form.codeType && this.Form.codeType.value === CorporateCodeTypeEnum.AccountCode ? 6 : 20;
    }

    if (this.Form.supplier === this.providerOptions[4].value) {
      return this.Form.codeType && this.Form.codeType.value === CorporateCodeTypeEnum.AccountCode ? 6 : 20;
    }

    if (this.Form.supplier === this.providerOptions[5].value) {
      return this.Form.codeType && this.Form.codeType.value === CorporateCodeTypeEnum.Corporate ? 6 : 20;
    }

    if (this.Form.supplier === this.providerOptions[6].value) {
      return this.Form.codeType && this.Form.codeType.value === CorporateCodeTypeEnum.AccountCode ? 6 : 20;
    }

    return 40;
  }

  get isMultipleAirlineId() {
    return (this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Amadeus &&
      this.Form.codeType &&
      (this.Form.codeType.value === CorporateCodeTypeEnum.AccountCode ||
      this.Form.codeType.value === CorporateCodeTypeEnum.OsiMessage)) ||
      (this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Sabre &&
      this.Form.codeType &&
      this.Form.codeType.value === CorporateCodeTypeEnum.OsiMessage);
  }

  get shouldShowAnyAirline() {
    return (this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Sabre &&
      this.Form.codeType &&
      this.Form.codeType.value === CorporateCodeTypeEnum.OsiMessage);
  }

  get codeTypeOptions() {
    if (this.isSabreSelected) {
      return [
        {
          value: CorporateCodeTypeEnum.CorporateId,
          label: translate('settings-nego-fare.corporate-id')
        },
        {
          value: CorporateCodeTypeEnum.Seaman,
          label: CorporateCodeTypeEnum.Seaman
        },
        {
          value: CorporateCodeTypeEnum.AccountCode,
          label: translate('settings-nego-fare.account-code')
        },
        {
          value: CorporateCodeTypeEnum.OsiMessage,
          label: translate('settings-nego-fare.osi-message')
        }
      ]; 
    }

    if (this.isAmadeusSelected) {
      return [
        {
          value: CorporateCodeTypeEnum.AccountCode,
          label: translate('settings-nego-fare.account-code')
        },
        {
          value: CorporateCodeTypeEnum.Clid,
          label: 'CLID'
        },
        {
          value: CorporateCodeTypeEnum.OsiMessage,
          label: translate('settings-nego-fare.osi-message')
        }
      ]; 
    }

    if (this.isLufthansaSelected) {
      return [
        {
          value: CorporateCodeTypeEnum.AccountCode,
          label: translate('settings-nego-fare.account-code')
        },
        {
          value: CorporateCodeTypeEnum.Clid,
          label: 'CLID'
        },
        {
          value: CorporateCodeTypeEnum.PartnerPlusBenefits,
          label: 'Partner Plus Benefits'
        }
      ]; 
    }

    if (this.isAirFranceKlmSelected) {
      return [
        {
          value: CorporateCodeTypeEnum.CorporateId,
          label: translate('settings-nego-fare.corporate')
        },
        {
          value: CorporateCodeTypeEnum.TourOperator,
          label: translate('settings-nego-fare.tour-operator')
        },
        {
          value: CorporateCodeTypeEnum.Vfr,
          label: translate('settings-nego-fare.vfr')
        },
        {
          value: CorporateCodeTypeEnum.Nam,
          label: translate('settings-nego-fare.nam')
        }
      ];
    }

    if (this.isAmericanAirlinesSelected) {
      return [
        {
          value: CorporateCodeTypeEnum.AccountCode,
          label: translate('settings-nego-fare.account-code')
        },
        {
          value: CorporateCodeTypeEnum.Clid,
          label: 'CLID'
        },
        {
          value: CorporateCodeTypeEnum.DubbedAAdvantageBusiness,
          label: 'AAdvantage Business'
        }
      ];
    }

    if (this.isBritishAirwaysSelected) {
      return [
        {
          value: CorporateCodeTypeEnum.Negotiated,
          label: translate('settings-nego-fare.negotiated')
        },
        {
          value: CorporateCodeTypeEnum.Corporate,
          label: translate('settings-nego-fare.corporated')
        },
        {
          value: CorporateCodeTypeEnum.Leasure,
          label: translate('settings-nego-fare.leasure')
        }
      ];
    }

    if (this.isEmiratesSelected) {
      return [
        {
          value: CorporateCodeTypeEnum.AccountCode,
          label: translate('settings-nego-fare.account-code')
        },
        {
          value: CorporateCodeTypeEnum.ContractId,
          label: 'Contract Id'
        },
      ];
    }

    return [];
  }

  get isSabreSelected() {
    return this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Sabre;
  }

  get isLufthansaSelected() {
    return this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Lufthansa;
  }

  get isAirFranceKlmSelected() {
    return this.Form.supplier === AirNegoFareConfigurationSupplierEnum.AirFranceKlm;
  }

  get isAmadeusSelected() {
    return this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Amadeus;
  }

  get isAmericanAirlinesSelected() {
    return this.Form.supplier === AirNegoFareConfigurationSupplierEnum.AmericanAirlines;
  }

  get isBritishAirwaysSelected() {
    return this.Form.supplier === AirNegoFareConfigurationSupplierEnum.BritishAirways;
  }

  get isEmiratesSelected() {
    return this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Emirates;
  }

  get shouldShowCodeType() {
    return this.isSabreSelected || this.isLufthansaSelected || this.isAirFranceKlmSelected || this.isAmadeusSelected || this.isAmericanAirlinesSelected || this.isBritishAirwaysSelected || this.isEmiratesSelected;
  }

  get shouldShowAltCodeLabel() {
    return this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Sabre &&
        this.Form.codeType.value === CorporateCodeTypeEnum.CorporateId;
  }

  get shouldShowAirlines() {
    return this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Lufthansa ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.AirFranceKlm ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Amadeus ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.AmericanAirlines ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.BritishAirways ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Emirates ||
      (this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Sabre &&
      this.Form.codeType &&
      this.Form.codeType.value === CorporateCodeTypeEnum.OsiMessage);
  }

  get shouldShowAirlinesRequired() {
    return this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Lufthansa ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.AirFranceKlm ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.AmericanAirlines ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.BritishAirways ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Emirates ||
      (this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Amadeus && this.Form.codeType && this.Form.codeType.value === 'OsiMessage') ||
      (this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Sabre && !this.Form.anyAirline && this.Form.codeType && this.Form.codeType.value === 'OsiMessage');
  }

  get classes() {
    return {
      'col-md-3 col-sm-12' : this.providerList || (this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Amadeus && this.Form.codeType && this.Form.codeType.value === 'Clid'),
      'col-md-6 col-sm-12': this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Sabre && this.Form.codeType && (this.Form.codeType.value === 'OsiMessage'),
      'col-md-9 col-sm-12': this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Amadeus && this.Form.codeType && (this.Form.codeType.value === 'AccountCode' || this.Form.codeType.value === 'OsiMessage'),
    };
  }

  get shouldDisableAirlines() {
    return this.Form.anyAirline || !this.supplier;
  }

  get providerList() {
    return this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Lufthansa ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.AirFranceKlm ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Sabre ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.AmericanAirlines ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.BritishAirways ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Emirates;
  }

  get shouldShowCode() {
    return this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Lufthansa ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Amadeus ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.AirFranceKlm ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.AmericanAirlines ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Emirates ||
      !!(this.Form.supplier === AirNegoFareConfigurationSupplierEnum.BritishAirways && this.Form.codeType && this.Form.codeType.value === CorporateCodeTypeEnum.Corporate) ||
      !!(this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Sabre && this.Form.codeType);
  }

  get shouldShowCodeOwner() {
    return this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Lufthansa ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Amadeus ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.AirFranceKlm ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.AmericanAirlines ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.BritishAirways ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Emirates ||
      (this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Sabre && this.Form.codeType);
  }

  get shouldShowCompanyIndex() {
      return this.Form.supplier === AirNegoFareConfigurationSupplierEnum.AirFranceKlm
          && this.Form.codeType
          && this.Form.codeType.value === CorporateCodeTypeEnum.Nam;
  }

  get shouldShowDescription() {
    return this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Lufthansa ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Amadeus ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.AirFranceKlm ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.AmericanAirlines ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.BritishAirways ||
      this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Emirates ||
      (this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Sabre && this.Form.codeType);
  }

  get shouldShowOsiMessages() {
    return this.isAmadeusSelected &&
        this.Form.codeType &&
        this.Form.codeType.value === CorporateCodeTypeEnum.AccountCode;
  }

  get shouldShowPnrRemarksList() {
    return (this.isAmadeusSelected && this.Form.codeType && (this.Form.codeType.value === CorporateCodeTypeEnum.AccountCode || this.Form.codeType.value === CorporateCodeTypeEnum.Clid)) || (this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Sabre &&
      this.Form.codeType &&
      this.Form.codeType.value !== CorporateCodeTypeEnum.OsiMessage);
  }

  get shouldShowPnrSsrCodes() {
    return (this.isAmadeusSelected && this.Form.codeType && (this.Form.codeType.value === CorporateCodeTypeEnum.AccountCode || this.Form.codeType.value === CorporateCodeTypeEnum.Clid)) || (this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Sabre &&
      this.Form.codeType &&
      this.Form.codeType.value !== CorporateCodeTypeEnum.OsiMessage);
  }

  get shouldShowUseSpecialTypesOfPax() {
    return this.isAirFranceKlmSelected && this.Form.codeType && (this.Form.codeType.value === CorporateCodeTypeEnum.TourOperator || this.Form.codeType.value === CorporateCodeTypeEnum.Vfr);
  }

  get shouldShowLeasureFareType() {
    return this.Form.supplier === AirNegoFareConfigurationSupplierEnum.BritishAirways && this.Form.codeType && this.Form.codeType.value === CorporateCodeTypeEnum.Leasure;
  }

  get leasureFareType() {
    return this.leasureFareTypeOptions.find(option => option.value === this.Form.code);
  }

  set leasureFareType(option) {
    this.Form.code = option ? option.value : null;
  }

  get airlinesOptionsLufthansa() {
    return [
      {
        code: AirlineIdEnum.LH,
        businessName: this.getAirlineString(AirlineIdEnum.LH)
      },
      {
        code: AirlineIdEnum.LX,
        businessName: this.getAirlineString(AirlineIdEnum.LX)
      },
      {
        code: AirlineIdEnum.OS,
        businessName: this.getAirlineString(AirlineIdEnum.OS)
      },
      {
        code: AirlineIdEnum.SN,
        businessName: this.getAirlineString(AirlineIdEnum.SN)
      },
      {
        code: AirlineIdEnum.BA,
        businessName: this.getAirlineString(AirlineIdEnum.BA)
      },
    ];
  }

  get airlinesOptionsAirFranceKlm() {
    return [
      {
        code: AirlineIdEnum.AF,
        businessName: this.getAirlineString(AirlineIdEnum.AF)
      },
    ];
  }

  get airlinesOptionsAmericanAirlines() {
    return [
      {
        code: AirlineIdEnum.AA,
        businessName: this.getAirlineString(AirlineIdEnum.AA)
      },
    ];
  }

  get airlinesOptionsEmirates() {
    return [
      {
        code: AirlineIdEnum.EK,
        businessName: this.getAirlineString(AirlineIdEnum.EK)
      },
    ];
  }

  onAnyAirlineChange(value) {
    if (value) {
      this.Form.airlines = [];
    }
  }

  get airlinesOptionsBritishAirways() {
    return [
      {
        code: AirlineIdEnum.BA,
        businessName: this.getAirlineString(AirlineIdEnum.BA)
      }
    ];
  }

  updatePnrRemarksList(list) {
    this.Form.pnrRemarks = list;
  }

  updatePnrSsrCodes(list) {
    this.Form.pnrSsrCodes = list;
  }

  validatePnrRemarksList() {
    this.$v.$touch();
    let negoFarePnrRemarksList = this.$refs.negoFarePnrRemarksListComponent as any;
  
    if (negoFarePnrRemarksList) {
      negoFarePnrRemarksList.$v.$touch();
    }
    return !(this.$v.$error || negoFarePnrRemarksList && negoFarePnrRemarksList.$v.$error);
  }

  validateNegoFarePnrSsrCodes() {
    this.$v.$touch();
    let negoFarePnrSsrCodesComponent = this.$refs.negoFarePnrSsrCodesComponent as any;
  
    if (negoFarePnrSsrCodesComponent) {
      negoFarePnrSsrCodesComponent.$v.$touch();
    }
    return !(this.$v.$error || negoFarePnrSsrCodesComponent && negoFarePnrSsrCodesComponent.$v.$error);
  }

  airlinesMaper() {
    if (this.Form.supplier === AirNegoFareConfigurationSupplierEnum.AirFranceKlm) {
      this.airlines = this.allAirline = this.airlinesOptionsAirFranceKlm;
    } else if (this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Lufthansa) {
      this.airlines = this.allAirline = this.airlinesOptionsLufthansa;
    } else if (this.Form.supplier === AirNegoFareConfigurationSupplierEnum.AmericanAirlines) {
      this.airlines = this.allAirline = this.airlinesOptionsAmericanAirlines;
    } else if (this.Form.supplier === AirNegoFareConfigurationSupplierEnum.BritishAirways) {
      this.airlines = this.allAirline = this.airlinesOptionsBritishAirways;
    } else if (this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Emirates) {
      this.airlines = this.allAirline = this.airlinesOptionsEmirates;
    } else {
      this.airlines = this.allAirline = DictionaryStore.allAirLinesSorted;
    }
  }

  findAirlines(query) {
    query = query.toLowerCase();
    if (this.airlines) {
      const response = this.airlines.filter((air: AirLine) => air.code.toLowerCase().includes(query) || air.businessName.toLowerCase().includes(query));
      let indexCode = response.findIndex(item => query === item.code.toLowerCase());
      if ( indexCode > -1) {
        let item = response.splice(indexCode, 1);
        response.unshift(...item);
      }

      this.allAirline = [];

      if (response) {
        response.forEach((element) => {
          this.allAirline.push(element);
        });
      } else {
        this.allAirline = this.airlines;
      }
    }
  }

  getAirlineString(code) {
    let airline = DictionaryStore.airLines!.find(line => {
      return line.code === code;
    });
    return airline ? airline.businessName : '';
  }

  goToConfiguration() {
    NegoFaresStore.setIsFromManage(true);
    router.push({
      name: 'nego-fare-configuration',
      params: {
        id: router.currentRoute.params.id,
        configurationId: router.currentRoute.params.configurationId ? router.currentRoute.params.configurationId : 'new'
      }
    });
  }

  handleEnterPress() {
    setTimeout(() => {
      const btn = ((this.$refs.confirmAndGoBackButton as Vue).$el as HTMLInputElement);
      btn.focus();
    }, 50);
  }

  mapFormData(data?: any) {
    if (data && Object.keys(data).length) {
      this.Form = {
        supplier: data.supplier,
        codeOwner: this.codeOwnerOptions.find(opt => {
          return opt.value === data.codeOwner;
        }),
        codeType: null,
        description: data.description,
        code: data.code,
        airlines: data.airlines ? data.airlines : [],
        anyAirline: !!data.anyAirline,
        useSpecialTypesOfPax: data.useSpecialTypesOfPax,
        companyIndex: data.companyIndex,
        osiMessage: data.osiMessage,
        pnrRemarks: data.pnrRemarks,
        pnrSsrCodes: data.pnrSsrCodes,
      } as any;

      let foundCodeType = this.codeTypeOptions.find(opt => {
        return opt.value === data.codeType;
      });
      this.Form.codeType = foundCodeType ? foundCodeType : null;
    } else {
      this.Form = {
        supplier: null,
        code: '',
        codeType: null,
        description: '',
        codeOwner: null,
        airlines: [],
        anyAirline: false,
        useSpecialTypesOfPax: false,
        companyIndex: '',
        osiMessage: '',
        pnrRemarks: [],
        pnrSsrCodes: []
      } as any;
    }
    this.formMapped = true;
  }

  createCode() {
    if (this.Form && this.Form.airlines && !Array.isArray(this.Form.airlines)) {
      this.Form.airlines = [this.Form.airlines];
    }

    let item = {
      code: this.shouldShowCode || this.shouldShowLeasureFareType ? this.Form.code.trim() : null,
      codeOwner: this.shouldShowCodeOwner ? this.Form.codeOwner.value : null,
      codeType: this.shouldShowCodeType ? this.Form.codeType.value : null,
      description: this.shouldShowDescription ? this.Form.description.trim() : '',
      supplier: this.Form.supplier,
      airlines: this.Form.airlines ? this.Form.airlines : [],
      anyAirline: this.Form.anyAirline,
      status: NegoFaresFlightStore.draftNewStatus,
      id: _.uniqueId(),
      useSpecialTypesOfPax: this.Form.useSpecialTypesOfPax,
      companyIndex: this.shouldShowCompanyIndex ? this.Form.companyIndex : '',
      osiMessage: this.Form.osiMessage,
      pnrRemarks: this.Form.pnrRemarks,
      pnrSsrCodes: this.Form.pnrSsrCodes,
    };

    if (this.codeExists) {
      NegoFaresFlightStore.editCorporateCode(item);
    } else {
      NegoFaresFlightStore.addCorporateCode(item);
    }
  }

  confirmAndAddNext() {
    let validPnr = this.validatePnrRemarksList();
    let validPnrSsrCodes = this.validateNegoFarePnrSsrCodes();
    this.$v.Form.$touch();
    this.validated = true;
    if (!this.$v.Form.$invalid && validPnr && validPnrSsrCodes) {
      this.createCode();
      NegoFaresFlightStore.selectCorporateCode({});
      this.validated = false;
      this.$v.Form.$reset();
      this.mapFormData();
    }
  }

  confirmAndGoBack() {
    let validPnr = this.validatePnrRemarksList();
    let validPnrSsrCodes = this.validateNegoFarePnrSsrCodes();
    this.$v.Form.$touch();
    this.validated = true;
    if (!this.$v.Form.$invalid && validPnr && validPnrSsrCodes) {
      this.createCode();
      this.goToConfiguration();
    }
  }

  onCodeTypechange() {
    if (this.formMapped && this.Form.supplier === AirNegoFareConfigurationSupplierEnum.Amadeus) {
      this.Form.airlines = null;
      this.Form.anyAirline = false;
      this.Form.pnrRemarks = [];
      this.Form.pnrSsrCodes = [];
    }
    if (this.formMapped && this.Form.supplier === AirNegoFareConfigurationSupplierEnum.BritishAirways) {
      this.Form.airlines = null;
      this.Form.code = null;
    }
    this.Form.osiMessage = '';
  }

  onSupplierChange(supplier) {
    if (this.formMapped) {
      this.Form.codeType = null;
      this.Form.airlines = null;
      this.Form.anyAirline = false;
      this.Form.osiMessage = '';
      this.Form.pnrRemarks = [];
      this.Form.pnrSsrCodes = [];
    }
  }

  @Watch('Form.supplier', { deep: true, immediate: true })
  onFormSupplierChange() {
    this.airlinesMaper();
  }

  @Watch('selectedCode', { deep: true, immediate: true })
  onCodeChange(code) {
    if (!code) {
      router.go(-1);
    }
  }

  created() {
    this.airlinesMaper();
    if (this.selectedCode) {
      this.mapFormData(JSON.parse(JSON.stringify(this.selectedCode)));
    } else {
      this.mapFormData();
    }
  }
}

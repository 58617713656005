




































































import { Vue, Component } from 'vue-property-decorator';
import { Validation} from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';

import { router } from '@/router';
import { ProfileAgencyApi } from '@/api/profile/agency.api';
import SettingsStore from '@/modules/settings/settings.store';
import { CreateAgencyCustomerMessage } from '@/api/profile/agency.model';
import { isCompanyCode } from '@/core/validation/is-company-code.validator';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';

@Component({})
export default class CreateAgencyCustomer extends Vue {
  Form = new CreateAgencyCustomerMessage();
  serverErrors: any = [];
  $v;

  @Validation()
  validationObject() {
    return {
      Form: {
        name: {
          required,
          maxLength: maxLength(128),
        },
        code: {
          required,
          isCompanyCode,
          maxLength: maxLength(32),
        },
      },
    };
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  async save() {
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error) {
      return;
    }

    try {

      this.Form.parentId = null;
      this.Form.agencyId = this.currentCompany!.companyId;

      const response = await ProfileAgencyApi.createAgencyCustomer(this.Form);
      router.push({
        path: '/settings/' + response.data.id,
      });

      EventHandler.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('common.saved'),
        message: translate('settings.config-saved')
      });
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  goBack() {
    router.go(-1);
  }
}















































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import _ from 'lodash';
import {
  ProfileDocument,
  CreateNationalIdMessage,
  Gender,
} from '@/api/profile/documents.model';
import DictionaryStore from '@/store/dictionary.store';
import { DocumentsApi } from '@/api/profile/documents.api';
import $handleErrors from '@/core/errors/handle-errors.service';
import moment from 'moment';
import BasketStore from '@/modules/basket/basket.store';
import { translate } from '@/i18n';
import { isNameValid } from '@/core/validation/is-name-valid.validator';
import EventBus from '@/services/event-handler';


@Component({})
export default class NationalIdDocumentData extends Vue {
  @Prop({ default: false }) documentType: any;
  @Prop({ default: null }) document: any;
  @Prop({ default: null }) profileId: any;
  @Prop({}) isGuestMode!: boolean;
  @Prop({ default: false }) withoutSaving!: boolean;
  
  Form: ProfileDocument | null = null;
  serverErrors: any[] = [];
  errMessage: string = '';
  wasSubmitted: boolean = false;
  formPending: boolean = false;
  genders: any[] = [
    {
      label: translate('profile-document.male'),
      value: Gender.Male
    },
    {
      label: translate('profile-document.female'),
      value: Gender.Female
    }, 
    {
      label: translate('profile-document.unspecified'),
      value: Gender.Unspecified
    }];
  $v;

  @Validation()
  validationObject() {
    return {
      Form: {
        number: {
          required,
          maxLength: maxLength(14),
        },
        expireDate: {
          required,
        },
        issueCountry: {
          required,
        },
        nationality: {
          required,
        },
        firstName: {
          required,
          isNameValid,
          maxLength: maxLength(100),
        },
        middleName: {
          isNameValid: (value) => (value ? isNameValid(value) : true),
          maxLength: maxLength(100),
        },
        lastName: {
          required,
          isNameValid,
          maxLength: maxLength(250),
        },
        birthDate: {
          required,
        },
        gender: {
          required
        }
      },
    };
  }

  get allCountries() {
    return DictionaryStore.allCountries;
  }

  get isEditing() {
    return !!this.document;
  }

  get minimalBirthDate() {
    let date = new Date();
    date.setFullYear( date.getFullYear() - 150 );
    return date;
  }

  validDate(date) {
    let dayOne = moment(date);
    let dayTwo = moment();
    if (date && dayOne.diff(dayTwo, 'days') < 0) {
      return true;
    }

    return false;
  }

  validDateIssueDate(date) {
    let dayOne = moment(date);
    let dayTwo = moment();
    if (date && dayOne.diff(dayTwo, 'days', true) > 0) {
      return true;
    }

    return false;
  }

  maxIssueDate(issueDate, expireDate) {
    if (issueDate && expireDate) {
      let dayOne = moment(issueDate);
      let dayTwo = moment(expireDate);
      if (dayOne.diff(dayTwo, 'days') > 0) {
        return true;
      }

      return false;
    }
    return false;
  }

  async created() {
    if (this.isEditing) {
      this.Form = new ProfileDocument();
      this.Form.type = this.documentType.value;
      this.Form.number = this.document.number;
      this.Form.id = this.document.id;
      this.Form.issueCountryCode = this.document.issueCountryCode;
      this.Form.issueCountry = this.allCountries!.find(country => { return country.code === this.document.issueCountryCode; })!;
      this.Form.nationalityCountryCode = this.document.nationalityCountryCode;
      this.Form.nationality = this.allCountries!.find(country => { return country.code === this.document.nationalityCountryCode; })!;
      this.Form.issueDate = this.document.issueDate ? moment(this.document.issueDate).toDate() : '';
      this.Form.expireDate = moment(this.document.expireDate).toDate();
      this.Form.gender = this.document.gender && this.document.gender !== 'Unknown' ? this.genders.find(gend => { return gend.value === this.document.gender; }) : null;
      this.Form.birthDate = this.document.birthDate ? moment(this.document.birthDate).toDate() : '';
      this.Form.firstName = this.document.firstName;
      this.Form.middleName = this.document.middleName;
      this.Form.lastName = this.document.lastName;
      this.Form.tempId = this.document.tempId;
    } else {
      this.Form = new ProfileDocument();
    }
  }

  notifyParent(reloadDocuments, document) {
    this.$emit('document-changed', reloadDocuments, document);
  }

  async saveDetails() {
    this.serverErrors = [];
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error || this.validDate(this.Form!.expireDate) || this.validDateIssueDate(this.Form!.issueDate) || this.maxIssueDate(this.Form!.issueDate, this.Form!.expireDate)) {
      return;
    }

    let request: CreateNationalIdMessage = {
      type: this.documentType.value,
      gender: this.getGender(),
      issueCountryCode: this.Form!.issueCountry!.code,
      nationalityCountryCode: this.Form!.nationality!.code,
      number: this.Form!.number,
      firstName: this.Form!.firstName,
      lastName: this.Form!.lastName,
      middleName: this.Form!.middleName,
      issueDate:  this.getIssueDate(),
      expireDate: moment(this.Form!.expireDate).format('YYYY-MM-DD'),
      birthDate: this.getBirthDate(),
    };

    if (this.withoutSaving) {
      this.notifyParent(true, request);
      return;
    }

    if (this.isEditing) {

      try {
        this.formPending = true;
        if (this.isGuestMode) {
          let document = {
            ...request,
            tempId: this.Form!.tempId,
          };
          BasketStore.updateGuestBasketTravellerDocument({ id: this.profileId, document: document});
        } else {
          await DocumentsApi.updateNationalIdDocument(this.profileId, this.document.id, request);
          this.notifyParent(true, null);
        }
      } catch (error) {
        this.serverErrors = $handleErrors(error, true);
      } finally {
        this.formPending = false;
      }
    } else {
      this.wasSubmitted = true;

      try {
        this.formPending = true;
        if (this.isGuestMode) {
          let document: any = request;
          document.tempId = _.uniqueId();
          BasketStore.setGuestBasketTravellerDocument({ id: this.profileId, document: document });
        } else {
          await DocumentsApi.createNationalIdDocument(this.profileId, request);
          this.notifyParent(true, null);
        }
      } catch (error) {
        this.serverErrors = $handleErrors(error, true);
      } finally {
        this.formPending = false;
      }
    }
  }

  getGender() {
    return this.Form!.gender && this.Form!.gender.value ? this.Form!.gender.value : null;
  }

  getBirthDate() {
    return this.Form!.birthDate ? moment(this.Form!.birthDate).format('YYYY-MM-DD') : null;
  }

  getIssueDate() {
    return this.Form!.issueDate ? moment(this.Form!.issueDate).format('YYYY-MM-DD') : null;
  }

  focusOnSaveButton() {
    setTimeout(() => {
      const btn = ((this.$refs.backButton as Vue).$el as HTMLInputElement);
      btn.focus();
    }, 50);
  }

  onTypeChanged() {
    ((this.$refs.firstInput as Vue).$refs.input as HTMLElement).focus();
  }

  mounted() {
    EventBus.$on('document-type-changed', this.onTypeChanged);
  }

  beforeDestroy() {
    EventBus.$off('document-type-changed', this.onTypeChanged);
  }
}









































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { requiredIf, numeric, maxLength } from 'vuelidate/lib/validators';
import { translate } from '@/i18n';
import EventBus from '@/services/event-handler';
import {
  TPreference,
  TSncfSeatPreferencesModel,
  TSeatMapPopupSelection,
  TSeatMapPreferencesAssignment,
} from '@/modules/basket/basket-rail-seats-extras/basket-rail-seats-extras.model';
import SeatMapSncfPopup from '@/modules/basket/basket-rail-seats-extras/popup/SeatMapSncfPopup.vue';
import BasketStore from '@/modules/basket/basket.store';

const Option = {
  noPreference: {
    get label() {
      return translate('basket.no-preference');
    },
    code: null,
  },
  seatMap: {
    get label() {
      return translate('basket.choose-seat-map');
    },
    code: 'SeatMap',
  },
  assignment: {
    get label() {
      return translate('basket.seat-assignment');
    },
    code: 'Assignment',
  },
  specified: {
    get label() {
      return translate('basket.seat-next-to-specified');
    },
    code: 'Specified',
  },
};
const COLS = 12;
const MD_COLS = 3;

@Component({
  components: {
    SeatMapSncfPopup,
  },
})
export default class SeatsSncfContent extends Vue {
  @Prop() value!: TSncfSeatPreferencesModel[];
  @Prop() segmentId!: string;
  @Prop() seatMapAvailable!: boolean;
  @Prop() supplier!: string;

  $v;
  Option = Option;
  category: TPreference = Option.noPreference;
  showSeatMapPopup: boolean = false;
  seatMapPopupSelections: TSeatMapPopupSelection[] = [];

  @Validation()
  validationObject() {
    return {
      seatMapPreferencesModel: {
        assignments: {
          required: requiredIf(() => this.category.code === Option.seatMap.code),
        },
      },
      assignmentsPreferencesModel: {
        assignments: {
          $each: {
            assignmentCode: {
              required: requiredIf(() => {
                const isAssigmentCode = this.category.code === Option.assignment.code;
                const assignments = this.assignmentsPreferencesModel && this.assignmentsPreferencesModel.assignments;
                return isAssigmentCode && (assignments || []).every((item) => !item.assignmentCode);
              }),
            },
          },
        },
      },
      coachNumberModel: {
        required: requiredIf(() => this.category.code === Option.specified.code),
        numeric,
        maxLength: maxLength(5),
      },
      seatNumberModel: {
        required: requiredIf(() => this.category.code === Option.specified.code),
        numeric,
        maxLength: maxLength(5),
      },
    };
  }

  get seatsPreferencesOptions() {
    return BasketStore.sncfSeatsAncillaries.seatsPreferencesOptions;
  }

  get preferencesCategories() {
    const seatsPreferencesOptions = this.seatsPreferencesOptions.find((item) => item.train.segmentId === this.segmentId);
    return (seatsPreferencesOptions && seatsPreferencesOptions.preferencesCategories) || [];
  }

  get categoryOptions() {
    let options: { label: string; code: string | null }[] = [Option.noPreference];
    if (this.seatMapAvailable) {
      options = options.concat(Option.seatMap);
    }
    if (this.preferencesCategories.length > 0) {
      options = options.concat([Option.assignment, Option.specified]);
    }
    return options;
  }

  get colMdSize() {
    const preferences = this.preferencesCategories;
    const divider = preferences.length > MD_COLS ? MD_COLS : preferences.length;
    if (divider > 0) {
      return COLS / (divider > MD_COLS ? MD_COLS : divider);
    }
    return COLS;
  }

  get seatSegmentModel() {
    return this.value.find((item) => item.segmentId === this.segmentId);
  }

  get seatMapPreferencesModel() {
    return this.seatSegmentModel && this.seatSegmentModel.seatMapPreferences;
  }

  get assignmentsPreferencesModel() {
    return this.seatSegmentModel && this.seatSegmentModel.assignmentsPreferences;
  }

  get closeToPreferencesModel() {
    return this.seatSegmentModel && this.seatSegmentModel.closeToPreferences;
  }

  get coachNumberModel() {
    if (this.closeToPreferencesModel) {
      return this.closeToPreferencesModel.coachNumber || '';
    }
    return '';
  }
  set coachNumberModel(coachNumber: string) {
    const value = this.value
      .filter((item) => item.segmentId !== this.segmentId)
      .concat({
        segmentId: this.segmentId,
        closeToPreferences: {
          seatNumber: this.seatNumberModel,
          coachNumber
        },
      });
    this.$emit('input', value);
  }

  get seatNumberModel() {
    if (this.closeToPreferencesModel) {
      return this.closeToPreferencesModel.seatNumber || '';
    }
    return '';
  }
  set seatNumberModel(seatNumber: string) {
    const value = this.value
      .filter((item) => item.segmentId !== this.segmentId)
      .concat({
        segmentId: this.segmentId,
        closeToPreferences: {
          coachNumber: this.coachNumberModel,
          seatNumber,
        },
      });
    this.$emit('input', value);
  }

  getPreference(category: string) {
    const assignments = ((this.assignmentsPreferencesModel && this.assignmentsPreferencesModel.assignments) || []);
    const assignment = assignments.find((item) => item.category === category);
    if (!assignment) {
      return Option.noPreference;
    }
    const preferencesCategory = this.preferencesCategories.find((item) => item.name === category);
    if (!preferencesCategory) {
      return Option.noPreference;
    }
    const preference = preferencesCategory.preferences.find((item) => item.code === assignment.assignmentCode);
    return preference || Option.noPreference;
  }

  setPreference({ category, code: assignmentCode }: { category: string, code: string | null }) {
    const assignments = ((this.assignmentsPreferencesModel && this.assignmentsPreferencesModel.assignments) || []);
    let assignmentsPreferences = {
      assignments: assignments
        .filter((item) => item.category !== category),
    };
    if (assignmentCode) {
      assignmentsPreferences = {
        assignments: assignmentsPreferences.assignments.concat({ category, assignmentCode })
      };
    }
    const value = this.value
      .filter((item) => item.segmentId !== this.segmentId)
      .concat({
        segmentId: this.segmentId,
        assignmentsPreferences,
      });
    this.$emit('input', value);
  }

  getSeatMapPopupSelection(assignment: TSeatMapPreferencesAssignment) {
    return this.seatMapPopupSelections.find(({ segmentId, coachNumber, seatNumber }) => {
      const isSegmentId = segmentId === this.segmentId;
      const isCoachNumber = coachNumber === assignment.coachNumber;
      const isSeatNumber = seatNumber === assignment.seatNumber;
      return isSegmentId && isCoachNumber && isSeatNumber;
    });
  }

  getTravellerByAssignment(assignment: TSeatMapPreferencesAssignment) {
    const seatMapPopupSelection = this.getSeatMapPopupSelection(assignment);
    return seatMapPopupSelection && seatMapPopupSelection.traveller;
  }

  getPriceByAssignment(assignment: TSeatMapPreferencesAssignment) {
    const seatMapPopupSelection = this.getSeatMapPopupSelection(assignment);
    return seatMapPopupSelection && seatMapPopupSelection.price;
  }

  onUpdateSeatMapPopupSelections(seatMapPopupSelections: TSeatMapPopupSelection[]) {
    this.seatMapPopupSelections = seatMapPopupSelections;
    this.$emit('update-seat-map-popup-selections', seatMapPopupSelections);
  }

  openSeatMapPopup() {
    this.showSeatMapPopup = true;
  }

  onPopupInput(value) {
    this.$emit('input', value);
  }

  onCategorySelect(category: TPreference) {
    let value = this.value.filter((item) => item.segmentId !== this.segmentId);
    if (category.code === Option.assignment.code) {
      value = value.concat({
        segmentId: this.segmentId,
        assignmentsPreferences: {
          assignments: [],
        },
      });
    }
    this.$emit('input', value);
  }

  isValid() {
    this.$v.$touch();
    return !this.$v.$error;
  }

  validate() {
    const isValid = this.isValid();
    BasketStore.setBasketRailSncfSeatPreferencesError(!isValid);
  }

  created() {
    EventBus.$on('validate-sncf-seats', this.validate);
  }

  beforeDestroy() {
    EventBus.$off('validate-sncf-seats', this.validate);
  }
}

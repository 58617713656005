













































































































































import moment from 'moment';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { TFlight, TPassenger, TProfileSeatSelection, TSeatMap, TDeck } from '@/api/trip/basket-air-seats-extras.model';
import { getSeatPrice } from '@/modules/basket/basket-air-seats-extras/BasketAirSeatsExtras.helper';
import { Price } from '@/api/expense/expense.model';
import { userFullName } from '@/core/user-full-name';
import AirCabin from '@/modules/basket/seatmap/AirCabin.vue';
import AirSeatMapLegend from '@/modules/basket/seatmap/AirSeatMapLegend.vue';

@Component({
  components: {
    AirCabin,
    AirSeatMapLegend,
  }
})
export default class SeatMapPopup extends Vue {
  @Prop() value!: TProfileSeatSelection[];
  @Prop() show!: boolean;
  @Prop() seatMap!: TSeatMap;
  @Prop() passenger!: TPassenger;
  @Prop() flight!: TFlight;

  userFullName = userFullName;
  seatSelections: TProfileSeatSelection[] = [];
  deck: TDeck | null = null;
  tooltip: { x: number, y: number, price: Price } | null = null;
  width: number = 0;
  height: number = 0;

  @Emit('update:show')
  closePopup() {
    return false;
  }

  get passengerId() {
    return this.passenger.id || this.passenger.profileId;
  }

  get avatars() {
    const selectedSeat = (this.seatMap.selectedSeats || []).map(({ seatNumber, profileId }) => {
      const pattern = /(\d+)([A-Za-z])/;
      const match = seatNumber.match(pattern);
      const row = match ? parseInt(match[1]) : null;
      const column = match ? match[2] : null;
      return { row, column, profileId };
    });
    const seatSelections = this.seatSelections.map((item) => ({
      row: item.seatRow,
      column: item.seatColumn,
      profileId: item.profileId,
      offerItemId: item.offerItemId,
    }));
    return [...selectedSeat, ...seatSelections];
  }

  get cabins() {
    return this.deck && this.deck.cabins;
  }

  get departureDate() {
    return moment(this.flight.departureDateTime).format('Do MMM YY');
  }

  get flightTime() {
    const departureTime = moment(this.flight.departureDateTime);
    const arrivalTime = moment(this.flight.arrivalDateTime);
    return `${departureTime.format('HH:mm')} - ${arrivalTime.format('HH:mm')}`;
  }

  clickSeat(event: {
    row: number,
    column: string,
    offers: Array<{
      commercialName: string,
      offerItemId: string | null,
      price: Price,
      profileId: string,
    }>,
  }) {
    this.tooltip = null;
    const offer = event.offers.find((item) => item.profileId === this.passengerId);
    const seatSelections = this.value
      .concat(this.seatSelections.filter(({ profileId }) => !this.value.some((item) => item.profileId === profileId)))
      .filter(({ profileId }) => profileId !== this.passengerId);
    this.seatSelections = seatSelections.concat({
      profileId: this.passengerId,
      seatColumn: event.column,
      seatRow: event.row,
      offerItemId: (offer && offer.offerItemId) || null,
    });
  }

  seatOver({ x, y, result }: {
    x: number,
    y: number,
    result: {
      column: string,
      row: number,
    },
  }) {
    const { column, row } = result;
    const price = getSeatPrice({
      seatMaps: [this.seatMap],
      profileId: this.passenger.profileId,
      flightId: this.flight.id,
      column,
      row,
    });
    this.tooltip = price && { x, y, price };
  }

  hideTooltip() {
    this.tooltip = null;
  }

  avatarClick(avatar: {
    row: number,
    column: string,
    profileId: string,
    offerItemId: string | null,
  }) {
    if (avatar.profileId === this.passengerId) {
      this.seatSelections = this.value
        .concat(this.seatSelections.filter(({ profileId }) => !this.value.some((item) => item.profileId === profileId)))
        .filter(({ profileId }) => profileId !== this.passengerId);
    } else {
      const seatSelections = this.seatSelections.filter(({ profileId }) => profileId !== this.passengerId && profileId !== avatar.profileId);
      this.seatSelections = seatSelections.concat({
        profileId: this.passengerId,
        seatColumn: avatar.column,
        seatRow: avatar.row,
        offerItemId: avatar.offerItemId,
      });
    }
  }

  onContinueClick() {
    this.$emit('input', this.seatSelections);
    this.closePopup();
  }

  @Watch('show', { immediate: true })
  onShow(isShow: boolean) {
    if (isShow) {
      this.seatSelections = [...this.value];
      [this.deck] = this.seatMap.decks;
      this.$nextTick(() => {
        const airCabinRef = this.$refs.airCabinRef as AirCabin[];
        if (airCabinRef) {
          this.width = airCabinRef.reduce((prev, next) => {
            const width = next.getWidth();
            return prev > width ? prev : width;
          }, 0);
          this.height = airCabinRef.reduce((prev, next) => {
            const height = next.getHeight();
            return prev + height;
          }, 0);
        }
      });
    }
  }
}

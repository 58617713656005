import moment from 'moment';
import SearchConst from '@/const/search.const';
export interface SearchModel {
  searchMode: number;
  travellers: Traveller[];
  from: From;
  to: From;
  departureDate: string;
  returnDate: string;
}

export interface InitAirSearchMessage {
  searchMode: string;
  travellers: Traveller[];
  legs: Legs[];
  from: {
    type: string;
    code: string;
  };
  to: {
    type: string;
    code: string;
  };
  departureDate: string;
  returnDate: string;
  cabinClass: string;
  cabinClasses: string[];
  offlineRequestId?: string | null;
  outwardCabinClass: string | null;
  inwardCabinClass: string | null;
  nonStopsOnly: boolean;
  flexOnly: boolean;
  isSplitSearchEnabled: boolean;
  additionalLuggageOnly: boolean;
  outwardTimeWindows: LegTimeWindow | null;
  inwardTimeWindows: LegTimeWindow | null;
  alliances?: string[];
  carriers?: string[];
  connectingLocations?: string[];
  searchCompanyId: string | null;
  advancedSearchParametersId: string | null;
}

export interface LegTimeWindow {
  departureWindow: TimeWindow | null;
  arrivalWindow: TimeWindow | null;
}

export interface LegTimeWindows {
  departureWindow?: Array<Number> | AirSearchAltTimeWindow | null;
  arrivalWindow?: Array<Number> | AirSearchAltTimeWindow | null;
  timeRange?: Array<Number>;
}

let staticCounter = 0;

export class Legs {
  id: string;
  legNumber: number = 0;
  from: any = null;
  to: any = null;
  cabinClass: string = 'Any';
  cabinClasses: any[] = [SearchConst.cabinClasses[0]];
  isFromLoading: boolean = false;
  isToLoading: boolean = false;
  departureDate: string = moment()
    .add(1, 'week')
    .format('YYYY-MM-DD');
  timeWindows: LegTimeWindows | null = null;

  public constructor(params?: any) {
    staticCounter++;
    this.id = 'leg' + staticCounter;
    if (!params) {
      return;
    }
    if (params.legNumber !== undefined) {
      this.legNumber = params.legNumber;
    }
    if (params.isFromLoading !== undefined) {
      this.isFromLoading = params.isFromLoading;
    }
    if (params.isToLoading !== undefined) {
      this.isToLoading = params.isToLoading;
    }
    if (params.from !== undefined) {
      this.from = params.from;
    }
    if (params.to !== undefined) {
      this.to = params.to;
    }
    if (params.departureDate !== undefined) {
      this.departureDate = params.departureDate;
    }
    if (params.timeWindows !== undefined) {
      this.timeWindows = params.timeWindows;
    }
    if (params.cabinClass !== undefined) {
      this.cabinClass = params.cabinClass;
    }
    if (params.cabinClasses !== undefined) {
      this.cabinClasses = params.cabinClasses;
    }
  }
}

export interface TimeWindow {
  start: string;    // HH:mm:ss
  end: string;  // HH:mm:ss
}

export interface InitExchangeAirSearchMessage {
  parentRecommendationId: string;
  airSupplier: string;
  travellers: Traveller[];
  flightLegs: FlightLeg[];
  preferredCabinClass: string;
}

export interface FlightLeg {
  flightLegNumber: number;
  isForExchange: boolean;
  from?: {
    type: string,
    code: string
  };
  to?: {
    type: string,
    code: string
  };
  date?: string;
}
export interface Traveller {
  id: string;
  firstName: string;
  lastName: string;
  isVirtual?: boolean;
  businessUnitId?: string;
  businessUnitName?: string;
  companyCode?: string;
  companyId?: string;
  companyName?: string;
  passengerTypeCode?: string;
}

export interface ResponseAirModel {
  searchId: string;
  searchState: number;
  stateId: string;
  basketId: string;
  previousSelection: PreviousSelection;
  recommendationsTotalCount: number;
  missionId?: any | null;
  feesConfigurationId?: string;
  hasDetailedPriceComponentAccess: boolean;
  defaultSortingMethod?: string;
}

export interface PreviousSelection {
  recommendationId: string;
  proposalId: string;
}

export interface AirSearchSession {
  metadata: ResponseAirModel;
  request: SearchModel;
}

export interface AirOffersResponseModel {
  searchResults: AirOffersModel;
  statistics: any[];
  currency?: Currency;
}
export interface AirOffersModel {
  searchState: number;
  recommendations: Recommendation[];
  recommendationsTotalCount: number;
  moreRecommendationAvailable: boolean;
  agencyMarkup: Price;
}

export interface Recommendation {
  id: string;
  providerSearchId: string;
  ticketingDate: string;
  isExchangeAvailable: boolean;
  ssid: string;
  isLowCost?: boolean;
  legFlights: LegFlight[];
  proposal: Proposal;
  airFranceKlmOfferItemIds: string[];
  prepareOfferCheck: boolean;
  supplier?: string;
  offerProposals?: SearchFlightProposalsModel;
  minPrice?: Price;
  travelPolicy?: string;
  legs?: LegFlight[];
  loadingProposals?: boolean;
}

export interface Proposal {
  legFlights: LegFlight[];
  id: string;
  ticketingCarrier: Carrier;
  name: string;
  price: Price;
  baggageInfo: BaggageInfo;
  mealType: string;
  isNegotiated: boolean;
  isSelected: boolean;
  isNDC: boolean;
  isLowCost?: boolean;
  travelPolicy: number;
  loyaltyProgram?: string[];
  supplier: string;
  agencyMarkup: Price;
  agencyFee: Price;
  pricePlusMarkup: Price;
  priceChanged: boolean;
  basePrice: Price;
  taxPrice: Price;
  totalPrice: Price;
  providerPrice?: Price;
  travelFusionData: TravelFusionData;
  ticketingDeadline: string;
  ticketingDeadlineDateTime?: string;
  fareType: string;
  passengerPrices: any;
  grandTotalDifference: any;
  brandNames?: string[];
  cabinClasses?: string[];
  minPrice?: Price;
  lufthansaProposal?: any;
}

export interface TravelFusionData {
  routingId: string;
  outwardId: string;
  inwardId: string;
}

export interface BaggageInfo {
  freeItems: number;
  weight: number;
  unit: string;
}

export interface Price {
  amount: number;
  currency: Currency;
}

export interface Currency {
  code: string;
  symbol: string;
}

export interface LegFlight {
  legNumber: number;
  flights: Flight[];
  flightNumberHash: number;
  selectedForEdit?: boolean;
  legBrandName: string;
  legCabinClass: string;
}

export interface Flight {
  type: string;
  from: From;
  to: From;
  departure: string;
  departureDateTime: string;
  arrival: string;
  arrivalDateTime: string;
  duration: number;
  cabinClass: string;
  flightInfo: FlightInfo;
  fareInfo: FareInfo;
  details?: any;
  id?: string;
  selectedSeats?: any;
  selectedAncillaries?: any;
  additionalStops?: any;
}

export interface FlightInfo {
  airplane: string;
  carrier: Carrier;
  number: string;
}

export interface Carrier {
  code: string;
  name: string;
}

export interface From {
  airportCode: string;
  airportName: string;
  cityName: string;
  cityCode: string;
  countryName: string;
  countryCode: string;
  type: string;
}

export interface FilterOffersQuery {
  searchId: string;
  filterId: string;
  sortQuery: string;
}

export interface OffersQuery {
  searchId: string;
  sortQuery: string;
  flightsHashes?: string[] | string;
  flightNumberHash1?: string;
  flightNumberHash2?: string;
}

export interface AvailableOption {
  id: string;
  price: Price;
}

export interface SortOptions {
  PRICE?: string;
  DEPARTURE_TIME?: string;
  BEST_FARE_DEPARTURE_TIME?: string;
  SHORTEST?: string;
}

export interface AgencyMarkup {
  amount: number;
  currency: Currency;
}

export interface SkyScannerModel {
  fromCity: string;
  targetCity: string;
  startDate: string;
  endDate: string;
  isOneWay: boolean;
}

export interface AirSearchSessionState {
  searchId: string;
  stateId: string;
  recommendationsTotalCount: number;
  searchState: SearchState;
  newResultsAvailable: boolean;
  providersErrors: AirSearchProviderError[];
}

export interface AirSearchProviderError {
  supplier: string;
  errorCode: string;
  providerError: string;
  serviceTag: string;
}

export enum SearchState {
  Undefined = '',
  InProgress = 'InProgress',
  Completed = 'Completed',
  CompletedWithErrors = 'CompletedWithErrors',
}

export enum AirSearchAllianceEnum {
  Undefined = '',
  StarAlliance = 'StarAlliance',
  OneWorld = 'Oneworld',
  SkyTeam = 'SkyTeam',
}

export interface AirSearchAltTimeWindow {
  value: number;
  label: string;
  timeRange: number[];
}

export interface AirResultsErrorPayload {
  error: any;
  customParams?: any;
  addMessageToError?: boolean;
}

export interface AirSearchFlightsModel {
  statistics: Statistic[];
  searchResults: SearchResults;
  currency?: Currency;
}

export interface SearchResults {
  searchState: string;
  flights: FlightDetails[];
  moreFlightsAvailable: boolean;
  stateId: string;
  agencyMarkup: Price;
  errors: Error[];
}

export interface Error {
  code: string;
  message: string;
  messageEng: string;
  field: string;
  params: any;
  resourceId: string;
  isTranslated: boolean;
  translationLanguage: string;
}

export interface FlightDetails {
  co2Emission: number;
  minPrice: Price;
  travelPolicy: string;
  legs: Leg[];
  id: string;
  offerProposals?: SearchFlightProposalsModel;
  loadingProposals?: boolean;
}

export interface Leg {
  legNumber: number;
  duration: number;
  flightNumberHash: number;
  containsStopWithAirportChange: boolean;
  flights: FlightsDetails[];
}

export interface FlightsDetails {
  id: string;
  type: string;
  from: FromDetails;
  to: FromDetails;
  departure: string;
  arrival: string;
  duration: number;
  totalMilesFlown: number;
  flightInfo: FlightInfoDetails;
  stopWithAirportChange: boolean;
}

export interface FlightInfoDetails {
  airplane: string;
  carrier: Carrier;
  number: string;
  operatingFlightNumber: string;
  operatingCarrier: CarrierDetails;
  co2Emission: number;
}

export interface CarrierDetails {
  code: string;
  name: string;
  alliance: string;
}

export interface FromDetails {
  airportCode: string;
  airportName: string;
  city: string;
  cityName: string;
  state: string;
  country: string;
  terminalId: string;
  countryCode: string;
}

export interface Statistic {
  code: string;
}

export interface SearchFlightProposalsModel {
  minPrice: Price;
  travelPolicy: string;
  proposalsTotalCount: number;
  moreProposalsAvailable: boolean;
  proposals: Proposal[];
  selectedProposals?: Proposal;
  priceChanged?: boolean;
}

export interface ProposalDetails {
  id: string;
  providerSearchId: string;
  ticketingCarrier: TicketingCarrier;
  price: Price;
  fareType: string;
  isNDC: boolean;
  isLowCost?: boolean;
  travelPolicy: string;
  supplier: string;
  agencyMarkup: Price;
  pricePlusMarkup: Price;
  basePrice: Price;
  ticketingDeadlineDateTime: string;
  loyaltyProgram?: string[];
  taxPrice: Price;
  priceChanged: boolean;
  brandNames: string[];
  flightsConditions: FlightsCondition[];
  grandTotalDifference?: Price;
  agencyFee: Price | null;
  providerPrice?: Price | null;
  passengerPrices?: Price | null;
}

export interface FlightsCondition {
  from: string;
  to: string;
  fareInfo: FareInfo;
}

export interface FareInfo {
  fareBasisCode: string;
  brandName: string;
  cabinClass: string;
  bookingClass: string;
  baggageInfo: BaggageInfo;
  baggageInfoDetails: BaggageInfoDetails;
  mealType: string;
  nonRefundable: boolean;
  fareType: string;
  accountCode: string;
  isGreenFare?: boolean;
}

export interface TicketingCarrier {
  code: string;
  name: string;
  alliance: string;
}

export interface BasketItemsRequest {
  forceAdd: boolean;
  recommendationId: string;
}

export interface BasketResult {
  tripId: string;
  missedSaving?: any;
  recommendationId?: string;
  isMissedSavingsCalculationInProgress?: boolean;
  confirmationNeeded: boolean;
  previousRecommendation: PreviousRecommendation;
  recommendation: PreviousRecommendation;
}

export interface IOfferChange {
  additionalTag: string;
  changeType: string;
  valueFrom: string;
  valueTo: string;
}

export interface IRawBasketAirOffer<T = Record<string, Price>> {
  id: string;
  supplier: string;
  co2Emission: number;
  isNDC: boolean;
  isLowCost: boolean;
  price: T;
  fareType: string;
  legFlights: LegFlightBasket[];
  cancelledFlights: object[];
  passengers: object[];
  ticketingCarrier: TicketingCarrierBasket;
  ticketingDeadline: string;
  fareLabel: string;
  bookingType: string;
  bookingChannel: string;
  hasAncillarySelected: boolean;
  hasSeatSelected: boolean;
  pnrCreateDate: string;
}
export interface IBasketAirOffer extends IRawBasketAirOffer {
  proposal: {
    pricePlusMarkup: Price,
  } & IRawBasketAirOffer<Price>;
  travelPolicy?: string;
}
export interface PreviousRecommendation {
  proposal: ProposalBasket;
  id: string;
  co2Emission: number;
  legFlights: LegFlightBasket[];
  providerSearchId: string;
}
export interface LegFlightBasket {
  legNumber: number;
  duration: number;
  flights: FlightBasket[];
  flightNumberHash: number;
  containsStopWithAirportChange: boolean;
}
export interface FlightBasket {
  id: string;
  type: string;
  from: From;
  to: From;
  departure: string;
  arrival: string;
  duration: number;
  totalMilesFlown: number;
  flightInfo: FlightInfoBasket;
  fareInfo: FareInfo;
  stopWithAirportChange: boolean;
}
export interface BaggageInfo {
  freeItems: number;
}
export interface BaggageInfoDetails {
  freeItems: number;
  passengerType: string;
}
export interface FlightInfoBasket {
  airplaneCode: string;
  airplane: string;
  carrier: TicketingCarrierBasket;
  number: string;
  operatingFlightNumber: string;
  operatingCarrier: TicketingCarrierBasket;
  co2Emission: number;
}
export interface From {
  airportCode: string;
  airportName: string;
  city: string;
  cityName: string;
  state: string;
  country: string;
  countryCode: string;
  terminalId: string;
}
export interface ProposalBasket {
  id: string;
  ticketingCarrier: TicketingCarrierBasket;
  price: Price;
  fareType: string;
  isNDC: boolean;
  travelPolicy: string;
  supplier: string;
  agencyMarkup: Price;
  pricePlusMarkup: Price;
  basePrice: Price;
  passengerPrices: PassengerPrice[];
  ticketingDeadline: string;
  ticketingDeadlineDateTime: string;
  taxPrice: Price;
  priceChanged: boolean;
}
export interface PassengerPrice {
  profileId: string;
  providerId: string;
  totalPrice: Price;
  basePrice: Price;
  taxPrice: Price;
}
export interface Price {
  amount: number;
  currency: Currency;
}
export interface TicketingCarrierBasket {
  code: string;
  name: string;
  alliance: string;
}

export interface AdvancedSearchOptions {
  travellers: Traveller[];
  isEnabledForAirFranceKlm: boolean;
}

export interface AdvancedSearchOptionsQuery {
  searchCompanyId: string;
  travellers: Traveller[];
  suppliers: string[];
}

export interface AdvancedSearchParameters {
  travellerId: string;
  passengerTypeCode?: string;
  airFranceAdvancedSearchParameters: AirFranceAdvancedSearchParameters;
}

export interface AirFranceAdvancedSearchParameters {
  flyingBlueCardNumber: string;
}

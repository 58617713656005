









































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import BasketStore from '@/modules/basket/basket.store';
import { translate } from '@/i18n';
import moment from 'moment';
import {
  ToTwoDigitStringValue,
  FromTwoDigitStringValue,
} from '@/core/utils';

@Component({})
export default class BasketDelayTicketingDeadline extends Vue {
  @Prop() basketItemsMapped!: any;

  dateModel: any = null;
  timeModel: number | null = null;
  labelNames = {
    start: translate('basket.ticketing-at')
  };

  get delayTicketingDeadline() {
    return BasketStore.delayTicketingDeadline;
  }

  get saveErrors() {
    return BasketStore.delayTicketingDeadlineSaveErrors;
  }

  get isDeadlineIsInPast() {
    if (!this.delayTicketingDeadline) {
      return false;
    }
    const maxDeadlineMoment = moment(this.delayTicketingDeadline.deadline);

    return maxDeadlineMoment.isBefore(moment());
  }

  get deadlineDate() {
    if (!this.delayTicketingDeadline) {
      return null;
    }
    return this.dateModel ? this.dateModel : moment(this.delayTicketingDeadline!.deadline).toDate();
  }

  set deadlineDate(value) {
    this.dateModel = value;
  }

  get deadlineTime() {
    let delayTicketingTime: number | null = null;

    if (this.delayTicketingDeadline) {
      const momentDate = moment(this.delayTicketingDeadline.deadline);
      const momentTime = momentDate.utcOffset(momentDate.utcOffset()).format('HH:mm');
      delayTicketingTime = FromTwoDigitStringValue(momentTime);
    }
    
    return this.timeModel ? this.timeModel : delayTicketingTime;
  }

  set deadlineTime(value) {
    this.timeModel = value;
  }

  get maxDeadlineTime() {
    let maxDelayTicketingTime: number | null = null;

    if (this.delayTicketingDeadline) {
      const momentDate = moment(this.delayTicketingDeadline.maxDeadline);
      maxDelayTicketingTime = FromTwoDigitStringValue(momentDate.utcOffset(momentDate.utcOffset()).format('HH:mm'));
    }
    
    return maxDelayTicketingTime;
  }

  get minDeadlineTime() {
    let minDelayTicketingTime: number | null = null;

    if (this.delayTicketingDeadline) {
      const momentDate = moment(this.delayTicketingDeadline.deadline);
      const momentTime = momentDate.utcOffset(momentDate.utcOffset()).format('HH:mm');
      minDelayTicketingTime = FromTwoDigitStringValue(momentTime);
    }
    
    return minDelayTicketingTime;
  }

  get minDeadlineDate() {
    if (!this.delayTicketingDeadline) {
      return null;
    }
    return new Date(this.delayTicketingDeadline.deadline);
  }

  get maxDeadlineDate() {
    if (!this.delayTicketingDeadline) {
      return null;
    }
    return new Date(this.delayTicketingDeadline.maxDeadline);
  }

  setDelayTicketingDate() {
    if (this.deadlineDate && this.deadlineTime) {
      const date = moment(this.deadlineDate).format('YYYY-MM-DD');
      const time = ToTwoDigitStringValue(this.deadlineTime / 60) + ':' + ToTwoDigitStringValue(this.deadlineTime % 60) + ':00';
      const dateTimeString = `${date} ${time}`;
      const momentObject = moment(dateTimeString, 'YYYY-MM-DD HH:mm');

      return {
        deadline: momentObject.format(),
      };
    }
  }

  onDateInvalid(value) {
    BasketStore.setDelayTicketingDeadlineError(value);
  }

  prepareRequest() {
    let deadline = this.setDelayTicketingDate();
    BasketStore.setDelayTicketingDeadlineSave(deadline);
  }

  created() {
    if (this.delayTicketingDeadline) {
      let request = {
        deadline: this.delayTicketingDeadline.deadline,
      };
      BasketStore.setDelayTicketingDeadlineSave(request);
    }
  }

  @Watch('deadlineTime', {deep: true})
  setDeadlineTime(val) {
    if (val) {
      this.$nextTick(() => {
        this.prepareRequest();
      });
    }
  }
}

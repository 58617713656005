
























































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import SearchChooseService from '@/modules/search/SearchChooseService.vue';
import SearchTravellersList from '@/modules/search/SearchTravellersList.vue';
import BasketStore from '@/modules/basket/basket.store';
import AccountStore from '@/store/account.store';
import AirSearchStore from '@/modules/search/air/air-search.store';
import HotelSearchStore from '@/modules/search/hotel/hotel-search.store';
import TrainSearchStore from '@/modules/search/train/train-search.store';
import CarSearchStore from '@/modules/search/car/car-search.store';
import AirCompareLocalStore from '@/modules/search/air/air-compare.local-store';
import { router } from '@/router';
import SearchStore from './search.store';
import { SearchInitModes } from '@/api/profile/profile.model';
import { serviceClassEnum, searchModes } from '@/api/home/home.model';
import { TravellersStateParams } from './travellers.params';
import EventBus from '@/services/event-handler';
import hasAccessFn from '@/core/permissions/has-access.service';
import moment from 'moment';
import settings from '@/settings';
import { DEFAULT_DURATION_HALF } from '@/modules/layout/layout.const';
import _ from 'lodash';
import { TErrorDetails } from '@/types/error.model';
import SearchValidationErrorPopup from '@/modules/search/SearchValidationErrorPopup.vue';

@Component({
  components: {
    SearchChooseService,
    SearchTravellersList,
    SearchValidationErrorPopup,
  }
})
export default class Search extends Vue {
  @Prop() isInBasket!: boolean;
  activeNav: boolean = false;
  skipTravellersModel: boolean = this.skipTravellers || false;
  isHotelGuestsInvalid: boolean = false;
  searchClicked: boolean = false;
  invalidState = {
    Flight: false,
    Train: false,
    Hotel: false,
    Car: false,
  };

  
  get airSearchParameters() {
    return SearchStore.getAirDefaultState;
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get editedTravellers() {
    return SearchStore.editedTravellers;
  }

  get skipTravellersModelSwitch() {
    return !this.skipTravellersModel;
  }

  get basketMetadata() {
    return BasketStore.basketMetadata ? BasketStore.basketMetadata.metadata : null;
  }

  set skipTravellersModelSwitch (value) {
    this.skipTravellersModel = !value;
    AccountStore.setChooseTravellerMode(true);
  }

  get airSearchModifyMode() {
    return AirSearchStore.modifyMode;
  }

  get canShowSkipTravellers() {
    return 'true' === settings.enableExternalSkipTravellers;
  }

  get canShow() {
    return this.$route.name !== 'search';
  }

  get currentService() {
    return SearchStore.serviceNavigating;
  }

  get searchInProgress() {
    return AirSearchStore.searchInProgress;
  }

  get defaultSearchMode() {
    if (!AccountStore.current) {
      return SearchInitModes.EnterNames;
    }
    return AccountStore.current.profile.defaultSearchMode;
  }

  get mainButtonsClasses() {
    return {
      'disabled pending': this.searchInProgress,
      'disabled': !this.canUseSearch(this.selectedService),
    };
  }

  get areGuestRoomsInvalid() {
    return this.isHotelGuestsInvalid &&
      SearchStore.serviceNavigating === serviceClassEnum.Hotel;
  }

  get isSearchButtonDisabled() {
    return this.searchInProgress ||
      !this.canUseSearch(this.selectedService) ||
      this.currentServiceHasInvalidDate ||
      this.areGuestRoomsInvalid;
  }

  get currentServiceHasInvalidDate() {
    return this.invalidState[this.selectedService as string];
  }

  get showHideCriteriaButton() {
    return this.$route.name !== 'basket' && this.$route.name !== 'home';
  }

  get selectedService() {
    return SearchStore.serviceNavigating;
  }

  get isSearchView() {
    return this.$route.name === 'search';
  }

  get user() {
    return AccountStore.current;
  }

  get skipTravellers() {
    return SearchStore.skipTravellers;
  }

  get isInExistingBasket() {
    return !!SearchStore.basketId || this.$router.currentRoute.name === 'basketAddSegment';
  }

  get chooseTravellerMode() {
    return AccountStore.chooseTravellerMode;
  }

  get travellerExist() {
    return this.travellersSearchList && this.travellersSearchList.travellers && this.travellersSearchList.travellers.length;
  }

  get localStore() {
    return AirCompareLocalStore.localStore;
  }

  get templateOffers() {
    if (this.localStore && this.localStore.length) {
      return this.localStore;
    } else {
      return AirSearchStore.templateOffers;
    }
  }

  setTraveller(user) {
    if (!user) {
      return {
        travellers: [],
      };
    }

    return {
      travellers: [
        {
          businessUnitId: user.companyId,
          businessUnitName: user.companyName,
          companyId: user.rootCompanyId,
          companyName: user.rootCompanyName,
          dateOfBirth: user.dateOfBirth ? moment(user.dateOfBirth).format('YYYY-MM-DD') : null,
          firstName: user.firstName,
          id: user.id,
          isMainTraveller: true,
          middleName: user.middleName || '',
          lastName: user.lastName
        }
      ],
    };
  }

  goToTravellers() {
    setTimeout(() => {
      SearchStore.updateSkipTravellers(false);
    }, DEFAULT_DURATION_HALF);
    SearchStore.updateBackFlag(false);
    AccountStore.setChooseTravellerMode(true);
    router.push('/');
  }

  focusSearchButton() {
    const inputValue = ((this.$refs.searchButton as Vue).$el as HTMLInputElement);
    inputValue.focus();
  }

  clickSearchButton() {
    if (this.$refs.searchButton) {
      const buttonElement = (this.$refs.searchButton as Vue).$el as HTMLInputElement;
      if (buttonElement) {
        buttonElement.focus();
      }
    }
  }

  isDateValid(date) {
    let timestamp = Date.parse(date);
    return isNaN(timestamp) ? false : true;
  }

  canUseFlightSearch(travellerResult) {
    if (!travellerResult) {
      return false;
    }
    if (SearchStore.airSearchParameters.searchMode === searchModes.roundTrip) {
      const result =
        this.isDateValid(SearchStore.airSearchParameters.departureDate) &&
        this.isDateValid(SearchStore.airSearchParameters.returnDate) &&
        SearchStore.airSearchParameters.from &&
        SearchStore.airSearchParameters.to;
      return !!result;
    } else if (SearchStore.airSearchParameters.searchMode === searchModes.oneWay) {
      const result =
        this.isDateValid(SearchStore.airSearchParameters.departureDate) &&
        SearchStore.airSearchParameters.from &&
        SearchStore.airSearchParameters.to;
      return !!result;
    } else if (SearchStore.airSearchParameters.searchMode === searchModes.multiLeg) {
      if (SearchStore.airSearchParameters.legs && SearchStore.airSearchParameters.legs.length) {
        const wrongLeg = SearchStore.airSearchParameters.legs.find(leg => {
          return !this.isDateValid(leg.departureDate) || !leg.from || !leg.to;
        });

        return !wrongLeg;
      }
      return false;
    }
    return false;
  }

  canUseTrainSearch(travellerResult) {
    if (SearchStore.trainSearchParameters.searchMode === searchModes.roundTrip) {
      const result =
        travellerResult &&
        this.isDateValid(SearchStore.trainSearchParameters.departureDate) &&
        this.isDateValid(SearchStore.trainSearchParameters.returnDate) &&
        SearchStore.trainSearchParameters.from &&
        SearchStore.trainSearchParameters.to;
      return !!result;
    } else if (SearchStore.trainSearchParameters.searchMode === searchModes.oneWay) {
      const result =
        travellerResult &&
        this.isDateValid(SearchStore.trainSearchParameters.departureDate) &&
        SearchStore.trainSearchParameters.from &&
        SearchStore.trainSearchParameters.to;
      return !!result;
    }
    return false;
  }

  canUseHotelSearch(travellerResult) {
    const result =
      travellerResult &&
      this.isDateValid(SearchStore.hotelSearchParameters.checkInDate) &&
      this.isDateValid(SearchStore.hotelSearchParameters.checkOutDate) &&
      SearchStore.hotelSearchParameters.to;
    return !!result;
  }

  canUseCarSearch(travellerResult) {
    const dateResult =
      this.isDateValid(SearchStore.carSearchParameters.pickupDate) &&
      this.isDateValid(SearchStore.carSearchParameters.returnDate);
    const destinationResult = 
      SearchStore.carSearchParameters.differentReturnLocation ?
      SearchStore.carSearchParameters.pickupLocation && SearchStore.carSearchParameters.returnLocation :
      !!SearchStore.carSearchParameters.pickupLocation;
    const arrivalBeforeDeparture =
      SearchStore.carSearchParameters.pickupDate === SearchStore.carSearchParameters.returnDate ?
      SearchStore.carSearchParameters.departureTime! > SearchStore.carSearchParameters.returnTime! :
      false;
    return !!(dateResult && destinationResult && !arrivalBeforeDeparture && travellerResult);
  }

  canUseSearch(service: string) {
    let travellerResult = this.editedTravellers.travellers.length;
    if (this.$route.name === 'basketAddSegment') {
      travellerResult = this.travellersSearchList.travellers.length;
    }
    switch (service) {
      case serviceClassEnum.Flight:
        return this.canUseFlightSearch(travellerResult);
      case serviceClassEnum.Train:
        return this.canUseTrainSearch(travellerResult);
      case serviceClassEnum.Hotel:
        return this.canUseHotelSearch(travellerResult);
      case serviceClassEnum.Car:
        return this.canUseCarSearch(travellerResult);
      default:
        return false;
    }
  }

  checkTravellersForCompareOffers() {
    if (
      this.templateOffers && this.templateOffers.length && this.travellersSearchList &&
      this.travellersSearchList.travellers && this.travellersSearchList.travellers.length
    ) {
      let templateTrav = this.templateOffers.map(offer => offer.travellers).flat();
      let templateTravellersIds = _.uniqWith(templateTrav, (arrVal, othVal) => {
        return arrVal.companyId === othVal.companyId && arrVal.travId === othVal.travId;
      });
      let searchCompanyIds = this.travellersSearchList.travellers.map(trav => trav.companyId);
      let searchTravellersIds = this.travellersSearchList.travellers.map(trav => trav.id);
      let isEqulNumOfTrav = searchTravellersIds && templateTravellersIds && searchTravellersIds.length === templateTravellersIds.length;
      let difference = templateTravellersIds.filter(x => searchCompanyIds.includes(x.companyId) && searchTravellersIds.includes(x.travId));

      const templateSearchModeItem = this.templateOffers.find(item => {
        return item && item.searchMode;
      });
      const templateSearchMode = templateSearchModeItem ? templateSearchModeItem.searchMode : '';
      const diffSearchMode = this.airSearchParameters.searchMode !== templateSearchMode;
      if (diffSearchMode || (difference && difference.length === 0) || !isEqulNumOfTrav) {
        SearchStore.resetTemplateOffers();
        AirCompareLocalStore.getLocalStore();
      }
    }
  }

  async startSearch() {
    const searchChooseService = this.$refs.searchChooseService as SearchChooseService;
    if (searchChooseService) {
      searchChooseService.closeTravellersSelect();
    }
    EventBus.$emit('update-travellers');
    AirSearchStore.clearOfferExpired();
    AirSearchStore.resetOffersProposalsList();
    AirSearchStore.clearOpenOffers();
    AirSearchStore.setTemplateOffers([]);
    AirSearchStore.setProvidersErrors([]);
    AirSearchStore.setFiltersChanging(false);
    AirSearchStore.updateFilters([]);
    AirSearchStore.loader(true);
    TrainSearchStore.updateFilters([]);
    TrainSearchStore.setFiltersChanging(false);
    TrainSearchStore.setLoading(true);
    TrainSearchStore.setProvidersErrors([]);
    TrainSearchStore.setAvailableMoreActions([]);
    HotelSearchStore.setSearchFilters([]);
    HotelSearchStore.stopSearch();
    HotelSearchStore.setFiltersChanging(false);
    HotelSearchStore.setLoading(true);
    CarSearchStore.updateFilters([]);
    CarSearchStore.setFiltersChanging(false);
    CarSearchStore.loader(true);
    this.checkTravellersForCompareOffers();

    const basketId = !!this.basketMetadata && this.basketMetadata.basketId || undefined;
    const param = this.isInBasket && this.$route.params.id || basketId;

    this.searchClicked = true;
    try {
      if (SearchStore.serviceNavigating === serviceClassEnum.Flight) {
        if (this.airSearchModifyMode) {
          AirSearchStore.initExchangeAirSearch(AirSearchStore.modificationBasketId);
        } else {
          AirSearchStore.initAirSearch(param);
        }
      } else if (SearchStore.serviceNavigating === serviceClassEnum.Hotel) {
        HotelSearchStore.initHotelSearch(param);
      } else if (SearchStore.serviceNavigating === serviceClassEnum.Train) {
        await TrainSearchStore.initTrainSearch(param);
      } else if (SearchStore.serviceNavigating === serviceClassEnum.Car) {
        CarSearchStore.initCarSearch(param);
      }
    } catch (error) {
      if (error.response.status === 400 && error.response.data.error.code === 'VALIDATION_ERROR') {
        await this.validationError(error.response.data.error.details);
      }
      this.searchClicked = false;
    }
  }

  async validationError(details: TErrorDetails<{ TravellerIds: string[] }>[]) {
    const validationErrorPopup = this.$refs.validationErrorPopup as SearchValidationErrorPopup;
    const result = await validationErrorPopup.open({ details });
    if (result) {
      await this.startSearch();
    }
  }

  hideCriteria() {
    if (this.$route.name === 'airDetails') {
      router.push({
        name: 'air',
        params: router.currentRoute.params,
      });
    } else if (this.$route.name === 'hotelResultsSearch') {
      router.push({
        name: 'hotel',
        params: router.currentRoute.params,
      });
    } else if (this.$route.name === 'hotelDetailsSearch') {
      router.push({
        name: 'hotelDetails',
        params: router.currentRoute.params,
      });
    } else if (this.$route.name === 'trainDetails') {
      if (this.$route.query.leg) {
        router.push({
          name: 'train',
          params: router.currentRoute.params,
          query: { leg:  this.$route.query.leg}
        });
        
      } else {
        router.push({
          name: 'train',
          params: router.currentRoute.params,
        });
      }
    } else if (this.$route.name === 'carDetails') {
      router.push({
        name: 'car',
        params: router.currentRoute.params,
      });
    } 
  }

  onHotelGuestsInvalid(value) {
    this.isHotelGuestsInvalid = value;
  }

  onDateInvalid({value, service}) {
    this.invalidState[service] = value;
  }

  @Watch('$route.params', { deep: true, immediate: true })
  onChildChanged(val) {
    if (val && val.searchId) {
      return;
    }
    AirSearchStore.setSearchInProgress(false);

    const rules = [
      {
        condition: () => !hasAccessFn('SelectTravellersForSearch') &&
          hasAccessFn('SearchOffers') &&
          this.user && this.user.profile,
        action: () => {
          if (!this.user) {
            return;
          }
          SearchStore.updateTravellersDefaultState(this.setTraveller(this.user.profile));
        },
      },
      {
        condition: () => this.defaultSearchMode === SearchInitModes.EnterNames &&
          this.travellersSearchList &&
          this.travellersSearchList.travellers.length === 0 &&
          !this.skipTravellers &&
          -1 === [
            'basketAddSegment',
            'basket',
            'basketTravellers',
          ].indexOf(this.$route.name || ''),
        action: () => {
          router.push('/');
        },
      },
    ];

    const rule = rules.find(item => !!item.condition());

    if (!rule) {
      return;
    }

    rule.action();
  }

  @Watch('skipTravellersModel', { immediate: true })
  onSkipTravellers(val, oldVal) {
    if (oldVal && oldVal !== val) {
      SearchStore.updateSkipTravellers(this.skipTravellersModel);
      SearchStore.updateTravellersDefaultState(new TravellersStateParams({ travellers: [] }));
      this.goToTravellers();
    }
  }

  @Watch('$route.query', { deep: true, immediate: true })
  async onMissionId(val) {
    if (val && val.missionId) {
      const missionId = val.missionId;
      SearchStore.setMissionId(missionId);
      await SearchStore.getMissionDetails();
    }
  }

  beforeDestroy() {
    EventBus.$off('hotel-guests-invalid', this.onHotelGuestsInvalid);
    EventBus.$off('date-invalid', this.onDateInvalid);
    EventBus.$off('focus-on-search', this.clickSearchButton);
    this.activeNav = false;
  }

  created() {
    EventBus.$on('hotel-guests-invalid', this.onHotelGuestsInvalid);
    EventBus.$on('date-invalid', this.onDateInvalid);
    EventBus.$on('focus-on-search', this.clickSearchButton);
    setTimeout(() => {
        return this.activeNav = true;
      }, 10);

    if (
      this.travellersSearchList &&
      this.travellersSearchList.travellers.length === 0 &&
      (this.skipTravellers ||
      ((this.defaultSearchMode === SearchInitModes.SelectGuests
      || this.defaultSearchMode === SearchInitModes.SelectGuestsWithNoCompanySelection)
      && 'search' === this.$route.name))
    ) {
      this.skipTravellersModel = true;
      SearchStore.updateSkipTravellers(this.skipTravellersModel);
    }
  }
}
















































































































































import { Component, Prop } from 'vue-property-decorator';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { translate } from '@/i18n';
import SettingsStore from '@/modules/settings/settings.store';
import { FareMapping } from '@/api/air-engine/fare-mapping-configuration.model';
import FaresMappingStore from '@/modules/settings/fares-mapping/fare-mapping.store';
import AirFaresMappingStore from '@/modules/settings/fares-mapping/air-fare-mapping.store';

@Component({})
export default class FareMappingAirList extends BaseTable<FareMapping> {
  @Prop({}) readonly!: boolean;

  params: BaseTableParams = new BaseTableParams();
  sortBy: string = '';
  currentPage: number = 1;
  imagesConst: string = '/assets/img/loader/1.gif';
  selected: FareMapping | null = null;
  menuActive: boolean = false;
  removePopup: boolean = false;
  errorsOnPopup: any[] = [];
  fields = {
    supplier: {
      sortable: true,
      label: translate('settings-fares-mapping.offer-sources'),
      thClass: 'pl-4'
    },
    airlinesText: {
      sortable: true,
      label: translate('settings-fares-mapping.airlines'),
      thClass: 'pl-4'
    },
    fareBasisCode: {
      sortable: true,
      label: translate('settings-fares-mapping.fare-basis-codes'),
      thClass: 'pl-4'
    },
    label: {
      sortable: true,
      label: translate('settings-fares-mapping.display-label'),
      thClass: 'pl-4'
    },
    status: {
      sortable: false,
      label: '',
      formatter: value => {
        if (value === undefined) {
          return '';
        } else if (value !== '') {
          return translate('settings-travel-policy.draft');
        }
      },
    },
    actions: {
      sortable: false,
      label: ''
    }
  };
  maxSize: number = Number.MAX_SAFE_INTEGER;

  get loadingPage() {
    return FaresMappingStore.loading;
  }

  get activeTab() {
    return FaresMappingStore.selectedTab;
  }

  get entries() {
    return AirFaresMappingStore.fareMapping;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configuration() {
    return FaresMappingStore.currentConfiguration;
  }

  get configurationId() {
    return router.currentRoute.params.configurationId ? router.currentRoute.params.configurationId : undefined;
  }

  get count() {
    return this.entries ? this.entries.length : 0;
  }

  get errors() {
    return FaresMappingStore.errMessages;
  }

  customSorting(a, b, key) {
    if (typeof a[key] === 'number' && typeof b[key] === 'number') {
      if (a[key] < b[key]) {
        return -1;
      }
      return a[key] > b[key] ? 1 : 0;
    } else {
      let first = this.toStringMthod(a[key]);
      let second = this.toStringMthod(b[key]);
      if (first < second) {
        return 1;
      }
      return first > second ? -1 : 0;
    }
  }

  toStringMthod(value) {
    if (!value) {
      return '';
    } else if (value instanceof Object) {
      return Object.keys(value)
        .sort()
        .map(key => value[key].businessName.toString())
        .join(' ');
    }
    return String(value).toLowerCase();
  }

  toggleMenu(item) {
    if (!this.selected || this.selected.id !== item.id) {
      this.selected = item;
      this.menuActive = true;
    } else if (this.selected && this.selected.id === item.id) {
      this.menuActive = false;
      this.selected = null;
    } else {
      this.selected = item;
      this.menuActive = !this.menuActive;
    }
  }

  addRule() {
    AirFaresMappingStore.selectFareMapping({});
    if (this.configurationId) {
      router.push({
        name: 'fares-mapping-rule-air',
        params: {
          configurationId: this.configurationId,
        }
      });
    } else {
      router.push({
        name: 'fares-mapping-rule-air',
        params: {
          configurationId: 'new',
        }
      });
    }
  }

  editRule(data) {
    AirFaresMappingStore.selectFareMapping(data);
    if (this.configurationId) {
      router.push({
        name: 'fares-mapping-rule-air',
        params: {
          configurationId: this.configurationId,
        }
      });
    } else {
      router.push({
        name: 'fares-mapping-rule-air',
        params: {
          configurationId: 'new',
        }
      });
    }
  }

  onRemovePopupClose() {
    this.removePopup = false;
    this.selected = null;
    this.menuActive = false;
  }

  removeRule() {
    AirFaresMappingStore.removeFareMapping(this.selected);
    this.closeRemovePopup();
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.onRemovePopupClose();
  }
}




































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import { CurrencyApi } from '@/api/currency/currency.api';
import { PreferencesApi } from '@/api/profile/preferences.api';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';

@Component({})
export default class GeneralPreferencesPopup extends Vue {
  @Prop() preferences!: any;
  serverErrors: any[] = [];
  formPending: boolean = false;
  formError: boolean = false;
  currencyOptions: any[] = [];
  Form: any = {};

  updateFormData(data) {
    if (data) {
      this.Form = {
        currencyCode: this.currencyOptions.find(curr => curr.code === data.currencyCode),
      };
    }
  }

  @Emit()
  close() {
  }

  async save() {
    this.formPending = true;

    try {
      if (this.preferences) {
        let profileId = this.$route.params.id;

        let request = {
          currencyCode: this.Form.currencyCode ? this.Form.currencyCode!.code : null,
          flightPreferences: {
            ...this.preferences.flightPreferences,
            carriers: this.preferences.flightPreferences!.carriers.map((carrier: any) => carrier.code),
            departureAirports: this.preferences.flightPreferences!.departureAirports.map(departure => departure.code),
            arrivalAirports: this.preferences.flightPreferences!.arrivalAirports.map(arrival => arrival.code),
          },
          railPreferences: {
            ...this.preferences.railPreferences,
          },
          accommodationPreferences: {
            ...this.preferences.accommodationPreferences,
            roomTypes: this.preferences.accommodationPreferences!.roomTypes.map(room => room.code ? room.code : room),
          },
          carPreferences: {
            ...this.preferences.carPreferences
          },
        };
        await PreferencesApi.updatePreferences(profileId, request);
      }
      let obj = {
        type: 'success',
        title: translate('profile-personal.data-saved'),
        message: translate('profile-personal.general-saved')
      };
      EventHandler.$emit('show-toast', obj);
      EventHandler.$emit('reload-loyalty');
      EventHandler.$emit('profile-data-saved');
      this.close();
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }

    this.formPending = false;
  }

  async created() {
    this.formPending = true;
    this.formError = false;
    try {
      const response = await CurrencyApi.getCurrencies();
      this.currencyOptions = response.data;
      if (this.preferences && this.currencyOptions) {
        this.updateFormData(this.preferences);
      }
    } catch (error) {
      this.formError = true;
      this.serverErrors = this.$handleErrors(error, true);
    }
    this.formPending = false;
  }

}





























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class UiSeatsExtrasTile extends Vue {
  @Prop() isIncluded!: boolean;
  @Prop() icon!: string;
  @Prop() title!: string;
}






















































































import { Vue, Prop, Component, Emit } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required } from 'vuelidate/lib/validators';

import BasketStore from './basket.store';
import { RejectionReasonType } from '@/api/approval-workflow/approval-workflow.model';

const eventName = 'update:show';

@Component
export default class BasketRejectionReasonPopup extends Vue {
  @Prop() show!: boolean;

  reason: string = '';



  get reasonType() {
    return BasketStore.currentApprovalLevelRejectionReasonType;
  }

  get currentLevel() {
    return BasketStore.currentApprovalLevel;
  }

  get isFreeText() {
    return this.reasonType === RejectionReasonType.FreeText;
  }

  get isDictionary() {
    return this.reasonType === RejectionReasonType.Dictionary;
  }

  get dictionaryOptions() {
    if (!this.isDictionary || !this.currentLevel) {
      return [];
    }

    return this.currentLevel.rejectionReasonValues.map((item, index) => {
      return {
        name: item,
        value: item,
        index,
      };
    });
  }

  get reasonOption() {
    if (!this.reason) {
      return null;
    }

    return this.dictionaryOptions
      .find(item => item.value === this.reason) || null;
  }

  set reasonOption(value) {
    this.reason = value.value;
  }



  @Validation()
  validationObject() {
    return {
      reason: {
        required,
      },
    };
  }

  @Emit(eventName)
  close() {
    return false;
  }

  async continueNow() {
    this.$v.$touch();

    if (this.$v.$error) {
      return;
    }

    BasketStore.setRejectionReason(this.reason);
    await BasketStore.rejectApproval();
    if (BasketStore.shouldShowUnconfirmedNotifications) {
      setTimeout(() => {
        BasketStore.setShowUnconfirmedNotificationsPopup(true);
      }, 200);
    }
    this.close(); 
  }
}


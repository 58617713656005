



































































































































































import { Vue, Component } from 'vue-property-decorator';
import SettingsStore from '@/modules/settings/settings.store';
import ExpensePolicyStore from './expense-policy.store';
import { ExpensePaymentsApi } from '@/api/expense/expense-payments.api';
import { PaymentInstrumentModel } from '@/api/expense/expense-payments.model';
import { router } from '@/router';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import consts from '@/const/table.const';

@Component({})
export default class ExpensePaymentIntrument extends Vue {
  $v;
  perPageOptions = consts.perPageOptions;
  perPage = consts.defaultPerPage;
  currentPage = 1;
  removePopup: boolean = false;
  serverErrors: any[] = [];
  payment = new PaymentInstrumentModel();

  @Validation()
  validationObject() {
    return {
      payment: {
        name: {
          required,
          maxLength: maxLength(64)
        },
        description: {
          maxLength: maxLength(1024)
        },
        code: {
          maxLength: maxLength(32)
        },
        isActive: {
        }
      },
    };
  }

  get expenseDetails() {
    return ExpensePolicyStore.expenseDetails;
  }

  get paymentInstrument() {
    return ExpensePolicyStore.paymentInstrument;
  }

  get paymentInstrumentId() {
    return router.currentRoute.params.paymentInstrumentId;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get expensePolicyId() {
    return router.currentRoute.params.itemId;
  }

  get configurationUrl() {
    return `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId !== undefined ? this.configurationId + '/configuration' : 'configuration'}`;
  }

  get configurationCategories() {
    return `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId}/payment-methods/${this.$route.params.itemId}`;
  }

  get loading() {
    return ExpensePolicyStore.loading;
  }

  get configuration() {
    return ExpensePolicyStore.configuration;
  }

  get errors() {
    return ExpensePolicyStore.errMessages;
  }

  get showError() {
    return ExpensePolicyStore.showError;
  }

  goToConfiguration() {
    let params: any = {
      id: this.$route.params.id,
      itemId: this.$route.params.itemId
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }

    router.push({
      name: 'payment-methods',
      params: {
        id: this.$route.params.id,
        ...params
      }
    });
  }

  remove() {
    ExpensePolicyStore.setShowError(false);
    ExpensePolicyStore.setErrMessages([]);
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.removePopup = false;
  }

  async saveDefinition() {
    ExpensePolicyStore.setShowError(false);
    ExpensePolicyStore.setErrMessages([]);
    this.$v.payment.$touch();
    if (this.$v.payment.$pending || this.$v.payment.$error) {
      return;
    }
    if (this.errors && this.errors.length === 0) {
      let request = {
        policyId: this.expensePolicyId,
        isActive: this.payment.isActive,
        name: this.payment.name,
        description: this.payment.description,
        code: this.payment.code,
      };

      const isEdit = !!this.payment.id;
      if (isEdit && this.payment.id) {
        const message = {
          paymentInstrumentId: this.payment.id,
          params: request,
        };
        await ExpensePolicyStore.updatePaymentInstrument(message);
      } else {
        await ExpensePolicyStore.createPaymentInstrument(request);
      }

      if (this.errors && this.errors.length === 0) {
        router.push({
          name: 'payment-methods',
          params: {
            id: this.$route.params.id,
            configurationId: this.configurationId,
            itemId: this.$route.params.itemId
          }
        });
      }
    }
  }

  async mapFormData() {
    if (this.paymentInstrumentId) {
      await ExpensePolicyStore.getPaymentInstrument(this.paymentInstrumentId);
      if (this.paymentInstrument) {
        this.payment = new PaymentInstrumentModel(this.paymentInstrument);
      }
    }
  }

  async removeDefinition() {
    try {
      await ExpensePaymentsApi.removePaymentInstrument(this.paymentInstrumentId);
      const obj = {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-removed'),
        message: translate('settings-gds.info-removed')
      };
      EventHandler.$emit('show-toast', obj);
      this.goToConfiguration();
    } catch (error) {
      ExpensePolicyStore.setErrMessages(this.$handleErrors(error, true));
      ExpensePolicyStore.setShowError(true);
    }
  }

  created() {
    ExpensePolicyStore.clearErrMessages();
    if (this.$route.params.configurationId === undefined) {
      router.push({
        name: 'payment-methods',
        params: {
          id: this.$route.params.id,
          itemId: this.$route.params.itemId,
          configurationId: this.configurationId,
        }
      });
    } else {
      ExpensePolicyStore.getExpensePolicyDetails(this.expensePolicyId);
      ExpensePolicyStore.getExpenseConfiguration({ companyId: this.$route.params.id, configurationId: this.$route.params.configurationId });
    }

    this.mapFormData();
  }
}

import { translate, translationExists } from '@/i18n';

export const getBundledSeatName = (bundledSeatName) => {
  if (bundledSeatName && translationExists(`bundled-seat-name.${bundledSeatName}`)) {
      return translate(`bundled-seat-name.${bundledSeatName}`);
  } else if (bundledSeatName) {
      return translate('search-air.seat-with-name-included', { seatName: bundledSeatName });
  } else {
      return translate('search-air.seat-included');
  }
};
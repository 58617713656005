

















































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import moment from 'moment';

import { router } from '@/router';
import LayoutStore from '@/modules/layout/layout.store';
import AirSearchStore from '@/modules/search/air/air-search.store';
import BasketStore from './basket.store';
import SearchStore from '@/modules/search/search.store';
import EventBus from '@/services/event-handler';
import { AirSearchStateParams } from '@/modules/search/air/air-search.params';
import BasketProcessingView from './BasketProcessingView.vue';
import BasketItemsView from './BasketItemsView.vue';
import BasketBookView from './BasketBookView.vue';
import BasketPopups from './BasketPopups.vue';
import BasketCustomFieldView from './BasketCustomFieldView.vue';
import BasketExtrasView from './BasketExtrasView.vue';
import BasketEnterTravellers from './BasketEnterTravellers.vue';
import BasketCheckTravellerData from './BasketCheckTravellerData.vue';
import BasketCustomFieldsView from './BasketCustomFieldsView.vue';
import BasketPaymentMethodsView from './BasketPaymentMethodsView.vue';
import BasketLoyaltyProgramsView from './BasketLoyaltyProgramsView.vue';
import BasketRailDiscountCodesView from './BasketRailDiscountCodesView.vue';
import BasketAvailableResponsesView from './BasketAvailableResponsesView.vue';
import AssignTravellersToRoomsView from './accommodation/AssignTravellersToRoomsView.vue';
import BasketModifyTravellersDetailsView from './BasketModifyTravellersDetailsView.vue';
import BasketReviewTravelPolicyCompliance from './BasketReviewTravelPolicyCompliance.vue';
import BasketSelectApproverView from './BasketSelectApproverView.vue';
import BasketPrice from './BasketPrice.vue';
import BasketBookingError from './BasketBookingError.vue';
import AccountStore from '@/store/account.store';
import BasketAirSeatsExtrasView from '@/modules/basket/basket-air-seats-extras/BasketAirSeatsExtrasView.vue';
import BasketRailSeatsExtrasView from '@/modules/basket/basket-rail-seats-extras/BasketRailSeatsExtrasView.vue';

@Component({
  components: {
    BasketAirSeatsExtrasView,
    BasketPrice,
    BasketProcessingView,
    BasketItemsView,
    BasketBookView,
    BasketPopups,
    BasketCustomFieldView,
    BasketExtrasView,
    BasketEnterTravellers,
    BasketCheckTravellerData,
    BasketCustomFieldsView,
    BasketPaymentMethodsView,
    BasketLoyaltyProgramsView,
    BasketRailDiscountCodesView,
    AssignTravellersToRoomsView,
    BasketAvailableResponsesView,
    BasketModifyTravellersDetailsView,
    BasketReviewTravelPolicyCompliance,
    BasketSelectApproverView,
    BasketBookingError,
    BasketRailSeatsExtrasView,
  },
})
export default class Basket extends Vue {
  basketExpired: boolean = router.currentRoute.name === 'basket-expired';
  currentDateTime: any = moment();
  dateTimeInterval: number = -1;
  scrollingToError: boolean = false;
  scrollingToErrorTimeout: any = null;

  get errors() {
    return BasketStore.errors;
  }

  get processingErrors() {
    return BasketStore.processingErrors;
  }

  get shouldShowProcessingView() {
    return (this.processing || this.showRetrySection);
  }

  get exchangeError() {
    return AirSearchStore.errMessages;
  }

  get noErrors() {
    return !this.errors.length && !this.exchangeError.length;
  }

  get showExchangeError() {
    return AirSearchStore.showError;
  }

  get loading() {
    return BasketStore.loading;
  }

  get processing() {
    return BasketStore.processing;
  }

  get rejectionReason() {
    if (!BasketStore.basket) {
      return null;
    }

    return BasketStore.basket.rejectionReason;
  }

  get hasRejectionReason() {
    return !!this.rejectionReason;
  }

  get showRetrySection() {
    return BasketStore.showRetrySection;
  }

  get showing3DSInterruptedPopup() {
    return BasketStore.showing3DSInterruptedPopup;
  }

  get shouldShowBasketItems() {
    return BasketStore.workflowError || 
      (BasketStore.shouldShowBasketItems && !this.shouldShowProcessingView &&
      !BasketStore.preventShowingItems &&
      !this.shouldShowBasketAvailableResponsesView &&
      !this.showing3DSInterruptedPopup);
  }

  get shouldShowBasketBookView() {
    return BasketStore.shouldShowBasketBook;
  }

  get shouldShowBasketCustomFieldView() {
    return BasketStore.shouldShowBasketCustomField;
  }

  get shouldShowBasketCarSabreExtrasView() {
    return BasketStore.shouldShowBasketCarSabreExtrasView;
  }

  get shouldShowBasketCustomFieldsView() {
    return BasketStore.shouldShowBasketCustomFieldsView;
  }

  get shouldShowBasketLoyaltyProgramsView() {
    return BasketStore.shouldShowBasketLoyaltyProgramsView;
  }

  get shouldShowBasketAirSeatsAndExtras() {
    return BasketStore.shouldShowBasketAirSeatsAndExtras;
  }

  get shouldShowBasketEnterTravellersView() {
    return BasketStore.shouldShowBasketEnterTravellers;
  }

  get shouldShowBasketCheckTravellerDataView() {
    return BasketStore.shouldShowBasketCheckTravellerData;
  }

  get shouldShowBasketPaymentMethodsView() {
    return BasketStore.shouldShowBasketPaymentMethods;
  }

  get shouldShowBasketRailNtvSeatSelectionView() {
    return BasketStore.shouldShowBasketRailNtvSeatSelection;
  }

  get shouldShowBasketRailSncfSeatsAncillariesSelection() {
    return BasketStore.shouldShowBasketRailSncfSeatsAncillariesSelection;
  }

  get shouldShowBasketRailBenerailSeatSelectionView() {
    return BasketStore.shouldShowBasketRailBenerailSeatSelection;
  }
  
  get shouldShowAssignTravellersToRooms() {
    return BasketStore.shouldShowAssignTravellersToRooms;
  }

  get shouldShowBasketTrenitaliaSeatSelectionView() {
    return BasketStore.shouldShowBasketRailTrenitaliaSeatSelection;
  }

  get shouldShowSelectApproverView() {
    return BasketStore.shouldShowSelectApproverView;
  }

  get shouldShowBasketAvailableResponsesView() {
    return BasketStore.isAvailableResponsesSections && !this.shouldShowProcessingView;
  }

  get shouldShowReviewTravelPolicyCompliance() {
    return BasketStore.shouldShowReviewTravelPolicyCompliance;
  }

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get isInWizard() {
    return BasketStore.isInWizard;
  }

  get pendingActions() {
    return BasketStore.status && BasketStore.status.pendingActions || [];
  }

  get canUserBookTrip() {
    return AccountStore.HasPermission('BookTrip');
  }

  get hasHoldActions() {
    return !this.isInWizard && this.pendingActions.find(obj => obj.code === 'HOLD') !== undefined;
  }

  get hasDesynchronizedBooking() {
    return BasketStore.hasDesynchronizedBooking;
  }

  get allBookingsConfirmed() {
    return BasketStore.allBookingsConfirmed;
  }

  get basketHoldDeadline() {
    return BasketStore.basketHoldDeadline;
  }

  get isDelayedTicketingEnabled() {
    return BasketStore.isDelayedTicketingEnabled;
  }

  get basketDelayedTicketingDeadline() {
    return BasketStore.basketDelayedTicketingDeadline;
  }

  get isBasketDelayedTicketingDeadlineIsInPast() {
    return BasketStore.isBasketDelayedTicketingDeadlineIsInPast;
  }

  get canApproveTrip() {
    return BasketStore.canApproveTrip;
  }

  get basketIsCancelled() {
    return BasketStore.basketIsCancelled;
  }

  get basketApprovalDeadline() {
    return BasketStore.basketApprovalDeadline;
  }

  get basketAutoApproval() {
    return BasketStore.willBasketBeApprovedAutomatically;
  }

  get shouldShowRailDiscountCodes() {
    return BasketStore.shouldShowRailDiscountCodes;
  }

  get hasApprovalWorkflowAction() {
    return !this.isInWizard && !this.basketIsCancelled &&
      this.pendingActions.find(obj => obj.code === 'APPROVAL_WORKFLOW_ACTION') !== undefined &&
      this.$hasAccess('ValidateTrips');
  }

  get lastSegmentName() {
    return BasketStore.lastSegmentName;
  }

  get basketStatus() {
    return BasketStore.status && BasketStore.status.status ? BasketStore.status.status : '';
  }

  get showRefreshButton() {
    return BasketStore.showRefreshButton;
  }

  get basketExpirationTime() {
    return BasketStore.basketExpirationTime;
  }

  get shouldShowBasketModifyTravellersDetailsView() {
    return BasketStore.shouldShowBasketModifyTravellersDetailsView;
  }

  get timeUntilBasketExpiration() {
    if (this.currentDateTime && this.basketExpirationDate) {
      let diffInSeconds: any = this.basketExpirationDate.diff(this.currentDateTime, 'seconds');
      let minutes: any = diffInSeconds / 60;
      let rminutes: any = Math.floor(minutes);
      if (rminutes < 0) {
        return -1;
      }
      if (rminutes < 10) {
        rminutes = '0' + rminutes;
      }
      let seconds: any = (minutes - rminutes) * 60;
      let rseconds: any = Math.round(seconds);
      if (rseconds < 10) {
        rseconds = '0' + rseconds;
      }
      
      return `${rminutes}:${rseconds}`;
    } else {
      return '';
    }
  }

  get basketExpirationDate() {
    return BasketStore.basketExpirationDate;
  }

  get isBasketExpired() {
    if (this.basketExpirationDate) {
      return this.currentDateTime.isAfter(this.basketExpirationDate);
    }
    return false;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get checkModifyTravellerDetails() {
    return BasketStore.checkModifyTravellerDetails;
  }

  set checkModifyTravellerDetails(value) {
    BasketStore.setCheckModifyTravellerDetails(value);
  }

  get shouldShowModifyTravellerDetailsConfirm() {
    if (
      this.wizardSteps &&
      this.wizardSteps.length &&
      this.bookingStep > 0 &&
      this.wizardSteps[this.bookingStep - 1]
    ) {
      return this.wizardSteps[this.bookingStep - 1].code === 'MODIFY_TRAVELLERS_DETAILS' &&
        BasketStore.bookingStepTripItem &&
        BasketStore.bookingStepTripItem.supplier === 'Sabre';
    }
    return false;
  }

  get user() {
    return AccountStore.current;
  }

  get dateFormat() {
    if (this.user!.profile.displayLanguage === 'pl') {
      return 'D MMMM YYYY, HH:mm';
    }
    return 'Do MMMM YYYY, HH:mm';
  }

  get basketItemsMapped() {
    return BasketStore.basketItemsMapped;
  }


  dateTime(date) {
    return moment.parseZone(date).format(this.dateFormat) + ' (GMT ' + moment.parseZone(date).format('Z') + ')';
  }

  processBasket() {
    BasketStore.processBasket();
  }

  openRefundPopup({id, conditions}) {
    BasketStore.openRefundPopup({id, conditions});
  }

  scrollToTop() {
    this.$nextTick(() => {
      LayoutStore.scrollUp();
    });
  }

  findExchangeBasketItemId(id) {
    BasketStore.findExchangeBasketItemId(id);
  }

  refreshDateTime() {
    this.currentDateTime = moment();
  }

  scrollToMobileModifyTravellerConsent() {
    const consentElementVue = this.$refs.basketMobileModifyTravellerDetailsConsent as Vue;

    if (!consentElementVue) {
      return;
    }
    
    const consentElement = consentElementVue.$el as HTMLElement;

    LayoutStore.scrollManager
      .scrollTo(consentElement, false);

    this.scrollingToError = true;

    clearTimeout(this.scrollingToErrorTimeout);

    this.scrollingToErrorTimeout = setTimeout(() => {
      this.scrollingToError = false;
    }, 4000);
  }

  async mounted() {
    SearchStore.updateAirDefaultState(new AirSearchStateParams({}));
    SearchStore.updateAirCurrentState(new AirSearchStateParams({}));
    AirSearchStore.setCustomError([]);
    await BasketStore.initBasket(this.$route.params.id);
    BasketStore.setBasketItemFollowUpAction({
      name: this.$route.params.followUpActionName,
      tripItemId: this.$route.params.followUpActionTripItemId
    });
    EventBus.$on('refresh-basket-status', this.processBasket);
    EventBus.$on('open-refund-popup', this.openRefundPopup);
    EventBus.$on('new-basket-item-id', this.findExchangeBasketItemId);
    EventBus.$on('scroll-to-mobile-modify-travellers-consent', this.scrollToMobileModifyTravellerConsent);
    this.dateTimeInterval = setInterval(this.refreshDateTime, 1000);
  }

  beforeDestroy() {
    BasketStore.clearBasket();
    BasketStore.destroyRefreshBasket();
    EventBus.$off('refresh-basket-status', this.processBasket);
    EventBus.$off('open-refund-popup', this.openRefundPopup);
    EventBus.$off('new-basket-item-id', this.findExchangeBasketItemId);
    EventBus.$off('scroll-to-mobile-modify-travellers-consent', this.scrollToMobileModifyTravellerConsent);
    clearInterval(this.dateTimeInterval);
  }

  @Watch('bookingStep')
  onBookingStepChange() {
    EventBus.$emit('basket-item-hide-details');
  }

  @Watch('errors', {deep: true})
  onErrorShow() {
    this.scrollToTop();
  }

  @Watch('$route', {deep: true})
  onRouteChange(to, from) {
    if (to.name === from.name) {
      SearchStore.updateAirDefaultState(new AirSearchStateParams({}));
      SearchStore.updateAirCurrentState(new AirSearchStateParams({}));
      BasketStore.initBasket(this.$route.params.id);
    }
    if (to.name === 'basket-expired') {
      this.basketExpired = true;
    }
  }

}





























import { Vue, Prop, Component } from 'vue-property-decorator';

import BasketStore from './basket.store';

@Component
export default class BasketPaymentTerminatedPopup extends Vue {
  @Prop() show!: boolean;

  closePopup() {
    BasketStore.setShowPaymentTerminatedPopup(false);
  }
}


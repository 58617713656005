



























































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import {
  required,
  requiredIf,
  maxLength
} from 'vuelidate/lib/validators';
import ProviderConfigurationDetailSimple from './ProviderConfigurationDetailSimple.vue';
import EventHandler from '@/services/event-handler';
import { TrainProviderConfigurationSetApi } from '@/api/train-engine/train-provider-configuration.api';
import settingsStore from '../settings.store';
import { translate } from '@/i18n';
import { serviceClassEnum } from '@/api/home/home.model';
import { Permission } from '@/const/permission.enum';
import LayoutStore from '@/modules/layout/layout.store';

@Component({
  components: {
    ProviderConfigurationDetailSimple,
  }
})
export default class RailProviderConfigurationDetail extends Vue {
  inViewMode: boolean = true;

  Form: any = {
    name: '',
    providerConfigurations: []
  };
  imagesConst = '/assets/img/loader/1.gif';
  loading = true;
  saving = false;
  showError = false;
  customError: boolean = false;
  errMessage = '';
  serverErrors: any = [];
  saveErrors: { [key: string]: boolean } = {};
  $v;

  get warningProviders() {
    return Object.entries(this.saveErrors)
      .filter(([key, value]) => value)
      .map(([key]) => translate(`supplier.${key}`))
      .join(', ');
  }

  get configurationId() {
    return this.$route.params.configurationId;
  }

  get rootCompanyId() {
    return this.$route.params.id;
  }

  get inEditMode() {
    return !!this.configurationId;
  }

  get service() {
    return serviceClassEnum.Train;
  }

  @Validation()
  validationObject() {
    let Form: any = {
      name: {
        required,
        maxLength: maxLength(128)
      },
      providerConfigurations: {
        $each: {
          secretKey: {
            requiredIf: requiredIf((vm) => {
              return vm.isEnabled && (!vm.secretKey || vm.secretKey.length < 1);
            }),
            maxLength: (value, vm) => {
              return vm.isEnabled && vm.secretKey ? vm.secretKey.length <= 128 : true;
            }
          },
          configurationSettings: {
            $each: {
              settingValue: {
                requiredIf: requiredIf((vm) => {
                  if (vm.settingType === 'SingleSelect') {
                    return !vm.settingValue &&
                      this.Form.providerConfigurations[vm.parentIndex] &&
                      this.Form.providerConfigurations[vm.parentIndex].isEnabled;
                  }
                  return vm.settingValue && vm.settingValue.length < 1 &&
                    this.Form.providerConfigurations[vm.parentIndex] &&
                    this.Form.providerConfigurations[vm.parentIndex].isEnabled;
                }),
              }
            }
          }
        }
      }
    };
    
    return {
      Form
    };
  }

  async mounted() {
    try {
      this.inViewMode = !this.$hasAccess('WriteRailProviders');
      this.showError = false;
      this.customError = false;
      let response: any;
      if (this.inEditMode) {
        response = await TrainProviderConfigurationSetApi.getById(this.configurationId, Permission.ReadRailProviders);
      } else {
        const { data: providerConfigurations } = await TrainProviderConfigurationSetApi.getEmpty(Permission.ReadRailProviders);
        response = { data: { providerConfigurations } };
      }
      response.data.providerConfigurations.forEach((configuration, parentIndex) => {
        if (configuration.configurationSettings && configuration.configurationSettings.length) {
          configuration.configurationSettings.forEach(setting => {
            setting.parentIndex = parentIndex;
            if (this.inEditMode) {
              if (setting.settingType === 'Boolean') {
                setting.settingValue = setting.settingValue === 'true';
              }
            } else {
              if (setting.settingKey === 'TravellerAgeValidationType') {
                setting.settingValue = setting.settingValue || 'Warning';
              }
            }
          });
        }
      });
      this.Form.providerConfigurations = response.data.providerConfigurations;
      this.Form.name = response.data.name;
      this.Form.id = response.data.id;
      this.Form.rootCompanyId = this.inEditMode ? response.data.rootCompanyId : this.rootCompanyId;
    }
    catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.customError = true;
      } else {
        this.serverErrors = this.$handleErrors(error, true);
      }
      this.showError = true;
    } finally {
      this.loading = false;
    }
  }

  prepareForm() {
    for (let i = this.Form.providerConfigurations.length - 1; i >= 0; i--) {
      if (this.Form.providerConfigurations[i].hasErrors) {
        this.Form.providerConfigurations.splice(i, 1);
      }

      if (this.Form.providerConfigurations[i].supplier === 'Sncf') {
        this.Form.providerConfigurations[i].configurationSettings.forEach(setting => {
          if (setting.settingType === 'String' && setting.settingValue) {
            setting.settingValue = setting.settingValue.trim();
          }
        });
      }

    }
  }

  async submitForm() {
    this.$v.Form.$touch();
    this.saving = true;
    this.showError = false;

    if (this.$v.Form.$pending || this.$v.Form.$error) {
      this.saving = false;
      return;
    }
    try {
      this.errMessage = '';
      let response: any;

      this.prepareForm();

      if (this.inEditMode) {
        response = await TrainProviderConfigurationSetApi.update(this.$route.params.configurationId, this.Form, Permission.WriteRailProviders);
      } else {
        response = await TrainProviderConfigurationSetApi.create(this.Form, Permission.WriteRailProviders);
      }

      response.data.providerConfigurations.forEach(config => {
        if (config.hasErrors) {
          this.errMessage += ' ' + config.supplier + ',';
        }
      });

      if (this.errMessage === '') {
        settingsStore.resetRailProvidersError(response.data.id);
        this.success();
      } else {
        settingsStore.someErrorsInRailProvider(response.data.id);
        this.saveErrors = response.data.providerConfigurations.reduce((prev, next) => {
          return {
            ...prev,
            [next.supplier]: next.hasErrors,
          };
        }, {});
        this.$nextTick(() => {
          const warningRef = this.$refs.warningRef as HTMLElement;
          if (warningRef) {
            LayoutStore.scrollManager.scrollTo(warningRef, false);
          }
        });
        this.$router.push({
          name: 'rail-provider-configuration',
          params: {
            configurationId: response.data.id,
          },
        });
      }

    } catch (error) {
      this.showError = true;
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.saving = false;
    }
  }

  success() {
    EventHandler.$emit('show-toast', {
      type: translate('common.success'),
      title: translate('common.saved'),
      message: translate('settings-provider.provider-saved-rail'),
    });
    this.$router.push({
      name: 'rail-provider-configurations',
    });
  }

  reset() {
    this.$router.push({
      name: 'rail-provider-configurations',
    });
  }
}

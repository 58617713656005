















































































































































































































































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import moment from 'moment';
import _ from 'lodash';

import { userFullName } from '@/core/user-full-name';
import AccountStore from '@/store/account.store';
import { LanguageCode } from '@/api/dictionary/dictionary.model';
import { Traveller } from '@/api/air-engine/air-search.model';
import EventBus from '@/services/event-handler';
import SearchStore from '../search.store';
import AirSearchStore from './air-search.store';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { HomeApi } from '@/api/home/home.api';
import { translate } from '@/i18n';
import BasketStore from '@/modules/basket/basket.store';
import formatPrice from '@/filters/format-price.filter';
import SearchConst from '@/const/search.const';

@Component({
  filters: {
    momentDate(date) {
      return moment(date).format('ddd, Do MMM');
    }
  }
})
export default class AirExchangeModifySearchForm extends Vue {
  @Prop() offer!: any;
  @Prop() travellers!: Traveller[];
  @Prop() basketId!: string;
  @Prop({ default: () => { return []; } }) loadErrors!: any[];
  @Prop() status!: string;

  formErrors: any[] = [];
  exchangeFromLocation: any = [];
  exchangeFromLocations: any[] = [];
  exchangeToLocation: any = [];
  exchangeToLocations: any[] = [];
  exchangeDateRange: any[] = [];
  exchangeLocations: { from: any[], to: any[]} = { from: new Array(2), to: new Array(2) };
  refreshData: boolean = true;
  copyExchangeDateRange: any[] = [];
  diffDays: number = 1;
  selectedLeg: number | null = null;
  preferredCabinClass: string = 'Economy';


  get exchangeToLocationsOptions() {
    return this.exchangeToLocations.map(location => ({ ...location, uniqId: location.cityCode + location.airportCode }));
  }

  get exchangeFromLocationsOptions() {
    return this.exchangeFromLocations.map(location => ({ ...location, uniqId: location.cityCode + location.airportCode }));
  }

  get showError() {
    return AirSearchStore.showError;
  }

  get errMessages() {
    return AirSearchStore.initExchangeErrors;
  }

  get useRadioInsteadOfCheckbox() {
    return this.offer && this.offer.supplier === 'AirFranceKlm' &&
      !this.offer.passengers.find(p => !!p.ticketNumber);
  }

  get isInHeldStatusRebookingNotAllowed() {
    return this.offer && this.offer.supplier === 'AirFranceKlm' && this.status && this.status === 'Held' && 
      this.offer.legFlights.every(leg => leg.isExchanged === true);
  }

  get isInHeldStatusPartialRebookingNotAllowed() {
    return this.offer && this.offer.supplier === 'AirFranceKlm' && this.status && this.status === 'Held' && !this.isInHeldStatusRebookingNotAllowed &&
      this.offer.legFlights.some(leg => leg.isExchanged === true);
  }

  get modifiedItemAgencyFeeAmount() {
    let itemFound = BasketStore.basketItems.find(item => {
      return item.providerReferenceId === this.offer.id;
    });
    return itemFound && itemFound.price.agencyFee ? itemFound.price.agencyFee.amount : 0.0;
  }

  get flagClasses() {
    return {
      'flag__public': 'Public' === this.offer.proposal.fareType,
      'flag__nego': 'Nego' === this.offer.proposal.fareType,
      'flag__private': 'Private' === this.offer.proposal.fareType,
    };
  }

  get classesTooltip() {
    switch (this.offer.proposal.fareType) {
      case 'Public':
        return translate('search-air.public-fare');
      case 'Nego':
        return translate('search-air.nego-fare');
      case 'Private':
        return translate('search-air.private-fare');
      default:
        return translate('search-air.public-fare');
    }
  }

  get languageCode() {
    return AccountStore.current!.profile.displayLanguage.toUpperCase() as LanguageCode;
  }

  get loadingExchangePopup() {
    return BasketStore.loadingExchangePopup;
  }

  get modifiedItemTotalPrice() {
    let item = BasketStore.basketItems.find(item => {
      return item.providerReferenceId === this.offer.id;
    });
    return item && item.price && item.price.total ? item.price.total.amount : 0.0;
  }

  get airSearchParameters() {
    return SearchStore.getAirDefaultState;
  }

  get cabinClasses() {
    return SearchConst.cabinClasses;
  }

  get legCabinClasses() {
    return this.offer.legFlights.map(leg => leg.legCabinClass);
  }

  get isCabinClassDifferentInLegs() {
    return _.uniq(this.legCabinClasses).length > 1;
  }

  get chosenCabinClasses() {
    return this.cabinClasses
      .find(item => item.value.replace(/\s/g, '').toLowerCase() === this.preferredCabinClass.replace(/\s/g, '').toLowerCase());
  }

  set chosenCabinClasses(value) {
    if (
      !value ||
      !this.cabinClasses.find(item => item.value.replace(/\s/g, '').toLowerCase() === value.value.replace(/\s/g, '').toLowerCase())
    ) {
      this.preferredCabinClass = 'Economy';
      return;
    }

    this.preferredCabinClass = value.value;
  }

  get isCabinClassSelectionAvailable() {
    if (this.offer) {
      return (this.offer as any).supplier === 'AirFranceKlm';
    }

    return false;
  }



  userFullName(user) {
    return userFullName(user);
  }

  autocompleteClass(index) {
    return `leg-${index}-autocomplete`;
  }

  formatPrice(price) {
    return formatPrice(price);
  }

  nothingSelectedForEdit() {
    return this.offer && 0 === this.offer.legFlights.filter(leg => leg.selectedForEdit).length;
  }

  @Debounce({
    delay: DebounceConst.defaultDelay
  })
  async findExchangeFromLocation(query) {
    if (query && query.length > 2) {
      const response = await HomeApi.findAirLocation(query, this.languageCode);

      if (response && response.data) {
        this.exchangeFromLocations = response.data;
      }
    } else {
      this.exchangeFromLocations = [];
    }
  }

  selectFromLocation() {
    this.exchangeFromLocations = [];
    this.exchangeLocations.from = this.exchangeFromLocation;
  }

  translateLabel(value) {
    return translate(value.label);
  }

  @Debounce({
    delay: DebounceConst.defaultDelay
  })
  async findExchangeToLocation(query) {
    if (query && query.length > 2) {
      const response = await HomeApi.findAirLocation(query, this.languageCode);

      if (response && response.data) {
        this.exchangeToLocations = response.data;
      }
    } else {
      this.exchangeToLocations = [];
    }
  }

  async goToSearchModify(offer: any) {
    AirSearchStore.setShowError(false);
    this.refreshData = false;
    if (-1 < this.exchangeDateRange.findIndex((item, index) => {
      return null === item;
    })) {
      this.formErrors = [{
        message: this.$t('exchange.date-empty'),
      }];
      return;
    }

    if (this.nothingSelectedForEdit()) {
      return;
    }

    this.formErrors = [];

    let travs = this.travellers;
    EventBus.$emit('new-basket-item-id', offer.id);
    AirSearchStore.setExchangeLocations(this.exchangeLocations);
    AirSearchStore.setExchangeDate(this.exchangeDateRange);
    AirSearchStore.setExchangeTravellers(travs as Traveller[]);
    AirSearchStore.setExchangedOffer({
      ...offer,
    });
    AirSearchStore.setExchangeCabinClass(this.preferredCabinClass);
    AirSearchStore.setModifyState(true);
    await AirSearchStore.initExchangeAirSearch(this.basketId);
    if (this.errMessages.length) {
      return;
    }

    this.hideForm();
  }

  updateLegDataFromOffer(index) {
    const element = this.offer.legFlights[index];

    this.exchangeDateRange[index] = new Date(moment(element.flights[0].departure).format('YYYY-MM-DD'));
    this.exchangeFromLocation[index] = {
      airportName: element.flights[0].from.airportName,
      airportCode: element.flights[0].from.airportCode,
      cityName: element.flights[0].from.cityName,
      cityCode: element.flights[0].from.cityCode ? element.flights[0].from.cityCode : element.flights[0].from.city,
      countryCode: element.flights[0].from.countryCode ? element.flights[0].from.countryCode : element.flights[0].from.country,
      type: element.flights[0].from.type ? element.flights[0].from.type : 'Airport',
    };
    this.exchangeToLocation[index] = {
      airportName: element.flights[element.flights.length - 1].to.airportName,
      airportCode: element.flights[element.flights.length - 1].to.airportCode,
      cityName: element.flights[element.flights.length - 1].to.cityName,
      cityCode: element.flights[element.flights.length - 1].to.cityCode ? element.flights[element.flights.length - 1].to.cityCode : element.flights[element.flights.length - 1].to.city,
      countryCode: element.flights[element.flights.length - 1].to.countryCode ? element.flights[element.flights.length - 1].to.countryCode : element.flights[element.flights.length - 1].to.country,
      type: element.flights[element.flights.length - 1].to.type ? element.flights[element.flights.length - 1].to.type : 'Airport',
    };
  }

  legSelectionChanged(leg, index) {
    if (!leg.selectedForEdit) {
      this.updateLegDataFromOffer(index);
    } else if (index > 0 && (new Date(this.exchangeDateRange[index]).getTime() < new Date(this.exchangeDateRange[index - 1]).getTime())) {
      this.exchangeDateRange[index] = null;
    }
    this.$forceUpdate();
    this.onDateRangeChange(this.exchangeDateRange);
  }

  selectToLocation() {
    this.exchangeToLocations = [];
    this.exchangeLocations.to = this.exchangeToLocation;
  }

  isDateValid(index) {
    let departure = moment(this.offer.legFlights[index].flights[0].departure);
    let now = moment();
    if (departure.diff(now) < 0) {
      return false;
    }

    return true;
  }

  isLegRebookingNotAllowed(leg) { 
    return leg.isExchanged === true && this.offer.supplier === 'AirFranceKlm' && this.status && this.status === 'Held';
  }

  legMinDate(leg, index) {
    if (index === 0 || !leg.selectedForEdit) {
      if (new Date(leg.flights[0].departure).getTime() < new Date().getTime() && index === 0) {
        return new Date(moment(leg.flights[0].departure).add(1, 'days').format('YYYY-MM-DD'));
      } else {
        return new Date(moment().format('YYYY-MM-DD'));
      }
    }

    if (!this.offer.legFlights[index - 1].selectedForEdit) {
      return new Date(moment(this.offer.legFlights[index - 1].flights[0].departure).format('YYYY-MM-DD'));
    }

    return new Date(this.exchangeDateRange[index - 1]);
  }

  onExchangeDateChange(i) {
    const one_day = 1000 * 60 * 60 * 24;
    this.diffDays = Math.ceil((new Date(this.copyExchangeDateRange[i + 1]).getTime() - new Date(this.copyExchangeDateRange[i]).getTime() ) / one_day);

    this.copyExchangeDateRange = [...this.exchangeDateRange];
  }

  @Emit('hide')
  hideForm() {

  }

  @Watch('exchangeDateRange', { deep: true })
  onDateRangeChange(value) {
    for (let i = 0; i < value.length; i++) {

      if (null === value[i] || (i + 1 < value.length && null === value[i + 1])) {
        continue;
      }
      if (
        this.offer.legFlights[i].selectedForEdit &&
        i + 1 < value.length &&
        moment(value[i + 1]).isSameOrBefore(value[i], 'day')
      ) {
        value[i + 1] = new Date(moment(value[i]).add(this.diffDays, 'days').format('YYYY-MM-DD'));
      } else if (
        this.offer.legFlights[i].selectedForEdit &&
        i > 0 &&
        moment(value[i]).isBefore(value[i - 1], 'day')
      ) {
        value[i] = null;
      }
    }

    this.copyExchangeDateRange = [...this.exchangeDateRange];
  }

  @Watch('selectedLeg')
  onRadioLegSelectionChanged(value) {
    if (!this.useRadioInsteadOfCheckbox) {
      return;
    }
    this.offer.legFlights.forEach((leg, index) => {
      if (index === value) {
        leg.selectedForEdit = true;
      } else {
        leg.selectedForEdit = false;
        this.updateLegDataFromOffer(index);
      }
      this.onDateRangeChange(this.exchangeDateRange);
    });
  }

  prepareFromLocation(element) {
    return {
      airportName: element.flights[0].from.airportName,
      airportCode: element.flights[0].from.airportCode,
      cityName: element.flights[0].from.cityName,
      cityCode: element.flights[0].from.cityCode ? element.flights[0].from.cityCode : element.flights[0].from.city,
      countryCode: element.flights[0].from.countryCode ? element.flights[0].from.countryCode : element.flights[0].from.country,
      type: element.flights[0].from.type ? element.flights[0].from.type : 'Airport'
    };
  }

  prepareToLocation(element) {
    return {
      airportName: element.flights[element.flights.length - 1].to.airportName,
      airportCode: element.flights[element.flights.length - 1].to.airportCode,
      cityName: element.flights[element.flights.length - 1].to.cityName,
      cityCode: element.flights[element.flights.length - 1].to.cityCode ? element.flights[element.flights.length - 1].to.cityCode : element.flights[element.flights.length - 1].to.city,
      countryCode: element.flights[element.flights.length - 1].to.countryCode ? element.flights[element.flights.length - 1].to.countryCode : element.flights[element.flights.length - 1].to.country,
      type: element.flights[element.flights.length - 1].to.type ? element.flights[element.flights.length - 1].to.type : 'Airport',
    };
  }

  mapLegData(element, index) {
    const canBeSelected = !this.useRadioInsteadOfCheckbox || index === 0;

    element.selectedForEdit = canBeSelected && this.isDateValid(index);
    this.exchangeDateRange[index] = new Date(moment(element.flights[0].departure).format('YYYY-MM-DD'));
    this.exchangeFromLocation[index] = this.prepareFromLocation(element);
    this.exchangeToLocation[index] = this.prepareToLocation(element);
  }

  @Watch('offer', { immediate: true, deep: true })
  onOfferLoad(value) {
    if (value && this.refreshData) {
      this.offer.legFlights.forEach(this.mapLegData);
      this.exchangeLocations.from = this.exchangeFromLocation;
      this.exchangeLocations.to = this.exchangeToLocation;

      this.copyExchangeDateRange = [...this.exchangeDateRange];

      if (!this.isInHeldStatusRebookingNotAllowed && !this.isInHeldStatusPartialRebookingNotAllowed) {
        this.selectedLeg = 0;
      }

      if (
        !this.isCabinClassDifferentInLegs &&
        this.cabinClasses.find(item => item.value.replace(/\s/g, '').toLowerCase() ===  this.legCabinClasses[0].replace(/\s/g, '').toLowerCase())
      ) {
        this.preferredCabinClass = this.legCabinClasses[0].replaceAll(' ', '');
      }
    }
  }
}


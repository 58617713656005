


























































































































































































































































































































































































































import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import moment from 'moment';
import { translate } from '@/i18n';

import { AccommodationSearchApi } from '@/api/accommodation-engine/accommodation-search.api';
import formatDate from '@/filters/format-date.filter';
import formatTime from '@/filters/format-time.filter';

@Component({})
export default class HotelOfferConditions extends Vue {
  @Prop() provider!: string;
  @Prop() offerId!: string;
  @Prop() searchId!: string;

  conditions: any = {};
  errors: any[] = [];
  loaded: boolean = false;
  notAvailable: boolean = false;

  link1: string = 'http://developer.ean.com/terms/en/';
  link2: string = 'http://developer.ean.com/terms/agent/en/';
  link3: string = 'https://developer.ean.com/terms/en/net-rates';

  get firstColumnAmenities() {
    if (
      !this.conditions ||
      !this.conditions.roomInfo ||
      !this.conditions.roomInfo.amenities ||
      !this.conditions.roomInfo.amenities.length
    ) {
      return [];
    }
    const len = this.conditions.roomInfo.amenities.length;

    return this.conditions.roomInfo.amenities.slice(0, len / 2 + len % 2);
  }

  get hasNullRoomIndex() {
    return this.conditions.cancellationRules.some(rule => rule.roomIndex === null);
  }

  get isGekko() {
    return -1 < [
      'HCorpo',
      'Teldar',
      'WonderHotel',
    ].indexOf(this.provider);
  }

  get isGekkoRulesStyle() {
    return this.isGekko && !this.hasNullRoomIndex;
  }

  get cancellationRulesForTeldar() {
    if (!this.isGekko) {
      return [];
    }

    return this.conditions.cancellationRules
      .reduce((prev, cur) => {
        const prevItem = prev.find(item => item.room === cur.roomIndex);

        if (!prevItem) {
          return [...prev, {
            room: cur.roomIndex,
            rules: [cur],
          }];
        }

        return prev.map(item => {
          if (item.room === cur.roomIndex) {
            return {
              ...item,
              rules: [...item.rules, cur],
            };
          }

          return item;
        });
      }, [])
      .sort((a, b) => a.room - b.room);
  }

  get secondColumnAmenities() {
    if (
      !this.conditions ||
      !this.conditions.roomInfo ||
      !this.conditions.roomInfo.amenities ||
      !this.conditions.roomInfo.amenities.length
    ) {
      return [];
    }
    const len = this.conditions.roomInfo.amenities.length;

    return this.conditions.roomInfo.amenities.slice(len / 2 + len % 2);
  }

  dateFilter(date) {
    const dateToForm = new Date(date);

    return formatDate(dateToForm) + ', ' + formatTime(dateToForm) + ' (' + translate('hotel-offer-conditions.local-property-time') + ')';
  }

  objectKeysNotNull(object) {
    return object ? 
    Object.keys(object).some(key => {
      return object[key] !== null;
    }) :
    false;
  }

  @Emit('close')
  close() {}

  async mounted() {
    try {
      const response = await AccommodationSearchApi.getOfferConditions(this.provider, this.offerId, this.searchId);

      this.conditions = response.data;
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    } finally {
      this.loaded = true;
    }
  }
}


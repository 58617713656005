




































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import AirOfferParametersSelect from '../AirOfferParametersSelect.vue';
import { Legs } from '@/api/air-engine/air-search.model';
import moment from 'moment';
import SplitBookingParameter from '../SplitBookingParameter.vue';

const updateAirSearchParameters: string = 'update:airSearchParameters';

@Component({
  components: {
    AirOfferParametersSelect,
    SplitBookingParameter
  }
})
export default class SearchOption extends Vue {
  @Prop() airSearchParameters!: any;
  @Prop() searchModes!: any;

  disabled: boolean = false;

  add() {
    let date: any = moment();
    if (this.airSearchParameters.legs && this.airSearchParameters.legs.length > 0) {
      let prevDate = this.airSearchParameters.legs[this.airSearchParameters.legs.length - 1].departureDate;
      date = moment(prevDate)
      .add(1, 'day')
      .format('YYYY-MM-DD');
    }

    this.airSearchParameters.legs.push(new Legs({legNumber: this.airSearchParameters.legs.length, departureDate: date}));
    this[updateAirSearchParameters](this.airSearchParameters);
  }

  @Emit()
  [updateAirSearchParameters](value) {
    return value;
  }

  @Watch('airSearchParameters.legs', { immediate: true })
  onLegUpdate() {
    if (this.airSearchParameters.legs && this.airSearchParameters.legs.length > 5) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  }
}


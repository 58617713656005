






















































import { Prop, Vue, Component } from 'vue-property-decorator';
import { userFullName } from '@/core/user-full-name';
import { getComponentByCode } from '@/modules/basket/basket-rail-seats-extras/step';
import BasketExpirationCountdown from '@/modules/basket/BasketExpirationCountdown.vue';
import BasketItem from '@/modules/basket/BasketItem.vue';
import BasketStore from '@/modules/basket/basket.store';

@Component({
  components: {
    BasketItem,
    BasketExpirationCountdown,
  },
})
export default class BasketRailSeatsExtrasView extends Vue {
  @Prop() basketExpired!: boolean;

  userFullName = userFullName;
  getComponentByCode = getComponentByCode;

  get basket() {
    return BasketStore.basket;
  }

  get bookingStepDef() {
    return BasketStore.wizardSteps[BasketStore.bookingStep - 1];
  }

  get basketItem() {
    if (this.bookingStepDef) {
      return BasketStore.basketItemsMapped.find((item) => item.id === this.bookingStepDef.tripItemId);
    }
    return BasketStore.basketItemsMapped;
  }
}

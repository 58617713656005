import DisplayPreferencesCarManageRule from '@/modules/settings/display-preferences/configurations/configuration/DisplayPreferencesCarManageRule.vue';

export default {
  path: ':configurationId?/car-rule',
  name: 'display-preferences-car-rule',
  component: DisplayPreferencesCarManageRule,
  displayName: 'Car Rule',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditDisplayPreferences',
    parentName: 'display-preferences-configurations',
    depthCategory: 'display-preferences',
    waitForDicts: true,
    depth: 3,
  }
};





















































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { Validation } from 'vue-plugin-helper-decorator';
import { requiredIf } from 'vuelidate/lib/validators';

import AccountStore from '@/store/account.store';
import { HomeApi } from '@/api/home/home.api';
import { CurrencyApi } from '@/api/currency/currency.api';
import TravelPolicyFlightStore from '@/modules/settings/travel-policy/travel-policy-flight.store';
import TravelPolicyStore from '@/modules/settings/travel-policy/travel-policy.store';
import SettingsStore from '@/modules/settings/settings.store';
import DictionaryStore from '@/store/dictionary.store';
import { 
  AirLine,
  RoutingType,
  AirlineType,
  AirportLocationTypes,
  GeographicalAreaTypes,
  LanguageCode,
 } from '@/api/dictionary/dictionary.model';
import { router } from '@/router';
import EventBus from '@/services/event-handler';
import { CabinClass, ICabinClassOption } from '@/api/travel-policy/travel-policy.model';
import { translate } from '@/i18n';
import { DictionaryApi } from '@/api/dictionary/dictionary.api';
import { TravellerGroupOptions } from '@/const/profile-personal.const';
import { TravellerCategoriesApi } from '@/api/profile/traveller-categories.api';

const priceRegex = value => {
  return /^(\d+)(\.\d{1,2})?$/.test(value);
};

const zeroesRegex = value => {
  return /[^0]+/.test(value);
};

@Component({})
export default class TravelPolicyFlightManageRule extends Vue {
  isLoading: boolean = false;
  formPending: boolean = false;
  airLines: AirLine[] = [];
  form: any = {
    id: this.generateUUID(),
    priority: 1,
    maxPrice: null,
    priceCurrency: 'EUR',
    cabinClass: [],
    ruleAirline: [],
    ruleLegTimeHour: '',
    ruleLegTimeMin: '',
    isMaxPriceEnabled: false,
    isCabinClassEnabled: false,
    isAirlineEnabled: false,
    isLegTimeEnabled: false,
    status: '',
    operator: {
      code: '',
      name: '',
    },
    isRoutingEnabled: false,
    routing: [],
    isRoutingTypeEnabled: false,
    routingType: [],
    isAirlineTypeEnabled: false,
    airLineType: [],
    isDistributionTypeEnabled: false,
    distributionType: [],
    geographicalAreas: [],
    isTravellerCategoriesEnabled: false,
    travellerCategories: [],
  };
  isRoutingFromLoading: boolean = false;
  isRoutingToLoading: boolean = false;
  isGeoAreaFromLoading: boolean = false;
  isGeoAreaToLoading: boolean = false;
  fromLocationOptions: any[] = [];
  toLocationOptions: any[] = [];
  geoAreaLocationOptions: any[] = [];
  formHasError: boolean = false;
  dropdownClassFix: boolean = false;
  travellerCategoriesOptions: any[] = [];
  $v;
  LegTimeOperator = [
    {
      code: '<=',
      name: translate('settings-travel-policy.up-to')
    },
    {
      code: '>=',
      name: translate('settings-travel-policy.from')
    }
  ];
  imagesConst: string = '/assets/img/loader/1.gif';
  loading: boolean = false;
  currencyCodes: any[] = [];
  loadErrors: any[] = [];
  classOptions: ICabinClassOption[] = Object
    .values(CabinClass)
    .map((item: CabinClass) => ({
      label: translate(`settings.cabin-class-${item}`),
      name: item,
      value: item,
    }));
  routingTypeOptions = [
    {
      label: translate('settings-travel-policy.domestic'),
      name: 'Domestic',
      value: RoutingType.Domestic,
    },
    {
      label: translate('settings-travel-policy.international'),
      name: 'International',
      value: RoutingType.International,
    },
    {
      label: translate('settings-travel-policy.intercontinental'),
      name: 'Intercontinental',
      value: RoutingType.Intercontinental,
    }
  ];

  airlineTypeOptions = [
    {
      label: translate('settings-travel-policy.low-cost'),
      name: 'LowCost',
      value: AirlineType.LowCost
    },
    {
      label: translate('settings-travel-policy.regular-airline'),
      name: 'RegularAirline',
      value: AirlineType.Regular
    }
  ];

  get isReadOnly() {
    return this.$route.query.readOnly === 'true';
  }

  get allAirlines() {
    return (DictionaryStore.allAirLines || [])
      .map(airline => {
        return {
          ...airline,
          normalizedName: airline.businessName
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/\ł/g, 'l')
            .toLowerCase(),
        };
      });
  }

  get selectedRule() {
    return TravelPolicyFlightStore.currentSelectedRules;
  }

  get allRules() {
    return TravelPolicyFlightStore.getFlightRules;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get rootCompanyId() {
    if (!this.currentCompany) {
      return this.$route.params.id;
    }

    return this.currentCompany.rootCompanyId;
  }

  get currentConfigurationId() {
    return this.$route.params.configurationId;
  }

  get currentConfigName() {
    return TravelPolicyStore.currentConfigName;
  }

  get ruleExist() {
    if (this.selectedRule) {
      return Object.keys(this.selectedRule).length === 0 && this.selectedRule.constructor === Object;
    }
  }

  get geoAreasFilled() {
    return !!(this.form.geographicalAreas.length > 0 && this.form.geographicalAreas[this.form.geographicalAreas.length - 1].from && this.form.geographicalAreas[this.form.geographicalAreas.length - 1].to);
  }

  get routingsFilled() {
    if (!this.form.routings) {
      return false;
    }
    return !!(this.form.routings[this.form.routings.length - 1].from && this.form.routings[this.form.routings.length - 1].to);
  }

  get travellerGroupOptions() {
    return TravellerGroupOptions;
  }

  get languageCode() {
    return AccountStore.current!.profile.displayLanguage.toUpperCase() as LanguageCode;
  }



  mapCabinClass(cabinClass: CabinClass) {
    return translate(`settings.cabin-class-${cabinClass}`);
  }

  mapRoutingType(routingType: RoutingType) {
    const type = this.routingTypeOptions.find(e => {
      return typeof(routingType) === 'number' ? e.value === routingType : e.name === routingType;
    });
    return type ? type : null;
  }

  mapAirlineType(airlineType: AirlineType) {
    const type = this.airlineTypeOptions.find(e => {
      return typeof(airlineType) === 'number' ? e.value === airlineType : e.name === airlineType;
    });
    return type ? type : null;
  }

  mapGeographicalAreas(areas: any[]) {
    if (!areas || !areas.length) {
      return [
        {
          from: null,
          to: null,
        }
      ];
    } else {
      return areas;
    }
  }

  mapRoutings(routings: any[]) {
    if (!routings || !routings.length) {
      return [
        {
          from: null,
          to: null,
        }
      ];
    } else {
      return routings;
    }
  }

  mapGeographicalAreasForRequest(areas: any[] | null) {
    if (!areas) {
      return;
    }
    const areasMapped = areas.map(e => {
      return (e.from && e.to) ?
      { 
        From: {
          code: e.from.code,
          type: GeographicalAreaTypes[e.from.type]
        },
        to: {
          code: e.to.code,
          type: GeographicalAreaTypes[e.to.type]
        }
      } : null;
    });
    const areasFiltered = areasMapped.filter(Boolean);
    return areasFiltered.length ? areasFiltered : null;
  }

  mapRoutingsForRequest(routings: any[]) {
    const routingsMapped = routings.map(e => {
      return (e.from && e.to) ?
      {
        from: {
          code: e.from.type === 'CityAirport' ? (e.from.airportCode || e.from.code) : (e.from.cityCode || e.from.code),
          type: AirportLocationTypes[e.from.type]
        },
        to: {
          code: e.to.type === 'CityAirport' ? (e.to.airportCode || e.to.code) : (e.to.cityCode || e.to.code),
          type: AirportLocationTypes[e.to.type]
        }
      }
      : null;
    });
    const routingsFiltered = routingsMapped.filter(Boolean);
    return routingsFiltered.length ? routingsFiltered : null;
  }

  linkClicked(e) {
    e.preventDefault();
  }

  returnToConfiguration() {
    TravelPolicyStore.setIsFromManage(true);
    router.push({
      name: 'travel-policy-configuration',
      params: {
        configurationId: this.currentConfigurationId,
      },
    });
  }

  isRouteRuleValid(route) {
    return route && route.from && route.to;
  }

  get isLeastOneSelectedError() {
    return this.$v.form.$dirty && ![
      this.form.isMaxPriceEnabled,
      this.form.isCabinClassEnabled,
      this.form.isAirlineEnabled,
      this.form.isLegTimeEnabled,
      this.form.isRoutingEnabled,
      this.form.isGeoAreaEnabled,
      this.form.isRoutingTypeEnabled,
      this.form.isAirlineTypeEnabled,
      this.form.isDistributionTypeEnabled,
      this.form.isTravellerCategoriesEnabled,
    ].some((item) => item);
  }

  @Validation()
  validationObject() {
    return {
      form: {
        maxPrice: {
          required: requiredIf(() => {
            return this.form.isMaxPriceEnabled;
          }),
          priceRegex: (val) => {
            return this.form.isMaxPriceEnabled && val ? priceRegex(val) : true;
          },
          zeroesRegex: (val) => {
            return this.form.isMaxPriceEnabled && val ? zeroesRegex(val) : true;
          },
          maxValue: (val) => {
            return this.form.isMaxPriceEnabled && val ? Number(val) < 10000000 : true;
          },
        },
        cabinClass: {
          required: requiredIf(() => {
            return this.form.isCabinClassEnabled;
          }),
        },
        ruleAirline: {
          required: requiredIf(() => {
            return this.form.isAirlineEnabled;
          }),
        },
        operator: {
          code: {
            required: requiredIf(() => {
              return this.form.isLegTimeEnabled;
            }),
          },
        },
        ruleLegTimeHour: {
          required: requiredIf(() => {
            return this.form.isLegTimeEnabled;
          }),
        },
        routings: {
          $each: {
            from: {
              required: requiredIf(() => {
                return this.form.isRoutingEnabled;
              }),
            },
            to: {
              required: requiredIf(() => {
                return this.form.isRoutingEnabled;
              }),
            },
          },
        },
        geographicalAreas: {
          $each: {
            from: {
              required: requiredIf(() => {
                return this.form.isGeoAreaEnabled;
              }),
            },
            to: {
              required: requiredIf(() => {
                return this.form.isGeoAreaEnabled;
              }),
            },
          },
        },
        routingType: {
          required: requiredIf(() => {
            return this.form.isRoutingTypeEnabled;
          })
        },
        airlineType: {
          required: requiredIf(() => {
            return this.form.isAirlineTypeEnabled;
          }),
        },
        distributionType: {
          required: requiredIf(() => {
            return this.form.isDistributionTypeEnabled;
          }),
        },
        travellerCategories: {
          required: requiredIf(() => {
            return this.form.isTravellerCategoriesEnabled;
          }),
        },
      },
    };
  }

  colorStyles(option) {
    return {
      backgroundColor: option && option.color ? option.color : '',
    };
  }

  checkForm() {
    this.formHasError = false;
    this.$v.form.$touch();
    if (this.$v.form.$pending || this.$v.form.$error || this.isLeastOneSelectedError) {
      this.formHasError = true;
    }
  }

  generateUUID() {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  mapFormData(data?: any) {
    let hour;
    let minutes;
    if (data && data.ruleLegTime) {
      hour = Math.floor(data.ruleLegTime / 60);
      minutes = data.ruleLegTime % 60;
    }

    let ruleCabinClasses: any[] = [];
    let routingTypes = new Array();
    let airlineTypes = new Array();
    let distributionTypes = new Array();
    
    let routings = this.mapRoutings([]);
    let geographicalAreas = this.mapGeographicalAreas([]);


    if (data) {
      if (data.ruleCabinClasses) {
        data.ruleCabinClasses.forEach(element => {
          const cabinClassOption = this.classOptions.find(item => item.value === element);

          if (cabinClassOption) {
            ruleCabinClasses.push(cabinClassOption);
          }
        });
      }

      if (data.tripTypes) {
        data.tripTypes.forEach(element => {
          let type = this.mapRoutingType(element);
          routingTypes.push(type);
        });
      }

      if (data.airlineTypes) {
        data.airlineTypes.forEach(element => {
          let type = this.mapAirlineType(element);
          airlineTypes.push(type);
        });
      }

      if (data.routings && data.routings.length) {
        routings = this.mapRoutings(data.routings);
      }

      if (data.geographicalAreas && data.geographicalAreas.length) {
        geographicalAreas = this.mapGeographicalAreas(data.geographicalAreas);
      }
    }


    this.form = {
      id: data && data.id ? data.id : this.generateUUID(),
      priority: data && data.rulePriority ? data.rulePriority : 1,
      maxPrice: data && data.maxPrice ? data.maxPrice : null,
      priceCurrency: data && data.priceCurrency ? data.priceCurrency : 'EUR',
      cabinClass: [...ruleCabinClasses],
      ruleAirline: data && data.ruleAirlines ? data.ruleAirlines : [],
      operator: {
        code: data && data.ruleLegTimeOperator ? data.ruleLegTimeOperator : '',
        name: data && data.ruleLegTimeOperator ? (data.ruleLegTimeOperator === '<=' ? translate('settings-travel-policy.up-to') + ' ' : translate('settings-travel-policy.from') + ' ') : ''
      },
      ruleLegTimeHour: hour ? hour : '',
      ruleLegTimeMin: minutes ? minutes : '',
      isMaxPriceEnabled: !!(data && data.maxPrice && data.maxPrice !== ''),
      isCabinClassEnabled: !!(data && data.ruleCabinClasses && data.ruleCabinClasses.length),
      isAirlineEnabled: !!(data && data.ruleAirlines && data.ruleAirlines.length),
      isLegTimeEnabled: !!(data && data.ruleLegTimeOperator && data.ruleLegTimeOperator !== ''),
      status: data && data.status === '' ? TravelPolicyFlightStore.draftEditStatus : TravelPolicyFlightStore.draftNewStatus,
      isRoutingEnabled: !!(data && data.routings && data.routings.length),
      routings: routings,
      isRoutingTypeEnabled: !!(data && data.tripTypes && data.tripTypes.length),
      routingType: routingTypes,
      isAirlineTypeEnabled: !!(data && data.airlineTypes && data.airlineTypes.length),
      airlineType: airlineTypes,
      isDistributionTypeEnabled: !!(data && distributionTypes && distributionTypes.length),
      distributionType: distributionTypes,
      isGeoAreaEnabled: !!(data && data.geographicalAreas && data.geographicalAreas.length),
      geographicalAreas: geographicalAreas,
      isTravellerCategoriesEnabled: !!(data && data.travellerCategories && data.travellerCategories.length),
      travellerCategories: data && data.travellerCategories && data.travellerCategories.length ? data.travellerCategories.map(e => {
        let index = this.travellerCategoriesOptions.findIndex(elem => {
          return elem.value === e;
        });
        if (-1 < index) {
          return this.travellerCategoriesOptions[index];
        }
      }) : [],
    } as any;
  }

  createRequest() {
    let time;
    if (this.form && parseInt(this.form.ruleLegTimeHour) > 0) {
      if (parseInt(this.form.ruleLegTimeMin) > 0) {
        time = parseInt(this.form.ruleLegTimeHour) * 60 + parseInt(this.form.ruleLegTimeMin);
      } else {
        time = parseInt(this.form.ruleLegTimeHour) * 60;
      }
    } else {
      time = parseInt(this.form.ruleLegTimeMin);
    }

    let ruleCabinClasses = [];

    if (this.form.cabinClass) {
      ruleCabinClasses = this.form.cabinClass.map(element => {
        return element.value;
      });
    }

    let request = {
      id: this.form.id,
      rulePriority: this.form.priority,
      ruleLegTime: this.form.isLegTimeEnabled ? time + '' : null,
      ruleLegTimeOperator: this.form.isLegTimeEnabled ? this.form.operator.code : null,
      ruleAirlines: this.form.isAirlineEnabled ? this.form.ruleAirline : [],
      ruleCabinClasses: this.form.isCabinClassEnabled ? ruleCabinClasses : [],
      maxPrice: this.form.isMaxPriceEnabled ? this.form.maxPrice : null,
      priceCurrency: this.form.priceCurrency,
      status: TravelPolicyFlightStore.draftNewStatus,
      distributionChannelTypes: this.form.isDistributionTypeEnabled ? this.form.distributionType.map(e => { return e.value; }) : null,
      tripTypes: this.form.isRoutingTypeEnabled ? this.form.routingType.map(e => { return e.value; }) : null,
      airlineTypes: this.form.isAirlineTypeEnabled ? this.form.airlineType.map(e => { return e.value; }) : null,
      routings: this.form.isRoutingEnabled ? this.form.routings : null,
      geographicalAreas: this.form.isGeoAreaEnabled ? this.form.geographicalAreas : null,
      travellerCategories: this.form.isTravellerCategoriesEnabled ? this.form.travellerCategories.map(e => e.value) : []
    };

    TravelPolicyFlightStore.legTime(request);


    if (this.allRules && this.allRules.length) {
      let isEdit = this.allRules.filter((rule) => {
        return rule.id === request.id;
      });

      if (isEdit.length) {
        TravelPolicyFlightStore.editFlightRule(request);
      } else {
        TravelPolicyFlightStore.addFlightRule(request);
      }
    } else {
        TravelPolicyFlightStore.addFlightRule(request);
    }
  }

  confirmAndBack() {
    this.checkForm();
    if (!this.formHasError) {
      this.formPending = true;
      this.createRequest();
      this.returnToConfiguration();
    }
  }

  confirmAndAddNext() {
    this.checkForm();
    if (!this.formHasError) {
      this.formPending = true;
      this.createRequest();
      this.mapFormData();
      this.$v.form.$reset();
      this.formPending = false;

      let obj = {
        type: translate('settings-travel-policy.success'),
        title: '',
        message: translate('settings-travel-policy.rule-added')
      };
      EventBus.$emit('show-toast', obj);
    }
  }

  @Watch('selectedRule', {deep: true,  immediate: true})
  existSelectedRule() {
    if (!this.selectedRule) {
      router.go(-1);
    }
  }

  addRouting() {
    if (this.routingsFilled && this.form.isRoutingEnabled) {
      this.form.routings.push({ from: null, to: null });
    }
  }

  removeRouting(index) {
    this.form.routings.splice(index, 1);
  }

  addGeoArea() {
    if (this.geoAreasFilled && this.form.isGeoAreaEnabled) {
      this.form.geographicalAreas.push({ from: null, to: null });
    }
  }

  removeGeoArea(index) {
    this.form.geographicalAreas.splice(index, 1);
  }

  clearGeoAreas() {
    this.dropdownClassFix = false;
    this.geoAreaLocationOptions = [];
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isLoading',
  })

  async findAirLines(query: string) {
    if (query && query.length > 1) {
      this.isLoading = true;
      query = query.toLowerCase();

      if (this.allAirlines) {
        const response = this.allAirlines.filter(airline => {
          return airline.code.toLowerCase().includes(query) ||
            airline.businessName.toLowerCase().includes(query) ||
            airline.normalizedName.includes(query);
        });

        this.airLines = [];

        if (response) {
          response.forEach((element) => {
            this.airLines.push(element);
          });
        }
      }

      this.isLoading = false;
    } else {
      this.isLoading = false;
      this.airLines = [];
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isRoutingLoading',
  })
  async findRoutingFromLocation(query: string) {
    if (query && query.length > 2) {
      this.dropdownClassFix = true;
      this.isRoutingFromLoading = true;
      const response = await HomeApi.findAirLocation(query, this.languageCode);

      if (response && response.data) {
        this.fromLocationOptions = [];
        this.fromLocationOptions = response.data;
      }

      this.isRoutingFromLoading = false;
    } else {
      this.isRoutingFromLoading = false;
      this.fromLocationOptions = [];
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isRoutingLoading',
  })
  async findRoutingToLocation(query: string) {
    if (query && query.length > 2) {
      this.dropdownClassFix = true;
      this.isRoutingToLoading = true;
      const response = await HomeApi.findAirLocation(query, this.languageCode);

      if (response && response.data) {
        this.toLocationOptions = [];
        this.toLocationOptions = response.data;
      }

      this.isRoutingToLoading = false;
    } else {
      this.isRoutingToLoading = false;
      this.toLocationOptions = [];
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isGeoAreaFromLoading',
  })
  async findGeoAreaFrom(query: string) {
    if (query && query.length > 1) {
      this.dropdownClassFix = true;
      this.isGeoAreaFromLoading = true;
      const response = await DictionaryApi.getCountriesContinentsNames(query);

      if (response && response.data) {
        this.geoAreaLocationOptions = [];
        this.geoAreaLocationOptions = response.data;
      }

      this.isGeoAreaFromLoading = false;
    } else {
      this.isGeoAreaFromLoading = false;
      this.geoAreaLocationOptions = [];
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isGeoAreaToLoading',
  })
  async findGeoAreaTo(query: string) {
    if (query && query.length > 1) {
      this.dropdownClassFix = true;
      this.isGeoAreaToLoading = true;
      const response = await DictionaryApi.getCountriesContinentsNames(query);

      if (response && response.data) {
        this.geoAreaLocationOptions = [];
        this.geoAreaLocationOptions = response.data;
      }

      this.isGeoAreaToLoading = false;
    } else {
      this.isGeoAreaToLoading = false;
      this.geoAreaLocationOptions = [];
    }
  }

  async created() {
    try {
      const response = await TravellerCategoriesApi.getCompanyTravellerCategories(this.rootCompanyId);

      this.travellerCategoriesOptions = response.data.map(e => {
        return {
          label: e.name,
          value: e.name,
        };
      });
    } catch (error) {
      this.loadErrors = this.$handleErrors(error, true);
      return;
    }
    if (this.selectedRule) {
      let data = JSON.parse(JSON.stringify(this.selectedRule));
      this.mapFormData(data);
    } else {
      this.mapFormData();
    }
    this.loading = true;
    this.loadErrors = [];
    try {
      const response = await CurrencyApi.getCurrencies();
      this.currencyCodes = response.data.map(item => item.code);
    } catch (error) {
      this.loadErrors = this.$handleErrors(error, true);
    }
    this.loading = false;
  }
}
